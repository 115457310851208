
import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom'

import { Divider, Form, Label, Input, Dropdown, Button, Segment, Header } from 'semantic-ui-react'


import { postAPI, uploadImageAPI } from '../../apiServices';
import {
  CCard,
  CCardBody,
} from '@coreui/react';


import '../../assets/css/custom.css'
import { toast } from 'react-toastify';
const defaultState = {
  username: '',
  password: '',
  loading: false,
  errors: {
    username: <>&nbsp;</>,
    password: <>&nbsp;</>,
  }
};
const AttributeAdd = () => {
  const [state, setState] = useState(defaultState);
  const [category, setcategory] = useState([]);
  const [attributelist, setattributelist] = useState([]);
  const [prodcategory, setprodcategory] = useState(true);
  const [imagefilename, setimagefilename] = useState();
  const [termtype, setTermType] = useState("product-attribute");
  const [termtypeProduct, setTermTypeProduct] = useState(1);
  const [termname, setTermName] = useState();
  const [parent, setparent] = useState();
  const [message, setmessage] = useState();
  const [uploadImage, setuploadImage] = useState();
  let categoryarryay = [];
  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      loading: true,
    }));
    postAPI('/get-product-attributes', {
    }).then(res => {
      if (res.status == 1) {
        setcategory(res.payload.attributes)
        setprodcategory(true)
      }
    })
  }, []);
  const fileChangedHandler = (event) => {
    setuploadImage(event)
  }
  const createCategory = async () => {
    var payloadimg = '';
    var imagePath = '';
    var po = null;
    if (uploadImage != undefined) {
      payloadimg = await uploadImageAPI('/uploads', uploadImage)
      imagePath = payloadimg.url[0];
    }

    if (parent == undefined && termtype == "product-attribute-list") {
      toast.info("Please select parent attribute")
    }
    else {
      const po = {
        type: "product-attribute",// product-attribute-list or product-attribute
        termName: termname,
        leadImg: imagePath,
        status: 1,
        parent: parent,
      }
      const { status, payload, message } = await postAPI('/save-category-data', po, {
      });
      if (status == 1) {
        toast.success(message)
        //setmessage(message)
      }
      else {
        toast.error(message)
       /*  setmessage(message) */
      }
    };


  }




  const comboxSelected = async (event) => {

    setTermType(event.target.value)
  }
  const setParentValue = async (event) => {
    setparent(event)
  }


  /*   if (prodcategory) { */
  categoryarryay.push(<option >Select</option>)
  let optionItems = category.map((child) => {
    categoryarryay.push(<option key={child.id} value={child.id}>{child.name}</option>)

    /*      child.child.map((child1) => {
           categoryarryay.push(<option key={child1.id} value={child1.id}>--{child1.name}</option>)
   
           child1.child.map((child2) => {
             categoryarryay.push(<option key={child2.id} value={child2.id}>-----{child2.name}</option>)
   
             child2.child.map((child3) => {
               categoryarryay.push(<option key={child3.id} value={child3.id}>--------{child3.name}</option>)
   
             });
           });
         }); */
  });

  return (
    <CCard>
      <CCardBody>
        <div style={{
          alignItems: "center", display: "flex",
          justifyContent: "center",
        }} ><h4>{message}</h4></div>

        <Form>
          <Form.Field>
            <span ><h5 style={{ marginBottom: '10px' }}>Image</h5></span>
            <input type="file" class="inputfile" id="embedpollfileinput" onChange={e => fileChangedHandler(e.target.files)} />

          </Form.Field><Form.Field>
            <span><h5 style={{ marginBottom: '10px' }}>Term Name</h5></span>
            <input type='text' placeholder='Term Name' onChange={e => setTermName(e.target.value)} />

          </Form.Field>
          <Form.Field>
            <span><h5 style={{ marginBottom: '10px' }}>Term Type</h5></span>
            <select
              onChange={(e) => comboxSelected(e)} >
              <option value="product-attribute">Product Attribute </option>
              <option value="product-attribute-list">Product Attribute List </option>
            </select>
          </Form.Field>

          {termtype == "product-attribute" ? null :
            <Form.Field>
              <span><h5 style={{ marginBottom: '10px' }}>Parent</h5></span>
              <select onChange={(e) => setParentValue(e.target.value)} >
                {categoryarryay}
              </select>
            </Form.Field>
          }
          <div style={{ float: 'right' }}>
            <Button primary onClick={createCategory}>Submit</Button>
          </div>
        </Form>
      </CCardBody>
    </CCard>
  )
}

export default AttributeAdd

