import React, { useEffect, useState } from "react";
import { DateRangePicker } from 'react-date-range';
import { Card, Button, Form } from "semantic-ui-react";
import { downloadFile, get, BASEURL, postAPI } from "../../../apiServices";
import { format } from 'date-fns';

// import { addDays } from 'date-fns';
import Swal from "sweetalert2";
import { toast } from "react-toastify";

function ProductReportDownload(props){
    const [selectedDateRange, setSelectedDateRange] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
      ]);

    const [keyword, setKeyword] = useState('')
    const [sellerLists, setSellerlists] = useState([]);
    const [selectedSellerId, setSelectedSellerId] = useState([])
    const [fileFormat, setFileFormat] = useState('xlsx')
    const [selectedSellerError, setSelectedSellerError] = useState()

    useEffect(() => {
        if(keyword?.length === 3){
            getSeller()
        }

    },[keyword])
    const getSeller = async () => {
        let data = {
            "page" : 0,
            "recPerPage" : 100,
            "searchKeyword" : keyword
        }
        let res  = await postAPI('/get-sellers-name-list/', data);
        if(res.status === 1){
            let sellerArray = sellerLists;
            res?.payload?.data?.map((row) => {
                sellerArray.push({key:row?.userId, text:row?.fullName+" ("+row?.companyDetails?.companyName+")", value:row?.userId},)
            })
            let jsonObject = sellerArray.map(JSON.stringify);
            let uniqueSet = new Set(jsonObject);
            let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
            setSellerlists(uniqueArray);
        }
    }
    const handleSeller = (e, value) => {
        setSelectedSellerId(value)
        setKeyword("")
    }

      const downloadSaleBill = async () => {
        setSelectedSellerError("")
        if(selectedSellerId?.length === 0){
            setSelectedSellerError("Required");
            return false
        }
        let sDate = format(new Date(selectedDateRange[0]?.startDate), 'dd-MMM-yy')
        let eDate = format(new Date(selectedDateRange[0]?.endDate), 'dd-MMM-yy');
        let tDate = format(new Date(), 'dd-MMM-yy')
        let fileName = "Product-Report_"+sDate+"_To_"+eDate+"_"+tDate;
        const data = {
            sellerId: selectedSellerId,
            from: format(new Date(selectedDateRange[0]?.startDate), 'dd-MM-yyyy'),
            to: format(new Date(selectedDateRange[0]?.endDate), 'dd-MM-yyyy'),
            format: fileFormat,
            fileName: fileName
        };
        let res = await downloadFile("/products/report?sellerId="+selectedSellerId+"&from="+data?.from+"&to="+data?.to+"&format="+fileFormat, data)

        if(res === false){
            toast.error("No data found for selected date range");

          }
      };
    return (<>
    <div style={{marginBottom:"20px"}}>
        <span style={{color:"red"}}>{selectedSellerError}</span>
        <Form.Dropdown
            label="Select Seller"
            fluid
            multiple
            search
            selection
            placeholder="Select..."
            options={sellerLists}
            onKeyUp={(e) => setKeyword(e.target.value)}
            onChange={(e, { value }) => {
                handleSeller(e, value);
            }}
            value={selectedSellerId}
        />
    </div>
    <DateRangePicker
        onChange={item => {
            setSelectedDateRange([item.selection])
            if(item.selection.startDate && item.selection.endDate){
                // setDateRangeModal(false)
            }
        }}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        showMonthAndYearPickers={true}
        showDateDisplay={true}
        dateDisplayFormat={"d MMM, yyyy"}
        months={1}
        ranges={selectedDateRange}
        direction="horizontal"
        inputRanges={[]}
        minDate={new Date(props?.minDate)}
        maxDate={new Date()}
        size="sm"
        />
        <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
                <select className="form-control" onChange={(e)=>setFileFormat(e.target.value)}>
                    <option value={"xlsx"}>MS Excel (.xlsx)</option>
                    <option value={"csv"}>CSV (.csv)</option>
                </select>

            </div>
            <div className="col-md-4">
            <Button primary onClick={() => downloadSaleBill()}><i className="fa fa-download"></i> Download</Button>
            </div>
        </div>


    </>)
}

export default ProductReportDownload
