import React, { useState, useEffect, useCallback, useContext } from "react";
import queryString from "query-string";
import { Checkbox, Button } from "semantic-ui-react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import MESSAGES from "../../message.json";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTooltip,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CFormInput,
  CFormText,
} from "@coreui/react";
import { postAPI } from "../../apiServices";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../contexts";

const defaultState = {
  entries: [],
  totalEntries: 0,
  page: 0,
  recPerPage: 50,
  searchKeyword: "",
  loading: true,
  slugType: "category",
  confirmDialog: null,
};

const CategorysMapping = (props) => {
  const { setSubHeader } = useContext(AppContext);
  let rolemappingarryay = [];
  const location = useLocation();
  const [mappingrole, setmappingrole] = useState([]);
  const [array, setarray] = useState([]);
  const [checkboxtrue, setcheckboxtrue] = useState(true);
  const [selectCateid, setselectCateid] = React.useState([]);
  const [SelectCat, setSelectCat] = React.useState([]);
  const [rowtrue, setrowtrue] = React.useState();

  const [state, setState] = useState(defaultState);
  const [isrole, setisrole] = useState(true);
  let queries = queryString.parse(location.search);
  const categoryId = queries.id;
  const categoryname = queries.categoryname;
  const fetchProducts = useCallback(
    async (
      page = 0,
      recPerPage = recPerPage,
      searchKeyword = "",
      slugType = slugType,
      parent = "",
      shouldUpdateState = true,
    ) => {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      const { status, payload, message } = await postAPI(
        "/get-product-attributes",
        {
          categoryId,
        }
      );

      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      Swal.close();

      if (!status) {
        if (message !== "No product found")
          toast.error(message || MESSAGES?.sellers?.defaultApiError);
        setState((prevState) => ({
          ...prevState,
          entries: defaultState.entries,
          totalEntries: defaultState.totalEntries,
        }));
      } else {
        setmappingrole(payload.attributes);
        let catData = payload.attributes || [];
        let __data = [];
        for (let i = 0; i < catData.length; i++) {
          let childData = catData[i].child;
          let trunCat = childData.filter((re) => {
            return re.selected === true;
          });
          if (trunCat.length > 0) {
            let selectedCat = [];
            for (let c = 0; c < trunCat.length; c++) {
              selectedCat.push({ key: trunCat[c].id });
            }
            __data.push({ key: catData[i].id, childs: selectedCat });
          }
        }
        shouldUpdateState && setSelectCat(__data);
      }
    },
    []
  );

  const renderSubHeader = () => {
    return (
      <div className="d-flex align-items-center w-100">
        <CButton
          color="info"
        //  variant='outline'
          onClick={() => updateCategoryBtn()}
        >
          Update
        </CButton>
      <CButton
        color="primary"
        className="ms-4"
        onClick={() => {
          setState((prevState) => ({
            ...prevState,
            confirmDialog: {
              category: null,
              action: "add-category-item",
              title: "Enter attribute",
              heading: "Please enter new attribute",
              name: "",
              isAddingCategory: true,
            },
          }));
        }}
      >
        Add Attribute
      </CButton>
      </div>
    );
  };

  useEffect(() => {
    fetchProducts(
      state.page,
      state.recPerPage,
      state.searchKeyword,
      state.slugType
    );
  }, [fetchProducts, state.page, state.recPerPage, state.slugType]);
  if (state.loading) {
    let timerInterval;
    Swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          // const content = Swal.getContent();
          // if (content) {
          //   const b = content.querySelector("b");
          //   if (b) {
          //     b.textContent = Swal.getTimerLeft();
          //   }
          // }
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
      }
    });
  }
  const handleChangeChk = (e, child) => {
    var isChecked = e.target.checked;
    /* alert(isChecked)
        setcheckboxtrue(e.target.value) */
  };

  const handleChangeChk12 = (subCat, cat) => {
    let categoryObj = SelectCat || [];
    let _Obj = {};
    let filterData = categoryObj.filter((r) => {
      return r.key === cat.id;
    });
    if (filterData.length > 0) {
      categoryObj = categoryObj.filter((r) => {
        return r.key !== cat.id;
      });
      let subCatFil = filterData[0].childs.filter((cRe) => {
        return cRe.key === subCat.id;
      });
      if (subCatFil.length > 0) {
        filterData[0].childs = filterData[0].childs.filter((cRe) => {
          return cRe.key !== subCat.id;
        });
      } else {
        filterData[0].childs.push({ key: subCat.id });
      }
      categoryObj.push(filterData[0]);
    } else {
      _Obj.key = cat.id;
      _Obj.childs = [{ key: subCat.id }];
      categoryObj.push(_Obj);
    }
    setSelectCat(categoryObj);
  };
  const updateCategoryBtn = async () => {
    let cateattributesobj = {
      categoryId: categoryId,
      attributes: SelectCat,
    };
    const { status, payload, message } = await postAPI(
      "/save-categories-mapping",
      cateattributesobj
    );

    if (status == 1) {
      toast.success(message || "Updated successfully");
    }
  };

  const updateCategoryList = useCallback(async (value, item = null) => {
    let cateattributesobj = {
      id: "",
      type: "product-attribute",
      parent: item ? item.id : "",
      termName: value,
      leadImg: null,
      status: 1,
    };
    const { status, payload, message } = await postAPI(
      "/save-category-data",
      cateattributesobj
    );

    if (!status) {
      toast.error(message || MESSAGES?.sellers?.defaultApiError);
    } else {
      fetchProducts(
        state.page,
        state.recPerPage,
        state.searchKeyword,
        state.slugType,
        "",
        false,
      );
    }
    setState((prevState) => ({
      ...prevState,
      confirmDialog: null,
    }));
  }, []);

  useEffect(() => {
    setSubHeader([renderSubHeader()]);
    return () => setSubHeader([]);
  }, [categoryId, SelectCat]);

  return (
    <>
      <div>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol lg={5}>
                <div>
                  <span style={{ fontWeight: "bold" }}>{categoryname}</span>{" "}
                  mapping with attribute
                </div>
              </CCol>
              <CCol lg={7}>
                {/* <div className="d-flex justify-content-end">
                    <CButton
                     color="info"
                    //  variant='outline'
                      onClick={() => updateCategoryBtn()}
                    >
                      Update
                    </CButton>
                  <CButton
                    color="primary"
                    className="ms-4"
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        confirmDialog: {
                          category: null,
                          action: "add-category-item",
                          title: "Enter attribute",
                          heading: "Please enter new attribute",
                          name: "",
                          isAddingCategory: true,
                        },
                      }));
                    }}
                  >
                    Add Attribute
                  </CButton>
                </div> */}
              </CCol>
            </CRow>
            {mappingrole.map((item, index) => {
              return (
                <div>
                  <div className="d-flex align-items-center ">
                    <h3 style={{ paddingTop: "17px", marginBottom: "7px" }}>
                      {item.name}
                    </h3>
                    <CTooltip content={`Add category`}>
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="ms-2 mt-2"
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            confirmDialog: {
                              category: item,
                              action: "add-category-item",
                              title: "Enter attribute",
                              heading: `Please enter attribute value for : ${item?.name}`,
                              name: "",
                              isAddingCategory: true,
                            },
                          }));
                        }}
                      />
                    </CTooltip>
                  </div>

                  <CRow>
                    {item.child.map((childItem, childIndex) => {
                      return (
                        <CCol lg={3}>
                          <Checkbox
                            defaultChecked={childItem.selected}
                            label={childItem.name}
                            value={childItem.id}
                            id={childItem.id}
                            onChange={() => handleChangeChk12(childItem, item)}
                          />
                        </CCol>
                      );
                    })}
                  </CRow>
                </div>
              );
            })}
          </CCardBody>
        </CCard>
      </div>
      <CModal
        alignment="center"
        show={!!state.confirmDialog?.isAddingCategory}
        onClosed={() => {
          setState((prevState) => ({
            ...prevState,
            confirmDialog: null,
          }));
        }}
      >
        <CModalHeader>
          <CModalTitle>{`${state.confirmDialog?.heading}`}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <>
            <CFormInput
              type="text"
              value={state.confirmDialog?.name || ""}
              className="mt-3 mb-3"
              maxLength={20}
              placeholder={state.confirmDialog?.title}
              onChange={(evt) => {
                const { value } = evt.currentTarget;
                setState((prevState) => ({
                  ...prevState,
                  confirmDialog: {
                    ...prevState.confirmDialog,
                    name: value || "",
                  },
                }));
              }}
            />
            <CFormText color="link" className="help-block mb-1 validation">
              {state.confirmDialog?.name?.length > 19 && "Max length: 20"}
            </CFormText>
          </>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                confirmDialog: null,
              }));
            }}
          >
            Cancel
          </CButton>
          <CButton
            color="primary"
            onClick={() => {
              updateCategoryList(
                state.confirmDialog?.name,
                state.confirmDialog?.category
              );
            }}
          >
            Save
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};
export default CategorysMapping;
