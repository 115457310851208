import React, { useEffect, useState } from "react";
import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import { getUrlFileExtension } from "../../constants/comonFunction";

function ImageGalleryWithCaption(props){
    const [showImage,  setShowImage] = useState(true);
    const [imageFile, setImageFile] = useState([]);
    const [pdfFile, setPdfFile] = useState([]);
    const [videoFile, setVideoFile] = useState([]);
    const onInit = () => {
    };
    useEffect(() => {
        let pdf = [];
        let image = [];
        let videos = [];
        props?.images?.map((ele) => {
            if(getUrlFileExtension(ele) === 'pdf'){
                pdf.push(ele)
            }else if(getUrlFileExtension(ele) === 'mp4'){
              image.push(ele)
              videos.push({src:ele, type:"video/mp4"})
            }else{
                image.push(ele)
            }
        })
        setImageFile(image);
        setPdfFile(pdf)
        setVideoFile(videos)
    },[props])

    return (<>
        <div className="App">
            {showImage ? (<>
                {pdfFile?.map((row) => {
                    return (<>
                         <div>
                            <div>
                            <embed src={row} style={{width:"150px", height:"150px", borderRadius:"5px", padding:"10px 10px 5px 10px"}}></embed>
                            </div>
                            <div style={{width:"150px", textAlign:"center"}}>
                            <a href={row} target="_blank">View</a>
                            </div>
                        </div>
                    </>)
                })}

            <LightGallery
                    // onInit={onInit}
                    // speed={500}
                    // plugins={[lgThumbnail, lgZoom]}
                    // onAfterOpen={handleAfterOpen}
                >
                {imageFile?.map((row, index) => {
                  let type = getUrlFileExtension(row)
                    return (<>
                    {type === 'mp4' ? (
                        <a
                        href={row}
                        // data-sub-html={props?.captions?.forEach(ele => {<li key='item' style={{listStyleType:"none"}}>{ele}</li>})}
                        data-sub-html={props?.captions?.map((row, i)=>"<li key='item"+i+"' style=list-style-type:none>"+row.trim()+"</li>")}
                        data-video={JSON.stringify({source: videoFile, attributes: {preload: false, controls: true}})}
                        >
                            {props?.viewPreview && (
                            <div>
                                <img src={row} style={{width:"150px", height:"150px", borderRadius:"5px", padding:"10px"}} />
                            </div>
                          )}

                          {index===0 && (
                            <div>
                              <a href={row}>View</a>
                            </div>
                          )}

                    </a>
                    ):(
                        <a
                          href={row}
                          data-sub-html={props?.captions?.map((row, i)=>"<li key='item"+i+"' style=list-style-type:none>"+row.trim()+"</li>")}
                          >
                            {props?.viewPreview && (
                            <div>
                                <img src={row} style={{width:"150px", height:"150px", borderRadius:"5px", padding:"10px"}} />
                            </div>
                          )}

                          {index===0 && (
                            <>
                            {props?.viewInitialImage ? (<>
                              <div>
                                  <img src={imageFile?.[0]} style={{width:"80px", height:"80px"}} />
                              </div>
                            </>) : (<>
                              <div>
                                <a href={row}>View</a>
                              </div>
                            </>)}
                            </>

                          )}

                        </a>
                    )}

                    </>)
                })}
            </LightGallery>
            </>) : (<></>)}
        </div>
    </>)
}

export default ImageGalleryWithCaption
