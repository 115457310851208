import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Button } from "semantic-ui-react";
import { postAPI, uploadImageAPIb2b } from "../../apiServices";
import { format } from 'date-fns';

import moment from 'moment';
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function AddPod(props){
    const [viewPod, setViewPod] = useState(props?.pod)
    const [podFiles, setPodFiles] = useState([])
    const [dateOfDelivery, setDateOfDelivery] = useState(props?.order?.deliveredOn ? (new Date(props?.order?.deliveredOn)) : '');
    const [podFileError, setPodFileError] = useState();
    const [dateOfDeliveryError, setDateOfDeliveryError] = useState();
    const [rowList, setRowList] = useState([{method:"cash", amount:"", dateOfPayment:"", transactionID:"", files:[]}])
    const [rowListError, setRowListError] = useState([{method:"", amount:"", dateOfPayment:"", transactionID:"", files:""}])
    const [errorMessage, setErrorMessage] = useState("")
    const [cancelMessageTitle, setCancelMessageTitle] = useState("")
    const [cancelMessageText, setCancelMessageText] = useState("")
    const [cancelConfirmVisible, setCancelConfirmVisible] = useState(false);

    const handleViewPodClose = () => {
        setCancelMessageTitle("Do you want to cancel?")
        setCancelMessageText("All unsaved changes will be lost")
        setCancelConfirmVisible(true)
    }

    const rejectCancel = () => {
        setCancelMessageTitle("")
        setCancelMessageText("")
        setCancelConfirmVisible(false)
    }

    const acceptCancel = () => {
        setViewPod(!viewPod)
        props?.handleShowPod(false)
    }

    const uploadPodFile = async (e) => {
        let files = e.target.files
        let images = podFiles;
        let payloadimg = await uploadImageAPIb2b("/uploads", files);
        if(payloadimg?.status === 1){
            payloadimg?.url?.forEach(ele => {
                images.push(ele)
            })
        }
        await setPodFiles(images);
    }

    const handleSubmit = async() => {
        let isError = false;
        setErrorMessage()
        let payloadPoD = {
            orderID: props?.order?.orderID,
            dateOfPayment: dateOfDelivery ? format(new Date(dateOfDelivery), 'dd-MM-yyyy') : null,
            files: podFiles
        };
        if(!dateOfDelivery){
            isError = true;
        }

        if(podFiles?.length === 0){
            isError = true;
        }
        if(isError){
            setErrorMessage("fields marked with (*) are mandatory")
            return false;
        }else{
            let res = await postAPI('/orders/pod/add-details/', payloadPoD)
            setViewPod(!viewPod)
            props?.handleShowPod(false, res.status)
        }

    }
    return (<>
    <Modal show={viewPod} onHide={handleViewPodClose} backdrop="static" keyboard={false} size="md" centered={true}>
        <Modal.Header>
            <Modal.Title>Proof of Delivery </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <span> Date of Delivery <sup>*</sup></span>
                        <span>
                            <DatePicker
                                selected={dateOfDelivery}
                                className="form-control date"
                                onChange={(date) => setDateOfDelivery(date)}
                                placeholderText="DD/MM/YYYY"
                                dateFormat="dd/MM/yyyy"
                                minDate={new Date(props?.order?.placedOn)}
                                maxDate={new Date()}
                                disabled={props?.order?.deliveredOn ? true :  false}
                            />
                            {/* <input type="date" className="form-control date" onChange={(e) => setDateOfDelivery(e.target.value)} min={props?.order?.placedOn} max={moment().format("YYYY-MM-DD")} /> */}
                        </span>
                    </div>
                    <div className="col-12 mt-2">
                        <span> Upload File <sup>*</sup></span>
                        <span>
                            <input type="file" className="fileBrowse" name="podFiles" multiple onChange={(e) => uploadPodFile(e)} />
                        </span>
                    </div>
                    {/* <div className="col-12 mt-4" style={{textAlign:"right"}}>
                        <Button className="btn btn-danger" onClick={handleViewPodClose}>
                            Close
                        </Button>
                        <Button className="btn blue btn-primary btn-md"  onClick={handleSubmit}>
                            Submit
                        </Button>
                    </div> */}
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            {!cancelConfirmVisible ? (<>
                <p className="required">{errorMessage}</p>
                <Button className="btn btn-danger" onClick={handleViewPodClose}>  Cancel </Button>
                <Button className="btn blue btn-primary btn-md"  onClick={handleSubmit}> Submit </Button>
            </>) : (<>
                <div>
                    <p><strong>{cancelMessageTitle}</strong> {" "}{cancelMessageText}</p>
                </div>
                <Button className="btn btn-danger" onClick={rejectCancel}>  No </Button>
                <Button className="btn blue btn-primary btn-md"  onClick={acceptCancel}> Yes </Button>
            </>)}
        </Modal.Footer>
    </Modal>
    </>);
}
export default AddPod;
