import React, { useState } from "react";
import { Avatar } from "../../common-components";
import { CButton, CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem } from "@coreui/react";
import { getApiCallCommon, postAPI } from "../../apiServices";
import { toast } from "react-toastify";
import QRIcon from "../../assets/icons/qr-code.png";

const noop = () => {};
const localPermits = JSON.parse(localStorage.getItem("module"));
const QR_CODE_SIZE = [
  {label:'0.8" X 0.8"', value:"w-76,h-76", w:76, h:76, iconSize:10},
  {label:'1.2" X 1.2"', value:"w-113,h-113", w:113, h:113, iconSize:12},
  {label:'2.0" X 2.0"', value:"w-188,h-188", w:188, h:188, iconSize:14}
];

const Skus = ({
  sku = {},
  product= {},
  onDelete = noop,
  showImageViewer = noop,
  showProductDetails = noop,
}) => {
  const [permissions, setPermissions] = useState(
    localPermits.find((o) => o.moduleId == 16)
  );
  const getQRCode = async({w, h, size, product_id, sku}) => {
    let res = await getApiCallCommon('/product/qr-code/'+product_id+"/"+sku+"?w="+w+"&h="+h);
    if(res.status === 1){
      let url = res.payload.qrCode
      window.open(url+'?'+size, '_blank').focus();
    }
    //
  }

  const [isOpen, setIsOpen] = useState(false);

  // Function to handle mouse enter event
  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  // const handleStatusChange = (
  //   status = {},
  //   type = "",
  //   id = null,
  //   action = "",
  //   entryName = ""
  // ) => {
  //   onStatusChange(status, type, id, action, entryName);
  // };

  // const updateStatus = async(sku, updatedStatus) => {
  //   let data = {
  //     id : 3733,
  //     remarks : null,
  //     status : updatedStatus,
  //     type : "product-status"
  //   }
  //   let res = await postAPI('/products/update-status',data)
  //   if(res.status === 1){

  //     if(sku?.status === 1){
  //       setProStatus('In Active')
  //     }else{
  //       setProStatus('Active')
  //     }
  //     toast.success(res.message);
  //   }else{
  //     toast.error(res.message);
  //   }

  // }
  return (
    <div className="d-flex align-items-center pt-2 pb-2">
      <Avatar
        alt={sku.title}
        src={sku.images && sku.images[0]}
        size={70}
        onClick={() => {
          if (!!sku.images.length) showImageViewer(sku.images);
        }}
      />
      <div className="ms-2">
        <div>
          <p
            onClick={() => showProductDetails({ ...sku, detailType: "sku" })}
            style={{ cursor: "pointer" }}
          >{`SKU Code: ${sku.skuCode || "Not Available"}`}</p>
          <p
            onClick={() => showProductDetails({ ...sku, detailType: "sku" })}
            style={{ cursor: "pointer" }}
          >
            {sku.title
              ? `SKU Title: ${sku.title || "Not Available"}`
              : `Color: ${sku.color || "Not Available"}`}
          </p>
        </div>
        <div className="d-flex">
          <CButton
            disabled={permissions.isEdit === 0 ? true : false}
            type="number"
            color="danger"
            variant="outline"
            className="ms-1 me-2"
            onClick={() =>
              onDelete(
                sku.skuId,
                "sku-trash",
                "Delete SKU",
                sku.skuCode || sku.title || ""
              )
            }
          >
            Delete
          </CButton>
          {product.currentStatus === 'approved' ? (<>
          <div className="dropdown-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <button className="dropdown-button info" color="info">Get QR Code</button>
              {isOpen && (
                <div className="dropdown-content">
                  {/* Dropdown content goes here */}
                  {QR_CODE_SIZE.map((size) => {
                    return <a
                      onClick={() => getQRCode({w:size.w, h:size.h, size:size.value, product_id:product.productId, sku:sku.skuId})}
                    >
                      <img src={QRIcon} width={size.iconSize} />
                      <span style={{marginLeft:"10px"}}>{size.label}</span>
                    </a>
                  })}
                </div>
              )}
            </div>
            </>):(<></>)}

            {/* {product.currentStatus === 'approved' ? (<>
              <CDropdown className="me-2">
              <CDropdownToggle
                color="info"
              >
                Get QR Code
              </CDropdownToggle>
              <CDropdownMenu>
                {[
                  {label:"100px X 100px", value:"w-100,h-100", w:100, h:100},
                  {label:"150 X 150", value:"w-150,h-150", w:150, h:150},
                  {label:"200 X 200", value:"w-200,h-200", w:200, h:200}
                ].map((size) => (
                  <CDropdownItem
                    href="#"
                    onClick={() => getQRCode({w:size.w, h:size.h, size:size.value, product_id:product.productId, sku:sku.skuId})}
                  >
                    {size.label}
                  </CDropdownItem>
                ))}
              </CDropdownMenu>
            </CDropdown>
            </>) : (<></>)} */}


        </div>
      </div>
    </div>
  );
};

export default Skus;
