import React, { useState, useEffect, useCallback, useContext } from "react";
import { AppContext } from "../../contexts/app";
import CategoryChildGrids from "./CategoryChildGrids";
import { toast } from "react-toastify";
import { postAPI } from "../../apiServices";
import MESSAGES from "../../message.json";
import { useNavigate, Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  CCol,
  CRow,
  CPagination,
  CFormInput,
  CButton,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CInputGroup,
  CInputGroupText,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CCard,
  CCardBody,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
} from "@coreui/react";
import { Menu, Table, Icon, Form } from "semantic-ui-react";
import Swal from "sweetalert2";
let timeout = null;
const defaultState = {
  entries: [],
  totalEntries: 0,
  page: 0,
  recPerPage: 50,
  searchKeyword: "",
  loading: true,
  slugType: "category",
  confirmDialog:null,
};

const CategoryChild = () => {
  let navigate = useNavigate();
  const { setSecondaryHeader } = useContext(AppContext);
  const [state, setState] = useState(defaultState);
  const [page, setpage] = useState(defaultState.page);
  const [label, setLabel] = useState(false);
  const [categorylabel, setCategoryLabel] = useState("category");

  const location = useLocation();
  let queries = queryString.parse(location.search);
  const categoryId = queries.id;
  const type = queries.type;

  const handleDeleteConfirm = async (data) => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    const { status, payload, message } = await postAPI(
      "/delete-category-data",
      {
        id: data?.id,
        type: "category",
      }
    );
    setState((prevState) => ({
      ...prevState,
      loading: false,
    }));
    Swal.close();
    if (!status) {
      toast.error(message);
    } else {
      toast.success("Deleted Successfully");
      fetchProducts(
        state.page,
        state.recPerPage,
        state.searchKeyword,
        state.slugType
      );
    }
  };

  const fetchProducts = useCallback(
    async (
      page = 0,
      recPerPage = recPerPage,
      searchKeyword = "",
      slugType = slugType,
      parent = categoryId
    ) => {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      const { status, payload, message } = await postAPI(
        "/get-categories-list",
        {
          page,
          recPerPage,
          searchKeyword,
          slugType,
          parent,
        }
      );

      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      Swal.close();

      if (!status) {
        if (message !== "No product found")
          toast.error(message || MESSAGES?.sellers?.defaultApiError);
        setState((prevState) => ({
          ...prevState,
          entries: defaultState.entries,
          totalEntries: defaultState.totalEntries,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          entries: payload?.data || defaultState.entries,
          totalEntries: payload?.totRecords || defaultState.totalEntries,
        }));
      }
    },
    []
  );

  useEffect(() => {
    fetchProducts(
      state.page,
      state.recPerPage,
      state.searchKeyword,
      state.slugType,
      state.parent
    );
  }, [
    fetchProducts,
    state.page,
    state.recPerPage,
    state.slugType,
    state.parent,
  ]);

  if (state.loading) {
    let timerInterval;
    Swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          // const content = Swal.getContent();
          // if (content) {
          //   const b = content.querySelector("b");
          //   if (b) {
          //     b.textContent = Swal.getTimerLeft();
          //   }
          // }
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
      }
    });
  }
  const openCategoryPage = () => {
    navigate({
      pathname: "/add-category",
      state: { button: "save" },
      parentId: {categoryId},
    });
  };
  const labelSlected = async (e) => {
    if (e) {
      if (e == "category") {
        setLabel(false);
        setCategoryLabel(e);
      } else {
        setLabel(true);
        setCategoryLabel(e);
      }
      const slugType = e;
      const page = 0;
      const recPerPage = 50;
      const searchKeyword = "";
      const entries = [];

      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      const { status, payload, message } = await postAPI(
        "/get-categories-list",
        {
          page,
          recPerPage,
          searchKeyword,
          slugType,
        }
      );
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      Swal.close();
      if (!status) {
        if (message !== "No product found")
          toast.error(message || MESSAGES?.sellers?.defaultApiError);
        setState((prevState) => ({
          ...prevState,
          entries: defaultState.entries,
          totalEntries: defaultState.totalEntries,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          entries: payload?.data || defaultState.entries,
          totalEntries: payload?.totRecords || defaultState.totalEntries,
        }));
      }
    }
  };
  const openbackPage = () => {
    navigate(-1);
  };
  const renderSecondaryHeader = () => {
    return (
      <div className="d-flex align-items-center justify-content-between w-100"></div>
    );
  };
  useEffect(() => {
    setSecondaryHeader([renderSecondaryHeader()]);
    return () => setSecondaryHeader([]);
  }, [state.searchKeyword, state.recPerPage, state.searchKeyword]);
  return (
    <>
      <CRow>
        <CCol lg={12}>
          {!state.loading && !state.entries.length ? (
            <div className="d-flex flex-column align-items-center"></div>
          ) : (
            <>
              <CRow>
                <CCol lg={9}>
                  <div style={{ width: 200, marginBottom: 10 }}>
                    <Form>
                      <Form.Field>
                        {/*    <select onChange={e => labelSlected(e.target.value)}>
                                                    <option value="category">Category Level 1 </option>
                                                    <option value="category">Category Level 2 </option>
                                                    <option value="category">Category Level 3 </option>
                                                    <option value="category">Category Level 4 </option>
                                                </select> */}
                        <div className="d-flex ">
                          <CButton
                            color="primary"
                            className="ms-1 me-1"
                            onClick={openbackPage.bind(this)}
                          >
                            Move level up
                          </CButton>
                        </div>
                      </Form.Field>
                    </Form>
                  </div>
                </CCol>
                <CCol lg={3}>
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="d-flex w-50"></div>
                    <div className="d-flex ">
                      <CButton
                        color="primary"
                        className="ms-1 me-1"
                        onClick={openCategoryPage.bind(this)}
                      >
                        Add Category
                      </CButton>
                    </div>
                  </div>
                </CCol>
              </CRow>

              <CCard>
                <CCardBody>
                  <Table basic="very">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell style={{ border: 0 }}>
                          S.No
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Category
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Image
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Parent
                        </Table.HeaderCell>

                        <Table.HeaderCell style={{ border: 0 }}>
                          Priority
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          <div className="d-flex align-items-center justify-content-center">
                            Action
                          </div>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {state.entries.map((entry, index) => (
                        <CategoryChildGrids
                          // onDeleteConfirm={() => handleDeleteConfirm(entry)}
                          category={entry}
                          index={index}
                          label={label}
                          slugType={categorylabel}
                          onDeleteConfirm={()=>{
                            setState(prevState=>({
                              ...prevState,
                              confirmDialog:{
                                  entry,
                                  action:'Delete Category',
                                  entryName:entry.name,
                              }
                            }))
                          }}
                        />
                      ))}
                    </Table.Body>
                  </Table>
                </CCardBody>
              </CCard>
              {!!state.entries.length && (
                <div className="d-flex align-items-center justify-content-between">
                  <p>{`Showing ${state.recPerPage * state.page + 1} to ${
                    state.recPerPage * state.page + state.entries.length
                  } of ${state.totalEntries} record(s)`}</p>
                  <CPagination
                    activePage={state.page + 1}
                    pages={Math.ceil(state.totalEntries / state.recPerPage)}
                    onActivePageChange={(page) => {
                      setState((prevState) => ({
                        ...prevState,
                        page: page ? page - 1 : page,
                        slugType: categorylabel,
                      }));
                    }}
                  />
                </div>
              )}
            </>
          )}
        </CCol>
      </CRow>
      <CModal
        alignment='center'
        show={!!state.confirmDialog}
        onClosed={() => {
          setState(prevState => ({
            ...prevState,
            confirmDialog: null
          }));
        }}
      >
        <CModalHeader>
        <CModalTitle>{`${state.confirmDialog?.action}`}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>{`Are you sure you want to continue?`}</div>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() => {
              setState(prevState => ({
                ...prevState,
                confirmDialog: null
              }))
            }}
          >
            Cancel
          </CButton>
          <CButton
            color="primary"
            onClick={() => {
              handleDeleteConfirm(state?.confirmDialog?.entry)
            }}
          >
           Delete
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default CategoryChild;
