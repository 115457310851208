import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { postAPI } from '../../apiServices'
import {CompanyInformation, BankDetails, Brands, ChangePassword, Category, Commercials, Settings, BulkUpdate} from './tab-panels'
import {CCard, CCardBody, CCardText, CNav, CNavItem, CNavLink, CTabContent, CTabPane, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem} from '@coreui/react'
import moment from 'moment'
import EmployeeId from './tab-panels/EmployeeId'
import ActionConfirmation from "./deleteRequest/actionConfirmation";
// import ChangePassword from './tab-panels/ChangePassword';

const noop = () => {}
const SELLER_STATUS = [
  {label: 'Active', value: 'active', key: 1, type: 'status', sections: ['new', 'approved', 'blocked']},
  {label: 'Inactive', value: 'inactive', key: 0, type: 'status', sections: ['new', 'approved', 'blocked']},
  {label: 'Blocked', value: 'blocked', key: -1, type: 'status', sections: ['approved']},
  {label: 'Approved', value: 'approved', key: 1, type: 'approve', sections: ['new', 'rejected']},
  {label: 'Not Approved', value: 'not-approved', key: 0, type: 'approve', sections: ['rejected']},
  {label: 'Rejected', value: 'rejected', key: 2, type: 'approve', sections: ['new']}
]

const TABS = [
  {label: 'Company Info',     key: 'company',         panelComponent: CompanyInformation, statusKey: 'companyProfileCompleted'},
  {label: 'Bank Details',     key: 'bank',            panelComponent: BankDetails,        statusKey: 'bankProcessCompleted'},
  {label: 'Brands',           key: 'brand',           panelComponent: Brands,             statusKey: 'brandProcessCompleted'},
  {label: 'Category',         key: 'category',        panelComponent: Category,           statusKey: 'categoryProcessCompleted'},
  {label: 'Settings',         key: 'settings',        panelComponent: Settings,           statusKey: 'commercialsProcessCompleted'},
  {label: 'Employee Id',      key: 'employeeId',      panelComponent: EmployeeId,         statusKey: 'commercialsProcessCompleted'},
  {label: 'Change Password',  key: 'changePassword',  panelComponent: ChangePassword,     statusKey: 'commercialsProcessCompleted'},
  {label: 'Bulk Update',      key: 'bulkUpdate',      panelComponent: BulkUpdate,         statusKey: 'bulkUpdateProcessCompleted'}
]

const defaultState = {
  activeTabIndex: -1,
  sellerStatus: SELLER_STATUS.find(status => status.type === 'status'),
  approveStatus: SELLER_STATUS.find(status => status.type === 'approve'),
  isFetching: {company: false, bank: false, brand: false, category: false, commercials: false, changePassword: false}
}

const localPermits = JSON.parse(localStorage.getItem('module'))

const Seller = ({ entry = {}, section = '', matchParamType="", onStatusChange, onDeleteAction=noop }) => {
  const [state, setState] = useState(defaultState)
  const [permissions, setPermissions] = useState(localPermits.find(o => o.moduleId == 2))
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteAction, setDeleteAction] = useState();
  useEffect(() => {
    setState(prevState => ({
      ...defaultState,
      sellerStatus: SELLER_STATUS.find(status => status.key === entry.status && status.type === 'status'),
      approveStatus: SELLER_STATUS.find(status => status.key === entry.approvalStatus && status.type === 'approve')
    }))
  }, [entry])

  const fetchSellerInfo = async (userId, type) => {
    try {
      const infoData = {
        company:        { endpoint: '/get-sellers-companyinfo' },
        bank:           { endpoint: '/get-sellers-bankinfo' },
        brand:          { endpoint: '/get-seller-brands' },
        category:       { endpoint: '/get-seller-categories' },
        commercials:    { endpoint: '/get-sellers-list' },
        employeeId:     { endpoint: '/get-sellers-list' },
        changePassword: { endpoint: '/get-sellers-list' },
        bulkUpdate:     { endpoint: '/products/get-bulk-count' }
      }

      setState(prevState => ({...prevState, isFetching: {...prevState.isFetching, [type]: true}}))

      if (infoData[type].endpoint != '/get-sellers-list') {
        if(infoData[type].endpoint === '/products/get-bulk-count'){
          var payload = await postAPI(infoData[type].endpoint, {sellerId:userId})
        }else{
          var { payload } = await postAPI(infoData[type].endpoint, {userId})
        }

      } else {
        let getComOBJ = {
          page: 0,
          recPerPage: '50',
          searchKeyword: '',
          listingType: 'all',
          filters: [
            {
              param: 'userId',
              value: [userId]
            },
            {
              param: 'seller-status',
              value: []
            },
            {
              param: 'seller-approval-status',
              value: []
            }
          ]
        }
        try {
          var { status, payload } = await postAPI(infoData[type].endpoint,getComOBJ)
          // if (infoData.employeeId !== undefined) {
          if (type == 'employeeId') {
            payload = { employeeId: payload.data[0] }
          }
          else if(type == 'changePassword'){
            payload = { changePassword: payload.data[0] }
          }
          else if(type == 'bulkUpdate'){
            payload = { bulkUpdate: payload }
          }
          else {
            payload = { commercial: payload.data[0] }
          }
        } catch (er) {
        }
      }

      const responseKey = {
        company:  'data',
        bank:     'data',
        brand:    'brands',
        category: 'categories',
        commercials: 'commercial',
        employeeId: 'employeeId',
        changePassword: 'changePassword',
        bulkUpdate:'payload'
      }
      setState(prevState => ({
        ...prevState,
        [type]: payload[responseKey[type]] || defaultState[type],
        isFetching: {
          ...prevState.isFetching,
          [type]: false
        }
      }))
    } catch (er) {
    }
  }

  const handleTabChange = (index, userId, key) => {
    setState(prevState => ({...prevState, activeTabIndex: prevState.activeTabIndex === index ? -1 : index}))
    if (!state[key]) {
      fetchSellerInfo(userId, key)
    }
  }

  const handleStatusChange = (
    status = {},
    type = '',
    id = null,
    action = '',
    entryName = ''
  ) => {
    onStatusChange(status, id, action, entryName, () => {
      setState(prevState => ({
        ...prevState,
        [type]: status
      }))
    })
  }

  const handleDeleteRequest = (d, action) => {
    setDeleteConfirm(d)
    setDeleteAction(action)
  }

  const handleShowConfirmation = (d, action) => {
    setDeleteConfirm(d)
    setDeleteAction()
    if(action === 'loading'){
      onDeleteAction()
    }
  }

  return (
    <>
    <CCard style={{marginBottom:"20px"}}>
      <CCardBody>
        <div className='d-flex justify-content-between mb-4'>
          <div className='w-50'>
            {[
              { label: 'Company Name', key: 'companyName' },
              { label: 'Name', key: 'fullName' },
              { label: 'Contact Number', key: 'mobile' },
              { label: 'Email ID', key: 'email' },
              { label: 'Registration Date', key: 'createdOn' },
              { label: "Onboarded By", key: "onboardedByName" },
              { label: `Reason For ${section === 'rejected' ? 'Rejection' : 'blocking'}`, key: 'remarks'}
            ]
              .filter(item => item.key === 'remarks' ? entry.approvalStatus === 2 || entry.status === -1 : true ).map(item => {
                return (
                  <div
                    key={item.key}
                    className={clsx({
                      'd-flex': item.key !== 'remarks'
                    })}
                  >
                    <CCardText
                      className={clsx('text-primary text-primary-label font-weight-bold m-1', {
                        'text-danger': item.key === 'remarks'
                      })}
                    >
                      {item.label}:
                    </CCardText>
                    <CCardText
                      className={clsx('m-1 ms-1 text-primary-value', {
                        'text-danger': item.key === 'remarks'
                      })}
                    >
                      {item.key === 'createdOn'
                        ? moment(entry[item.key]).format(
                            'DD MMMM YYYY, h:mm:ss a'
                          )
                        : entry[item.key]}
                      {item.key === 'companyName'
                        ? entry.companyDetails.companyName
                        : ''}

                      {item.key === 'onboardedByName'
                        ? (<>{entry.onboardedByName || entry.showroomB2BEmployeeId ? entry.onboardedByName + " ("+entry.showroomB2BEmployeeId+")" : ""}</>)
                        : ''}
                    </CCardText>
                  </div>
                )
              })}
          </div>
          <div>
            {[
              { label: 'Total Product', key: 'totalProducts' },
              { label: 'Total Active Product', key: 'activeProducts' },
              { label: 'Total Inactive Product', key: 'inActiveProducts' },
              { label: 'Total Sold Products', key: 'totalSoldProducts' },
              { label: 'Last Updated Date', key: 'updatedOn' },
              { label: 'Current Status', key: 'currentStatus' }
            ]
              .filter(item =>
                section === 'incomplete' ? entry[item.key] : true
              )
              .map(item => (
                <div className='d-flex' key={item.key}>
                  <CCardText className='text-primary text-primary-label font-weight-bold m-1'>
                    {item.label}:
                  </CCardText>
                  <CCardText className='m-1 ms-1 text-primary-value'>
                    {item.key === 'updatedOn'
                      ? moment(entry[item.key]).format('DD MMM YYYY, ddd')
                      : entry[item.key]}
                  </CCardText>
                </div>
              ))}
          </div>
        </div>
        <div
          className={clsx('d-flex align-items-center', {
            'justify-content-between': !entry.allProfileCompleted,
            'justify-content-end': entry.allProfileCompleted
          })}
        >

          <div className={clsx('d-flex align-items-center')}>
            {SELLER_STATUS.some(seller => seller.type === 'status' && seller.sections.includes(section) && (matchParamType !== "delete-request" && matchParamType !== "deleted")) && (
              <CDropdown className='me-2'>
                <CDropdownToggle
                  color='info'
                  disabled={permissions.isEdit === 0 ? true : false}
                >
                  {state.sellerStatus?.label}
                </CDropdownToggle>
                <CDropdownMenu>
                  {SELLER_STATUS.filter(
                    status =>
                      status.type === 'status' &&
                      status.key !== state.sellerStatus?.key &&
                      status.sections.includes(section)
                  ).map(status => (
                    <CDropdownItem
                    key={status?.key}
                      style={{cursor:"pointer"}}
                      disabled={!entry.allProfileCompleted}
                      onClick={() =>
                        handleStatusChange(
                          status,
                          'sellerStatus',
                          entry.userId,
                          `Update Status`,
                          entry.fullName || entry.email || entry.mobile
                        )
                      }
                    >
                      {status.label}
                    </CDropdownItem>
                  ))}
                </CDropdownMenu>
              </CDropdown>
            )}
            {SELLER_STATUS.some(
              seller =>
                seller.type === 'approve' && seller.sections.includes(section)
              && (matchParamType !== "delete-request" && matchParamType !== "deleted")
            ) && (
              <CDropdown>
                <CDropdownToggle
                  color='primary'
                  disabled={permissions.isEdit === 0 ? true : false}
                >
                  {state.approveStatus?.label}
                </CDropdownToggle>
                <CDropdownMenu>
                  {SELLER_STATUS.filter(
                    status =>
                      status.type === 'approve' &&
                      status.key !== state.approveStatus?.key &&
                      status.sections.includes(section)
                  ).map(status => (
                    <CDropdownItem
                      style={{cursor:"pointer"}}
                      disabled={!entry.allProfileCompleted}
                      onClick={() =>
                        handleStatusChange(
                          status,
                          'approveStatus',
                          entry.userId,
                          `Update Approval Status`,
                          entry.fullName || entry.email || entry.mobile
                        )
                      }
                    >
                      {status.label}
                    </CDropdownItem>
                  ))}
                </CDropdownMenu>
              </CDropdown>
            )}

            {matchParamType === "delete-request" && (<>
              <CDropdown>
                <CDropdownToggle
                  color="primary"
                  disabled={permissions.isEdit === 0 ? true : false}
                >
                  Delete Request
                </CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem
                      style={{cursor:"pointer"}}
                      onClick={() =>
                        handleDeleteRequest(true, 'accept')
                      }
                    >
                      Accept
                    </CDropdownItem>

                    <CDropdownItem
                      style={{cursor:"pointer"}}
                      onClick={() =>
                        handleDeleteRequest(true, 'reject')
                      }
                    >
                      Reject
                    </CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </>)}
          </div>
        </div>
        <>
          <CNav variant='tabs'>
            {TABS.filter(tab =>
              {
                if (
                  section === 'incomplete' &&
                  (tab.key === 'company' ||
                    tab.key === 'bank' ||
                    tab.key === 'brand' ||
                    tab.key === 'category' ||
                    tab.key === 'settings')
                ) {
                  return false
                } else {
                  if(tab.key === 'bulkUpdate' && section !== 'approved'){
                    return false
                  }
                  return true
                }
              }
            ).map((tab, index) => (
              <CNavItem key={tab.key}>
                <CNavLink
                  style={{cursor:"pointer"}}
                  active={state.activeTabIndex === index}
                  onClick={() => handleTabChange(index, entry.userId, tab.key)}
                >
                  <div>
                    {tab.label}
                  </div>
                </CNavLink>
              </CNavItem>
            ))}
          </CNav>
          <CTabContent>
            {TABS.filter(tab =>
              // section === "incomplete" ? entry[tab.statusKey] : true
              {
                if (
                  section === 'incomplete' &&
                  (tab.key === 'company' ||
                    tab.key === 'bank' ||
                    tab.key === 'brand' ||
                    tab.key === 'category' ||
                    tab.key === 'settings' ||
                    tab.key === 'bulkUpdate')
                ) {
                  return false
                } else {
                  return true
                }
              }
            ).map((tab, index) => {
              return (
                <CTabPane
                  key={index}
                  visible={state.activeTabIndex}
                  style={{
                    opacity: state.activeTabIndex === index ? 1 : 0,
                    display: state.activeTabIndex === index ? 'block' : 'none'
                  }}
                >
                  <CCard>
                    <CCardBody style={{ background: '#ebedef' }}>
                      <tab.panelComponent
                        details={state[tab.key]}
                        isLoading={state.isFetching[tab.key]}
                        entry={entry}
                      />
                    </CCardBody>
                  </CCard>
                </CTabPane>
              )
            })}
          </CTabContent>
        </>
      </CCardBody>
    </CCard>
    {deleteConfirm ? (<><ActionConfirmation deleteConfirm={deleteConfirm} deleteAction={deleteAction} entry={entry} handleShowConfirmation={handleShowConfirmation}  /></>) : (<></>)}
    </>
  )
}

export default Seller
