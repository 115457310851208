import React, { useState, useEffect } from "react";
import {
  CCardText,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import axios from "axios";
import NoDetails from "./NoDetails";
import Swal from "sweetalert2";
import { postAPI } from "./../../../apiServices";

const CompanyInformation = ({ details = {}, isLoading = false }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [componyInfo, setcomponyInfo] = useState([]);
  const [gst, setGst] = useState([]);
  useEffect(() => {
    async function getBuyerInfo() {
      let userId = details?.userId;
      var { status, payload } = await postAPI("/get-buyer-info", {
        userId,
      });
      if (status === 1) {
        setcomponyInfo(payload.data);
      } else {
        setcomponyInfo("");
      }
    }
    getBuyerInfo();
  }, []);

  const updateStatus = async (gstNumber) => {
    setLoading(true);
    const gstApiKey = "09b9ff91aaa26d1fa548c29f7ff87485/";
    try {
      let url = "https://sheet.gstincheck.co.in/check/" + gstApiKey + gstNumber;
      let requestOptions = {
        method: "GET",
        url: url,
      };
      const ApiRes = await axios(url);

      //  postApiRes.data
      setModal(true);
      setGst(ApiRes.data);
      setLoading(false);
      Swal.close();

    } catch (err) {
      return { data: { error: "Server down" } };
    }
  };

  if (loading) {
    let timerInterval;
    Swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getContent();
          if (content) {
            const b = content.querySelector("b");
            if (b) {
              b.textContent = Swal.getTimerLeft();
            }
          }
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {

      }
    });
  }

  const showRupifyBalance = async (userId) => {
    let buyerDom = document.getElementById("showRupifyBalance"+userId)
    if(buyerDom?.textContent === 'Check Status'){
      buyerDom.style.color = "#3c4b64";
      buyerDom.style.cursor = 'text';
    }
    // let res = await postAPI('/rupifi/check-credit-eligibility', {userId: userId})
    let res = await postAPI('/switchpe/check-credit-line', {buyerID: userId})
    if(res?.status === 1){
      buyerDom.textContent = `${res?.payload?.availableLimit}`;
      // let rupifiStatus = res?.payload?.rupifiResponse;
      // if(rupifiStatus?.success === true){
      //   let rupifiData = rupifiStatus?.data;
      //   if(rupifiData?.status === 'ACTIVE'){
      //     let status = rupifiData?.account?.status;
      //     let balance = rupifiData?.account?.balance?.formattedValue;
      //     buyerDom.textContent = 'Available limit: '+balance + ', Status:'+status+'';
      //   }else{
      //     buyerDom.textContent = rupifiData?.status
      //   }

      // }else{
      //   buyerDom.textContent = "Not registered on rupifi"
      // }
    }else{
      buyerDom.textContent = `${res?.message}`;
    }

  }

  return (
    <div className="d-flex justify-content-between">
      {componyInfo?.length === 0 ? (
        <NoDetails isLoading={isLoading} tabName="Company" />
      ) : (
        <>
          <>
            <div className="w-30">
              {[
                { label: "Address Line 1", key: "buildingName" },
                { label: "Address Line 2", key: "area" },
                { label: "Pin Code", key: "postalCode" },
                { label: "City", key: "city" },
                { label: "State", key: "state" },
              ].map((item) => {
                return (
                  <div className="d-flex">
                    <CCardText className="font-weight-bold">
                      {item.label}:
                    </CCardText>
                    <CCardText className="ms-1">
                      {componyInfo && componyInfo.address[item.key]
                        ? componyInfo.address[item.key]
                        : " not available"}
                    </CCardText>
                  </div>
                );
              })}
              <div className="d-flex">
                  <CCardText className="font-weight-bold">
                    Latitude:
                  </CCardText>
                  <CCardText className="ms-1">
                    {componyInfo && componyInfo.address['geoLocation']?.latitude ? componyInfo.address['geoLocation']?.latitude : 'NA'}
                  </CCardText>
                </div>
                <div className="d-flex">
                  <CCardText className="font-weight-bold">
                    Longitude:
                  </CCardText>
                  <CCardText className="ms-1">
                  {componyInfo && componyInfo.address['geoLocation']?.longitude ? componyInfo.address['geoLocation']?.longitude : 'NA'}
                  </CCardText>
                </div>
                {componyInfo && componyInfo.address['geoLocation']?.latitude && componyInfo.address['geoLocation']?.longitude ? (
                  <div className="d-flex">
                  <CCardText className="font-weight-bold">
                    {/* <a href={"https://www.google.com/maps/@"+details?['geoLocation']?.latitude+","+details?['geoLocation']?.longitude} target="_blank"><i className="fa fa-map-marker"></i> View on Google Map</a> */}
                    <a href={"https://maps.google.com/?q="+componyInfo.address['geoLocation']?.latitude+","+componyInfo.address['geoLocation']?.longitude} target="_blank"><i className="fa fa-map-marker"></i> View on Google Map</a>
                  </CCardText>
                </div>
                ) : ("")}
            </div>
            <div style={{width:"40%"}}>
              {[
                // { label: 'Business Type', key: 'businessType' },
                { label: "GST Number", key: "gstNumber" },
                { label: "Trade Name", key: "shopName" },
                // { label: 'Representative Name', key: 'representativeName' },
                // { label: 'Representative Email', key: 'email' },
                // { label: 'Representative Phone', key: 'mobile' },
                // { label: 'Free Shipping', key: 'freeShipping' },
              ].map((item) => {
                return (
                  <div className="d-flex">
                    <CCardText className="font-weight-bold">
                      {item.label}:
                    </CCardText>
                    <CCardText className="ms-1">
                      {componyInfo[item.key]
                        ? componyInfo[item.key]
                        : " not available"}
                    </CCardText>
                    &nbsp;
                    {item.label === "GST Number" &&
                      componyInfo[item.key].length > 0 && (
                        <>
                          <a
                            href="javascript:void(0)"
                            onClick={() => {
                              updateStatus(componyInfo[item.key]);
                            }}
                          >RE
                            Verify
                          </a>
                          &nbsp;
                          <a
                            href={componyInfo?.documentDetails[0]}
                            target="_blank"
                          >
                            | View
                          </a>
                        </>
                      )}
                  </div>
                );
              })}

                <div className="d-flex">
                    <CCardText className="font-weight-bold">
                    SwitchPe Balance:
                    </CCardText>

                    <span  id={"showRupifyBalance"+details?.userId} style={{color:"blueviolet", cursor:"pointer", marginLeft:"5px"}} onClick={() => showRupifyBalance(details?.userId)}>Check Status</span>
                  </div>
            </div>
          </>
          <div>
            <div className="font-weight-bold">Document:&nbsp;</div>
            {componyInfo?.documentDetails.length == 0 ? (
              "not available"
            ) : (
              <>
                <div>
                  {(() => {
                    if (componyInfo.documentType == 1) {
                      return "Shop establishment paper";
                    } else if (componyInfo.documentType == 2) {
                      return "Aadhar+Shop Photo";
                    } else if (componyInfo.documentType == 3) {
                      return "Cancelled Cheque";
                    } else if (componyInfo.documentType == 4) {
                      return "At least 2 purchase invoice";
                    }
                  })()}
                </div>
                <div style={{ display: "flex" }}>
                  {componyInfo.documentDetails.map((item) => {
                    return (
                      <div>
                        {/* <a href={details.documentDetails[0]}> */}
                        <a href={item}>
                          <img
                            src={item}
                            style={{
                              height: "60px",
                              width: "70px",
                              padding: "3px",
                            }}
                            alt=""
                          />
                        </a>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {}
          </div>
        </>
      )}
      <CModal alignment="center" show={modal} size="lg" onClose={() => {}}>
        <CModalHeader>
          <CModalTitle>GST Verification</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className="d-flex justify-content-between mb-4 ms-3">
            <div className="w-33.3 col-4">
              {gst.data ? (
                <>
                  <div className="row">
                    GST Number:&nbsp;<b>{gst.data.gstin}</b>
                  </div>
                  <div className="mt-2 row">
                    Trader Name: <b>{gst.data.tradeNam}</b>
                  </div>
                  <div className="mt-2 row">
                    Registration Date:&nbsp; <b> {gst.data.rgdt}</b>
                  </div>
                  <div className="mt-2 row">
                    Frequency Type:&nbsp; <b>{gst.data.frequencyType}</b>
                  </div>
                  <div className="mt-2 row">
                    CTB:&nbsp; <b> {gst.data.ctb}</b>
                  </div>
                  <div className="mt-2 row">
                    CTJ:&nbsp; <b>{gst.data.ctj}</b>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="col-4">
              {gst.data ? (
                <>
                  <div className="row">
                    CXTD:&nbsp; <b>{gst.data.cxdt}</b>
                  </div>
                  <div className="mt-2 row">
                    DTY:&nbsp; <b>{gst.data.dty}</b>
                  </div>
                  <div className="mt-2 row">
                    Last Update:&nbsp; <b>{gst.data.lstupdt}</b>
                  </div>
                  <div className="mt-2 row">
                    Stj:&nbsp; <b>{gst.data.stj}</b>
                  </div>
                  <div className="mt-2 row">
                    Stj Cd:&nbsp; <b>{gst.data.stjCd}</b>
                  </div>
                  <div className="mt-2 row">
                    CtjCd:&nbsp; <b>{gst.data.ctjCd}</b>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="col-4">
              {gst.data ? (
                <div>
                  <div
                    className="row"
                    style={{ display: "flex", width: "45%" }}
                  >
                    NTR:<b> {gst.data.pradr.ntr} </b>
                  </div>
                  <div className="mt-2 row">
                    Location:&nbsp; <b> {gst.data.pradr.addr.loc} </b>
                  </div>
                  <div className="mt-2 row">
                    DST:&nbsp; <b> {gst.data.pradr.addr.dst} </b>
                  </div>
                  <div className="mt-2 row">
                    PinCode:&nbsp; <b> {gst.data.pradr.addr.pncd} </b>
                  </div>
                  <div className="mt-2 row">
                    ST:&nbsp; <b> {gst.data.pradr.addr.st} </b>
                  </div>
                  <div className="mt-2 row">
                    STCD:&nbsp; <b> {gst.data.pradr.addr.stcd} </b>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </CModalBody>
        <CModalFooter>
          {gst.data ? (
            <>
              <div style={{ color: "red", marginRight: "563px" }}>
                Note: {gst.message}
              </div>
            </>
          ) : (
            ""
          )}
          <CButton
            color="secondary"
            onClick={() => {
              setModal(false);
            }}
          >
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default CompanyInformation;
