import React, { useState } from "react";
import { Button, Input, Form, Label } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { putAPI } from "./../../../apiServices";
import { toast } from "react-toastify";
function RefundDetails({ details, type }) {
  const [date, setDate] = useState(new Date(details?.processedOn * 1000));
  const [buyerBankData, setbuyerBankData] = useState(
    details?.refund?.payment?.bank
  );
  const [item, setitem] = useState(details?.returns?.details);
  const [transactionId, settransactionId] = useState(
    details?.refund?.payment?.transactionId
  );
  const [amount, setamount] = useState(details?.refund?.payment?.amount);
  const [amountError, setamountError] = useState();

  const handleChange = (event) => {
    event.persist();
    setbuyerBankData(() => ({
      ...buyerBankData,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let listItem = [];
    if (item.length > 0) {
      for (let i = 0; i < item.length; i++) {
        listItem.push({
          returnItemId: item[i].returnItemId,
          qty: item[i].quantity,
        });
      }
    }
    const updateData = {
      action: type === "settlement" ? type : "settle",
      returnId: details?.returnId,
      orderId: details?.orderID,
      items: listItem,
      amount: amount,
      buyerPerson: buyerBankData.beneficiary,
      remark: "this is dummy",
      bankName: buyerBankData.bankName,
      branchName: buyerBankData.branchName,
      accountNumber: buyerBankData.accountNumber,
      ifsc: buyerBankData.ifsc,
      transactionId: transactionId,
    };

    const buyerResponse = await putAPI(
      "/return-orders/update-status",
      updateData
    );
    toast.info(buyerResponse.message);
  };

  const handleRefundAmount = (e) => {

    setamount(e.target.value)
    if(e.target.value < details?.refund?.payment?.amount){
      setamountError();
    }else{
      setamountError("Amount can not be greater than "+details?.refund?.payment?.amount)
    }
  }

  const handleDateChange = (event, data) => setDate(data.value);
  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <Form className="d-flex justify-content-end">
          <div d-flex align-items-center justify-content-end>
            <Label color="blue">
              Approved Item : {details?.refund?.payment?.items}
            </Label>
            <Label color="teal">
              Approved Quantity : {details?.returns?.approval?.quantity}
            </Label>
          </div>
        </Form>
        <Form.Field required>
          <label style={{display:"inline-flex"}}>Amount to be Refunded</label><span>{amountError}</span>
          <input
            placeholder="Amount"
            value={amount}
            name="amount"
            type={Number}
            readOnly={true}
            onChange={(event) => handleRefundAmount(event)}
          />
        </Form.Field>
        {/* <Form.Group widths="equal">
          <Form.Field
            required
            control={Input}
            value={transactionId}
            name="transactionId"
            label="Transaction Id"
            placeholder="Transaction Id"
            onChange={(event) => settransactionId(event.target.value)}
          />
          <Form.Field
            required
            control={Input}
            value={buyerBankData.bankName}
            name="bankName"
            label="Bank Name"
            placeholder="Bank name"
            onChange={handleChange}
          />
        </Form.Group> */}
        {/* <h4>Buyer Bank Details</h4> */}
        {/* <Form.Group widths="equal">
           <Form.Field
            required
            control={Input}
            name="beneficiary"
            value={buyerBankData.beneficiary}
            label="Beneficiary Name"
            placeholder="Beneficiary Name"
            onChange={handleChange}
          />
          <Form.Field
            required
            control={Input}
            name="accountNumber"
            value={buyerBankData.accountNumber}
            label="Account Number"
            placeholder="Account Number"
            onChange={handleChange}
          />
          <Form.Field
            required
            control={Input}
            name="ifsc"
            label="IFSC"
            value={buyerBankData.ifsc}
            placeholder="IFSC"
            onChange={handleChange}
          />
        </Form.Group> */}
        {/* <Form.Group widths="equal">
          <Form.Field
            required
            control={Input}
            name="bankName"
            value={buyerBankData.bankName}
            label="Bank Name"
            placeholder="Bank Name"
            onChange={handleChange}
          />
          <Form.Field
            required
            control={Input}
            name="branchName"
            value={buyerBankData.branchName}
            label="Branch Name"
            placeholder="Branch name"
            onChange={handleChange}
          />
          <SemanticDatepicker
            required
            label="Date"
            placeholder="Date"
            value={date}
            onChange={handleDateChange}
          />
        </Form.Group> */}
        <Button
          style={{ float: "right", width: "120px" }}
          color="blue"
          type="submit"
          disabled={amountError ? true : false}
        >
          Save
        </Button>
      </Form>
    </React.Fragment>
  );
}

export default React.memo(RefundDetails);
