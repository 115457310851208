import React, {useState} from "react";
import { Routes, Route } from "react-router-dom";
import { TheSidebar, TheFooter, TheHeader } from "./index";
import Dashboard from "../views/dashboard/Dashboard";
import Sellers from "../views/sellers/Sellers";
import Buyers from "../views/buyers/Buyers";
import Products from "../views/products/Products";
import Category from "../views/managecategory/Category";
import CategoryAdd from "../views/managecategory/CategoryAdd";
import CategorysMapping from "../views/managecategory/CategorysMapping";
import CategoryEdit from "../views/managecategory/CategoryEdit";
import CategoryChild from "../views/managecategory/CategoryChild";
import CategoryLevel3 from "../views/managecategory/CategoryLevel3";
import CategoryLevel4 from "../views/managecategory/CategoryLevel4";
import Attribute from "../views/attribute/Attributes";
import AttributeAdd from "../views/attribute/AttributeAdd";
import AttributeEdit from "../views/attribute/AttributeEdit";
import Brands from "../views/brand/Brands";
import User from "../views/access/UserManagement";
import Orders from "../views/orders/Orders";
import ReturnRefund from "../views/return&refund/Returns";
import Coupons from "../views/coupons/Coupons";
import Coupon from "../views/coupons/Coupon";
import Finance from "../views/finance/Finance";
import AllReport from "../views/reports/allReport";
import PaymentSetting from "../views/settings/payment_methods/PaymentSetting";
import GlobalPlatformMargin from "../views/settings/platform_margins/global_platform_margin";
import RoleManagement from "../views/access/RoleManagement";
import Module from "../views/access/ModuleManagement";
import Page404 from "../views/pages/page404/Page404";
import Page500 from "../views/pages/page500/Page500";
import { CContainer, CRow, CCol } from "@coreui/react";

const TheLayout = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible((prevVisible) => !prevVisible);
  };

  return (
    <div className="c-app c-default-layout" style={{ backgroundColor: "#ebedef" }}>
      <CContainer fluid={true}>
        <CRow>
          <CCol md={sidebarVisible ? 2 : 2} style={{ padding: 0, transition: "width 0.3s" }}>
            <TheSidebar visible={sidebarVisible} toggleSidebar={()=>toggleSidebar()} />
          </CCol>
          <CCol md={sidebarVisible ? 10 : 10} style={{ transition: "width 0.3s" }}>
            <div className="c-wrapper">
              <TheHeader toggleSidebar={()=>toggleSidebar()} />
              <div className="c-body" style={{minHeight:"100vh"}}>
                <Routes>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/sellers/:type" element={<Sellers />} />
                  <Route path="/buyers/:type" element={<Buyers />} />
                  <Route path="/products/:type" element={<Products />} />
                  <Route path="/order/:type" element={<Orders />} />
                  <Route path="/return&refund-due" element={<ReturnRefund />} />
                  <Route path="/return&refund-approved" element={<ReturnRefund />} />
                  <Route path="/return&refund-settlement" element={<ReturnRefund />} />
                  <Route path="/return&refund-cancelled" element={<ReturnRefund />} />
                  <Route path="/return&refund-rejected" element={<ReturnRefund />} />
                  <Route path="/return&refund-completed" element={<ReturnRefund />} />
                  <Route path="/reports/all-reports" element={<AllReport />} />
                  <Route path="/reports/products" element={<AllReport />} />
                  <Route path="/reports/sales" element={<AllReport />} />
                  <Route path="/reports/purchases" element={<AllReport />} />
                  <Route path="/reports/cancelled-orders" element={<AllReport />} />
                  <Route path="/reports/download-bulk-invoice" element={<AllReport />} />
                  <Route path="/categories-list" element={<Category />} />
                  <Route path="/add-category" element={<CategoryAdd />} />
                  <Route path="/category-attribute-mapping" element={<CategorysMapping />} />
                  <Route path="/edit-category" element={<CategoryEdit />} />
                  <Route path="/categories-list_2" element={<CategoryChild />} />
                  <Route path="/child-category_level_3" element={<CategoryLevel3 />} />
                  <Route path="/child-category_level_4" element={<CategoryLevel4 />} />

                  <Route path="/attribute-list" element={<Attribute />} />
                  <Route path="/attribute-list/attributeId/:attributeId/level/:level" element={<Attribute />} />
                  <Route path="/add-attribute" element={<AttributeAdd />} />
                  <Route path="/edit-attribute" element={<AttributeEdit />} />

                  <Route path="/brands-list" element={<Brands />} />
                  <Route path="/access-management-user" element={<User />} />


                  <Route path="/coupon" element={<Coupons />} />
                  <Route path="/add-coupon" element={<Coupon />} />
                  <Route path="/finance" element={<Finance />} />
                  <Route path="/settings/payment_methods" element={<PaymentSetting />} />
                  <Route path="/settings/global_platform_margin" element={<GlobalPlatformMargin />} />
                  <Route path="/access-management-role" element={<RoleManagement />} />
                  <Route path="/module" element={<Module />} />
                  <Route path="/500" element={<Page500 />} />
                  <Route path="*" element={<Page404 />} />
                </Routes>
              </div>
              <TheFooter />
            </div>
          </CCol>
        </CRow>

      </CContainer>


    </div>
  );
};

export default TheLayout;
