import React, { useState, useEffect } from "react";
import { postAPI } from "./../../../apiServices";
import { Table, Pagination } from "semantic-ui-react";
import moment from "moment";

const defaultState = {
  status: 0,
};

function ReturnHistory({ details, type }) {
  var urlPar = type;
  try {
    if (urlPar === "initiate") {
      urlPar = 0;
    } else if (urlPar === "approve") {
      urlPar = 1;
    } else if (urlPar === "reject") {
      urlPar = 2;
    } else if (urlPar === "settlement") {
      urlPar = 3;
    } else if (urlPar === "complete") {
      urlPar = 4;
    } else if (urlPar === "cancel") {
      urlPar = 5;
    }
    defaultState.status = urlPar;
  } catch (er) {
  }
  const [state, setState] = useState(defaultState);
  const [returnHistory, setreturnHistory] = useState([]);
  const [Records, setRecords] = useState({
    total: 0,
    totalPages: 0,
    current_page: 0,
    perPage: 10,
    nextPage: null,
    previousPage: null,
    startAt: 0,
  });

  const getReturnHistory = async (page) => {
    const data = {
      recPerPage: 10,
      page: page,
      status: state.status,
      userId: details?.userID,
      returnId: details?.returnId,
    };
    const buyerhistory = await postAPI("/return/history", data);
    if (buyerhistory.status === 1) {
      setreturnHistory(buyerhistory.payload);
      setRecords(buyerhistory.metaInfo);
    } else {
      setreturnHistory("");
    }
  };
  useEffect(() => {
    getReturnHistory(0);
  }, []);
  return (
    <div>
      {returnHistory?.length > 0 ? (
        <React.Fragment>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Order Number</Table.HeaderCell>
                <Table.HeaderCell>Return Number</Table.HeaderCell>
                <Table.HeaderCell>Item Count</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
                <Table.HeaderCell>Return Amount</Table.HeaderCell>
                <Table.HeaderCell>Process Date</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                {/* <Table.HeaderCell>Buyer Name</Table.HeaderCell> */}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {returnHistory?.map((history, index) => {
                if(history.returnId !== details?.returnId){
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>{history.orderNumber}</Table.Cell>
                      <Table.Cell>{history.returnOrderNumber}</Table.Cell>
                      <Table.Cell>{history.returns.approval.items !== 0  ? history.returns.approval.items : history.returns.items}</Table.Cell>
                      <Table.Cell>{history.returns.approval.quantity !== 0 ? history.returns.approval.quantity : history.returns.quantity}</Table.Cell>
                      <Table.Cell>{history.returns.approval.amount !== 0 ? history.returns.approval.amount : history.returns.amount}</Table.Cell>
                      <Table.Cell>
                        {history.status.code === 0 ? (<>
                          {moment(new Date(history.initiatedOn * 1000)).format(
                          "DD MMM yyyy"
                        )}
                        </>) : (<>
                          {moment(new Date(history.processedOn * 1000)).format(
                          "DD MMM yyyy"
                        )}
                        </>)}

                      </Table.Cell>
                      <Table.Cell>
                        {history.status.code === 0
                          ? "Initiated"
                          : history.status.code === 1
                          ? "Approved"
                          : history.status.code === 2
                          ? "Rejected"
                          : history.status.code === 3
                          ? "Settlement"
                          : history.status.code === 4
                          ? "Completed"
                          : "Cancelled"}
                      </Table.Cell>
                      {/* <Table.Cell>{history.buyer.name?.trim()}  ({history.buyer.tradeName?.trim()})</Table.Cell> */}
                    </Table.Row>
                  );
                }
              })}
            </Table.Body>
          </Table>
          <Pagination
            defaultActivePage={1}
            totalPages={Math.ceil(Records.totalPages / 10)}
            onPageChange={(event, data) =>
              getReturnHistory(data.activePage - 1)
            }
          />
        </React.Fragment>
      ) : (
        "No Data Found"
      )}
    </div>
  );
}

export default React.memo(ReturnHistory);
