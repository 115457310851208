import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const location = useLocation();
  const previousPathname = useRef(location.pathname); // Store the previous pathname

  useEffect(() => {
    if (previousPathname.current !== location.pathname) {
      // URL has changed
      window.scrollTo(0, 0);
      previousPathname.current = location.pathname; // Update the previous pathname
    }
  }, [location.pathname]); // Only run when pathname changes

  return null; // This component doesn't render anything
};

export default ScrollToTop;
