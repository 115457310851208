import React, { useEffect, useState } from "react";
import { Button, Form, Checkbox } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { CCard } from "@coreui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { getApiCall, postAPI } from "./../../apiServices";
import { toast } from "react-toastify";
import moment from "moment";
const localPermits = JSON.parse(localStorage.getItem("module"));

function Coupon() {
  const navigate = useNavigate();
  const location = useLocation();
  const [userCoupon, setuserCoupon] = useState(null);
  const [validfromdate, setvalidfromdate] = useState(null);
  const [validtodate, setvalidtodate] = useState(null);
  const [coupontype, setcoupontype] = useState(null);
  const [statustype, setstatustype] = useState(0);
  const [mainCategory, setmainCategory] = useState(null);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState([]);
  const [subCategory, setsubCategory] = useState(null);
  const [categoryList, setcategoryList] = useState([]);
  const [mainCouponCategory, setmainCouponCategory] = useState([]);
  const [couponSubCategory, setcouponSubCategory] = useState([]);
  const [paymentMode, setPaymentMode] = useState([]);
  const [couponList, setCouponList] = useState([]);
  const [permissions, setPermissions] = useState(
    localPermits.find((o) => o.moduleId == 32)
  );
  const regexp = /^\S*$/;
  const couponStatus = [
    { key: "1", value: "1", text: "Active" },
    { key: "0", value: "0", text: "InActive" },
  ];
  const couponType = [
    { key: "fixed", value: "fixed", text: "Fixed" },
    { key: "percent", value: "percent", text: "Percent" },
    { key: "freeShipping", value: "free-shipping", text: "Free Shipping" },
  ];

  useEffect(() => {
    if (location.state) {
      const coupon = location.state.coupon;
      coupon.discVal =
        coupon?.codeType === "percent"
          ? (coupon?.discVal * 100).toFixed(2)
          : coupon?.discVal;
      setuserCoupon(coupon);
    }
  }, []);
  const getUserCouponList = async (page) => {
    const data = {
      recPerPage: 10,
      page: 0,
      searchKeyword: "",
    };
    const { status, payload } = await postAPI("/coupons/list", data);
    if (status) {
      setCouponList(payload?.data);
    }
  };

  const couponId = JSON.parse(localStorage?.getItem("couponId"));
  const couponObj = couponList?.find((o) => o.couponId == couponId);
  useEffect(() => {
    if (couponObj) {
      setsubCategory(
        couponObj?.vCategories?.split(",").map((e) => parseInt(e))
      );
      setmainCategory(
        couponObj?.mainCategories?.split(",").map((e) => parseInt(e))
      );
      couponObj.discVal =
        couponObj?.codeType === "percent"
          ? (couponObj?.discVal * 100).toFixed(2)
          : couponObj?.discVal;

      setuserCoupon(couponObj);
    }
  }, [couponObj]);


  useEffect(() => {
    getUserCouponList();
  }, []);

  useEffect(() => {
    async function getPaymentMode(){
      let payMode = [];
      const { status, payload } = await getApiCall("/settings/get-payment-methods")
      if(status){
        payload?.forEach((ele) => {
          let val = {
            key:ele.pgId,
            value:ele.value,
            text: ele.label
          }
          payMode.push({
            value:JSON.stringify(val),
            text: ele.label,
            key:ele.pgId,
          })
        })
        setPaymentMode(payMode)
      }
    }
    getPaymentMode();
  },[])

  useEffect(() => {
    async function getCaegoryList() {
      let mainList = [];
      let subList = [];
      const { status, payload } = await postAPI("/get-all-categories", {});
      if (status) {
        setcategoryList(payload?.categories);
        payload?.categories[0].child.forEach((element) => {
          mainList.push({
            key: element.name,
            value: element.id,
            text: element.name,
          });

          element.child.forEach((item) => {
            // if (!subList.find((sub) => sub.text === item.name)) {
            subList.push({
              key: item.name,
              value: item.id,
              text: item.name,
              parentId: element.id,
            });
            // }
          });
        });
        setmainCouponCategory(mainList);
        setcouponSubCategory(subList);
      }
    }
    getCaegoryList();
  }, []);

  const handleChange = (event) => {
    event.persist();
    setuserCoupon((preState) => ({
      ...preState,
      [event.target.name]: event.target.value,
    }));
  };

  const handlefromChange = (event, data) => setvalidfromdate(data.value);
  const handletoChange = (event, data) => setvalidtodate(data.value);
  const saveCoupuns = async (e) => {
    let paymentPayload = [];
    selectedPaymentMode?.forEach((ele) => {
      let element = JSON.parse(ele)
      paymentPayload.push({
        mode: element.key,
        gateway: element.value
      })
    })

    const couponJson = {
      couponCode: userCoupon?.couponCode?.toUpperCase().trim(),
      paymentMode: null,
      applicableOrderCount: userCoupon?.applicableOrderCount || 0,
      codeType: userCoupon?.codeType ? userCoupon?.codeType : userCoupon,
      validFrom: moment.utc(validfromdate).valueOf() / 1000,
      validUpto: moment.utc(validtodate).valueOf() / 1000,
      userID: null,
      minOrderValue: userCoupon?.minOrderValue,
      userLimit: userCoupon?.userLimit ? userCoupon?.userLimit : 0,
      useLimit: userCoupon?.useLimit || 0,
      // discVal: userCoupon?.discVal,
      discVal:
        userCoupon.codeType === "percent"
          ? parseFloat(userCoupon?.discVal) / 100
          : userCoupon.codeType === "fixed" ? userCoupon?.discVal : 0,
      discCap: userCoupon?.discCap || 0,
      status: userCoupon?.status ? userCoupon?.status : statustype,
      description: userCoupon?.description,
      checkoutDescription: userCoupon?.checkoutDescription,
      showCoupon: userCoupon?.showCoupon ? userCoupon?.showCoupon : 0,
      pincodeAvailable: 0,
      couponAppliedOn: "defaultPrice",
      couponIcon: "",
      couponId: userCoupon?.couponId ? userCoupon?.couponId : "",
      categories: [
        {
          level: 1,
          catgeoryId: [1],
        },
        {
          level: 2,
          catgeoryId: mainCategory || [],
        },
        {
          level: 3,
          catgeoryId: subCategory || [],
        },
      ],
      paymentMode: paymentPayload
    };
    const { status, message } = await postAPI("/coupons/addEdit", couponJson);
    if (status) {
      // history.push("/coupon");
      navigate("/coupon");
    } else {
      toast.info(message);
    }
  };
  const handleshowChange = (e, { value, name }) => {
    setuserCoupon((preState) => ({
      ...preState,
      [name]: value,
    }));
  };
  const changeStatusType = (stype) => {
    setstatustype(stype);
  };

  const changeCouponType = (e, { value, name }) => {
    setuserCoupon((preState) => ({
      ...preState,
      [name]: value,
    }));
  };
  const changePaymentMode = (value) => {
    setSelectedPaymentMode(value);
  };
  const changeMainCategoryType = (cCaegory) => {
    setmainCategory(cCaegory);
  };
  const changeSubCategoryType = (sCaegory) => {
    setsubCategory(sCaegory);
  };

  useEffect(() => {
    if (couponObj) {
      let payMode = couponObj?.paymentMode
      let selPayMode = [];
      if(payMode){
        payMode.forEach((ele) => {
          let findPaymentMode = paymentMode?.find((o) => o.key == ele.mode);
          selPayMode.push(findPaymentMode?.value)
        })
        setSelectedPaymentMode(selPayMode)
      }
    }
  }, [couponObj, paymentMode]);

  const cValue = /^[0-9]{4}$/;

  return (
    <React.Fragment>
      <CCard style={{ padding: "15px", marginTop: "-15px" }}>
        <Form onSubmit={() => saveCoupuns()}>
          <Form.Group widths="equal">
            <Form.Dropdown
              label="Category"
              fluid
              multiple
              search
              selection
              // placeholder={userCoupon?.codeType}
              placeholder="Select..."
              options={mainCouponCategory}
              onChange={(e, { value }) => {
                changeMainCategoryType(value);
              }}
              value={mainCategory}
            />

            <Form.Dropdown
              label="Sub Category"
              content="fsdfs"
              fluid
              multiple
              search
              selection
              placeholder="Select..."
              options={couponSubCategory.filter(
                (c) => mainCategory?.indexOf(c.parentId) > -1
              )}
              onChange={(e, { value }) => {
                changeSubCategoryType(value);
              }}
              value={subCategory}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field required>
              <Form.Input
                required
                label="Coupon Code"
                placeholder="Coupon Code"
                name="couponCode"
                value={userCoupon?.couponCode
                  ?.toUpperCase()
                  .replace(/[^\w\s]/gi, "")
                  .replace(" ", "")}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                label="Minimum Order Value"
                placeholder="Value"
                name="minOrderValue"
                value={userCoupon?.minOrderValue}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Select
              label="Coupon Type"
              required
              name="codeType"
              options={couponType}
              value={userCoupon?.codeType}
              onChange={changeCouponType}
              placeholder="Select..."
              disabled={couponObj?.discVal ? true : false}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <Form.Input
                required = {userCoupon?.codeType === 'free-shipping' ? false : true}
                label="Coupon Value"
                placeholder={userCoupon?.codeType === "fixed" ? "₹" : "%"}
                type="number"
                name="discVal"
                readOnly={couponObj?.discVal || userCoupon?.codeType === 'free-shipping' ? true : false}
                disabled={couponObj?.discVal ? true : false}
                value={userCoupon?.discVal}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                label="Discount Cap"
                placeholder="₹"
                name="discCap"
                type="number"
                value={userCoupon?.discCap}
                readOnly={userCoupon?.codeType === 'free-shipping' ? true : false}
                disabled={couponObj?.discVal ? true : false}
                onChange={handleChange}
              />
            </Form.Field>

            <SemanticDatepicker
              required
              label="Valid From"
              placeholder="Valid From"
              name="validFrom"
              value={
                userCoupon?.validFrom
                  ? new Date(userCoupon?.validFrom * 1000)
                  : validfromdate
              }
              onChange={handlefromChange}
            />
            <SemanticDatepicker
              required
              label="Valid Upto"
              placeholder="Valid Upto"
              name="validUpto"
              value={
                userCoupon?.validUpto
                  ? new Date(userCoupon?.validUpto * 1000)
                  : validtodate
              }
              onChange={handletoChange}
            />
          </Form.Group>
          <Form.Group widths="two">
            <Form.Field>
              <Form.Input
                label="Applicable Order Count"
                placeholder="Order Count"
                name="applicableOrderCount"
                type="number"
                value={userCoupon?.applicableOrderCount}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Dropdown
              label="Applicable Payment Mode"
              fluid
              multiple
              search
              selection
              // placeholder={userCoupon?.codeType}
              placeholder="Select..."
              options={paymentMode}
              onChange={(e, { value }) => {
                changePaymentMode(value);
              }}
              value={selectedPaymentMode}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <Form.Input
                label="User Limit (use 0 for unlimited)"
                name="userLimit"
                value={userCoupon?.userLimit}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                label="Use Limit (use 0 for unlimited)"
                name="useLimit"
                value={userCoupon?.useLimit}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Select
              label="Coupon Status"
              required
              name="status"
              value={statustype}
              options={couponStatus}
              onChange={(e, { value }) => changeStatusType(value)}
              placeholder={userCoupon?.status === 0 ? "InActive" : "Active"}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <Form.TextArea
                rows={2}
                label="Coupon Description"
                placeholder="Coupon Description"
                name="description"
                value={userCoupon?.description}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              <Form.TextArea
                rows={2}
                label="Checkout Description"
                name="checkoutDescription"
                placeholder="Checkout Description"
                value={userCoupon?.checkoutDescription}
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="two">
            <Form.Field style={{ marginTop: "20px" }}>
              <Checkbox
                name="showCoupon"
                label="Show Coupon on Checkout"
                value={userCoupon?.showCoupon ? userCoupon?.showCoupon : 0}
                onChange={handleshowChange}
              />
            </Form.Field>
          </Form.Group>
          <div className="d-flex justify-content-lg-start m-2">
            <Button
              disabled={permissions.isEdit === 0 ? true : false}
              style={{ width: "120px" }}
              color="blue"
              type="submit"
            >
              Save
            </Button>
            <Button
              disabled={permissions.isEdit === 0 ? true : false}
              style={{ width: "120px" }}
              color="youtube"
              type="button"
              onClick={() => navigate("/coupon")}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </CCard>
    </React.Fragment>
  );
}

export default React.memo(Coupon);
