import React from 'react'
import { CFooter } from '@coreui/react'

const TheFooter = () => {
  return (
    <CFooter fixed={false}>
      <div>
        <span className="ms-1">&copy; 2023 3Fate Technologies Pvt Ltd. All Rights Reserved</span>
      </div>
    </CFooter>
  )
}

export default React.memo(TheFooter)
