import React, { useState, useEffect, useCallback } from "react";
import { Button, Table, Input } from "semantic-ui-react";
import { postAPI } from "../../../apiServices";
import { CBadge } from "@coreui/react";
import Swal from "sweetalert2";
import ImageGalleryWithCaption from "../../../common-components/image-viewer/ImageGalleryWithCaption"

const defaultState = {
  entries: [],
  totalEntries: 0,
  page: 0,
  recPerPage: 50,
  loading: false,
  buyerOrderSubtotal: 0,
  sellerOrderSubTotal: 0,
  orderRedeemedLP: 0,
  orderCreditedLP: 0,
  buyerGrandTotal: 0,
  sellerGrandTotal: 0,
  orderCouponDiscount:0,
  orderCouponName:"",
  shipping: 0,
};

const OrderItem = ({ details = {}, isLoading = false, section = "", canEdit="" }) => {
  const [state, setState] = useState(defaultState);
  const [isEdit, setIsEdit] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [customInvoiceAmount, setCustomInvoiceAmount] = useState(details?.customInvoiceData?.invoiceAmount ? details?.customInvoiceData?.invoiceAmount : 0)

  useEffect(() => {
    async function fetchData() {
      fetchOrders();
    }
    fetchData();
  }, []);

  let sellerOrderSubTotal = [];
  let buyerOrderSubTotal = [];
  const Total = (item) => {
    orderData.map((order) => {
      if (order?.orderItemId === item?.orderItemID) {
        return (
          <>
            {sellerOrderSubTotal?.push(
              parseInt(order?.quantity || item?.setQty) *
                parseInt(
                  order?.sellerPricePerItem || item?.sellerPricePerItem
                ) *
                item.setItem
            )}
            {buyerOrderSubTotal?.push(
              parseInt(order?.quantity || item?.setQty) *
                parseInt(order?.buyerPricePerItem || item?.buyerPricePerItem) *
                item.setItem
            )}
          </>
        );
      }
    });
  };
  const onValueChange = (value, key, orderItemID) => {
    setOrderData((previousOrderData) =>
      previousOrderData.map((order) => {
        if (order?.orderItemId === orderItemID) {
          return {
            ...order,
            [key]: value,
          };
        }
        return order;
      })
    );
  };

  const fetchOrders = useCallback(async (page = 0, recPerPage = 50) => {
    try {
      if (!details.orderID) {
        return;
      }
      setState((prevState) => ({ ...prevState, loading: true }));
      const data = {
        page,
        recPerPage,
        orderID: details.orderID,
      };
      const { status, payload } = await postAPI("/orders/items/list", data);
      console.clear();

      setState((prevState) => ({ ...prevState, loading: false }));
      // Swal.close();

      if (!status) {
        // if (message !== "No Order found")
        // toast.error(message || MESSAGES?.sellers?.defaultApiError);
        setState((prevState) => ({
          ...prevState,
          entries: defaultState.entries,
          totalEntries: defaultState.totalEntries,
          buyerOrderSubtotal: defaultState.buyerOrderSubtotal,
          sellerOrderSubTotal: defaultState.sellerOrderSubTotal,
          buyerGrandTotal: defaultState.buyerGrandTotal,
          sellerGrandTotal: defaultState.sellerGrandTotal,
          shipping: defaultState.shipping,
          orderRedeemedLP: defaultState.orderRedeemedLP,
          orderCreditedLP: defaultState.orderCreditedLP,
          orderCouponDiscount:defaultState.orderCouponDiscount,
          orderCouponName:defaultState.orderCouponName
        }));
        setOrderData(
          defaultState.entries.map((d) => ({
            orderItemId: d.orderItemID,
          }))
        );
      } else {
        setState((prevState) => ({
          ...prevState,
          entries: payload?.data || defaultState.entries,
          buyerOrderSubtotal:
            payload?.buyerOrderSubtotal || defaultState.buyerOrderSubtotal,
          sellerOrderSubTotal:
            payload?.sellerOrderSubTotal || defaultState.sellerOrderSubTotal,
          sellerGrandTotal:
            payload?.sellerGrandTotal || defaultState.sellerGrandTotal,
          buyerGrandTotal:
            payload?.buyerGrandTotal || defaultState.buyerGrandTotal,
          shipping: payload?.shipping || defaultState.shipping,
          orderRedeemedLP: payload?.orderRedeemedLP || defaultState.orderRedeemedLP,
          orderCreditedLP: payload?.orderCreditedLP || defaultState.orderCreditedLP,
          orderCouponDiscount:payload?.orderCouponDiscount || defaultState.orderCouponDiscount,
          orderCouponName:payload.orderCouponName  || defaultState.orderCouponName
          // totalEntries: payload?.totRecords || defaultState.totalEntries,
        }));

        setOrderData(
          payload?.data.map((d) => ({ orderItemId: d.orderItemID })) ||
            defaultState.entries.map((d) => ({
              orderItemId: d.orderItemID,
            }))
        );
      }
    } catch (er) {
    }
  }, []);

  const SaveOrderEdit = async () => {
    const data = JSON.stringify({
      orderID: details?.orderID,
      itemsData: orderData,
    });

    const { payload } = await postAPI("/orders/update-details", data);
  };

  const UpdateEditOrderBtn = () => {
    Swal.fire({
      icon: "info",
      title: "Do you want to save changes ?",
      showConfirmButton: true,
      confirmButtonText: "Yes",
      cancelButtonColor: "lightgray",
      showCancelButton: true,
    }).then(async (er) => {
      if (er.isConfirmed === true) {
        SaveOrderEdit();
        isLoading = true;
        setTimeout(function () {
          fetchOrders();
          Swal.fire("Saved!", "", "success");
          setIsEdit(false);
        }, 1000);
      } else if (er.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const handleEditButtom = () => {
    setIsEdit(true);
  };
  const handleCancelEdit = () => setIsEdit(false);

  const EditButton = () => {
    if (isEdit === false && canEdit === true) {
      return (
        <>
          <Button
            style={{ float: "right", width: "120px" }}
            color="blue"
            type="edit"
            onClick={() => {
              handleEditButtom();
            }}
          >
            Edit Order
          </Button>
        </>
      );
    } else if (isEdit === true && canEdit === true) {
      return (
        <>
          <Button
            style={{ float: "right", width: "120px" }}
            color="blue"
            type="cancel"
            onClick={() => {
              handleCancelEdit();
              fetchOrders();
            }}
          >
            Cancel
          </Button>
          <Button
            style={{ float: "right", width: "120px" }}
            color="blue"
            type="save"
            onClick={() => UpdateEditOrderBtn()}
          >
            Save
          </Button>
        </>
      );
    }
  };

  return (
    <>
      <div>
        <div>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Product</Table.HeaderCell>
                <Table.HeaderCell>SKU</Table.HeaderCell>
                <Table.HeaderCell style={{}}>
                  Seller Price
                  <br /> per item
                </Table.HeaderCell>
                <Table.HeaderCell style={{}}>
                  Buyer Price
                  <br /> per item
                </Table.HeaderCell>
                <Table.HeaderCell>Item Count</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
                <Table.HeaderCell>Seller Sub Total</Table.HeaderCell>
                <Table.HeaderCell>Buyer Sub Total</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {(state?.entries || []).map((item, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell className="d-flex">
                      <div>
                      <ImageGalleryWithCaption images={item?.images} captions={[]} viewPreview={false} viewInitialImage={true} />
                      </div>
                      <div className="ms-5">
                        {item.productName}
                        <br />
                        <div className="pt-2 pb-2">
                          <CBadge className="p-2" color="warning">
                            {item.productType === 1
                              ? "Color Variant"
                              : "Set Variant"}
                          </CBadge>
                        </div>
                      </div>
                    </Table.Cell>

                    <Table.Cell>{item.skuCode}</Table.Cell>
                    <Table.Cell style={{}}>
                      {/* {item.productType == 1 ? item.priceOnApp : item.priceOnApp / item.setQty} */}
                      {/*Seller Price Per Item */}
                      {(isEdit === true && canEdit === true) ? (
                        <Input
                          style={{ maxWidth: "50%" }}
                          type="number"
                          min="1"
                          defaultValue={item?.sellerPricePerItem}
                          onChange={(e) => {
                            onValueChange(
                              parseInt(e.target.value),
                              "sellerPricePerItem",
                              item.orderItemID
                            );
                          }}
                        />
                      ) : (
                        item.sellerPricePerItem
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {/* Buyer Price Per Item */}
                      {(isEdit === true && canEdit=== true) ? (
                        <Input
                          style={{ maxWidth: "50%" }}
                          type="number"
                          min="1"
                          defaultValue={item.buyerPricePerItem}
                          onChange={(e) => {
                            onValueChange(
                              parseInt(e.target.value),
                              "buyerPricePerItem",
                              item.orderItemID
                            );
                          }}
                        />
                      ) : (
                        item.buyerPricePerItem
                      )}
                    </Table.Cell>

                    <Table.Cell>
                      <>{item.setItem}</>
                    </Table.Cell>
                    <Table.Cell>
                      <>
                        {(isEdit === true && canEdit === true)? (
                          <>
                            <Input
                              style={{ maxWidth: "40%" }}
                              type="number"
                              min="1"
                              defaultValue={item.setQty}
                              onChange={(e) => {
                                onValueChange(
                                  parseInt(e.target.value),
                                  "quantity",
                                  item.orderItemID
                                );
                              }}
                            />
                          </>
                        ) : (
                          item.setQty
                        )}
                      </>
                    </Table.Cell>

                    <Table.Cell>
                      {(isEdit === true && canEdit === true) ? (
                        <>
                          {orderData.map((order) => {
                            return (
                              <>
                                {order?.orderItemId === item?.orderItemID ? (
                                  <>
                                    {Total(item)}
                                    {parseInt(order?.quantity || item?.setQty) *
                                      parseInt(
                                        order?.sellerPricePerItem ||
                                          item?.sellerPricePerItem
                                      ) *
                                      item.setItem}
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })}
                        </>
                      ) : (
                        item.sellerSubTotal
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {(isEdit === true && canEdit === true) ? (
                        <>
                          {orderData.map((order) => {
                            return (
                              <>
                                {order?.orderItemId === item?.orderItemID ? (
                                  <>
                                    {parseInt(order?.quantity || item?.setQty) *
                                      parseInt(
                                        order?.buyerPricePerItem ||
                                          item?.buyerPricePerItem
                                      ) *
                                      item.setItem}
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })}
                        </>
                      ) : (
                        item.buyerSubTotal
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}

              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell>
                  <div className="mb-2">Order subtotal</div>
                  <div className="mb-2">Handling Charges</div>
                  {state?.orderCouponDiscount > 0 ? (
                    <div className="mb-2">
                      <div>Coupon Discount</div>
                      <div style={{fontWeight:"bold"}}>({state?.orderCouponName})</div>
                    </div>
                  ):("")}

                </Table.Cell>
                <Table.Cell>
                <span style={{textAlign:"right", display:"inline-block"}}>
                  <div className="mb-2">
                    {" "}
                    {(isEdit === true && canEdit === true) ? (
                      <>
                        {sellerOrderSubTotal.reduce(
                          (prevValue, currentValue) => prevValue + currentValue,
                          0
                        )}
                      </>
                    ) : (
                      state.sellerOrderSubTotal
                    )}
                  </div>

                  <div className="mb-2">
                    0
                  </div>

                    {state?.orderCouponDiscount > 0 ? (
                      <div className="mb-2">
                      0
                      </div>
                    ) : ("")}
                  </span>
                </Table.Cell>
                <Table.Cell>
                  <span style={{textAlign:"right", display:"inline-block"}}>
                  <div className="mb-2">
                    {" "}
                    {(isEdit === true && canEdit === true) ? (
                      <>
                        {buyerOrderSubTotal.reduce(
                          (prevValue, currentValue) => prevValue + currentValue,
                          0
                        )}
                      </>
                    ) : (
                      state.buyerOrderSubtotal
                    )}
                  </div>
                  <div className="mb-2">{state?.shipping}</div>

                  {state?.orderCouponDiscount > 0 ? (
                    <div className="mb-2">- {state?.orderCouponDiscount}</div>
                  ) : ("")}
                  </span>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell><span style={{fontWeight:"bold", fontSize:"14px"}}>Gross Total</span></Table.Cell>
                <Table.Cell>
                  <div className="2" style={{fontWeight:"bold", fontSize:"14px"}}>
                  {" "}
                  {(isEdit === true && canEdit === true) ? (
                    <>
                      {sellerOrderSubTotal.reduce(
                        (prevValue, currentValue) => prevValue + currentValue,
                        state?.shipping
                      )}
                    </>
                  ) : (
                    state.sellerGrandTotal
                  )}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div style={{fontWeight:"bold", fontSize:"14px"}}>
                  {" "}
                  {(isEdit == true && canEdit === true) ? (
                    <>
                      {buyerOrderSubTotal.reduce(
                        (prevValue, currentValue) => prevValue + currentValue,
                        state?.shipping
                      )}
                    </>
                  ) : (
                    state.buyerGrandTotal
                  )}
                  </div>
                </Table.Cell>
              </Table.Row>
              {details?.advance > 0 && <>
              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell><span style={{fontWeight:"bold", fontSize:"14px"}}>Advance (Paid) </span></Table.Cell>
                <Table.Cell>-
                </Table.Cell>
                <Table.Cell>
                  <div style={{fontWeight:"bold", fontSize:"14px"}}>
                  {details?.advance}
                  </div>
                </Table.Cell>
              </Table.Row>

              <Table.Row>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell><span style={{fontWeight:"bold", fontSize:"14px"}}>Balance</span></Table.Cell>
              <Table.Cell>-
              </Table.Cell>
              <Table.Cell>
                <div style={{fontWeight:"bold", fontSize:"14px"}}>
                {state.buyerGrandTotal - details?.advance}
                </div>
              </Table.Cell>
              </Table.Row>
              </>
              }
              <Table.Row>
                <Table.Cell collapsing={3}>
                  {details?.isCustomInvoice === 1 && <>
                    <div className="mb-2">
                      <strong>Showroom's Seller Invoice: </strong> ₹{state.sellerGrandTotal} || &nbsp;
                      <strong>Seller's Custom Invoice: </strong> ₹{customInvoiceAmount} ||  &nbsp;
                      <strong>Diff: </strong>{state.sellerGrandTotal - customInvoiceAmount === 0 ? (<>{state.sellerGrandTotal - customInvoiceAmount}</>) : (<><span style={{color:"#ff0000"}}>{state.sellerGrandTotal - customInvoiceAmount}</span></>)}
                    </div>

                    </>}
                </Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>

              </Table.Row>
            </Table.Body>
          </Table>
          {section === "placed" ||
          section === "confirmed" ||
          section === "packed" ? (
            <>{EditButton()}</>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(OrderItem);
