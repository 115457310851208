export const changeDateFormat_DMY = (inputDateString, format="YMD") =>{

    // Create a Date object from the input string
    const inputDate = new Date(inputDateString);

    // Extract the year, month, and day from the Date object
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Month is 0-based, so add 1 and pad with leading zero
    const day = String(inputDate.getDate()).padStart(2, '0');

    // Form the desired date string in 'YYYY-MM-DD' format
    let formattedDate = `${year}-${month}-${day}`;
    if(format === "dd-MM-yyyy"){
      // Form the desired date string in 'dd-MM-yyyy' format
      formattedDate = `${day}-${month}-${year}`;
    }
    return formattedDate;

}



export const changeDateFormat_toLocaleString = (inputDateString) => {
    const inputDate = new Date(inputDateString + 'T00:00:00Z'); // Assuming UTC time
    // Options for formatting the date
    const options = {
    weekday: 'short',
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'long'
    };

    // Convert the date to the desired format
    const formattedDate = inputDate.toLocaleString('en-US', options);
    return formattedDate;
}

export const convertCamelCaseToNormalWord = (str) => {
    if(str){
        return str.replace(/([a-z])([A-Z])/g, '$1 $2');
    }else{
        return "Drop Down";
    }

  };

  export const isBeforeGivenHours = (givenTimestamp, hours) => {
    // Get current timestamp in seconds
    const currentTimestamp = Math.floor(Date.now() / 1000);

    // Calculate timestamp at given hours ago
    const givenHoursAgo = currentTimestamp - (hours * 60 * 60);

    // Compare the given timestamp with timestamp of given hours ago
    return givenTimestamp > givenHoursAgo;
}

export const compareCurrentTimeWithGiven = (givenTimestamp) => {
  // Get current timestamp in seconds
  const currentTimestamp = Math.floor(Date.now() / 1000);
  // Compare the given timestamp with current timestamp
  return givenTimestamp > currentTimestamp;
}

export const numberWithComma = (numValue) => {
  let number = numValue;
  let formattedNumber = '';

  if (number.length <= 3) {
    formattedNumber = number;
  } else if (number.length <= 5) {
    formattedNumber = `${number.slice(0, number.length - 3)},${number.slice(-3)}`;
  } else if (number.length <= 7) {
    formattedNumber = `${number.slice(0, number.length - 5)},${number.slice(-5, -3)},${number.slice(-3)}`;
  } else {
    formattedNumber = `${number.slice(0, number.length - 7)},${number.slice(-7, -5)},${number.slice(-5, -3)},${number.slice(-3)}`;
  }
    return formattedNumber
}

export const getUrlFileExtension = (url) => {
    const parts = url.split('/');
    let file = parts[parts.length - 1].split('.');
    let extension = file[file.length-1];
    return extension.toLowerCase();
  }

export const getDateBeforeNDays = (beforeN, days) => {
    const today = new Date();
    const dateBeforeGiven = new Date(today);
    if(days === 'days'){
      dateBeforeGiven.setDate(today.getDate() - beforeN);
    }else if(days === 'months'){
      dateBeforeGiven.setMonth(today.getMonth() - beforeN);
    }
    else if(days === 'years'){
      dateBeforeGiven.setFullYear(today.getFullYear() - beforeN);
    }

    return dateBeforeGiven;
  };

  export const getCurrentUrl = () => {
    const currentPageUrl = window.location.href;
    const startIndex = currentPageUrl.indexOf('/#/');
    let result = '/'
    if (startIndex === -1) {
      result = '/#/dashboard'
    } else {
      result = currentPageUrl.substring(startIndex + 3);
      result = '/#/'+result
    }
    result = result.replace('#undefined', '');
    result = result.replace('undefined', '');
    result = result.replace('/login/', '/');
    result = result.replace('//', '/');
    window.localStorage.setItem("currentUrl", result.replace('/login/',''));
    return result;
  }

  export const getAutoLogoutUrl = () => {
    let result = window.localStorage.getItem("currentUrl");
    result = '/#/login/'+result.replace('#/','');
    result = result.replace('//','/');
    // window.location.href='/#/login/'+result.replace('/#/','')
    window.location.href = result;
    localStorage.removeItem('TOKEN')
  }
