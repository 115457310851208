import React from 'react';
import { Avatar } from '../../common-components';
import { CBadge } from "@coreui/react";
const STATUS = [
  { label: 'Active', key: 1 },
  { label: 'Inactive', key: 0 },
  { label: 'Blocked', key: -1 },
];
const PRICE_DETAILS = [
  { label: 'MRP', field: 'mrp' },
  { label: 'Price On App', field: 'priceOnApp' },
  { label: 'Taxable Price', field: 'taxablePrice' },
  { label: 'Factory Price', field: 'factoryPrice' },
];

const PRODUCT_DETAILS = [
  { label: 'Product Type', field: 'productType' },
  { label: 'SKU Count', field: 'skus', render: row => row?.skus?.length },
  { label: 'Status', field: 'status', render: row => STATUS.find(status => status.key === row.status)?.label },
  { label: 'Seller Name', field: 'sellerName' },
];
const config = [
  { label: "Brand Name", field: "brand" },
  { label: "Super Category", field: "superCategory" },
  { label: "Main Category", field: "mainCategory" },
  { label: "Category", field: "category" },

];
const productconfig = [
  { label: "Sub Category", field: "subCategory" },
  { label: "Sizing System", field: "sizingSystemTxt" },
  { label: "Product Name", field: "fullName" },
  { label: "Short Name", field: "shortName" },
];
const productconfig2 = [
  { label: "Dispatch Time", field: "dispatchDays" },
  { label: "Packaging Type", field: "packagingTypeTxt" },
  { label: "HSN Code", field: "hsnCode" },
  // { label: "Description", field: "description" },
];
const PRODUCT_TYPE = {
  1: { label: "COLOR VARIANTS", color: "success" },
  2: { label: "SETS VARIANTS", color: "warning" },
};
const STATUS_TYPE = {
  1: { label: "ACTIVE", color: "success" },
  2: { label: "INACTIVE", color: "danger" },
};

const noop = () => { };
const ProductDetails = ({ product = {}, showImageViewer = noop, }) => {
  // debugger
  return (
    <div>
      <div className='d-flex align-items-center'>
        <Avatar
          alt={product?.fullName}
          size={60}
        />
        <div className='ms-2'>
          <h5 className='mb-1'>{product?.fullName}</h5>
          <div className="pt-2 pb-2">
            <CBadge
              className="p-2"
              color={PRODUCT_TYPE[product.productType]?.color}
            >
              {PRODUCT_TYPE[product.productType]?.label}
            </CBadge>
          </div>
        </div>
      </div>
      <br />
      <h5 className='mb-2'>Product Details:</h5>
      <div style={{ width: '100%', height: 0.5, backgroundColor: '#08176C' }} />
      <div className='mt-2 d-flex justify-content-between'>
        <div>
          {config.map(item => (
            <p className='mb-1'>{item.label}: <b>{product[item.field] || ''}
              {product.brandDetails && item.field === "brand"
                ? product.brandDetails[0].name
                : ''}</b></p>
          ))}
        </div>
        <div>
          {productconfig.map(item => (
            <p className='mb-1'>{item.label}: <b>{product[item.field] || '-'}
              {product.brandDetails && item.field === "dispatchDays"
                ? ' days'
                : ''}

            </b></p>
          ))}
        </div>
        <div>
          {productconfig2.map(item => (
            <p className='mb-1'>{item.label}: <b>{product[item.field] || '-'}
              {product.brandDetails && item.field === "dispatchDays"
                ? ' days'
                : ''}

            </b></p>
          ))}
        </div>
      </div>
      <br />

      <div>
        <p className='mb-1'>Description: &nbsp;
          {product['overview'] && (
            <b>{product['overview'] || '-'}</b>
          )}
        </p>
      </div>

      <h5 className='mt-2'>{product.productType === 1 ? 'Attributes' : 'Specification:'}</h5>
      <div style={{ width: '100%', height: 0.5, backgroundColor: '#08176C' }} />
      <div className='mt-2'>
        <div className='d-flex justify-content-between'>
          {product.productAttributes && product.productAttributes.map((item, index) => {
            if (index < 3) {
              return (<>{item.child.map(con => (
                <div>{item.value}: <b> {con.value}</b></div>
              ))}</>)
            }
          })}
        </div>
        <div className='d-flex justify-content-between'>
          {product.productAttributes && product.productAttributes.map((item, index) => {
            if (index >= 3 && index < 6) {
              return (<> {item.child.map(con => (
                <div style={{ alignSelf: 'centre' }}>{item.value}: <b> {con.value}</b></div>
              ))}</>)
            }
          })}
        </div>
        <div className='d-flex justify-content-between'>
          {product.productAttributes && product.productAttributes.map((item, index) => {
            if (index >= 6) {
              return (<> {item.child.map(con => (
                <div style={{ alignSelf: 'centre' }}>{item.value}: <b> {con.value}</b></div>
              ))}</>)
            }
          })}
        </div>
      </div>
      <br />
      <div className='mt-2 d-flex justify-content-between'>
        <div> <h5 >{product?.fullName}</h5></div>
        <div className="pb-1">
          <CBadge className="p-2" color={STATUS_TYPE[product.status]?.color}>
            {STATUS_TYPE[product.status]?.label}
          </CBadge>
        </div>
      </div>
      <div style={{ width: '100%', height: 0.5, backgroundColor: '#08176C' }} />
      <div className='mt-2'>
        {product.skus && product.skus.map(item => (
          <div>
            <b >{item.title}</b>
            <div className='mt-2 d-flex justify-content-between'>
              <div>
                <Avatar
                  alt={product?.fullName}
                  src={product.skus && item.images[0]}
                  size={90}
                  onClick={() => {
                    if (!!item.images.length) showImageViewer(item.images)
                  }}
                />
              </div>
              <div>
                <br />
                {product.productType === 2 ? <>
                  Sku Code:
                  <b> {item.skuCode ? item.skuCode : '-'} </b>
                </> : <><br />Factory Price:
                  <b> {product.productType === 1 ? '\u20B9 ' + product?.factoryPrice : '\u20B9 ' + item.factoryPrice} </b></>}
                <br />
                Taxable Price:
                <b> {product.productType === 1 ? '\u20B9 ' + product?.taxablePrice : '\u20B9 ' + item.taxablePrice} </b>
                <br />
                {product.productType === 2 && (
                  <>
                    Price Per Item:
                    <b> {'\u20B9 ' + item.pricePerItem} </b>
                    <br />
                  </>
                )}

                Dimension:
                <b> {item.dimensionValue} cm</b>
                <br />
                {product.productType === 2 && (<table className="table">
                  <tr>
                    <th style={{ color: 'black' }}>Color</th>
                    <th></th>
                    <th style={{ color: 'black' }}>Sizes</th>
                    <th></th>
                    <th style={{ color: 'black' }}>Items In Set</th>
                  </tr>
                  {item.variants.map(con => {
                    return (
                      <tr>
                        <td >{con.origcolorName}</td>
                        <th></th>
                        <td style={{ textAlign: 'center' }}>{con.size}</td>
                        <th></th>
                        <td style={{ textAlign: 'center' }}>{con.stockQty}</td>
                      </tr>
                    )
                  })}
                </table>)}
                {product.productType === 1 && (<table className="table">
                  <tr>
                    <th style={{ color: 'black' }}> Sizes</th>
                    {item.variants.map(con => {
                      return (<td>{con.size}</td>)
                    })}
                  </tr>
                  <tr><th style={{ color: 'black' }}>Stock </th>{item.variants.map(con => {
                    return (<td>{con.stockQty}</td>)
                  })}</tr>
                  <tr><th style={{ color: 'black' }}>Color </th><td>{item.origColorName}</td> </tr>
                </table>)}
              </div>
              <div>
                <br />
                {product.productType === 2 ? <>
                  Factory Price:
                  <b> {product.productType === 1 ? '\u20B9 ' + product?.factoryPrice : '\u20B9 ' + item.factoryPrice} </b>
                </> : ''}
                <br />
                GST:
                <b> {(item.taxPercent * 100).toFixed(2)} %</b>
                <br />
                MRP:
                <b> {product.productType === 1 ? '\u20B9 ' + product?.mrp : '\u20B9 ' + item.mrp} </b>
                <br />
                MOQ:
                <b> {product.productType === 1 ? product?.moq :
                  item.variants.reduce((a, v) => a = a + v.stockQty, 0)
                } </b>
                <br />
              </div>
              <div>
                <br />
                {product.productType === 1 ? <br /> : ''}

                Margin:
                {/* <b> {product.productType === 1 ? 10 + '%' : 10 + '%'} </b> */}
                {product.showroomMargin && (<b> {product.showroomMargin + '%'} </b>)}
                <br />
                Price On App:
                <b> {product.productType === 1 ? '\u20B9 ' + product?.priceOnApp : '\u20B9 ' + item.priceOnApp} </b>
                <br />
                Weight:
                <b> {item.packageWeight} gm</b>
                <br />
                {product.productType === 2 ?
                  <>
                    Total Items In Set:
                    <b> {product.productType === 1 ? product?.moq :
                      item.variants.reduce((a, v) => a = a + v.stockQty, 0)
                    } </b>
                  </> : ''}
                <br />
              </div>
            </div>
          </div>
        ))}

      </div>
      {/* <h5 className='mb-2' >Pricing:</h5>
      <hr /> */}
      {/* <div className='mt-0 d-flex justify-content-between'> */}
      {/* <div>
          <Avatar
            alt={product?.fullName}
            src={product.skus && product.skus[0].images[0]}
            size={90}
          />
        </div> */}
      {/* <div>
          {PRICE_DETAILS.map(item => (
            <p className='mb-1'>{item.label}: <b>{product[item.field] || '-'}</b></p>
          ))}
        </div>
        <div>
          {PRODUCT_DETAILS.map(item => (
            <p className='mb-1'>{item.label}: <b>{item.render ? item.render(product) : product[item.field] || '-'}</b></p>
          ))}
        </div>
      </div> */}
    </div>

  );
};

export default ProductDetails;
