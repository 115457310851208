
import React from 'react';
import NoDetails from './NoDetails';
import { CCard, CCardBody, CCardText, CContainer, CRow, CCol } from '@coreui/react';

const TYPE = [
  { label: 'Unregistered', value: 0 },
  { label: 'Registered', value: 1 },
];

const DOCUMENT_TYPE = [
  { label: 'Unknown', value: 0 },
  { label: 'Ownership', value: 1 },
  { label: 'Distributorship', value: 2 },
];

const Brands = ({
  details = null,
  isLoading = false,
}) => {
  return (
    <div className='d-flex'>
      <div className='w-100'>
        {!details ? (
          <NoDetails isLoading={isLoading} tabName='Brands' />
        ) : (
          <CContainer fluid>
            <CRow>
              {(details || []).map(detail => (
                <CCol md="4" sm="6">
                  <CCard>
                    <CCardBody>
                      <div className='d-flex align-items-center'>
                        {detail?.image ? (
                          <img src={detail?.image} style={{ width: 70, height: 70, borderRadius: 70 }} />
                        ): (
                          <div style={{ width: 70, height: 70, borderRadius: 70, background: '#39f', color: '#fff' }} className='d-flex align-items-center justify-content-center'>
                            <h1>{(detail?.name || ' ')[0].toUpperCase()}</h1>
                          </div>
                        )}
                        <div className='ms-2'>
                          <CCardText style={{ color: '#000', lineHeight: 1.4 }}>{detail?.name}</CCardText>
                          <CCardText style={{ lineHeight: 1.4 }}>{TYPE.find(type => type.value === detail?.type)?.label}</CCardText>
                          <CCardText style={{ lineHeight: 1.4 }}>{DOCUMENT_TYPE.find(type => type.value === detail?.documentType)?.label}</CCardText>
                        </div>
                      </div>
                      <div className='d-felx pt-2'>
                        {(detail.documentDetails || []).map((document, index) => (
                          <a href={document.documentfile} target='_blank'>View{`${index < detail.documentDetails.length -1 ? ', ': ''}`}</a>
                        ))}
                      </div>
                    </CCardBody>
                  </CCard>
                </CCol>
              ))}
            </CRow>
          </CContainer>
          )
        }
      </div>
    </div>
  )
};

export default Brands;
