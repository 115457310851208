import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Button } from "semantic-ui-react";
import { getUrlFileExtension } from "../../constants/comonFunction";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function GalleryOnModal(props){
  const [showImage,  setShowImage] = useState(true);
    const [files, setFiles] = useState([]);

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    useEffect(() => {
      let file = [];
      props?.images?.map((ele) => {
        file.push(ele)
      })
      setFiles(file);
  },[props])

  const handleModal = () => {
    props?.handleViewProofModal(false)
  }

  return (<>
  <Modal show={true} onHide={() => handleModal()} backdrop="static" keyboard={false} size={"md"}>
    <Modal.Header>
      <Modal.Title>Return Proof</Modal.Title>
    </Modal.Header>
    <Modal.Body style={{marginBottom:"10px"}}>
    {files.length > 1 ? (
  <Slider {...settings}>
    {files.map((item, index) => {
      let type = getUrlFileExtension(item);
      return (
        <div key={index}>
          {type === 'mp4' ? (
            <div>
              <video height="240" width="100%" controls>
                <source src={item} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : (
            <div>
              <center>
                <img src={item} alt="Image" style={{ maxHeight: "200px" }} />
              </center>
            </div>
          )}
        </div>
      );
    })}
  </Slider>
) : (
  <div>
    {files.map((item, index) => {
      let type = getUrlFileExtension(item);
      return (
        <div key={index}>
          {type === 'mp4' ? (
            <div>
              <video height="240" width="100%" controls>
                <source src={item} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : (
            <div>
              <center>
                <img src={item} alt="Image" style={{ maxHeight: "200px" }} />
              </center>
            </div>
          )}
        </div>
      );
    })}
  </div>
)}



    </Modal.Body>
    <Modal.Footer>
      <div style={{width:"100%"}}>
        {props?.captions?.map((row,i) => {
          return (<li key={"item"+i} style={{listStyleType:"none"}}><strong>Reason: </strong>{row.trim()}</li>)
        })}

        <p style={{textAlign:"justify"}}><strong>Remarks: </strong>{props?.remarks}</p>
      </div>

      <Button variant="secondary" onClick={() => handleModal()}> Close </Button>

    </Modal.Footer>
  </Modal>
  </>)
}

export default GalleryOnModal
