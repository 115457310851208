// import { HttpClient, HttpHeaders } from '@angular/common/http';
import axios from "axios";
import download from "downloadjs";
import { toast } from "react-toastify";
import { getAutoLogoutUrl, getCurrentUrl } from "./constants/comonFunction";

const API_BASE = process.env.REACT_APP_LIVE_API_BASE
const API_BASE_COMMON = process.env.REACT_APP_LIVE_API_BASE_COMMON
const API_BASE_LMS = process.env.REACT_APP_LIVE_API_BASE_LMS

let token = window.localStorage.getItem("TOKEN");

export const catchErrorMessage = async (err, showToast=true) => {
  if (err.response) {
    if(showToast){
      toast.error(err.response.data.message);
    }
  } else if (err.request) {
  } else {
  }
  return { data: { error: "Server down", message: err.response?.data?.message, payload: err.response?.data?.payload } };
}

export const postAPI = async function (url, data, showToast) {
  // let _token = JSON.parse(window.localStorage.getItem('TOKEN'))
  let _token = window.localStorage.getItem("TOKEN");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + _token,
  };
  try {
    url = API_BASE + url;
    let requestOptions = {
      method: "POST",
      url: url,
      data: data,
      headers: headers,
    };
    const postApiRes = await axios(requestOptions);
    if (postApiRes?.data?.messageCode === "UNAUTHORIZED" || postApiRes?.data?.messageCode === "TOKEN_EXPIRED") {
      const currentUrl = getCurrentUrl()
      getAutoLogoutUrl()
      // window.location.href = "#/login";
    }
    return postApiRes.data;
  } catch (err) {
    return catchErrorMessage(err, showToast)
  }
};

export const postFormDataAPI = async function (url, data) {
  // let _token = JSON.parse(window.localStorage.getItem('TOKEN'))
  let _token = window.localStorage.getItem("TOKEN");
  const headers = {
    "Content-Type": "multipart/form-data",
    Accept:"application/json",
    Authorization: "Bearer " + _token,
  };
  try {
    url = API_BASE + url;
    let requestOptions = {
      method: "POST",
      url: url,
      data: data,
      headers: headers,
    };
    const postApiRes = await axios(requestOptions);
    if (postApiRes?.data?.messageCode === "UNAUTHORIZED" || postApiRes?.data?.messageCode === "TOKEN_EXPIRED") {
      const currentUrl = getCurrentUrl()
      getAutoLogoutUrl()
    }

    return postApiRes.data;
  } catch (err) {
    return catchErrorMessage(err)
  }
};

export const getApiCall = async function (url) {
  let token = window.localStorage.getItem("TOKEN");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
    "Access-Control-Allow-Origin": "*",
  };
  try {
    url = API_BASE + url;
    let requestOptions = {
      method: "GET",
      url: url,
      headers: headers,
    };
    const getApiRes = await axios(requestOptions);
    if (getApiRes?.data?.messageCode === "UNAUTHORIZED" || getApiRes?.data?.messageCode === "TOKEN_EXPIRED") {
      const currentUrl = getCurrentUrl()
      getAutoLogoutUrl()
    }
    return getApiRes?.data;
  } catch (err) {
    return catchErrorMessage(err)
  }
};

export const get = async function (url, data) {
  data.token = token;
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  try {
    url = API_BASE + url;
    let requestOptions = {
      method: "GET",
      url: url,
      data: data,
      headers: headers,
    };
    const getApiRes = await axios(requestOptions);
    if (getApiRes?.data?.messageCode === "UNAUTHORIZED" || getApiRes?.data?.messageCode === "TOKEN_EXPIRED") {
      const currentUrl = getCurrentUrl()
      getAutoLogoutUrl()
    }
    return getApiRes.data;
  } catch (err) {
    return catchErrorMessage(err)
  }
};

export const putAPI = async function (url, data) {
  let _token = window.localStorage.getItem("TOKEN");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + _token,
  };
  try {
    url = API_BASE + url;
    let requestOptions = {
      method: "PUT",
      url: url,
      data: data,
      headers: headers,
    };
    const postApiRes = await axios(requestOptions);
    if (postApiRes?.data?.messageCode === "UNAUTHORIZED" || postApiRes?.data?.messageCode === "TOKEN_EXPIRED") {
      const currentUrl = getCurrentUrl()
      getAutoLogoutUrl()
    }
    return postApiRes.data;
  } catch (err) {
    return catchErrorMessage(err)
  }
};

export const deleteAPI = async function (url, data) {
  let _token = window.localStorage.getItem("TOKEN");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + _token,
  };
  try {
    url = API_BASE + url;
    let requestOptions = {
      method: "DELETE",
      url: url,
      headers: headers,
    };
    const postApiRes = await axios(requestOptions);
    if (postApiRes?.data?.messageCode === "UNAUTHORIZED" || postApiRes?.data?.messageCode === "TOKEN_EXPIRED") {
      const currentUrl = getCurrentUrl()
      getAutoLogoutUrl()
    }
    return postApiRes.data;
  } catch (err) {
    return catchErrorMessage(err)
  }
};

export const downloadFile = async function (url, data) {
  let _token = window.localStorage.getItem("TOKEN");
  url = API_BASE + url;

  try {
    return fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/csv",
        Authorization: "Bearer " + _token,
      },
    })
      .then(function (resp) {
        return resp.blob();
      })
      .then(function (blob) {
        if(blob?.type !== 'application/json'){
          return download(blob, data?.fileName);
        }else{
          return false;
        }

      });
  } catch (err) {
    return catchErrorMessage(err)
  }
};

export const uploadImageAPI = async function (url, fileData) {
  let _token = window.localStorage.getItem("TOKEN");
  var formData = new FormData();
  for (let i = 0; i < fileData.length; i++) {
    formData.append("file", fileData[i]);
  }
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer  " + token,
  };
  try {
    const postApiRes = await axios({
      method: "POST",
      url: API_BASE + url,
      data: formData,
      headers: headers,
    });
    if (postApiRes?.data?.messageCode === "UNAUTHORIZED" || postApiRes?.data?.messageCode === "TOKEN_EXPIRED") {
      const currentUrl = getCurrentUrl()
      getAutoLogoutUrl()
    }
    return postApiRes.data;
  } catch (err) {
    return catchErrorMessage(err)
  }
};

export const uploadBiltiImageAPI = async function (url, fileData, orderId) {
  let file = fileData.file;
  let _token = window.localStorage.getItem("TOKEN");
  var formData = new FormData();
  formData.append("file", file);
  formData.append("orderId", orderId);
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + _token,
  };
  try {
    const postApiRes = await axios({
      method: "POST",
      url: API_BASE + url,
      data: formData,
      headers: headers,
    });
    // checkToken(postApiRes.data)
    if (postApiRes?.data?.messageCode === "UNAUTHORIZED" || postApiRes?.data?.messageCode === "TOKEN_EXPIRED") {
      const currentUrl = getCurrentUrl()
      getAutoLogoutUrl()
    }
    return postApiRes.data;
  } catch (err) {
    return catchErrorMessage(err)
  }
};

// For base 64
function createBase64(fileData) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileData);
    reader.onload = function () {
      resolve(reader.result);
    };
  });
}
export const uploadImageAPIb2b = async function (url, fileData, payloadData=null) {
  let _token = window.localStorage.getItem("TOKEN");
  var formData = new FormData();
  for (let i = 0; i < fileData.length; i++) {
    formData.append("files", fileData[i]);
  }
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer  " + _token,
  };
  if(payloadData && payloadData.bucket){
    formData.append("bucket",payloadData?.bucket);
  }

  try {
    const postApiRes = await axios({
      method: "POST",
      url: API_BASE + url,
      data: formData,
      headers: headers,
    });
    if (postApiRes?.data?.messageCode === "UNAUTHORIZED" || postApiRes?.data?.messageCode === "TOKEN_EXPIRED") {
      const currentUrl = getCurrentUrl()
      getAutoLogoutUrl()
    }
    return postApiRes.data;
  } catch (err) {
    return catchErrorMessage(err)
  }
};




// For Common instance
export const getApiCallCommon = async function (url, source="admin") {
  let _token = window.localStorage.getItem("TOKEN");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + _token,
    source: source
  };
  try {
    url = API_BASE_COMMON + url;
    let requestOptions = {
      method: "GET",
      url: url,
      headers: headers,

    };
    const getApiRes = await axios(requestOptions);
    if (getApiRes?.data?.messageCode === "UNAUTHORIZED" || getApiRes?.data?.messageCode === "TOKEN_EXPIRED") {
      const currentUrl = getCurrentUrl()
      getAutoLogoutUrl()
    }
    return getApiRes.data;
  } catch (err) {
    return catchErrorMessage(err)
  }
};

// For LMS
export const postAPILMS = async function (url, data) {
  // let _token = JSON.parse(window.localStorage.getItem('TOKEN'))
  // checkVPN()
  let _token = window.localStorage.getItem("TOKEN");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + _token,
  };
  try {
    url = API_BASE_LMS + url;
    let requestOptions = {
      method: "POST",
      url: url,
      data: data,
      headers: headers,
    };
    const postApiRes = await axios(requestOptions);
    if (postApiRes?.data?.messageCode === "UNAUTHORIZED" || postApiRes?.data?.messageCode === "TOKEN_EXPIRED") {
      const currentUrl = getCurrentUrl()
      getAutoLogoutUrl()
      // window.location.href = "#/login";
    }
    return postApiRes.data;
  } catch (err) {
    return catchErrorMessage(err)
  }
};

export const getAPILMS = async function (url) {
  let token = window.localStorage.getItem("TOKEN");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  try {
    url = API_BASE_LMS + url;
    let requestOptions = {
      method: "GET",
      url: url,
      headers: headers,
    };
    const getApiRes = await axios(requestOptions);
    if (getApiRes?.data?.messageCode === "UNAUTHORIZED" || getApiRes?.data?.messageCode === "TOKEN_EXPIRED") {
      const currentUrl = getCurrentUrl()
      getAutoLogoutUrl()
    }
    return getApiRes.data;
  } catch (err) {
    return catchErrorMessage(err)
  }
};
