import React, { useState, useEffect } from "react";
import { Grid } from "semantic-ui-react";

const localPermits = JSON.parse(localStorage.getItem("module"));
const SellerDetails = ({ details = {}, isLoading = false }) => {
  const [sellerPermission, setSellerPermission] = useState(localPermits.find((o) => o.moduleName == "Sellers"))
  useEffect(() => {
    async function fetchData() {}
    fetchData();
  }, []);
  const viewSellerDetail = (name, sellerStatus) => {
    window.open("/#/sellers/"+sellerStatus+"?q="+name+"&f=name&o=equals", "_blank");
  }
  return (
    <div>
      <Grid className="ml-1">
        <Grid.Row>
          <Grid.Column width={8}>
            <div style={{ textDecoration: "underline", marginBottom: "6px" }}>
              Seller Information:
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              Name:{" "}
              <span style={{ fontWeight: "normal" }}>{details?.sellerDetails?.companyDetails?.companyName + " ("+details?.sellerDetails?.name+")"}</span>{" "}
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              Mobile:{" "}
              <span style={{ fontWeight: "normal" }}>{details?.sellerDetails?.mobile}</span>
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              Seller Onborded By: <span style={{ fontWeight: "normal" }}>
                {details?.sellerOnboardedByName + " ("+details?.sellerOnBoardedBy+")"}
                </span>{" "}
            </div>
            {sellerPermission?.isView && (<>
              <div className="viewLink" style={{ fontWeight: "bold", fontSize: "13px" }} onClick={()=> viewSellerDetail(details?.sellerName, details?.slrAccountStatus)}>
                View Detail{" "}
            </div>
            </>)}
            </Grid.Column>
          <Grid.Column width={8}>
            <div style={{ textDecoration: "underline", marginBottom: "6px" }}>
              Pickup Address
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              Address:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.sellerDetails?.companyDetails?.address1}
                {` ${details?.sellerDetails?.companyDetails?.address2}`}
              </span>
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              City:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.sellerDetails?.companyDetails?.city}
              </span>
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              State:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.sellerDetails?.companyDetails?.state}
              </span>
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              Pincode:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.sellerDetails?.companyDetails?.pincode}
              </span>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default SellerDetails;
