import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import { Button } from "semantic-ui-react";
import { postAPILMS } from "../../apiServices";
import { format } from 'date-fns';
import moment from 'moment';
import { invoiceNumberRegex } from "../../constants/regexValidation";
import DatePicker from "react-datepicker";
import { changeDateFormat_DMY, getUrlFileExtension } from "../../constants/comonFunction";

function OrderShip(props){
    const [viewModal, setViewModal] = useState(true)
    const [ewayBill, setEwayBill] = useState();
    const [boxCount, setBoxCount] = useState();
    const [expectedPickup, setExpectedPickup] = useState(new Date());
    const [errorMessage, setErrorMessage] = useState("")
    const [cancelMessageTitle, setCancelMessageTitle] = useState("")
    const [cancelMessageText, setCancelMessageText] = useState("")
    const [cancelConfirmVisible, setCancelConfirmVisible] = useState(false);

    // useEffect(() => {
    //     getDetail()
    // },[props])

    // const getDetail = async () => {
    //     let resData = await getApiCall('/orders/'+props?.order?.orderID+'/seller-custom-invoice/details/');
    //     if(resData.status === 0){
    //         let res = resData?.payload?.[0]
    //         setInvoiceNumber(res?.invoiceNumber)
    //         setFile(res?.fileURL)
    //         let fType = '';
    //         if(res?.fileURL){
    //             fType = getUrlFileExtension(res?.fileURL)
    //             if(fType === 'pdf'){
    //                 setFileType('application/pdf')
    //             }
    //         }
    //         setInvoiceAmount(res?.invoiceAmount)
    //         setInvoiceDate(res?.invoiceDate ? new Date(res?.invoiceDate*1000) : '')
    //     }
    // }

    // const handleViewSellerInvoiceClose = () => {
    //     Swal.fire({
    //         title: 'Do you want to cancel',
    //         showCancelButton: true,
    //         confirmButtonText: 'Yes',
    //         cancelButtonText: "No"
    //       }).then( async (result) => {
    //         if (result.isConfirmed) {
    //             setViewSellerInvoiceModal(!viewSellerInvoiceModal)
    //             props?.handleShowSellerInvoiceModal(false)
    //         }
    //       })
    // }

    const handleModalClose = () => {
        setCancelMessageTitle("Do you want to cancel?")
        setCancelMessageText("All unsaved changes will be lost")
        setCancelConfirmVisible(true)
    }

    const rejectCancel = () => {
        setCancelMessageTitle("")
        setCancelMessageText("")
        setCancelConfirmVisible(false)
    }

    const acceptCancel = async() => {
        // setViewSellerInvoiceModal(!viewSellerInvoiceModal)
        let date = changeDateFormat_DMY(expectedPickup)
        let data = {
            orderID: props?.order?.orderID,
            orderNumber: props?.order?.orderNumber,
            ewaybill_number: ewayBill,
            numberOfBoxes: parseInt(boxCount),
            expected_pickup_time: date
        }
        let res = await postAPILMS('/orders/create', data);
        if(res?.status === 1){
          // props?.handleShowOrderShipModal(false, res)
          toast.success(res.message)
        }else{
          toast.error(res.message)
        }
        props?.handleShowOrderShipModal(res.status, res)
    }

    const handleSubmit = async() => {
        setErrorMessage()
        let isError = false;
        if(!boxCount || boxCount < 1){
          setErrorMessage("Box count should be greater than 1.")
          isError = true;
        }else if(!expectedPickup){
          setErrorMessage("Expected pickup is required and should be future date")
          isError = true;
        }
        // else if(props?.order?.netAmount > 50000 && !ewayBill){
        //   setErrorMessage("E-Way bill required");
        //   isError = true;
        // }

        if(isError){
            return false;
        }else{
            setCancelMessageText("Please check! you can't update post this step")
            setCancelConfirmVisible(true)
          }
        }

        // const handleUpdate = async() => {
        //     setErrorMessage()
        //     let isError = false;
        //     if(!file){
        //         isError = true;
        //     }
        //     if(!invoiceNumber){
        //         isError = true;
        //     }
        //     if(!invoiceAmount || invoiceAmount < 1){
        //         isError = true;
        //     }
        //     if(isError){
        //         setErrorMessage("fields marked with (*) are mandatory")
        //         return false;
        //     }else{
        //         let data = {
        //             orderId: props?.order?.customInvoiceData?.id,
        //             invoiceUrl: file,
        //             orderId: props?.order?.orderID,
        //             invoiceNumber: invoiceNumber,
        //             invoiceAmount: invoiceAmount,
        //             invoiceDate: format(new Date(invoiceDate), 'dd-MM-yyyy')
        //         }
        //         let res = await postAPI('/orders/update-seller-invoice-details', data)
        //         if(res.status === 1){
        //             toast.success(res?.message);
        //             props?.handleShowSellerInvoiceModal(false)
        //             setViewModal(false)
        //         }
        //     }
        // }


    return (<>
    <Modal show={viewModal} onHide={handleModalClose} backdrop="static" keyboard={false} size="md">
        <Modal.Header>
            <Modal.Title>Ship Order </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <lable className="label" style={{float:"left"}}>Order Number <sup>*</sup></lable>
                            <input type="text" className="form-control" name="orderNumber" placeholder="Order Number" value={props?.order.orderNumber}  required></input>
                        </div>

                        <div className="form-group">
                            <lable className="label" style={{float:"left"}}>Box Count <sup>*</sup></lable>
                            <input type="number" className="form-control" placeholder="Box Count" defaultValue={boxCount} onChange={(e) => setBoxCount(e.target.value)} required></input>
                        </div>

                        <div className="form-group">
                            <lable className="label" style={{float:"left"}}>Expected Pickup Time <sup>*</sup></lable><br />
                            {/* <input id="swal-input1" type="date" className="form-control" placeholder="Invoice #" onChange={(e) => setInvoiceNumber(e.target.value)} required></input> */}
                            <DatePicker
                                selected={expectedPickup ? new Date(expectedPickup) : new Date()}
                                className="form-control date"
                                onChange={(date) => setExpectedPickup(date)}
                                placeholderText="DD/MM/YYYY"
                                dateFormat="dd/MM/yyyy"
                                // minDate={new Date(props?.order?.dispatchedOn)}
                                minDate={new Date()}
                                style={{width:"100%"}}
                            />
                            {/* <input type="date" className={"form-control date"} name="invliceDate" min="2020-01-01" max={moment().format("YYYY-MM-DD")} onChange={(e) => setInvoiceDate(e.target.value)} defaultValue={invoiceDate} /> */}
                        </div>
                        {props?.order?.netAmount > 50000 && (
                          <div className="form-group">
                              <lable className="label" style={{float:"left"}}>E-way Number</lable>
                              <input type="text" className="form-control" name="ewayBill" placeholder="E-Way Number" onChange={(e)=>setEwayBill(e.target.value)}></input>
                          </div>
                        )}


                    </div>

                    {/*  */}
                </div>

            </div>
        </Modal.Body>
        <Modal.Footer>
            {!cancelConfirmVisible ? (<>
                <p className="required">{errorMessage}</p>
                <Button className="btn btn-danger" onClick={handleModalClose}>  Cancel </Button>
                <Button className="btn blue btn-primary btn-md"  onClick={handleSubmit}> Submit </Button>
            </>) : (<>
                <div>
                    <p><strong>{cancelMessageTitle}</strong> {" "}{cancelMessageText}</p>
                </div>
                <Button className="btn btn-danger" onClick={rejectCancel}>  No </Button>
                <Button className="btn blue btn-primary btn-md"  onClick={acceptCancel}> Yes </Button>
            </>)}

        </Modal.Footer>
    </Modal>
    </>);
}
export default OrderShip;
