import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button } from "semantic-ui-react";
import { uploadImageAPIb2b } from "../../apiServices";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const defaultPaymentMethod = [
    {id:1, name:"Cash", value:"cash"},
    {id:2, name:"Cheque", value:"cheque"},
    {id:3, name:"Online", value:"online"},
]
const pocRestricted = ["bnpl", "online", "srb2b-credit"]
const deliveryDateRestricted = ["bnpl", "srb2b-credit"]
function OrderPod(props){
    const [viewPod, setViewPod] = useState(props?.pod)
    const [podFiles, setPodFiles] = useState([])
    const [dateOfDelivery, setDateOfDelivery] = useState();
    const [paymentMethod, setpaymentMethod] = useState(defaultPaymentMethod);
    const [rowList, setRowList] = useState([{method:"cash", amount:"", dateOfPayment:"", transactionID:"", files:[]}])
    const [rowListError, setRowListError] = useState([{method:"", amount:"", dateOfPayment:"", transactionID:"", files:""}])
    const [cancelMessageTitle, setCancelMessageTitle] = useState("")
    const [cancelMessageText, setCancelMessageText] = useState("")
    const [cancelConfirmVisible, setCancelConfirmVisible] = useState(false);
    const [cancelConfirmAmountVisible, setCancelConfirmAmountVisible] = useState(false);
    const [payloadData, setPayloadData] = useState();
    const [amountReceive, setAmountReceive] = useState(0);
    const [amountMessage, setAmountMessage] = useState("");
    const [deliveryDateMessage, setDeliveryDateMessage] = useState("");
    const [errorMessageText, setErrorMessageText] = useState("")

    const handleViewPodClose = () => {
        setCancelMessageTitle("Do you want to cancel?")
        setCancelMessageText("All unsaved changes will be lost")
        setCancelConfirmVisible(true)
    }

    const rejectCancel = () => {
        setCancelMessageTitle("")
        setCancelMessageText("")
        setAmountMessage("")
        setDeliveryDateMessage("")
        setCancelConfirmVisible(false)
        setCancelConfirmAmountVisible(false)
    }

    const acceptCancel = () => {
        setViewPod(!viewPod)
        props?.handleShowPod(false)
    }

    const rejectAmount = () => {
        setAmountMessage("")
        setCancelMessageTitle("")
        setCancelMessageText("")
        setCancelConfirmAmountVisible(false)
    }

    const handlePaymentReceive = () => {
        let amountReceived = props?.order?.advance;
        rowList.forEach(ele => {
            if(ele.method === 'cash'){
                amountReceived+=parseInt(ele.amount)
            }else if(ele.method === 'cheque'){
                amountReceived+=parseInt(ele.amount)
            }else if(ele.method === 'online'){{
                amountReceived+=parseInt(ele.amount)
            }}
        });
        if(amountReceived < props?.order?.totalDue){
            setAmountMessage("Amount received is less than due amount by ₹"+ (props?.order?.totalDue - amountReceived) +". Do you want to save the changes?")
        }else if(amountReceived > props?.order?.totalDue){
            setAmountMessage("Amount received is greater than due amount by ₹"+(amountReceived - props?.order?.totalDue)+".");
        }else{
            setAmountMessage("")
        }
        setAmountReceive(amountReceived)
    }

    const addRowListFormFields = () => {
        setRowList([...rowList, { method:"cash", amount: "", dateOfPayment: "", transactionID:"", files:[] }])
        setRowListError([...rowListError, { method:"", amount: "", dateOfPayment: "", transactionID:"", files:"" }])
      }

    const removeRowListFields = (i) => {
    let newRowFormValues = [...rowList];
    newRowFormValues.splice(i, 1);
    setRowList(newRowFormValues)

    let newRowError = [...rowListError];
    newRowError.splice(i, 1);
    setRowListError(newRowError)
    handlePaymentReceive()
}

    const handleRowListChange = (e, i) => {
        let newFormValues = [...rowList];
        let value = e.target.value;
        if(e.target.name === 'dateOfPayment' && value !=""){
            value = format(new Date(value), 'dd-MM-yyyy')
            e.target.blur();
        }
        if(e.target.name === 'method'){
            if(value === 'cash'){
                document.getElementById("transactionID"+i).disabled = true;
                document.getElementById("images"+i).disabled = true;
            }else{
                document.getElementById("transactionID"+i).disabled = false;
                document.getElementById("images"+i).disabled = false;
            }
        }
        newFormValues[i][e.target.name] = value;
        setRowList(newFormValues);
        handlePaymentReceive()
    }

    const handleRowListDateChange = (date, i) => {
        let updatedData = [...rowList]; // Create a copy of the array
        updatedData[i] = { ...updatedData[i], dateOfPayment:  date }; // Update the specific object's property
        setRowList(updatedData); // Update the state
    }

    const uploadPodFile = async (e) => {
        let files = e.target.files
        let images = podFiles;
        let payloadimg = await uploadImageAPIb2b("/uploads", files);
        if(payloadimg?.status === 1){
            payloadimg?.url?.forEach(ele => {
                images.push(ele)
            })
            setErrorMessageText("");
        }
        await setPodFiles(images);
    }
    const handlePocFile = async (e, i) => {
        let newRowListValues = [...rowList];
        let files = e.target.files
        let images = [];
        let payloadimg = await uploadImageAPIb2b("/uploads", files);
        if(payloadimg?.status === 1){
            payloadimg?.url?.forEach(ele => {
                images.push(ele)
            })
        }
        newRowListValues[i]['files'] = images;
        setRowList(newRowListValues);
    }

    const handleSubmit = async() => {
        let dop = format(new Date(),  "dd-MM-yyyy");
        if(dateOfDelivery){
            dop = format(new Date(dateOfDelivery), 'dd-MM-yyyy')
        }

        if(podFiles.length === 0) {
          setErrorMessageText("Please upload proof of delivery");
          setCancelConfirmAmountVisible(false)
          return false;
        }
        let payloadPoD = {
            orderID: props?.order?.orderID,
            dateOfPayment: dop,
            files: podFiles
        };
        let cash = [];
        let cheque = [];
        let online = [];
        let amountReceived = 0;
        let error = false;
        rowList.forEach(ele => {
          if(rowList.length === 1 && ele.method === 'cash' && (!ele.dateOfPayment || !ele.amount)){

          }else{
            if(!ele.dateOfPayment){
              setErrorMessageText("Select payment date in poc");
              setCancelConfirmAmountVisible(false)
              error = true;
              return false;
            }else{
              if(ele.method === 'cash'){
                amountReceived+=parseInt(ele.amount)
                if(ele.amount){

                    let newDate = format(new Date(ele?.dateOfPayment), 'dd-MM-yyyy')
                    ele = { ...ele, dateOfPayment:  newDate }
                }
                cash.push(ele)
            }else if(ele.method === 'cheque'){
                amountReceived+=parseInt(ele.amount)
                if(ele.amount){
                    let newDate = format(new Date(ele?.dateOfPayment), 'dd-MM-yyyy')
                    ele = { ...ele, dateOfPayment:  newDate }
                }
                cheque.push(ele)
            }else if(ele.method === 'online'){{
                amountReceived+=parseInt(ele.amount)
                if(ele.amount){
                    let newDate = format(new Date(ele?.dateOfPayment), 'dd-MM-yyyy')
                    ele = { ...ele, dateOfPayment:  newDate }
                }
                online.push(ele)
            }}
            }
          }
        });
        if(error){
          setErrorMessageText("Select payment date in poc");
          setCancelConfirmAmountVisible(false)
          return false;
        }
        let payloadPOC = {
            orderID: props?.order?.orderID,
            paymentDetails:{
                cash: cash,
                cheque: cheque,
                online: online,
            }
        }
        if(Number.isNaN(amountReceived) || props?.order?.advance === amountReceived){
          payloadPOC = null
        }

        let payloadData = {
            dateOfDelivery: deliveryDateRestricted?.indexOf(props?.order?.paymentMode) < 0 ? dop : "",
            poc: payloadPOC,
            pod: payloadPoD
        }
        setPayloadData(payloadData)

        if (deliveryDateRestricted?.indexOf(props?.order?.paymentMode) < 0 && !dateOfDelivery){
            setDeliveryDateMessage("Delivery date not selected. Do you want to save the changes?")
            setCancelConfirmAmountVisible(true)
        }

        if(amountReceived > 0){
            if(amountReceived < props?.order?.totalDue){
                setAmountMessage("Amount received is less than due amount by ₹"+ (props?.order?.totalDue - amountReceived) +". Do you want to save the changes?")
                setCancelConfirmAmountVisible(true)
            }else if(amountReceived > props?.order?.totalDue){
                setAmountMessage("Amount received is greater than due amount by ₹"+ (amountReceived - props?.order?.totalDue) +".")
                setCancelConfirmAmountVisible(true)
            }else{
                    props?.updateOrderStatusBtn(props?.defaultAction, payloadData )
                }
        }else{
            props?.updateOrderStatusBtn(props?.defaultAction, payloadData )
        }
    }

    const handleAmountSubmit = async() => {
        props?.updateOrderStatusBtn(props?.defaultAction, payloadData )
        props?.handleShowPod(false)
    }
    return (<>
    <Modal show={viewPod} onHide={handleViewPodClose} backdrop="static" keyboard={false} size="lg">
        <Modal.Header>
            <Modal.Title>Proof of Delivery & Collection </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div>
                            <span style={{marginRight:"10px", float:"left", marginTop:"3px"}}>Date of Delivery</span>
                            <span style={{float:"left"}}>
                            <DatePicker
                                selected={dateOfDelivery}
                                className="form-control date"
                                onChange={(date) => setDateOfDelivery(date)}
                                placeholderText="DD/MM/YYYY"
                                dateFormat="dd/MM/yyyy"
                                disabled={deliveryDateRestricted?.indexOf(props?.order?.paymentMode) < 0 ? false : true}
                                minDate={new Date(props?.order?.placedOn)}
                                maxDate={new Date()}
                            />
                            </span>
                        </div>

                    </div>
                    <div className="col-12">
                        <div className="strike">
                            <span>Proof of Delivery</span>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-inline">
                            <label style={{marginRight:"10px"}}>Upload File</label>
                            <input type="file" style={{width:"89%"}} className="fileBrowse" name="podFiles" multiple onChange={(e) => uploadPodFile(e)} />
                        </div>

                    </div>

                    {pocRestricted?.indexOf(props?.order?.paymentMode) < 0 ? (
                      <>
                      <div className="col-12">
                        <div className="strike" style={{marginBottom:"20px"}}>
                            <span>Proof of Collection</span>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <table>
                            <thead>
                                <tr>
                                    <th>Method</th>
                                    <th>Amount</th>
                                    <th>Payment Date</th>
                                    <th>Trxn ID/Cheque No.</th>
                                    <th>Files</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {rowList?.map((row, i) => {
                                    return (<>
                                    <tr>
                                        <td style={{width:"12%"}}>
                                            <select name="method" className="form-control" onChange={(e) => handleRowListChange(e,i)}>
                                                {paymentMethod?.map((pr) => {
                                                    return (<>
                                                        <option value={pr?.value}>{pr.name}</option>
                                                    </>)
                                                })}
                                            </select>
                                        </td>
                                        <td style={{width:"10%"}}>
                                            <input type="number" className={"form-control "} name="amount" onChange={(e) => handleRowListChange(e, i)} />
                                        </td>
                                        <td style={{width:"14%"}}>
                                        <DatePicker
                                            selected={row?.dateOfPayment}
                                            className={"form-control date"}
                                            onChange={(date) => handleRowListDateChange(date, i)}
                                            placeholderText="DD-MM-YYYY"
                                            dateFormat="dd-MM-yyyy"
                                            disabled={pocRestricted?.indexOf(props?.order?.paymentMode) < 0 ? false : true}
                                            minDate={new Date(props?.order?.placedOn)}
                                            maxDate={row?.method === 'cheque' ? "" : new Date()}
                                        />
                                        </td>
                                        <td style={{width:"22%"}}>
                                            <input type="text" className={row?.method === 'cash' ? "form-control readOnly" : "form-control"} id={"transactionID"+i} placeholder={row?.method === "online" ? "Transaction ID" : row?.method === "cheque" ? "Cheque No" : ""} name="transactionID" onChange={(e) => handleRowListChange(e, i)} disabled={true} />
                                        </td>
                                        <td style={{width:"22%"}}>
                                            <input type="file" className={"fileBrowse"} id={"images"+i} multiple onChange={(e) => handlePocFile(e, i)} disabled={true} />
                                        </td>
                                        <td style={{width:"5%"}}>
                                            <span>
                                                {i > 0 && (
                                                    <FontAwesomeIcon
                                                        icon={faTrashAlt}
                                                        style={{ cursor: "pointer" }}
                                                        className="mr-2 text-danger"
                                                        onClick={() => removeRowListFields(i)}
                                                    />
                                                )}
                                                {i === rowList?.length -1 && (
                                                    <FontAwesomeIcon
                                                    icon={faPlus}
                                                    style={{ cursor: "pointer" }}
                                                    className="mr-2 text-primary"
                                                    onClick={() => addRowListFormFields()}
                                                />
                                                )}
                                            </span>
                                        </td>
                                    </tr>

                                    </>)
                                })}

                            </tbody>
                        </table>
                    </div>
                        </>
                    ) : (<></>)}

                    {/*  */}
                </div>

            </div>
        </Modal.Body>
        <Modal.Footer>
            {!cancelConfirmVisible ? (<>

                {!cancelConfirmAmountVisible ? (<>
                <p className="error" style={{color:"#ff0000"}}>{errorMessageText}</p>
                    <Button className="btn btn-danger" onClick={() => handleViewPodClose()}>  Cancel </Button>
                    <Button className="btn blue btn-primary btn-md"  onClick={() => handleSubmit()}> Submit</Button>
                </>) : (<>
                {amountMessage && deliveryDateMessage ? (<><p>{amountMessage}</p></>):(<>
                  <p>{amountMessage}</p>
                  <p>{deliveryDateMessage}</p>
                </>)}


                    {amountReceive > props?.order?.totalDue ? (<>
                        <Button className="btn blue btn-primary" onClick={() => rejectAmount()}>  No </Button>
                        <Button className="btn blue btn-primary btn-md"  onClick={() => handleAmountSubmit()}> Yes</Button>
                    </>) : (<>
                        <Button className="btn btn-danger" onClick={() => rejectAmount()}>  No </Button>
                        <Button className="btn blue btn-primary btn-md"  onClick={() => handleAmountSubmit()}> Yes</Button>
                    </>)}
                </>)}

            </>) : (<>
                <div>
                    <p><strong>{cancelMessageTitle}</strong> {" "}{cancelMessageText}</p>
                </div>
                <Button className="btn btn-danger" onClick={() => rejectCancel()}>  No </Button>
                <Button className="btn blue btn-primary btn-md"  onClick={() => acceptCancel()}> Yes </Button>
            </>)}
        </Modal.Footer>
    </Modal>
    </>);
}
export default OrderPod;
