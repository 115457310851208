import React, { useState, useEffect, useCallback, useContext } from "react";
import { Form, Grid } from "semantic-ui-react";
import { postAPI } from "../../../apiServices";

const SellerDetails = ({ details = {}, isLoading = false }) => {
  return (
    <div>
      <Grid className="ms-1">
        <Grid.Row>
          <Grid.Column width={8}>
            <div style={{ textDecoration: "underline", marginBottom: "6px" }}>
              Seller Information:
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              Name:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.seller?.companyName}
              </span>{" "}
            </div>
            {/* <div style={{ fontWeight: "bold", fontSize: "13px" }}>Email: <span style={{ fontWeight: "normal" }}>{details?.seller?.byrEmail}</span></div>
                        <div style={{ fontWeight: "bold", fontSize: "13px" }}>Mobile: <span style={{ fontWeight: "normal" }}>{details?.seller?.byrMobile}</span></div> */}
            {details?.seller?.pancard ? (<>
              <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              Pancard:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.seller?.pancard}
              </span>
            </div>
            </>) : ""}

            {details?.seller?.gstNumber ? (<>
              <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              GST Number:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.seller?.gstNumber}
              </span>
            </div></>) : ""}

            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
             Seller Onborded By:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.seller?.onboardedByName + " ("+details?.seller?.onboardedBy+")"}
              </span>
            </div>

          </Grid.Column>
          <Grid.Column width={8}>
            {/* <div style={{ textDecoration: "underline", marginBottom: "6px" }}>Shipping Address</div> */}
            {/* <div style={{ fontWeight: "bold", fontSize: "13px" }}>Name: <span style={{ fontWeight: "normal" }}>{details?.seller?.shippingAddress?.delName}</span></div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>Mobile: <span style={{ fontWeight: "normal" }}>{details?.seller?.shippingAddress?.delMobile} </span></div> */}
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              Address:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.seller?.sellerAddress}
              </span>
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              City:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.seller?.city}
              </span>
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              State:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.seller?.state}
              </span>
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              Pincode:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.seller?.pincode}
              </span>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default SellerDetails;
