import React, { useEffect, useState } from 'react';
import ImageGallery from "../imageGallery";
import { getApiCall } from '../../../apiServices';
import AddPod from '../AddPod';
import { Button } from "semantic-ui-react";

const PodDetail = ({ details = {} }) => {
    const [showAddPod, setshowAddPod] = useState(false);
    const [podImages, setPodImages] = useState([]);
    const [loading, setLoading] = useState(true)
    useEffect(() => {

        getPodDetail()
    },[])
    const getPodDetail = async () => {
        setLoading(true)
        let res = await getApiCall('/orders/pod/details/'+details?.orderID);
        if(res?.status === 1){
            let images = []
            res?.payload.forEach(row => {
              images.push(row?.files[0])
            });
            setPodImages(images)
            setLoading(false)
        }else{
            setLoading(false)
        }
    }

    const handleShowPod = (showStatus, apiResponseStatus) => {
        if(apiResponseStatus === 1){
          getPodDetail()
        }
        setshowAddPod(!showAddPod)
    }
  return (
    <>
    <div>
      {!loading ? (<>
        {podImages?.length > 0 ? (<>
        <div className={"font-weight-bold m-1"} style={{display:"-webkit-inline-flex"}}>
          <div>
            <ImageGallery images={podImages} />
          </div>
          <div>
            <div style={{textAlign:"center", marginTop:"60px"}}>
              {!loading && podImages.length < 6  && <>
                  <Button color="blue" onClick={() => setshowAddPod(true)}>
                      Add Proof of Delivery
                  </Button>
              </>}
              {showAddPod ?  <AddPod pod={showAddPod} order={details} handleShowPod={handleShowPod} /> : ''}
          </div>
          </div>
            </div>
    </>):(<>
      <div>
            <div style={{textAlign:"center", marginTop:"10px"}}>
              {!loading && podImages.length < 6  && <>
                  <Button color="blue" onClick={() => setshowAddPod(true)}>
                      Add Proof of Delivery
                  </Button>
              </>}
              {showAddPod ?  <AddPod pod={showAddPod} order={details} handleShowPod={handleShowPod} /> : ''}
          </div>
          </div>
    </>)}
      </>) : (<>
        <div style={{textAlign:"center"}}>Loading...</div>
      </>)}


    </div>
    {/* <div style={{textAlign:"center"}}>
        {!loading && podImages?.length < 6  && <>
            <Button color="blue" onClick={() => setshowAddPod(true)}>
                Add Proof of Delivery
            </Button>
        </>}
        {showAddPod ?  <AddPod pod={showAddPod} order={details} handleShowPod={handleShowPod} /> : ''}
    </div> */}
    </>
  )
};

export default PodDetail;
