import React, { useState, useEffect, useCallback, useContext } from "react";
import NoDetails from "./NoDetails";
import {
  CCard,
  CCardBody,
  CCardText,
  CContainer,
  CButton,
  CRow,
  CCol,
} from "@coreui/react";
import { Form } from "semantic-ui-react";
import { postAPI, putAPI } from "../../../apiServices";
import { toast } from "react-toastify";
const localPermits = JSON.parse(localStorage.getItem("module"));
const EmployeeId = ({ details = {}, isLoading = false }) => {
  const [employeeId, setEmployeeId] = useState(details.showroomB2BEmployeeId);
  const [codLimit, setCodLimit] = useState(details.buyerCODLimit);
  const [codLimits, setCodLimits] = useState();
  const [userId, setUserId] = useState(details.userId || "");
  useEffect(() => {
    if (details.userId) {
      setEmployeeId(details.showroomB2BEmployeeId);
      setUserId(details.userId || "");
      setCodLimit(details.buyerCODLimit);
    }
    async function fetchData() {}
    fetchData();
  }, []);

  // Permission Code Start
  const [permissions, setPermissions] = useState(
    localPermits.find((o) => o.moduleId == 9)
  );

  const updateStatus = useCallback(
    async (updatedStatus = {}, id, remarks = "") => {
      const { status, payload, message } = await putAPI("/buyer-cod-limit", {
        buyerCODLimit: details.buyerCODLimit,
        userId: details.userId,
      });
      if (status == 1) {
        return toast.success(message);
      }
      toast.error("Something went wrong");
    }
  );

  // const updateCodLimit = async () => {
  //   if (!details.userId || !codLimit) { return }
  //   const { status, payload, message, error } = await postAPI("/get-buyer-info", {
  //     "userId": details.userId,
  //     "userType": "buyer",
  //     "buyerCODLimit": codLimit
  //   });
  //   if (status == 1) {
  //     return toast.success(message);
  //   }
  //   toast.error("Something went wrong");
  // }

  const handleCodChange = (e) => {
    let value = e.target.value;
    let id = e.target.id;
    switch (id) {
      case "codId":
        var reg = /[^a-zA-Z0-9]/g;
        var vaidNum = reg.test(value);
        if (vaidNum && value != "") {
          break;
        }
        details.buyerCODLimit = value;
        setCodLimit(value);
        break;
    }
  };

  const handleChange = (e) => {
    let value = e.target.value;
    let id = e.target.id;
    switch (id) {
      case "empId":
        var reg = /[^a-zA-Z0-9]/g;
        var vaidNum = reg.test(value);
        if (vaidNum && value != "") {
          break;
        }
        details.showroomB2BEmployeeId = value;
        setEmployeeId(value);
        break;
    }
  };

  const updateBuyerEmployeeId = async () => {
    if (!details.userId || !employeeId) {
      return;
    }
    const { status, payload, message, error } = await postAPI(
      "/update-user-details",
      {
        userId: details.userId,
        userType: "buyer",
        showroomB2BEmployeeId: employeeId,
      }
    );
    if (status == 1) {
      return toast.success(message);
    }
    toast.error("Something went wrong");
  };

  return (
    <>
      <div className="d-flex">
        <div className="col-6">
          <b>Employee Id</b>
          <div className="d-flex">
            <Form className="searchForm">
              <Form.Field>
                <input
                  disabled={permissions?.isEdit === 0 ? true : false}
                  type="text"
                  className="form-control"
                  onChange={(e) => handleChange(e)}
                  id="empId"
                  value={details.showroomB2BEmployeeId}
                  placeholder="Employee Id"
                />
              </Form.Field>
            </Form>
            <CButton
              disabled={permissions?.isEdit === 0 ? true : false}
              color="primary"
              className="ms-1 me-1 btn btn-primary"
              onClick={() => updateBuyerEmployeeId()}
            >
              Update
            </CButton>
          </div>
        </div>
        <div>
          <b>Modify COD limit</b>
          <div className="d-flex">
            <Form className="searchForm">
              <Form.Field disabled={permissions?.isEdit === 0 ? true : false}>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => handleCodChange(e)}
                  id="codId"
                  value={details.buyerCODLimit}
                  // onChange={(e) => { setCodLimits(e.target.value) }}
                  placeholder="COD limit"
                />
              </Form.Field>
            </Form>
            <CButton
              disabled={permissions?.isEdit === 0 ? true : false}
              color="primary"
              className="ms-1 me-1 btn btn-primary"
              onClick={() => updateStatus()}
            >
              Update
            </CButton>
          </div>
        </div>
      </div>
    </>
  );
};
export default EmployeeId;
