import React, { useEffect, useState } from "react";
import { getApiCall, postAPI } from "../../../apiServices";
import { toast } from "react-toastify";
import { Table, Button } from "semantic-ui-react";
import ImageGalleryWithCaption from "../../../common-components/image-viewer/ImageGalleryWithCaption"
import { CBadge,
} from "@coreui/react";
import moment from "moment";

const ReturnDetails = (props) => {

  const [returnItems, setReturnItems] = useState([]);
  const [returnSummary, setReturnSummry] = useState();

  useEffect(() => {
    async function fetchData() {
      const res = await getApiCall('/return/'+props?.details.returnId+'/items')
      if(res.status === 1){
        setReturnItems(res?.payload)
        setReturnSummry(res?.data)
      }
    }
    fetchData();
  }, []);
    return (
      <>
<div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="3">Summary</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Row>
          <Table.Cell style={{width:"33.33%"}}><strong>Return Number:</strong> {returnSummary?.returnOrderNumber}</Table.Cell>
          <Table.Cell style={{width:"33.33%"}}><strong>Amount:</strong> {returnSummary?.amount}</Table.Cell>
          <Table.Cell style={{width:"33.33%"}}><strong>Status:</strong> {returnSummary?.status}</Table.Cell>
        </Table.Row>

        <Table.Row>
          {returnSummary?.initiatedOn !== 0 && (<Table.Cell style={{borderStyle:"none"}}><strong>Initiated On:</strong> {moment(returnSummary?.initiatedOn*1000).format( "YYYY-MM-DD")}</Table.Cell>)}
          {returnSummary?.approvedOn !== 0 && (<Table.Cell style={{borderStyle:"none"}}><strong>Approved On:</strong> {moment(returnSummary?.approvedOn*1000).format( "YYYY-MM-DD")}</Table.Cell>)}
          {returnSummary?.settledOn !== 0 && (<Table.Cell style={{borderStyle:"none"}}><strong>Settled On:</strong> {moment(returnSummary?.settledOn*1000).format( "YYYY-MM-DD")}</Table.Cell>)}
          {returnSummary?.completedOn !== 0 && (<Table.Cell style={{borderStyle:"none"}}><strong>Completed On:</strong> {moment(returnSummary?.completedOn*1000).format( "YYYY-MM-DD")}</Table.Cell>)}
          {returnSummary?.rejectedOn !== 0 && (<Table.Cell style={{borderStyle:"none"}}><strong>Rejected On:</strong> {moment(returnSummary?.rejectedOn*1000).format( "YYYY-MM-DD")}</Table.Cell>)}

        </Table.Row>
      </Table>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Product</Table.HeaderCell>
            <Table.HeaderCell>Return Reason</Table.HeaderCell>
            <Table.HeaderCell>Price per item</Table.HeaderCell>
            <Table.HeaderCell>Quantity</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {(returnItems || []).map((item, index) => {
            let qtyArray = []
            if(item?.quantity){
              qtyArray = Array.from({ length: item.quantity + 1 }, (_, index) => index);
            }
            return (
              <>
              <Table.Row key={index}>
                <Table.Cell className="d-flex">
                  <div className="ml-3">
                    {item.title}
                    <br />
                    <div className="pt-2 pb-2">
                      <CBadge className="p-2" color="warning">
                        {item.productType === 1
                          ? "Color Variant"
                          : "Set Variant"}
                      </CBadge>
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <span style={{cursor:"pointer", color:"blue"}}>
                    {<ImageGalleryWithCaption images={item.img} captions={item.reasons} viewPreview={false} />}
                  </span>

                </Table.Cell>
                <Table.Cell>₹{item.rate}</Table.Cell>
                <Table.Cell>{item.status.code === 0 || item.status.code === 2 ? item.quantity : item.approval.quantity}</Table.Cell>
                <Table.Cell>₹{item.status.code === 0 || item.status.code === 2 ? item.amount.toFixed(2) : item.approval.amount}</Table.Cell>
              </Table.Row>
              {/* More reason */}
            </>
            );
          })}

          {/* <Table.Row>
            <Table.Cell></Table.Cell>
            <Table.Cell colspan="2">
              <div>Subtotal</div>
              <div>Forward Handling Charges</div>
            </Table.Cell>
            <Table.Cell>
              <div>
                {details?.returns?.quantity} /{" "}
                {state?.subTotalQty === 0
                  ? details?.returns.approval?.quantity
                  : state?.subTotalQty}
              </div>
              <div> _ </div>

            </Table.Cell>
            <Table.Cell>
              <div>₹ {details?.returns?.amount} </div>
              <div> _ </div>
            </Table.Cell>
            <Table.Cell>
              <div style={{ marginLeft: "40px" }}>
                {" "}
                {state?.subTotalValue === 0
                  ? details?.returns.approval?.amount
                  : state?.subTotalValue}{" "}
              </div>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell></Table.Cell>
            <Table.Cell colspan="2">Grand Total</Table.Cell>
            <Table.Cell>
              {details?.returns?.quantity} /{" "}
              {state?.grandTotalQty === 0
                ? details?.returns.approval?.quantity
                : state?.grandTotalQty}
            </Table.Cell>
            <Table.Cell>₹ {details?.returns?.amount} </Table.Cell>
            <Table.Cell>
              <div style={{ marginLeft: "40px" }}>
                {" "}
                {state?.grandTotalValue === 0
                  ? details?.returns.approval?.amount
                  : state?.grandTotalValue}{" "}
              </div>
            </Table.Cell>
          </Table.Row> */}
        </Table.Body>
      </Table>
    </div>
    </>
      );
}
export default ReturnDetails;
