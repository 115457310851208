import React, {useState, useEffect} from "react";
import Modal from 'react-bootstrap/Modal';
import { Button } from "semantic-ui-react";
import { postAPI } from "../../../apiServices";
import { toast } from "react-toastify";

function ActionConfirmation(props){
  const maxLength = 30;
  const [viewConfirmation, setViewConfirmation] = useState(props?.deleteConfirm)
  const [cancelConfirmVisible, setCancelConfirmVisible] = useState(false);
  const [rejectReason, setRejectReason] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [currentLength, setCurrentLength] = useState(0)

  const handleViewConfirmationClose = () => {
    setCancelConfirmVisible(true)
    setViewConfirmation(false)
    props?.handleShowConfirmation(false, '')
  }

  const handleReason = (e) => {
    let len = e.target.value?.length
      if(len <= maxLength){
        setCurrentLength(len)
        setRejectReason(e.target.value)
      }

  }
  const handleSubmit = async () => {
    setErrorMessage()
    if(props?.deleteAction === 'reject' && !rejectReason){
      setErrorMessage("Reject reason is required.")
      return false;
    }else{
      let data= {
        action: props?.deleteAction,
        requestId: props?.entry?.deleteRequestID,
        remarks:rejectReason
      }
      let res = await postAPI('/manage-account-deletion-request', data)
      if(res?.status === 1){
        toast.success(res.message)
      }else{
        toast.error(res.message)
      }
      props?.handleShowConfirmation(false, 'loading')
    }
  }
  return (<>
<Modal show={viewConfirmation} onHide={handleViewConfirmationClose} backdrop="static" keyboard={false} size="md">
        <Modal.Header>
            <Modal.Title>
              <span style={{textTransform:"capitalize"}}>
              {props?.deleteAction + " Request" }
              </span>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                      {props?.deleteAction === 'accept' ? (
                        <>Are you sure you want to accept the request?</>
                      ) : (
                        <>
                        <label>Reject Reason<sup>*</sup></label>
                        <textarea className="form-control" cols={30} rows={3} onChange={(e) => handleReason(e)}></textarea>
                        <div style={{textAlign:"right"}}>{currentLength+'/'+maxLength}</div>
                        </>
                      )}

                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
          {props?.deleteAction === 'accept' &&
          <>
            {!cancelConfirmVisible ? (<>
              <Button className="btn btn-danger" onClick={handleViewConfirmationClose}>  No </Button>
              <Button className="btn blue btn-primary btn-md"  onClick={handleSubmit}> Yes </Button>
            </>) : (<>

            </>)}
          </>
          }

          {props?.deleteAction === 'reject' &&
          <>
            {!cancelConfirmVisible ? (<>
            <p className="required">{errorMessage}</p>
              <Button className="btn btn-danger" onClick={handleViewConfirmationClose}>  Cancel </Button>
              <Button className="btn blue btn-primary btn-md"  onClick={handleSubmit}> Submit </Button>
            </>) : (<>

            </>)}
          </>
          }

        </Modal.Footer>
    </Modal>
  </>)
}

export default ActionConfirmation
