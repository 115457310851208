import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { AppContext } from "../../contexts/app";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Buyer from "./Buyer";
import { toast } from "react-toastify";
import { postAPI } from "../../apiServices";
import MESSAGES from "../../message.json";
import { Checkbox } from "semantic-ui-react";
import {
  CCol,
  CRow,
  CPagination,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CFormTextarea,
  CFormText,
  CHeader,
} from "@coreui/react";
import Swal from "sweetalert2";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "../../common-components/Pagination";

let timeout = null;

const BUYER_TYPE = [
  {
    label: "new",
    value: 0,
    type: "buyer-approval-status",
  },
  {
    label: "approved",
    value: 1,
    type: "buyer-approval-status",
  },
  {
    label: "rejected",
    value: 2,
    type: "buyer-approval-status",
  },
  {
    label: "blocked",
    value: -1,
    type: "buyer-status",
  },
  {label: "delete-request", value: 1, type: "delete-requested"},
  {label: "deleted", value: 2, type: "buyer-status"},
];

const Buyers = ({ match }) => {
  const search = useLocation().search;
  var {type} = useParams()
  let qglobal = 0;
  let qpage = new URLSearchParams(search).get("pg");
  let qkeyword = new URLSearchParams(search).get("q");
  let qOperator = new URLSearchParams(search).get("o");
  let qFilterType = new URLSearchParams(search).get("f");
  qglobal = new URLSearchParams(search).get("global");
  if(qpage != null && qpage > 0){
    qpage = qpage-1;
  }else{
    qpage = 0;
  }
  const defaultState = {
    entries: [],
    totalEntries: 0,
    page: qpage,
    recPerPage: 50,
    searchKeyword: qkeyword,
    operator:qOperator,
    filterType: qFilterType,
    loading: false,
    confirmDialog: null,
    // shouldSearchGlobally: qglobal !== null && qglobal === 1 ? true : false,
    shouldSearchGlobally: false
  };

    const { setSecondaryHeader } = useContext(AppContext);
  const [state, setState] = useState(defaultState);
  const [keywordError, setKeywordError] = useState()
  const [operatorError, setOperatorError] = useState()
  const [filterTypeError, setFilterTypeError] = useState()
  // const history = useHistory();
  const navigate = useNavigate();
  const location = useLocation();
  const searchRef = useRef(null);

  if(!state?.searchKeyword){
    let el = document.getElementById('searchKeywordId');
    if(el){
      el.value = "";
    }
  }

  const handleUrls = async () => {
    let data = [];
    if(state.searchKeyword && state.filterType && state.operator){
      data.push({key:'q', val:state.searchKeyword},{key:'f', val:state?.filterType},{key:'o', val:state.operator});
    }
    if(state?.page){
      data.push({key:'pg', val:state?.page+1})
    }
    if(state.shouldSearchGlobally){
      data.push({key:'global', val:1})
    }

    let searchUrl = '';
    data?.map((ele) => {
      searchUrl = searchUrl + ele?.key+'='+ele?.val+'&'
    })
    let lastChar = searchUrl.charAt(searchUrl.length - 1)
    if(lastChar === '&'){
      searchUrl = searchUrl.slice(0, -1)
    }
    // history.push({search : searchUrl})
    navigate(`?${searchUrl}`, { replace: true });
      }


  const fetchBuyers = useCallback(
    async (
      page = qpage,
      recPerPage = 50,
      searchKeyword = qkeyword,
      operator = qOperator,
      filterType,
      shouldSearchGlobally = false,
      listingType = "all",
      searchGlobally = 0,
    ) => {
      setState((prevState) => ({ ...prevState, entries: [], totalEntries: 0 }));
      setState((prevState) => ({ ...prevState, loading: true }));

      const data = {
        page,
        recPerPage,
        searchKeyword,
        listingType,
        searchGlobally,
        operator,
        filterType,
        filters: [
          {
            param:
              BUYER_TYPE.find((item) => item.label === type)
                ?.type || "",
            value: [
              BUYER_TYPE.find((item) => item.label === type)
                ?.value,
            ],
          },
        ],
      };
      //
      // debugger
      if (type === "incomplete" && !shouldSearchGlobally) {
        delete data.filters;
        data.listingType = "incomplete";
      }
      if (type === "delete-request" && !shouldSearchGlobally) {
        delete data.filters;
        data.listingType = "delete-requested";
      }
      if (shouldSearchGlobally) {
        delete data.filters;
        data.listingType = "";
        data.searchGlobally = 1;
      }
      if(filterType){
        data.filterType = filterType
      }

      const { status, payload, message } = await postAPI(
        "/get-buyers-list",
        data
      );

      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      //
      Swal.close();

      if (!status) {
        if (message !== "No buyer found")
          toast.error(message || MESSAGES?.sellers?.defaultApiError);
        setState((prevState) => ({
          ...prevState,
          entries: defaultState.entries,
          totalEntries: defaultState.totalEntries,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          entries: payload?.data || defaultState.entries,
          // entries: [],
          totalEntries: payload?.totRecords || defaultState.totalEntries,
        }));
      }
      // window.scrollTo(0, 0);
    },
    [type]
  );
  // debugger

  const updateStatus = useCallback(
    async (updatedStatus = {}, id, remarks = "") => {
      const { status, payload, message } = await postAPI(
        "/update-user-status",
        {
          id,
          type: updatedStatus.value,
          userType: "buyer",
          remarks,
        }
      );

      if (!status) {
        toast.error(message || MESSAGES?.buyers?.defaultApiError);
      } else {
        handleUrls()
        fetchBuyers(
          state.page,
          state.recPerPage,
          state.searchKeyword,
          state.operator,
          state.filterType,
          state.shouldSearchGlobally
        );
      }
    },
    [
      state.page,
      state.recPerPage,
      state.searchKeyword,
      state.operator,
      state.filterType,
      state.remarks,
      state.shouldSearchGlobally,
    ]
  );

  useEffect(() => {
    if(!search){
      setState((prevState) => ({
        ...prevState, filterType: null, operator: null, searchKeyword: '', page:0
      }))
      if(!state.loading){
        fetchBuyers(
          state.page,
          state.recPerPage,
          state.searchKeyword,
          state.operator,
          state.filterType,
          state.shouldSearchGlobally,
        );
      }
    }
  }, [search, type, state.recPerPage])

  useEffect(() => {
    handleUrls();
    fetchBuyers(
      state.page,
      state.recPerPage,
      state.searchKeyword,
      state.operator,
      state.filterType,
      state.shouldSearchGlobally
    );
  }, [state.page, search, type]);

  const handleData = async (e) => {
    setKeywordError()
    setOperatorError()
    setFilterTypeError()
    let id = e.target.id;
    let value = e.target.value;
    if(id==='filterType'){
      await setState((prevState) => ({
        ...prevState, 'filterType':value
      }));
      if(value){
        document.getElementById("filterType").style.borderColor = ""
      }
    }else if(id === 'operator'){
      await setState((prevState) => ({
        ...prevState, 'operator':value
      }));
      if(value){
        document.getElementById("operator").style.borderColor = ""
      }
    }

  }

  useEffect(() => {
    setSecondaryHeader([renderSecondaryHeader()]);
    return () => setSecondaryHeader([]);
  }, [
    state.searchKeyword,
    state.recPerPage,
    state.operator,
    state.filterType,
    state.shouldSearchGlobally,
    type
  ]);

  if (state.loading) {
    Swal.fire({
      title: "Loading...",
      timerProgressBar: false,
      allowOutsideClick: false,
      showCancelButton:false,
      showConfirmButton:false

    })
  }

  const resetFilter = () => {
    setState((prevState) => ({
      ...prevState,
      searchKeyword: null,
      operator: null,
      filterType: null,
      shouldSearchGlobally: false
    }))

    fetchBuyers(0, state.recPerPage, null, null, null, false)
    // setState(defaultState);
    // history.replace({ pathname: history.location.pathname})
    navigate(location.pathname, { replace: true });

  }
  const renderSecondaryHeader = () => {
    return (
      <></>
    );
  };

  const handlePagination = (pgInfo) => {
    setState((prevState) => ({
      ...prevState,
      recPerPage: parseInt(pgInfo?.recPerPage),
      page: pgInfo?.page
    }));
  }


  return (
    <>
    <CHeader>
      <CRow>
        <CCol>
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
        <form
       className="d-flex flex-column flex-sm-row align-items-center flex-wrap w-100 searchForm"
      onKeyPress={(e) => {
        if (e.key === "Enter") {
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            page: 0,
          }))
        },1);
        e.preventDefault();
        document.getElementById("searchKeywordId").style.borderColor = ""
        document.getElementById("filterType").style.borderColor = ""
        document.getElementById("operator").style.borderColor = ""
        setKeywordError()
        setOperatorError()
        setFilterTypeError()
        if (e.key === "Enter") {
          if((state.searchKeyword && state.operator && state.filterType) || state.shouldSearchGlobally){
            handleUrls()
            // fetchBuyers(
            //   state.page,
            //   state.recPerPage,
            //   state.searchKeyword,
            //   state.operator,
            //   state.filterType,
            //   state.shouldSearchGlobally
            // );
          }else{
            if(!state.searchKeyword){
              document.getElementById("searchKeywordId").style.borderColor = "red"
              setKeywordError("Required")
            }
            if(!state.operator){
              document.getElementById("operator").style.borderColor = "red"
              setOperatorError("Required")
            }
            if(!state.filterType){
              document.getElementById("filterType").style.borderColor = "red"
              setFilterTypeError("Required")
            }
          }
        }
      }
      }}>
        <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-2">
        <select className="ms-2 form-control"
          onChange={(e) => handleData(e)}
          id="filterType"
          style={{borderColor: filterTypeError ? "red" : "", minWidth:"150px"}}
          >
            <option value="" selected={state.filterType==='' ? true :false}>Select</option>
            <option value="name" selected={state.filterType==='name' ? true :false}>Name</option>
            <option value="email" selected={state.filterType==='email' ? true :false}>Email</option>
            <option value="mobile" selected={state.filterType==='mobile' ? true :false}>Mobile</option>
          </select>
        </div>
        <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-2">
          <select className="ms-2 form-control" id="operator" onChange={(e) => handleData(e)} style={{borderColor: operatorError ? "red" : "", minWidth:"150px"}}>
            <option value="" selected={state.filterType==='' ? true :false}>Select</option>
            <option value={"equals"} selected={state.operator==='equals' ? true :false}>Equals</option>
            <option value={"contains"} selected={state.operator==='contains' ? true :false}>Contains</option>

          </select>
        </div>
        <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-2">
          <input
            type="text"
            id="searchKeywordId"
            className="ms-2 form-control"
            placeholder="Name, Email, and Mobile"
            defaultValue={state?.searchKeyword}
            style={{borderColor: keywordError ? "red" : ""}}
            onChange={(evt) => {
              const { value } = evt.currentTarget;
              clearTimeout(timeout);
              timeout = setTimeout(() => {
                setState((prevState) => ({
                  ...prevState,
                  searchKeyword: (value || "").trim(),
                }));
              },1);
            }}
          />
          </div>
          <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-2">
          <Checkbox
            id="isGlobal"
            label="Search Globally"
            // value={state.shouldSearchGlobally}
            checked={state.shouldSearchGlobally}
            className="ms-4 searchGloballyText"
            onChange={(evt) => {
              setTimeout(() => {
              setState((prevState) => (
                {
                ...prevState,
                shouldSearchGlobally: !prevState.shouldSearchGlobally,
              }))
            },1);
            }}
          />
        </div>
        <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-2">
          <CButton
            color="primary"
            className="ms-1"
            onClick={() => {
              setTimeout(() => {
                setState((prevState) => ({
                  ...prevState,
                  page: 0,
                }))
              },1);
              document.getElementById("searchKeywordId").style.borderColor = ""
              document.getElementById("filterType").style.borderColor = ""
              document.getElementById("operator").style.borderColor = ""
              setKeywordError()
              setOperatorError()
              setFilterTypeError()
              if((state.searchKeyword && state.operator && state.filterType) || state.shouldSearchGlobally){
                handleUrls()
                fetchBuyers(
                  state.page,
                  state.recPerPage,
                  state.searchKeyword,
                  state.operator,
                  state.filterType,
                  state.shouldSearchGlobally
                );
              }else{
                if(!state.searchKeyword){
                  document.getElementById("searchKeywordId").style.borderColor = "red"
                  setKeywordError("Required")
                }
                if(!state.operator){
                  document.getElementById("operator").style.borderColor = "red"
                  setOperatorError("Required")
                }
                if(!state.filterType){
                  document.getElementById("filterType").style.borderColor = "red"
                  setFilterTypeError("Required")
                }
              }
            }}
          >
            <FontAwesomeIcon icon={faSearch} style={{ cursor: "pointer" }} />
          </CButton>
          </div>
          {(state.shouldSearchGlobally || state.searchKeyword && state.operator && state.filterType) &&
            <>
            <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-2">
            <CButton
              color="danger"
              className="ms-5"
              onClick={()=>resetFilter()}
              // onClick={() => filterData()}
            >
              Clear
            </CButton>
            </div>
            </>
          }

        </form>
      </div>
        </CCol>
      </CRow>
    </CHeader>
      <CRow key={"keyRow"}>
        <CCol lg={12}>
        {!!state.entries.length && (
            <Pagination  handlePagination={handlePagination} state={state} />
          )}
          {!state.loading && !state.entries.length ? (
            <>
              <div className="d-flex flex-column align-items-center">
                <h4>No Buyer(s) found</h4>
              </div>
            </>
          ) : (
            state.entries.map((entry) => (
              <Buyer
                entry={entry}
                section={entry.currentStatus}
                matchParamType={type}
                onDeleteAction={() =>fetchBuyers() }
                onStatusChange={(
                  updatedStatus = {},
                  id,
                  action,
                  entryName,
                  callback = () => {}
                ) => {
                  updateStatus(
                      updatedStatus,
                      id,
                      action,
                      entryName,
                      callback,
                  )
                  // setState((prevState) => ({
                  //   ...prevState,
                  //   confirmDialog: {
                  //     updatedStatus,
                  //     id,
                  //     action,
                  //     entryName,
                  //     callback,
                  //   },
                  // }));
                }}
              />
            ))
          )}
          {!!state.entries.length && (
            <div className="d-flex justify-content-between">
            <span className="d-flex">
            <select selected={state.recPerPage} className="form-control w-10" style={{width:"80px"}} onChange={(e) => {
              setTimeout(() => {
              setState((prevState) => ({
                ...prevState,
                recPerPage: parseInt(e.target.value),
              }))
            },1);
            }}>
              <option value={5} selected={state.recPerPage === 5 ? true : false}>5</option>
              <option value={10} selected={state.recPerPage === 10 ? true : false}>10</option>
              <option value={20} selected={state.recPerPage === 20 ? true : false}>20</option>
              <option value={50} selected={state.recPerPage === 50 ? true : false}>50</option>
              <option value={100} selected={state.recPerPage === 100 ? true : false}>100</option>
              <option value={500} selected={state.recPerPage === 500 ? true : false}>500</option>
            </select>
            <p className="w-125" style={{paddingTop:"7px"}}> &nbsp; Records per page</p>
            </span>
            <span className="d-flex">
            <p style={{paddingTop:"7px"}}>{`Showing ${state.recPerPage * state.page + 1} to ${
              state.recPerPage * state.page + state.entries.length
            } of ${state.totalEntries} record(s)`} &nbsp; </p>
            <CPagination
              activePage={state.page + 1}
              pages={Math.ceil(state.totalEntries / state.recPerPage)}
              onActivePageChange={(page) => {
                setTimeout(() => {
                setState((prevState) => ({
                  ...prevState,
                  page: page ? page - 1 : page,
                }))
              },1);
              }}
            />
            </span>
          </div>
          )}
        </CCol>
      </CRow>

      <CModal
        alignment="center"
        show={!!state.confirmDialog}
        onClosed={() => {
          setState((prevState) => ({ ...prevState, confirmDialog: null }));
        }}
      >
        <CModalHeader>
          <CModalTitle>{`${state.confirmDialog?.action} - ${state.confirmDialog?.entryName}`}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>Are you sure you want to continue?</div>
          {(state?.confirmDialog?.updatedStatus?.value === "rejected" ||
            state?.confirmDialog?.updatedStatus?.value === "blocked") && (
            <>
              {" "}
              <CFormTextarea
                size="lg"
                value={state.confirmDialog?.remarks || ""}
                className="mt-3 mb-3"
                maxLength={255}
                placeholder="Reason"
                onChange={(evt) => {
                  const { value } = evt.currentTarget;
                  setTimeout(() => {
                  setState((prevState) => ({
                    ...prevState,
                    confirmDialog: {
                      ...prevState.confirmDialog,
                      remarks: value || "",
                    },
                  }))
                },1);
                }}
              />
              <CFormText color="link" className="help-block mb-1 validation">
                {state.confirmDialog?.remarks?.length > 254 &&
                  "Max length: 255"}
              </CFormText>
            </>
          )}
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() => {
              setTimeout(() => {
              setState((prevState) => ({
                ...prevState,
                confirmDialog: null,
              }))
            },1);
            }}
          >
            Cancel
          </CButton>

          {state.confirmDialog?.remarks &&
            state?.confirmDialog?.updatedStatus?.value === "rejected" && (
              <CButton
                color="primary"
                onClick={() => {
                  updateStatus(
                    state.confirmDialog?.updatedStatus,
                    state.confirmDialog?.id,
                    state.confirmDialog?.remarks
                  );
                  state.confirmDialog.callback();
                  setState((prevState) => ({
                    ...prevState,
                    confirmDialog: null,
                  }));
                }}
              >
                Ok
              </CButton>
            )}
          {state?.confirmDialog?.updatedStatus?.value !== "rejected" && (
            <CButton
              color="primary"
              onClick={() => {
                updateStatus(
                  state.confirmDialog?.updatedStatus,
                  state.confirmDialog?.id,
                  state.confirmDialog?.remarks
                );
                state.confirmDialog.callback();
                setState((prevState) => ({
                  ...prevState,
                  confirmDialog: null,
                }));
              }}
            >
              Ok
            </CButton>
          )}
        </CModalFooter>
        {/* )} */}
      </CModal>
    </>
  );
};

export default Buyers;
