import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import moment from "moment";
import {
  CCol,
  CRow,
  CPagination,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CCard,
  CCardBody,
} from "@coreui/react";
import { Button, Table, Form } from "semantic-ui-react";
import { deleteAPI, postAPI } from "../../apiServices";
import { useNavigate } from "react-router-dom";
const defaultState = {
  entries: [],
  totalEntries: 0,
  page: 0,
  recPerPage: 10,
  searchKeyword: "",
  loading: true,
  slugType: "brands-list",
  confirmDialog: null,
  users: null,
};
const localPermits = JSON.parse(localStorage.getItem("module"));
function Brands() {
  const [state, setState] = useState(defaultState);
  const [lists, setLists] = useState([]);
  const navigate = useNavigate();
  const [showConfirm, setshowConfirm] = useState(false);
  const [permissions, setPermissions] = useState(
    localPermits.find((o) => o.moduleId == 20)
  );
  const getList = async (page) => {
    const data = {
      recPerPage: 50,
      page: 0,
      searchKeyword: "",
    };
    const { status, payload } = await postAPI("/brands/list", data);
    if (status) {
        setLists(payload);
    }
  };
  useEffect(() => {
    getList();
  }, []);

  return (
    <React.Fragment>
      <CRow>
        <CCol lg={12}>
          {lists?.length === 0 ? (
            <div className="d-flex flex-column align-items-center">
              <p>No Data Found</p>
            </div>
          ) : (
            <React.Fragment>
              <CRow>
                <CCol lg={9}>
                  <div style={{ width: 200, marginBottom: 10 }}>
                    <Form>
                      <Form.Field></Form.Field>
                    </Form>
                  </div>
                </CCol>
                {/* <CCol lg={3}>
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="d-flex w-50"></div>
                    <div className="d-flex " style={{ marginBottom: 10 }}>
                      <Button
                        disabled={permissions.isEdit === 0 ? true : false}
                        color="primary"
                        style={{ width: "200px" }}
                        // onClick={() => addNewCoupon()}
                      >
                        Add New Brand
                      </Button>
                    </div>
                  </div>
                </CCol> */}
              </CRow>
              <CCard>
                <CCardBody>
                  <Table basic="very">
                    <Table.Header>
                      <Table.Row>
                        {/* <Table.HeaderCell style={{ border: 0 }}>
                          Logo
                        </Table.HeaderCell> */}
                        <Table.HeaderCell style={{ border: 0 }}>
                          Name
                        </Table.HeaderCell>

                        <Table.HeaderCell style={{ border: 0 }}>
                          Created
                        </Table.HeaderCell>

                        <Table.HeaderCell style={{ border: 0 }}>
                          Status
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Action
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {lists?.map((list, index) => (
                        <Table.Row key={index}>
                          {/* <Table.Cell>{list.leadImg ? (<><img src={list.leadImg} /></>) : (<></>)}</Table.Cell> */}
                          <Table.Cell>
                            {list.termText}
                          </Table.Cell>

                          <Table.Cell>
                            {moment(new Date(list.createdOn * 1000)).format(
                              "DD MMM yyyy"
                            )}
                          </Table.Cell>

                          {list.status === 1 ? (
                            <Table.Cell positive>Active</Table.Cell>
                          ) : (
                            <Table.Cell negative>InActive</Table.Cell>
                          )}
                          <Table.Cell>
                            <div>
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                style={{ cursor: "pointer" }}
                                color="primary"
                                className={
                                  permissions.isTrash === 0
                                    ? "me-4 disabled"
                                    : "me-4 text-danger"
                                }
                                // className="me-4 text-danger"
                                onClick={() => {
                                  setshowConfirm(true);
                                //   setcouponId(coupon?.couponId);
                                //   setcouponName(coupon?.couponCode);
                                }}
                              />
                              <FontAwesomeIcon
                                icon={faPencilAlt}
                                style={{ cursor: "pointer" }}
                                color="primary"
                                className={
                                  permissions.isEdit === 0
                                    ? "me-4  disabled"
                                    : "me-4 text-primary"
                                }
                                // className="me-2 text-primary"
                                onClick={() => {
                                //   editCoupon(coupon);
                                  localStorage.setItem(
                                    "termTypeId",
                                    JSON.stringify(list?.termTypeId)
                                  );
                                }}
                              />
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </CCardBody>
              </CCard>
              {/* {couponList?.length && (
                <div className="d-flex align-items-center justify-content-between">
                  <p>{`Showing ${state.recPerPage * state.page + 1} to ${
                    state.recPerPage * state.page + state.entries.length
                  } of ${state.totalEntries} record(s)`}</p>
                  <CPagination
                    activePage={state.page + 1}
                    pages={Math.ceil(state.totalEntries / state.recPerPage)}
                    onActivePageChange={(page) => {}}
                  />
                </div>
              )} */}
            </React.Fragment>
          )}
        </CCol>
      </CRow>
      <CModal
        alignment="center"
        show={!!state.confirmDialog}
        onClosed={() => {
          setState((prevState) => ({
            ...prevState,
            confirmDialog: null,
          }));
        }}
      >
        <CModalHeader>
          <CModalTitle>{`${state.confirmDialog?.action}`}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>{`Are you sure you want to continue?`}</div>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                confirmDialog: null,
              }));
            }}
          >
            Cancel
          </CButton>
          <CButton color="primary">Delete</CButton>
        </CModalFooter>
      </CModal>
      <CModal
        alignment="center"
        show={showConfirm}
        onClosed={() => {
          setState((prevState) => ({
            ...prevState,
            confirmDialog: null,
          }));
        }}
      >
        <CModalHeader>
          <CModalTitle>{`Delete Coupon `}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>{`Are you sure you want to continue?`}</div>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() => {
              setshowConfirm(false);
            }}
          >
            Cancel
          </CButton>
          <CButton
            color="primary"
            // onClick={() => {
            //   handleDeleteConfirm();
            // }}
          >
            Delete
          </CButton>
        </CModalFooter>
      </CModal>
    </React.Fragment>
  );
}

export default Brands;
