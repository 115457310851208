import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <div className='breadcum-wrapper'>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {pathnames.length > 0 ? (
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
        ) : (
          <li className="breadcrumb-item active" aria-current="page">
            Home
          </li>
        )}

        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;

          return isLast ? (
            <li className="breadcrumb-item active" aria-current="page" key={to} style={{textTransform:"capitalize"}}>
              {value}
            </li>
          ) : (
            <li className="breadcrumb-item" key={to}>
              <Link to={to} style={{textTransform:"capitalize"}}>{value}</Link>
            </li>
          );
        })}
      </ol>
    </nav>
    </div>
  );
};

export default Breadcrumbs;
