import React, { useState, useEffect } from "react";
import { CButton } from "@coreui/react";
import { Form } from "semantic-ui-react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { postAPI } from "../../../apiServices";
import { Checkbox } from "semantic-ui-react";

const defaultState = {
  loading: false,
};
const localPermits = JSON.parse(localStorage.getItem("module"));

const Settings = ({ details = {}, isLoading = false, entry={} }) => {
  const [state, setState] = useState(defaultState);
  const [loyaltyPoints, setLoyaltyPoints] = useState();
  const [permissions, setPermissions] = useState(
    localPermits.find((o) => o.moduleId == 2)
  );

  const [marginOld, setMarginOld] = useState(entry.showroomMarginInPercent);
  const [margin, setMargin] = useState(entry.showroomMarginInPercent);
  const [isAutoInvoiceNo, setIsAutoInvoiceNo] = useState(entry?.b2bInvoiceSeries)
  const [userId, setUserId] = useState(entry.userId || "");

  useEffect(() => {
    if (entry.userId) {
      setMargin(entry.showroomMarginInPercent);
      setUserId(entry.userId || "");
    }
    async function fetchData() {}
    fetchData();
  }, []);

  const handleChange = (e, sellerId) => {
    let value = e.target.value;
    let id = e.target.id;
    switch (id) {
      case "margin"+sellerId:
        var reg = /^\d+$/;
        var vaidNum = reg.test(value);
        if (!vaidNum && value != "") {
          break;
        }

        entry.showroomMarginInPercent = value;
        setMargin(value);
        break;
      case "loyaltyPoints":
        var reg = /^\d+$/;
        var vaidNum = reg.test(value);
        if (!vaidNum && value != "") {
          break;
        }

        // details.showroomMarginInPercent = value;
        setLoyaltyPoints(value);
        break;
    }
  };
  const updateLoyaltyPoints = async () => {
    if (!entry.userId) {
      return;
    }
    setState((prevState) => ({ ...prevState, loading: true }));
    const { status, payload, message, error } = await postAPI(
      "/set-loyalty-points",
      {
        sellerId: entry.userId,
        productId: null,
        amount: loyaltyPoints,
      }
    );
    Swal.close();
    setState((prevState) => ({ ...prevState, loading: false }));

    if (status == 0) {
      toast.error(message);
    } else {
      toast.success(message);
    }
  };

  const updateMargin = async () => {

    if (!entry.userId || !margin) {
      return;
    }
    setState((prevState) => ({ ...prevState, loading: true }));
    const { status, payload, message, error } = await postAPI(
      "/update-seller-commercials",
      {
        id: entry.userId,
        type: "showroom-margin",
        showroomMargin: margin,
      }
    );
    Swal.close();
    setState((prevState) => ({ ...prevState, loading: false }));

    if (status == 0) {
      toast.error(message);
    } else {
      setMarginOld(margin)
      toast.success(message);
    }
  };

  if (state.loading) {
    let timerInterval;
    Swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getContent();
          if (content) {
            const b = content.querySelector("b");
            if (b) {
              b.textContent = Swal.getTimerLeft();
            }
          }
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
      }
    });
  }

  const updateInvoiceSeries = async () => {
    let autoInvoiceEnable = 0
    if(isAutoInvoiceNo === 1){
      autoInvoiceEnable = 0
    }else{
      autoInvoiceEnable = 1
    }
    let data = {
        id: entry?.userId,
        b2bInvoiceSeries: autoInvoiceEnable
    }
    let message = "Are you sure you want to enable Showroom's seller invoice?"
    let txtmessage = "Note: Seller won't be able to upload their custom invoice. \n This change will be effective on all newly orders placed from now on. Already placed orders will not be affected by this action."
    if(autoInvoiceEnable === 0){
        message = "Are you sure you want to disable Showroom's seller invoice number?"
        txtmessage = "Note: Seller won't be able to download Showroom's Seller invoice. Custom invoice is mandatory for dispatching orders. \n This change will be effective on all newly orders placed from now on. Already placed orders will not be affected by this action. "
    }
    Swal.fire({
        title: message,
        text: txtmessage,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: "No"
      }).then( async (result) => {
        if (result.isConfirmed) {
            let res = await postAPI('/update-seller-invoice-option', data)
            if(res.status === 1){
                toast.success(res?.message);
            }else{
                toast.error(res?.message);
            }
        }else{
          if(autoInvoiceEnable === 0){
            setIsAutoInvoiceNo(1)
            let check = document.getElementById("invoiceEnable"+entry?.userId).checked = true
          }else{
            setIsAutoInvoiceNo(0)
            let check = document.getElementById("invoiceEnable"+entry?.userId).checked = false
          }
        }
      })


  }

  return (
    <>
      <div className="row">
            <div className="col-4">
                <b>Margin (%)</b>
                <div className="d-flex">
                    <Form>
                        <Form.Field>
                            <input
                                disabled={permissions.isEdit === 0 ? true : false}
                                type="text"
                                className="inputfile"
                                onChange={(e) => handleChange(e, entry.userId)}
                                id={"margin"+entry.userId}
                                defaultValue={entry?.showroomMarginInPercent}
                                placeholder="Margin"
                            />
                        </Form.Field>
                    </Form>
                    <CButton
                        disabled={permissions.isEdit === 0 || marginOld === margin ? true : false}
                        color="primary"
                        className="ms-1 me-1"
                        onClick={() => updateMargin()}
                    >
                        Update
                    </CButton>
                </div>
            </div>

            {/* <div className="col-4 ms-15">
                <b>Showroom's Seller Invoice</b>
                <div className="d-flex">
                    <Form className="custom-checkbox">
                    <Checkbox toggle
                    className="mt-2 me-2"
                    id={"invoiceEnable"+entry?.userId}
                      onChange={(e) => {
                        if(e.target.checked){
                            setIsAutoInvoiceNo(1)
                            updateInvoiceSeries()
                        }else{
                            setIsAutoInvoiceNo(0)
                            updateInvoiceSeries()
                        }
                    }}
                    defaultChecked={isAutoInvoiceNo === 1 ? true : false} />
                    </Form>
                </div>
            </div> */}
      </div>
    </>
  );
};

export default Settings;
