import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { CButton } from "@coreui/react";
import { Menu, Table, Icon } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const noop = () => {};
const CategoryGrids = ({
  category = {},
  index = 0,
  onStatusChange = noop,
  label,
  slugType,
  onDeleteConfirm = noop,
}) => {
  let navigate = useNavigate();
  var idorscatename = [];
  const [page, setpage] = useState(1);

  const editCategoryGrids = async (value) => {
    navigate({
      pathname: "/edit-category",
      search: "?id=" + value + "&slugType=" + slugType,
      state: { id: value, button: "edit", slugType: slugType },
    });
  };
  const showChildCategory = async (event) => {
    navigate({
      pathname: "/child-category_level_3",
      search: "?id=" + event.target.value + "&type=3",
      //  state: { id: event.target.value, button: 'edit', slugType: slugType } */
    });
  };
  const checkOpenCategory = async (id, name) => {
    navigate({
      pathname: "/category-attribute-mapping",
      search: "?id=" + id + "&categoryname=" + name,
      /*  state: { id: event.target.value, button: 'edit', slugType: slugType } */
    });
  };
  return (
    <Table.Row>
      <Table.Cell>{index + 1}</Table.Cell>
      <Table.Cell>{category.name}</Table.Cell>
      {
        <Table.Cell>
          <img
            src={category.leadImg}
            style={{ maxHeight: 100, maxWidth: 60 }}
          />
        </Table.Cell>
      }
      <Table.Cell>{category.parentName}</Table.Cell>
      <Table.Cell>{category.priority}</Table.Cell>
      <Table.Cell>
        {/*  <div className='d-flex align-items-center justify-content-end'> */}
        <div style={{ paddingRight: 0, marginRight: -90 }}>
          <CButton
            color="primary"
            shape="rounded-pill"
            value={category.id}
            onClick={(e) => showChildCategory(e)}
            variant="outline"
            className="ms-1 me-4"
          >
            Show Child
          </CButton>
          <FontAwesomeIcon
            icon={faTrashAlt}
            style={{ cursor: "pointer" }}
            color="primary"
            className="me-4 text-danger"
            onClick={() => onDeleteConfirm(category.id)}
          />
          <FontAwesomeIcon
            icon={faPencilAlt}
            style={{ cursor: "pointer" }}
            color="primary"
            className="me-2 text-primary"
            onClick={(e) => editCategoryGrids(category.id)}
            value={category.id}
          />
          {/* <CButton
            color="primary"
            shape="rounded-pill"
            value={category.id}
            onClick={(e) => editCategoryGrids(e)}
            variant="outline"
            className="ms-1 me-1"
          >
            Edit
          </CButton> */}
          {/*  {slugType == "category-level-3" || slugType == "category-level-4" ?
            <CButton color="primary" shape="rounded-pill" value={category.id, category.name}
              onClick={() => checkOpenCategory(category.id, category.name)}
              variant='outline' className='ms-1 me-1'>
              <i className="fa fa-sitemap"></i>
            </CButton> : null} */}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default CategoryGrids;
