import React, { useState, useEffect, useCallback, useContext } from "react";
import { Form, Grid } from "semantic-ui-react";
import { postAPI } from "../../../apiServices";

const BuyerDetails = ({ details = {}, isLoading = false }) => {
  return (
    <div>
      <Grid className="ms-1">
        <Grid.Row>
          <Grid.Column width={6}>
            <div style={{ textDecoration: "underline", marginBottom: "6px" }}>
              Buyer Information:
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              Name:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.buyer?.name}
              </span>{" "}
            </div>
            {/* <div style={{ fontWeight: "bold", fontSize: "13px" }}>Email: <span style={{ fontWeight: "normal" }}>{details?.buyer?.byrEmail}</span></div> */}
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              GST Number:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.buyer?.gstNumber}
              </span>
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
             Buyer Onborded By:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.buyer?.onboardedByName + " ("+details?.buyer?.onboardedBy+")"}
              </span>
            </div>

            {/* <div style={{ fontWeight: "bold", fontSize: "13px" }}>Mobile: <span style={{ fontWeight: "normal" }}>{details?.buyer?.byrMobile}</span></div> */}
          </Grid.Column>
          <Grid.Column width={5}>
            {/* <div style={{ textDecoration: "underline", marginBottom: "6px" }}>Shipping Address</div> */}
            {/* <div style={{ fontWeight: "bold", fontSize: "13px" }}>Name: <span style={{ fontWeight: "normal" }}>{details?.buyer?.shippingAddress?.delName}</span></div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>Mobile: <span style={{ fontWeight: "normal" }}>{details?.buyer?.shippingAddress?.delMobile} </span></div> */}
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              Address:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.buyer?.address}
              </span>
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              City:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.buyer?.city}
              </span>
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              State:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.buyer?.state}
              </span>
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              Pincode:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.buyer?.pincode}
              </span>
            </div>
          </Grid.Column>

        </Grid.Row>
      </Grid>
    </div>
  );
};

export default BuyerDetails;
