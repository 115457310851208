import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import { Button } from "semantic-ui-react";
import { getApiCall, postAPI, uploadImageAPIb2b } from "../../apiServices";
import { format } from 'date-fns';
import moment from 'moment';
import { invoiceNumberRegex } from "../../constants/regexValidation";
import DatePicker from "react-datepicker";
import { getUrlFileExtension } from "../../constants/comonFunction";

function SellerInvoice(props){
    const [viewSellerInvoiceModal, setViewSellerInvoiceModal] = useState(true)
    const [invoiceNumber, setInvoiceNumber] = useState()
    const [file, setFile]= useState();
    const [fileType, setFileType] = useState();
    const [fileName, setFileName]= useState("Drop files here or click to upload");
    const [invoiceAmount, setInvoiceAmount] = useState();
    const [invoiceDate, setInvoiceDate] = useState()
    const [errorMessage, setErrorMessage] = useState("")
    const [cancelMessageTitle, setCancelMessageTitle] = useState("")
    const [cancelMessageText, setCancelMessageText] = useState("")
    const [cancelConfirmVisible, setCancelConfirmVisible] = useState(false);

    useEffect(() => {
        getDetail()
    },[props])

    const getDetail = async () => {
        let resData = await getApiCall('/orders/'+props?.order?.orderID+'/seller-custom-invoice/details/');
        if(resData.status === 0){
            let res = resData?.payload?.[0]
            setInvoiceNumber(res?.invoiceNumber)
            setFile(res?.fileURL)
            let fType = '';
            if(res?.fileURL){
                fType = getUrlFileExtension(res?.fileURL)
                if(fType === 'pdf'){
                    setFileType('application/pdf')
                }
            }
            setInvoiceAmount(res?.invoiceAmount)
            setInvoiceDate(res?.invoiceDate ? new Date(res?.invoiceDate*1000) : '')
        }
    }

    // const handleViewSellerInvoiceClose = () => {
    //     Swal.fire({
    //         title: 'Do you want to cancel',
    //         showCancelButton: true,
    //         confirmButtonText: 'Yes',
    //         cancelButtonText: "No"
    //       }).then( async (result) => {
    //         if (result.isConfirmed) {
    //             setViewSellerInvoiceModal(!viewSellerInvoiceModal)
    //             props?.handleShowSellerInvoiceModal(false)
    //         }
    //       })
    // }

    const handleViewSellerInvoiceClose = () => {
        setCancelMessageTitle("Do you want to cancel?")
        setCancelMessageText("All unsaved changes will be lost")
        setCancelConfirmVisible(true)
    }

    const rejectCancel = () => {
        setCancelMessageTitle("")
        setCancelMessageText("")
        setCancelConfirmVisible(false)
    }

    const acceptCancel = () => {
        setViewSellerInvoiceModal(!viewSellerInvoiceModal)
        props?.handleShowSellerInvoiceModal(false)
    }

    const handleInvliceFile = async (e) => {
        let files = e.target.files
        setFileType(files?.[0]?.type)
        let res = await uploadImageAPIb2b("/uploads", files);
        if(res?.status === 1){
            setFile(res?.url[0])

        }
    }

    const handleData = (e) => {
        let {name, value} = e.target;
        if(name === 'invoiceNumber'){
            if(invoiceNumberRegex.test(value)){
                setInvoiceNumber(e.target.value);
            }
        }
    }

    const handleSubmit = async() => {
        setErrorMessage()
        let isError = false;
        if(!file){
            isError = true;
        }
        if(!invoiceNumber){
            isError = true;
        }
        if(!invoiceAmount || invoiceAmount < 1){
            isError = true;
        }
        if(isError){
if(invoiceAmount < 1){
                setErrorMessage("Amount should be greater than 0")
            }else{
            setErrorMessage("fields marked with (*) are mandatory")
}

            return false;
        }else{
            let invDate = format(new Date(), 'dd-MM-yyyy');
            if(invoiceDate){
                invDate = format(new Date(invoiceDate), 'dd-MM-yyyy')
            }
            let data = {
                invoiceUrl: file,
                orderId: props?.order?.orderID,
                invoiceNumber: invoiceNumber,
                invoiceAmount: invoiceAmount,
                invoiceDate: invDate
            }
            props?.handleSellerInvoicePayload(props?.defaultAction ,data)
            setViewSellerInvoiceModal(false)
          }
        }

        const handleUpdate = async() => {
            setErrorMessage()
            let isError = false;
            if(!file){
                isError = true;
            }
            if(!invoiceNumber){
                isError = true;
            }
            if(!invoiceAmount || invoiceAmount < 1){
                isError = true;
            }
            if(isError){
                setErrorMessage("fields marked with (*) are mandatory")
                return false;
            }else{
                let data = {
                    orderId: props?.order?.customInvoiceData?.id,
                    invoiceUrl: file,
                    orderId: props?.order?.orderID,
                    invoiceNumber: invoiceNumber,
                    invoiceAmount: invoiceAmount,
                    invoiceDate: format(new Date(invoiceDate), 'dd-MM-yyyy')
                }
                let res = await postAPI('/orders/update-seller-invoice-details', data)
                if(res.status === 1){
                    toast.success(res?.message);
                    props?.handleShowSellerInvoiceModal(false)
                    setViewSellerInvoiceModal(false)
                }
            }
        }


    return (<>
    <Modal show={viewSellerInvoiceModal} onHide={handleViewSellerInvoiceClose} backdrop="static" keyboard={false} size="md">
        <Modal.Header>
            <Modal.Title>Seller's Invoice </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <lable style={{float:"left"}}>Invoice Number <sup>*</sup></lable>
                            <input type="text" className="form-control" name="invoiceNumber" placeholder="Invoice #" value={invoiceNumber} onChange={(e) => handleData(e)} required></input>
                        </div>

                        <div className="form-group">
                            <lable style={{float:"left"}}>Invoice Amount <sup>*</sup></lable>
                            <input type="number" className="form-control" placeholder="Amount" defaultValue={invoiceAmount} onChange={(e) => setInvoiceAmount(e.target.value)} required></input>
                        </div>

                        <div className="form-group">
                            <lable style={{float:"left"}}>Invoice Date <sup>*</sup></lable><br />
                            {/* <input id="swal-input1" type="date" className="form-control" placeholder="Invoice #" onChange={(e) => setInvoiceNumber(e.target.value)} required></input> */}
                            <DatePicker
                                selected={invoiceDate ? new Date(invoiceDate) : new Date()}
                                className="form-control date"
                                onChange={(date) => setInvoiceDate(date)}
                                placeholderText="DD/MM/YYYY"
                                dateFormat="dd/MM/yyyy"
                                minDate={new Date(props?.order?.placedOn)}
                                maxDate={new Date()}
                                style={{width:"100%"}}
                            />
                            {/* <input type="date" className={"form-control date"} name="invliceDate" min="2020-01-01" max={moment().format("YYYY-MM-DD")} onChange={(e) => setInvoiceDate(e.target.value)} defaultValue={invoiceDate} /> */}
                        </div>

                        <div className="form-group">
                            <lable style={{float:"left"}}>Invoice File <sup>*</sup></lable>
                        </div>
                        <br />
                        <div className="form-group22">
                            <div className="image-upload-container">

                                <div class="image-previews">
                                    <div class="image">
                                        <div>{file ? "" : fileName}</div>
                                    </div>
                                </div>
                                {file ? (<>
                                {fileType === 'application/pdf' ? (
                                    <div style={{ maxWidth:"100%", maxHeight:"100px", position:"absolute", bottom:"0", left:"10", overflow:"scroll", zIndex:999}}>
                                        <object data={file} type='application/pdf'></object>
                                    </div>

                                ) : (
                                    <img src={file} style={{ maxWidth:"100%", maxHeight:"100px", position:"absolute", bottom:"0", left:"10"}} />

                                )}

                                </>) : (<></>)}
                                <input name="images" type="file" accept="image/x-png, image/jpeg, image/x-jpg, image/x-gif, application/pdf" style={{ width:"100%", height:"100px", position:"absolute", opacity:"0", bottom:"0", right:"0"}} onChange={(e) => handleInvliceFile(e)} />
                            </div>
                        </div>
                    </div>

                    {/*  */}
                </div>

            </div>
        </Modal.Body>
        <Modal.Footer>
            {!cancelConfirmVisible ? (<>
                <p className="required">{errorMessage}</p>
                <Button className="btn btn-danger" onClick={handleViewSellerInvoiceClose}>  Cancel </Button>
                {props?.order?.customInvoiceData?.id ? (<>
                    <Button className="btn blue btn-primary btn-md"  onClick={handleUpdate}> Update </Button>
                </>) : (
                    <Button className="btn blue btn-primary btn-md"  onClick={handleSubmit}> Submit </Button>
            )}
            </>) : (<>
                <div>
                    <p><strong>{cancelMessageTitle}</strong> {" "}{cancelMessageText}</p>
                </div>
                <Button className="btn btn-danger" onClick={rejectCancel}>  No </Button>
                <Button className="btn blue btn-primary btn-md"  onClick={acceptCancel}> Yes </Button>
            </>)}

        </Modal.Footer>
    </Modal>
    </>);
}
export default SellerInvoice;
