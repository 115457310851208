import React, { useState, useEffect, useCallback, useContext } from "react";
import NoDetails from "./NoDetails";
import {
  CCard,
  CCardBody,
  CCardText,
  CContainer,
  CButton,
  CRow,
  CCol,
} from "@coreui/react";
import { Form, Select } from "semantic-ui-react";
import { postAPI } from "../../../apiServices";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
const localPermits = JSON.parse(localStorage.getItem("module"));

const BulkUpdate = ({entry={}, details = {}, isLoading = false }) => {
const options = [
                {key:1, text:"Approve All", value:"approve-all"},
                {key:2, text:"Approve under review", value:"approve-in-review"},
                {key:3, text:"Reject All", value:"reject-all"},
                {key:4, text:"Reject Under Review", value:"reject-in-review"}];

  const [userId, setUserId] = useState(details.userId || "");
  const [selectedValue, setSelectedValue] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [productCount, setProductCount] = useState();
  const [loading, setLoading] = useState(true);
  const [rejectReason, setRejectReason] = useState("");
  const [permissions, setPermissions] = useState(
    localPermits.find((o) => o.moduleId == 2)
  );

  const handleData = (e) => {
      setSelectedValue(e.target.value)
  }

  const buldUpdateData = async () => {
      
    setErrorMessage('');
      if(selectedValue == null || selectedValue == '' ){
        toast.error("Please select action");
        return false
      }
      else{
          let selectedText ="";
          let msgTitle = "";
          let msgText = "";
          if(selectedValue === 'approve-all'){
            selectedText = 'Approve all';
            msgTitle = "Aprroval Confirmation";
          }else if(selectedValue === 'approve-in-review'){
            selectedText = 'Approve under review';
          }else if(selectedValue === 'reject-all'){
            selectedText = 'Reject all';
          }else if(selectedValue === 'reject-in-review'){
            selectedText = 'Reject under review';
          }else{
            selectedText = '';
          }
        const data = {
            sellerId: entry.userId,
            action: selectedValue,
        };
        const { status, payload, message, error } = await postAPI("/products/get-bulk-count",data);
        if (status == 1) {
            if(payload.totalCount > 0){
              if(selectedValue === 'approve-all'){
                msgText = "Are you sure you want to approve "+payload.totalCount +" product"+(payload.totalCount > 1 ? 's':'')+" ?";
                msgTitle = "Aprroval Confirmation";
              }else if(selectedValue === 'approve-in-review'){
                msgText = 'Are you sure want to approve '+payload.totalCount+' product'+(payload.totalCount > 1 ? "s":"")+' from "Under Review" status?';
                msgTitle = "Aprroval Confirmation";
              }else if(selectedValue === 'reject-all'){
                msgText = 'Are you sure want to reject '+payload.totalCount+' product'+(payload.totalCount > 1 ? "s":"")+' ?';
                msgTitle = "Rejection Confirmation";
              }else if(selectedValue === 'reject-in-review'){
                msgText = 'Are you sure want to reject '+payload.totalCount+' product'+(payload.totalCount > 1 ? "s":"")+' ?';
                msgTitle = "Rejection Confirmation";
              }else{
                selectedText = '';
              }
              
                Swal.fire({
                    title: msgTitle,
                    text: msgText,
                    showCancelButton: true,
                    showConfirmButton: true,
                    html: (selectedValue ==='reject-all' || selectedValue === 'reject-in-review') ? `
                    <input class="swal2-input" id="rejectReason" type="text" placeholder="Reason for rejection" />`:"" ,
                    confirmButtonText: "Yes",
                    allowOutsideClick: false,
                    preConfirm: () => {
                      if(selectedValue ==='reject-all' || selectedValue === 'reject-in-review'){
                        const rejectreason = Swal.getPopup().querySelector('#rejectReason').value
                        if (!rejectreason) {
                          Swal.showValidationMessage(`Please enter reason for rejection`)
                        }
                        return { rejectionRemarks: rejectreason }
                      }
                      
                    }
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        data.rejectionRemarks = result.value?.rejectionRemarks;
                        const { status, payload, message, error } = await postAPI("/products/bulk-action",data);
                        if(status == 1){
                              Swal.fire({
                                icon: 'success',
                                title: selectedText,
                                text: selectedText + ' successfully',
                                showConfirmButton: false,
                                timer: 5500
                              })
                        }
                        else{
                            Swal.fire({
                                icon: 'error',
                                title: 'Opps...',
                                text: message,
                                showConfirmButton: false,
                                timer: 1500
                              })
                        }
                    }
                  });
            }
            else{
              msgTitle = "No Product Found";
              if(selectedValue === 'approve-all'){
                msgText = "No product found to approve.";
                
              }else if(selectedValue === 'approve-in-review'){
                msgText = 'No product found in "Under Review" status to approve.';
              }else if(selectedValue === 'reject-all'){
                msgText = 'No product found to reject.';
              }else if(selectedValue === 'reject-in-review'){
                msgText = 'No product found in "Under Review" status to reject.';
              }else{
                selectedText = '';
              }
                Swal.fire({
                    title: msgTitle,
                    text: msgText,
                    showCancelButton: false,
                    showConfirmButton: true,
                    confirmButtonText: "Ok",
                    allowOutsideClick: false,
                  })
            }
        }
        else{
            toast.error("Something went wrong");
        }
        
      } 
  };

  return (
    <>
    <div className="d-flex">
        <div className="col-12">
          
          <div>
            <Form>
                  <div className="row">
                    <div className="col-12">
                      <label style={{marginRight:"40px", padding:"2px 10px 2px 0", fontSize:"16px", fontWeight:"bold"}}>Draft Products : {details?.totalDraftProducts} </label>  
                      <label style={{marginRight:"40px", padding:"2px 10px 2px 0", fontSize:"16px", fontWeight:"bold"}}>Under Review Products : {details?.totalReviewProducts} </label> 
                      <label style={{marginRight:"40px", padding:"2px 10px 2px 0", fontSize:"16px", fontWeight:"bold"}}>Approved Products : {details?.totalApprovedProducts} </label>
                      <label style={{marginRight:"40px", padding:"2px 10px 2px 0", fontSize:"16px", fontWeight:"bold"}}>Total Product : {details?.totalProducts}</label>
                    </div>
                    </div>
                    
                    <div className="row">
                    <div className="col-4">
                        <b>Product Status Update</b>
                        <div>
                            <select required="required" onChange={(e) => handleData(e)}>
                                <option>Please Select</option>
                                <option value="approve-all">Approve All</option>
                                <option value="approve-in-review">Approve Under Review</option>
                                <option value="reject-all">Reject All</option>
                                <option value="reject-in-review">Reject Under Review</option>
                            </select>
                            {/* <Form.Field
                                control={Select}
                                options={options}
                                // value={selectedGst}
                                placeholder="GST"
                                defaultValue={selectedValue}
                                onChange={(e, data) => handleData(e, data)}
                                /> */}
                        </div>
                    </div>
                  <div className="col-2">
                  <br />
                  <CButton
                        disabled={permissions?.isEdit === 0 ? true : false}
                        color="primary"
                        className="ml-1 mr-1"
                        onClick={buldUpdateData}
                    >
                Go
            </CButton>
                  </div>
                  </div>
            </Form>
            
          </div>
        </div>
      </div>
    </>
  );
};
export default BulkUpdate;
