
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom'

import { Divider, Form, Label, Input, Dropdown, Button, Segment, Header } from 'semantic-ui-react'
import Swal from 'sweetalert2';
import queryString from 'query-string'
import { toast } from 'react-toastify';
import MESSAGES from '../../message.json';

import { postAPI, uploadImageAPI } from '../../apiServices';
import {
  CCard,
  CCardBody,
} from '@coreui/react';


import '../../assets/css/custom.css'
const defaultState = {
  username: '',
  password: '',
  loading: false,
  errors: {
    username: <>&nbsp;</>,
    password: <>&nbsp;</>,
  }
};
const AttributeEdit = () => {
  // let router = useHistory();
  let history = useNavigate();
  const [state, setState] = useState(defaultState);
  const [attribute, setattribute] = useState([]);
  const [attributelist, setattributelist] = useState([]);
  const [prodcategory, setprodcategory] = useState(true);
  const [imagefilename, setimagefilename] = useState();
  const [termtype, setTermType] = useState("product-attribute");
  const [termtypeProduct, setTermTypeProduct] = useState(1);
  const [termname, setTermName] = useState();
  const [parent, setparent] = useState();
  const [message, setmessage] = useState();
  const [uploadImage, setuploadImage] = useState();
  const [parentName, setparentName] = useState();
  const location = useLocation();
  const [leadImg, setleadImg] = useState();
  let queries = queryString.parse(location.search)
  let attributearryay = [];
  const [slugTypes, setslugTypes]=useState(queries.slugType)


  const fetchProducts = useCallback(async (searchKeyword = '') => {
    setState(prevState => ({
      ...prevState,
      loading: true,
    }));
    const page = 0
    const recPerPage = 50
    const id = queries.id;
    const slugType = 'product-attribute';
    const parent = "";


    const { status, payload, message } = await postAPI('/get-categories-list', {
      id,
      page,
      recPerPage,
      slugType,
      parent

    });
    await postAPI('/get-product-attributes', {
    }).then(res => {
      setattribute(res.payload.attributes)
    })

    setState(prevState => ({
      ...prevState,
      loading: false,
    }));
    Swal.close()

    if (!status) {


      if (message !== 'No product found') toast.error(message || MESSAGES?.sellers?.defaultApiError);
      setState(prevState => ({
        ...prevState,
        entries: defaultState.entries,
        totalEntries: defaultState.totalEntries,
      }))

    } else {
      payload.data.map((data) => {
        setTermName(data.name)
        setparent(data.parentId)
        setleadImg(data.leadImg)
        setparentName(data.parentName)


      });
    }
  }, []);

  useEffect(() => {
    fetchProducts(
      state.page,
      state.recPerPage,
      state.searchKeyword,
      state.slugType
    );

  }, [
    fetchProducts,
    state.page,
    state.recPerPage,
    state.slugType
  ]);
  if (state.loading) {
    let timerInterval;

  }
  const fileChangedHandler = (event) => {
    setuploadImage(event)
  }
  const EditCategory = async () => {
    var payloadimg = '';
    var imagePath = "";
    if (uploadImage != undefined) {

      payloadimg = await uploadImageAPI('/uploads', uploadImage)
      setleadImg(payloadimg.url[0])
      imagePath = payloadimg.url[0];
    }
    else { imagePath = leadImg }

    const po = {
      id: queries.id,
      type: "product-attribute",// product-attribute-list or product-attribute
      termName: termname,
      leadImg: imagePath,
      status: 1,
      parent: parent,
    }
    const { status, payload, message } = await postAPI('/save-category-data', po, {
    });
    if (status == 1) {
      //setmessage(message)
      toast.success(message)
    }
    else {
     toast.error(message)
    }
  };


  const comboxSelected = async (event) => {
    setslugTypes(event.target.value)
    setTermType(event.target.value)
  }
  const setParentValue = async (event) => {
    setparent(event)
  }


  /*   if (prodcategory) { */
  attributearryay.push(<option >Select</option>)
  let optionItems = attribute.map((child) => {

    if (child.id == parent) {
      attributearryay.push(<option key={child.id} selected value={child.id}>{child.name}</option>)
    }
    else
    {
      attributearryay.push(<option key={child.id}  value={child.id}>{child.name}</option>)
    }

    /*    child.child.map((child1) => {
         attributearryay.push(<option key={child1.id} value={child1.id}>--{child1.name}</option>)

         child1.child.map((child2) => {
           attributearryay.push(<option key={child2.id} value={child2.id}>-----{child2.name}</option>)

           child2.child.map((child3) => {
             attributearryay.push(<option key={child3.id} value={child3.id}>--------{child3.name}</option>)

           });
         });
       }); */
  });
  //}

  return (
    <CCard>
      <CCardBody>
        <div style={{
          alignItems: "center", display: "flex",
          justifyContent: "center",
        }} ><h3>{message}</h3></div>

        <Form>
          <Form.Field>
            <span ><h5 style={{ marginBottom: '10px' }}>Image</h5></span>
            <input type="file" class="inputfile" id="embedpollfileinput" onChange={e => fileChangedHandler(e.target.files)} />
            <div style={{ marginTop: 10 }}> <img src={leadImg} width='100' height='100' /></div>
          </Form.Field><Form.Field>
            <span><h5 style={{ marginBottom: '10px' }}>Term Name</h5></span>
            <input type='text' placeholder='Term Name' value={termname} onChange={e => setTermName(e.target.value)} />

          </Form.Field>
          {slugTypes== "product-attribute" ?  <Form.Field>
            <span><h5 style={{ marginBottom: '10px' }}>Term Type</h5></span>
            <select
              onChange={(e) => comboxSelected(e)} >
              <option value="product-attribute">Product Attribute </option>
              <option value="product-attribute-list">Product Attribute List </option>

            </select>
          </Form.Field> : <Form.Field>
            <span><h5 style={{ marginBottom: '10px' }}>Term Type</h5></span>
            <select
              onChange={(e) => comboxSelected(e)} >
              <option value="product-attribute-list">Product Attribute List </option>
              <option value="product-attribute">Product Attribute </option>


            </select>
          </Form.Field>

}

          {slugTypes== "product-attribute" ? null :
            <Form.Field>
              <span><h5 style={{ marginBottom: '10px' }}>Parent</h5></span>
              <select onChange={(e) => setParentValue(e.target.value)} >
                {attributearryay}
              </select>
            </Form.Field>
          }
          <div style={{ float: 'right' }}>
            <Button primary onClick={EditCategory}>Submit</Button>
          </div>
        </Form>
      </CCardBody>
    </CCard>
  )
}

export default AttributeEdit

