import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    useContext,
  } from "react";

import {
    CCard,
    CCardBody,
    CCardText,
    CNav,
    CNavItem,
    CNavLink,
    CTabContent,
    CTabPane,
    CFormInput,
    CBadge,
    CButton
} from "@coreui/react";
import { Button, Table, Dropdown } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import { postAPI, uploadImageAPIb2b } from "../../apiServices";
import { amountRegex } from "../../constants/regexValidation";

const defaultState = {
    selectedItem: [],
  };

  const CreateReturn = ({ createRetrun, updateCreteReturn}, props) => {
    const [shwoCreateReturnModal, setShowCreateReturnModal] = useState()
    const [orderId, setOrderId] = useState();
    const [returnType, setReturnType] = useState()
    const [returnItems, setReturnItems] = useState([]);
    const [noItemerror, setNoItemerror] = useState()
    const [selectedQty, setSelectedQty] = useState(defaultState.selectedItem);
    const [priceDiffValue, setPriceDiffValue] = useState()
    const [gstDiffValue, setGstDiffValue] = useState()
    const [reasonList, setReasonList] = useState([]);

    useEffect(() => {
        setShowCreateReturnModal(createRetrun)
    },[createRetrun])

    const handleViewCreateReturnModalClose = () => {
        setShowCreateReturnModal(false);
        updateCreteReturn();
    }

    const getReasonList = async () => {
        let data = {
            page: 0,
            recPerPage: 50,
            slugType: "cancellation-reason",
            parent: ""
        }
        let res = await postAPI('/get-data-list', data);
        if(res?.status === 1){
            setReasonList(res?.payload?.["cancellation-reason"])
        }
    }

    const handleDiff = (e) => {
        let {name, value} = e.target;
        if(name === 'priceDiff'){
            if(amountRegex.test(value) === true){
                setPriceDiffValue(e.target.value);
            }
        }

        if(name === 'gstDiff'){
            if(amountRegex.test(value)){
                setGstDiffValue(e.target.value);
            }
        }
    }

    const selectQuantity = async (item, qItem, index) => {
        let reData = returnItems;
        for (let i = 0; i < reData.length; i++) {
          let amount = (qItem * parseFloat(item.buyerPricePerItem)).toFixed(2);
          reData[index].approvedAmount = amount;
          reData[index].selectedQtyValue = qItem;
          reData[index].qty = qItem;

          document.getElementById('amount'+index).innerHTML = amount;
        }

        await setReturnItems(reData);
      };

      const uploadProofOfReturn = async (e, indexToAdd) => {

        let newKey = "returnProof";
        // let imgs = returnItems[indexToAdd]?.returnProof;

        let files = e.target.files
        let images = returnItems[indexToAdd]?.returnProof ? returnItems[indexToAdd]?.returnProof : [];
        let payloadimg = await uploadImageAPIb2b("/uploads", files);
        if(payloadimg?.status === 1){
            payloadimg?.url?.forEach(ele => {
                images.push(ele)
            })
        }
        let test = returnItems;
        // test[indexToAdd].returnProof = [];
        test[indexToAdd].returnProof = "images";
        setReturnItems((prevReturnItem) =>
            prevReturnItem.map((item, index) =>
                index === indexToAdd ? { ...item, [newKey]: images } : item
            )
        );
      }

      const changeItemQty = async (selectItm, qty, index) => {
        let isfind = defaultState.selectedItem.find(
          (item) => parseInt(item.returnItemId) === selectItm.returnItemId
        );
        if (!isfind) {
          defaultState.selectedItem.push({
            returnItemId: `${selectItm.returnItemId}`,
            qty: `${qty}`,
          });
        } else {
          const objIndex = defaultState.selectedItem.findIndex(
            (obj) => parseInt(obj.returnItemId) === selectItm.returnItemId
          );
          defaultState.selectedItem[objIndex].qty = qty;
        }
        await setSelectedQty(defaultState.selectedItem);
      };

    const getItems = async () => {
        let payload = {
            action : "deliver",
            searchGlobally : 2,
            orderID : orderId,
            page : 0,
            recPerPage: 50}
        let res = await postAPI('/orders/items/list', payload)
        if(res.status === 1){
            getReasonList()
            setReturnItems(res.payload.data)
        }else{
            setNoItemerror(res.message)
        }

    }

    const submitCreateReturn = async () => {
    }
    return (<>
    <Modal show={shwoCreateReturnModal} className="createReturn" onHide={handleViewCreateReturnModalClose} backdrop="static" keyboard={false} size="lg" style={{width:"100%", margin:"auto"}}>
        <Modal.Header>
            <Modal.Title>Create Return</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="container">
                <div className="row">
                    <div className="col-2"></div>
                    <div className="col-6 mb-2">
                        <div>
                          <input type="text" name="orderId" className="form-control" placeholder="Order Id*"
                          onChange = {(e) => setOrderId(e.target.value)}
                          // onChange={(e) => handleTransDetail(e)}
                           />
                        </div>
                    </div>

                    <div className="col-3">
                        <div>
                            <CButton
                                color="primary"
                                className="ms-1"
                                onClick={getItems}
                                >
                                <FontAwesomeIcon icon={faSearch} style={{ cursor: "pointer" }} />
                            </CButton>
                        </div>
                    </div>

                </div>

            </div>
        </Modal.Body>
        <Modal.Footer>
            {returnItems?.length > 0 ? (<>
                <div style={{width:"100%"}}>
                <Table>
                    <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Product</Table.HeaderCell>
                        <Table.HeaderCell>Price per item</Table.HeaderCell>
                        <Table.HeaderCell>Quantity</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell>Return Reason</Table.HeaderCell>
                        <Table.HeaderCell>Proof of Return</Table.HeaderCell>
                    </Table.Row>
                    </Table.Header>

                    <Table.Body>
                    {(returnItems || []).map((item, index) => {
                        let qtyArray = []
                        if(item?.setItem > 0 && item?.setQty>0){
                        qtyArray = Array.from({ length: (item.setItem * item.setQty) + 1 }, (_, index) => index);
                        }
                        return (
                        <Table.Row key={"item"+index}>
                            <Table.Cell className="d-flex">
                            <div className="ms-3">
                                {item.productName}
                                <br />
                                <div className="pt-2 pb-2">
                                <CBadge className="p-2" color="warning">
                                    {item.productType === 1
                                    ? "Color Variant"
                                    : "Set Variant"}
                                </CBadge>
                                </div>
                            </div>
                            </Table.Cell>

                            <Table.Cell>₹{item.buyerPricePerItem}</Table.Cell>
                            <Table.Cell>
                            <div style={{ display: "flex" }}>

                                <select onChange={(e) => selectQuantity(item, e.target.value, index, e.target.key)}>
                                    <option value="">Select</option>
                                    {(qtyArray || []).map((qItem, qIndex) => {
                                        return (<option key={qIndex} value={qItem} selected>{qItem}</option>)
                                    })}
                                </select>
                                <div> &nbsp; / {item.setItem * item.setQty} </div>

                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                ₹<span id={"amount"+index}>{(item.setItem * item.setQty * item.buyerPricePerItem).toFixed(2)}</span>
                            </Table.Cell>
                            <Table.Cell>
                                <select name="" className="form-control" style={{maxWidth:"150px"}}>
                                    <option value="">Select</option>
                                    {reasonList?.map((row) => {
                                        return (<option value={row?.attb_title}>{row?.attb_title}</option>)
                                    })}
                                </select>
                            </Table.Cell>
                            <Table.Cell>
                                <input type="file" name={"proofOfReturn"+index} multiple onChange={(e) => uploadProofOfReturn(e, index)} className="form-control" style={{height:"auto", padding:"4px"}} />
                            </Table.Cell>
                        </Table.Row>
                        )
                    })}

                    </Table.Body>
                </Table>



                <Table>
                    <Table.Body>
                        <Table.Row key={"index"}>

                            <Table.Cell style={{width:"20%"}}>
                                <div><input type="checkbox" name="returnType" checked = {returnType==='priceDiff' ? true : false} onChange={(e)=>{
                                    if(e.target.checked){
                                        setReturnType("priceDiff")
                                    }else{
                                        setReturnType()
                                    }
                                }} /> Price Difference Return</div>
                            </Table.Cell>
                            <Table.Cell style={{width:"20%"}}>
                            <div className="mb-1"><CFormInput type="number" name="priceDiff" pattern="/^[0-9.]*$/" disabled={returnType==='priceDiff' ? false : true} className="form-control" placeholder="Price Difference" value={priceDiffValue} onChange={(e) => handleDiff(e)}></CFormInput></div>
                            </Table.Cell>
                            <Table.Cell style={{width:"20%"}}>
                            <div><CFormInput type="number" className="form-control" name="gstDiff" pattern="/^[0-9.]*$/" disabled={returnType==='priceDiff' ? false : true} placeholder="GST Difference" value={gstDiffValue} onChange={(e) => handleDiff(e)}></CFormInput></div>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>
            <div>
                <Button className="btn btn-danger" onClick={handleViewCreateReturnModalClose}>  Cancel </Button>
                <Button className="btn blue btn-primary btn-md" onClick={() => submitCreateReturn()}> Submit </Button>
            </div>
            </>) : (<><div style={{color:"red", display:"block", textAlign:"center", width:"100%"}}>{noItemerror}</div></>)}

            {/* {!cancelConfirmVisible ? (<>
              <Button className="btn btn-danger" onClick={handleViewTransnModalClose}>  Cancel </Button>
              <Button className="btn blue btn-primary btn-md"  onClick={markComplete}> Submit </Button>
              <span style={{color:"red"}}>{errorMessage}</span>
            </>) : (<>

                <Button className="btn btn-danger" onClick={rejectCancel}>  No </Button>
                <Button className="btn blue btn-primary btn-md"  onClick={acceptCancel}> Yes </Button>
                <div>
                    <p style={{color:"red"}}><strong>{cancelMessageTitle}</strong> {" "}{cancelMessageText}</p>
                </div>
            </>)} */}
        </Modal.Footer>
    </Modal>
    </>)

  }
  export default CreateReturn
