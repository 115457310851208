import React from 'react';

const noop = () => { };

const Avatar = ({
  radius = 4,
  size = 40,
  src = '',
  alt = '',
  type = 'square',
  isBorder = false,
  onClick = noop,
}) => {
  return (
    <div onClick={onClick}>
      {src ? (
        <img alt={alt} src={src} style={{ height: size, width: size, borderRadius: type === 'square' ? radius : size, border: isBorder ? '1px solid #ccc' : '', cursor: 'pointer' }} />
      ) : (
        <div className='d-flex align-items-center justify-content-center bg-primary' style={{ height: size, width: size, borderRadius: type === 'square' ? radius : size, border: isBorder ? '1px solid #ccc' : '' }}>
          <p className='text-white' style={{ fontSize: size / 2 }}>{(alt || ' ')[0].toUpperCase()}</p>
        </div>
      )}
    </div>
  );
};

export default Avatar;