import React from 'react';
import { CCardText } from '@coreui/react';
import NoDetails from './NoDetails';

const BankDetails = ({
  details = null,
  isLoading = false,
}) => {

  return (
    <div className='d-flex'>
      <div className='w-100'>
        {!details ? (
          <NoDetails isLoading={isLoading} tabName='Bank' />
        ) : (
          [
            { label: 'Beneficiary', key: 'beneficiary'},
            { label: 'Account Number', key: 'accountNumber'},
            { label: 'Bank', key: 'bankName'},
            { label: 'Branch', key: 'branchName'},
            { label: 'IFSC', key: 'ifsc'},
            { label: 'View Cancelled Cheque', key: 'cancelledCheque'},
          ].map(item => (
            <div className='d-flex'>
              {item.key === 'cancelledCheque' ? (
                <a target='_blank' href={details && details[item.key]}>{item.label}</a>
              ) : (
                <>
                  <CCardText className='font-weight-bold'>{item.label}:</CCardText>
                  <CCardText className='ms-1'>{details && details[item.key]}</CCardText>
                </>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  )
};

export default BankDetails;
