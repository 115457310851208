import React, { useState, useEffect } from "react";
import NoDetails from "./NoDetails";
import { CButton } from "@coreui/react";
import { Form } from "semantic-ui-react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { postAPI } from "../../../apiServices";

const TYPE = [
  { label: "Unregistered", value: 0 },
  { label: "Registered", value: 1 },
];

const DOCUMENT_TYPE = [
  { label: "Unknown", value: 0 },
  { label: "Ownership", value: 1 },
  { label: "Distributorship", value: 2 },
];

let timeout = null;
const defaultState = {
  loading: false,
};
const localPermits = JSON.parse(localStorage.getItem("module"));

const Commercials = ({ details = {}, isLoading = false }) => {
  const [state, setState] = useState(defaultState);
  const [loyaltyPoints, setLoyaltyPoints] = useState();
  const [permissions, setPermissions] = useState(
    localPermits.find((o) => o.moduleId == 2)
  );

  const [margin, setMargin] = useState(details.showroomMarginInPercent);
  const [userId, setUserId] = useState(details.userId || "");

  useEffect(() => {
    if (details.userId) {
      setMargin(details.showroomMarginInPercent);
      setUserId(details.userId || "");
    }
    async function fetchData() {}
    fetchData();
  }, []);

  const handleChange = (e) => {
    let value = e.target.value;
    let id = e.target.id;
    switch (id) {
      case "margin":
        var reg = /^\d+$/;
        var vaidNum = reg.test(value);
        if (!vaidNum && value != "") {
          break;
        }

        details.showroomMarginInPercent = value;
        setMargin(value);
        break;
      case "loyaltyPoints":
        var reg = /^\d+$/;
        var vaidNum = reg.test(value);
        if (!vaidNum && value != "") {
          break;
        }

        // details.showroomMarginInPercent = value;
        setLoyaltyPoints(value);
        break;
    }
  };
  const updateLoyaltyPoints = async () => {
    if (!details.userId) {
      return;
    }
    setState((prevState) => ({ ...prevState, loading: true }));
    const { status, payload, message, error } = await postAPI(
      "/set-loyalty-points",
      {
        sellerId: details.userId,
        productId: null,
        amount: loyaltyPoints,
      }
    );
    Swal.close();
    setState((prevState) => ({ ...prevState, loading: false }));

    if (status == 0) {
      toast.error(message);
    } else {
      toast.success(message);
    }
  };

  const updateMargin = async () => {
    if (!details.userId || !margin) {
      return;
    }
    setState((prevState) => ({ ...prevState, loading: true }));
    const { status, payload, message, error } = await postAPI(
      "/update-seller-commercials",
      {
        id: details.userId,
        type: "showroom-margin",
        showroomMargin: margin,
      }
    );
    Swal.close();
    setState((prevState) => ({ ...prevState, loading: false }));

    if (status == 0) {
      toast.error(message);
    } else {
      toast.success(message);
    }
  };

  if (state.loading) {
    let timerInterval;
    Swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getContent();
          if (content) {
            const b = content.querySelector("b");
            if (b) {
              b.textContent = Swal.getTimerLeft();
            }
          }
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
      }
    });
  }

  return (
    <>
      <div className="row">
        <div>
          <b>Margin (%)</b>
          <div className="d-flex">
            <Form>
              <Form.Field>
                <input
                  disabled={permissions.isEdit === 0 ? true : false}
                  type="text"
                  className="inputfile"
                  onChange={(e) => handleChange(e)}
                  id="margin"
                  defaultValue={details.showroomMarginInPercent}
                  placeholder="Margin"
                />
              </Form.Field>
            </Form>
            <CButton
              disabled={permissions.isEdit === 0 ? true : false}
              color="primary"
              className="ms-1 me-1"
              onClick={() => updateMargin()}
            >
              Update
            </CButton>
          </div>
        </div>
        {/* <div className="ms-5">
          <b>S-Cash (Per 100)</b>
          <div className="d-flex">
            <Form>
              <Form.Field>
                <input
                  disabled={permissions.isEdit === 0 ? true : false}
                  type="text"
                  className="inputfile"
                  onChange={(e) => handleChange(e)}
                  placeholder="S-Cash"
                  defaultValue={details?.loyalty_points}
                />
              </Form.Field>
            </Form>
            <CButton
              disabled={permissions.isEdit === 0 ? true : false}
              color="primary"
              className="ms-1 me-1"
              onClick={() => updateLoyaltyPoints()}
            >
              Update
            </CButton>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Commercials;
