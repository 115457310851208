import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Button } from "semantic-ui-react";
import { postAPI } from "../../apiServices";

const defaultCancelReason = [
    { id: 1, name: "No need", value: "no-need" },
    { id: 2, name: "Damaged", value: "damaged" },
    { id: 3, name: "Other", value: "other" },
];

const CancelOrder = (props) => {
    const { cancelShow: initialCancelShow, order } = props;
    const [cancelShow, setCancelShow] = useState(initialCancelShow);
    const [reasonList, setReasonList] = useState(defaultCancelReason);
    const [inputType, setInputType] = useState('select');
    const [cancelReason, setCancelReason] = useState('');
    const [refundDestinationList, setRefundDestinationList] = useState(order?.refundDestination || []);
    const [refundSource, setRefundSource] = useState('');
    const [channel, setChannel] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [cancelMessageTitle, setCancelMessageTitle] = useState('');
    const [cancelMessageText, setCancelMessageText] = useState('');
    const [cancelConfirmVisible, setCancelConfirmVisible] = useState(false);
    const [showSubmitWarning, setShowSubmitWarning] = useState(false);

    useEffect(() => {
        const fetchReasonList = async () => {
            const data = { page: 0, recPerPage: 50, slugType: "cancellation-reason", parent: "" };
            const res = await postAPI('/get-data-list', data);
            if (res?.status === 1) {
                setReasonList(res?.payload?.["cancellation-reason"]);
            }
        };
        fetchReasonList();
    }, []);

    const handleViewCancelClose = () => {
        setCancelMessageTitle("Do you want to cancel?");
        setCancelMessageText("All unsaved changes will be lost");
        setCancelConfirmVisible(true);
    };

    const handleCancelReasonChange = (e) => {
        const value = e.target.value;
        setCancelMessageText('');
        setCancelMessageTitle('');
        setCancelConfirmVisible(false);
        setShowSubmitWarning(false);
        if (value === 'Others') {
            setInputType("text");
            setCancelReason('');
        } else {
            setInputType("select");
            setCancelReason(value);
        }
    };

    const handleRefundDestination = (destination) => {
        setRefundSource(destination);
        setCancelMessageText('');
        setCancelMessageTitle('');
        setCancelConfirmVisible(false);
        setShowSubmitWarning(false);
        setChannel('')
    };

    const handleChannelChange = (e) => {
        setChannel(e.target.value);
    };

    const handleTransactionIdChange = (e) => {
        setTransactionId(e.target.value);
    };

    const rejectCancel = () => {
        setCancelMessageTitle('');
        setCancelMessageText('');
        setCancelConfirmVisible(false);
    };

    const acceptCancel = () => {
        setCancelShow(false);
        props?.handleShowCancel(false);
    };

    const handleSubmitWarning = () => {
      const selectedChannel = refundDestinationList
            .filter(dest => dest.key === refundSource)[0]
            ?.channels?.find(cRow => cRow.key === channel);
        if (!cancelReason) {
            setCancelMessageTitle("Cancel reason is mandatory");
            setShowSubmitWarning(true);
            setCancelConfirmVisible(true);
            return;
        }else if (order.paymentMode !== 'cod' && !refundSource) {
            setCancelMessageTitle("Refund destination is mandatory");
            setShowSubmitWarning(true);
            setCancelConfirmVisible(true);
            return;
        }else if(refundSource === 'source' && !selectedChannel){
            setCancelMessageTitle("Channel is mandatory");
            setShowSubmitWarning(true);
            setCancelConfirmVisible(true);
            return;
        }else if ((refundSource === 'source' && selectedChannel?.type )=== 'manual' && (!transactionId || transactionId === '')) {
            setCancelMessageTitle("Transaction ID is mandatory");
            setShowSubmitWarning(true);
            setCancelConfirmVisible(true);
            return;
        }else{
          setCancelMessageTitle("Are you sure?");
          setCancelMessageText("You won't be able to update this later");
          setShowSubmitWarning(false);
          setCancelConfirmVisible(true);
        }
    };

    const rejectSubmit = () => {
        setCancelMessageTitle('');
        setCancelMessageText('');
        setShowSubmitWarning(false);
        setCancelConfirmVisible(false);
    };

    const acceptSubmit = () => {
        const data = {
            action: "cancel",
            orderId: order?.orderID,
            reason: cancelReason,
            refundDestination: { type: refundSource, channel, transactionID: transactionId },
        };
        props?.updateOrderStatusBtn("cancel", data);
        setCancelShow(false);
        props?.handleShowCancel(false);
    };

    return (
        <Modal show={cancelShow} onHide={handleViewCancelClose} backdrop="static" keyboard={false} size="md">
            <Modal.Header>
                <Modal.Title>Cancel Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mt-1">
                            Cancellation Reason*
                        </div>
                        <div className="col-md-12 CancleField">
                            {inputType === 'select' ? (
                                <select className="form-control" onChange={handleCancelReasonChange}>
                                    <option value="select">Select</option>
                                    {reasonList?.map((row) => (
                                        <option key={row.attb_key} value={row.attb_title}>{row.attb_title}</option>
                                    ))}
                                </select>
                            ) : (
                                <>
                                    <input type="text" className="form-control" autoFocus onChange={(e) => setCancelReason(e.target.value)} />
                                    <button className="cancelBtn btn btn-danger" onClick={() => {
                                        setCancelReason('');
                                        setCancelConfirmVisible(false);
                                        setShowSubmitWarning(false);
                                        setCancelMessageText('');
                                        setCancelMessageTitle('');
                                        setInputType('select');
                                    }}>X</button>
                                </>
                            )}
                        </div>
                    </div>
                    {(refundDestinationList.length > 0) && (
                        <div className="row">
                            <div className="col-md-12 mt-2 mb-1">
                                Refund Destination*
                            </div>
                            {refundDestinationList?.map((row) => (
                                <div key={row.key} className="col-md-6 CancleField">
                                    <label>
                                        <input
                                            type="radio"
                                            name="refundDestination"
                                            value={row.key}
                                            onChange={() => handleRefundDestination(row.key)}
                                        />
                                        {" " + row.label}
                                    </label>
                                </div>
                            ))}
                            {order?.paymentMode === 'cod' && refundSource === 'source' ? ("") : (
                              <>
                              {refundSource === "source" && (
                                <>
                                    <div className="col-md-12 mt-1">
                                        Channel*
                                    </div>
                                    <div className="col-md-12">
                                        <select className="form-control" onChange={handleChannelChange}>
                                            <option value="select">Select</option>
                                            {refundDestinationList
                                                .filter(dest => dest.key === 'source')[0]
                                                ?.channels?.map((cRow) => (
                                                    <option key={cRow.key} value={cRow.key}>{cRow.label}</option>
                                                ))}
                                        </select>
                                    </div>
                                </>
                            )}
                            {refundDestinationList
                                .filter(dest => dest.key === 'source')[0]
                                ?.channels?.find(cRow => cRow.key === channel)?.type === 'manual' && (
                                <>
                                    <div className="col-md-6 mt-1">
                                        Transaction Id*
                                    </div>
                                    <div className="col-md-12">
                                        <input type="text" className="form-control" autoFocus onChange={handleTransactionIdChange} />
                                    </div>
                                </>
                            )}
                              </>
                            )}

                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                {cancelConfirmVisible ? (
                    <>
                        <div>
                            <p><strong>{cancelMessageTitle}</strong> {cancelMessageText}</p>
                        </div>
                        {showSubmitWarning ? (
                            <Button className="btn btn-danger" onClick={rejectSubmit}>Ok</Button>
                        ) : (
                            <>
                                <Button className="btn btn-danger" onClick={rejectSubmit}>No</Button>
                                <Button className="btn blue btn-primary btn-md" onClick={acceptSubmit}>Yes</Button>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <Button className="btn btn-danger" onClick={acceptCancel}>Cancel</Button>
                        <Button className="btn blue btn-primary btn-md" onClick={handleSubmitWarning}>Submit</Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default CancelOrder;
