import React, { useState, useEffect, useCallback, useContext } from "react";
import {CButton} from "@coreui/react";
import { Form } from "semantic-ui-react";
import { postAPI } from "../../../apiServices";
import { toast } from "react-toastify";
const localPermits = JSON.parse(localStorage.getItem("module"));

const ChangePassword = ({ details = {}, entry={}, isLoading = false }) => {
  const [userId, setUserId] = useState(entry.userId || "");
  const [newPassword, setNewPassword] = useState();
  const [rePassword, setRePassword] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [inputFieldType, setInputFieldType] = useState("password");
  const [checkBoxText, setCheckBoxText] = useState("View Password");
  const [permissions, setPermissions] = useState(
    localPermits.find((o) => o.moduleId == 2)
  );

  useEffect(() => {
    if (entry.userId) {
      setUserId(entry.userId || "");
    }
    async function fetchData() {}
    fetchData();
  }, []);


  const handleType = (e) => {
    if(inputFieldType == 'text'){
        setInputFieldType('password');
        setCheckBoxText("Hide Password");
    }
    else{
        setInputFieldType('text');
        setCheckBoxText("View Password")
    }
  };
  const updatedPassword = async () => {
    setErrorMessage('');
      if(newPassword == null || newPassword == ''){
        toast.error("New password is required");
      }
      else if(rePassword == null || rePassword == ''){
        toast.error("Re-password is required");
      }
      else{
        if(newPassword == rePassword){
            const data = {
                userId: entry.userId,
                userType: "seller",
                updatedPassword: newPassword,
            };
            const { status, payload, message, error } = await postAPI("/update-user-details",data);
            if (status == 1) {
                return toast.success(message);
            }
            toast.error("Something went wrong");
        }
        else{
            setErrorMessage('Password not match');
        }
      }
  };

  return (
    <>
      <div className="d-flex">
        <div className="col-12">

          <div>
            <Form>
              <Form.Field>
                  <div className="row">
                    <div className="col-4">
                        <b>New Password</b>
                        <div>
                            <input
                            disabled={permissions?.isEdit === 0 ? true : false}
                            type={inputFieldType}
                            className="inputfile"
                            // id="newPassword"
                            placeholder="New Password"
                            name="newPassword"
                            value={newPassword}
                            onChange={(e)=>setNewPassword(e.target.value)}
                            />
                            <br />
                            <div style={{paddingTop:10}}>
                            <input type="checkbox" onChange={handleType} style={{marginTop:3}} /> {checkBoxText}
                            </div>
                        </div>
                    </div>

                  <div className="col-4">
                    <b>Re-enter Password</b>
                    <input
                    disabled={permissions?.isEdit === 0 ? true : false}
                    type={inputFieldType}
                    className="inputfile"
                    name="rePassword"
                    // id="rePassword"
                    placeholder="Re Enter"
                    style={{float:'left'}}
                    value={rePassword}
                    onChange={(e)=>setRePassword(e.target.value)}
                    />
                    <p style={{color:'red'}}>{errorMessage}</p>
                  </div>
                  <div className="col-2">
                  <br />
                  <CButton
                        disabled={permissions?.isEdit === 0 ? true : false}
                        color="primary"
                        className="ms-1 me-1"
                        onClick={updatedPassword}
                    >
                Update
            </CButton>
                  </div>
                  </div>
              </Form.Field>
            </Form>

          </div>
        </div>
      </div>
    </>
  );
};
export default ChangePassword;
