import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  CCardText,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import axios from "axios";
import NoDetails from "./NoDetails";
import Swal from "sweetalert2";

const CompanyInformation = ({ details = null, isLoading = false }) => {
  // const latitude = details?['']?
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gst, setGst] = useState([]);
  const updateStatus = async (gstNumber) => {
    setLoading(true);
    const gstApiKey = "09b9ff91aaa26d1fa548c29f7ff87485/";
    try {
      let url = "https://sheet.gstincheck.co.in/check/" + gstApiKey + gstNumber;
      let requestOptions = {
        method: "GET",
        url: url,
      };
      const ApiRes = await axios(url);
      //  postApiRes.data
      setModal(true);
      setGst(ApiRes.data);
      setLoading(false);
      Swal.close();
    } catch (err) {
      return { data: { error: "Server down" } };
    }
  };

  if (loading) {
    let timerInterval;
    Swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getContent();
          if (content) {
            const b = content.querySelector("b");
            if (b) {
              b.textContent = Swal.getTimerLeft();
            }
          }
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("I was closed by the timer");
      }
    });
  }
  return (
    <div className="d-flex justify-content-between">
      {!details ? (
        <NoDetails isLoading={isLoading} tabName="Company" />
      ) : (
        <>
          <div>
            {[
              { label: "Name", key: "companyName" },
              { label: "Address Line 1", key: "address1" },
              { label: "Address Line 2", key: "address2" },
              { label: "Pin Code", key: "pincode" },
              { label: "City", key: "city" },
              { label: "State", key: "state" },
              { label: "Business Type", key: "businessType" },
            ].map((item) => {
              return (
                <div className="d-flex">
                  <CCardText className="font-weight-bold">
                    {item.label}:
                  </CCardText>
                  <CCardText className="ms-1">
                    {details && details[item.key]}
                  </CCardText>
                </div>
              );
            })}
                <div className="d-flex">
                  <CCardText className="font-weight-bold">
                    Latitude:
                  </CCardText>
                  <CCardText className="ms-1">
                    {details && details['geoLocation']?.latitude ? details['geoLocation']?.latitude : 'NA'}
                  </CCardText>
                </div>
                <div className="d-flex">
                  <CCardText className="font-weight-bold">
                    Longitude:
                  </CCardText>
                  <CCardText className="ms-1">
                  {details && details['geoLocation']?.longitude ? details['geoLocation']?.longitude : 'NA'}
                  </CCardText>
                </div>
                {details && details['geoLocation']?.latitude && details['geoLocation']?.longitude ? (
                  <div className="d-flex">
                  <CCardText className="font-weight-bold">
                    {/* <a href={"https://www.google.com/maps/@"+details?['geoLocation']?.latitude+","+details?['geoLocation']?.longitude} target="_blank"><i className="fa fa-map-marker"></i> View on Google Map</a> */}
                    <a href={"https://maps.google.com/?q="+details['geoLocation']?.latitude+","+details['geoLocation']?.longitude} target="_blank"><i className="fa fa-map-marker"></i> View on Google Map</a>
                  </CCardText>
                </div>
                ) : ("")}

          </div>
          <div>
            {[
              { label: "GST Number", key: "gstNumber" },
              { label: "PAN Number", key: "panNumber" },
              { label: "Representative Name", key: "representativeName" },
              { label: "Representative Email", key: "representativeEmail" },
              { label: "Representative Phone", key: "representativePhone" },
              { label: "Free Shipping", key: "freeShipping" },
            ].map((item) => (
              <div className="d-flex">
                <CCardText className="font-weight-bold">
                  {item.label}:
                </CCardText>
                <CCardText className="ms-1">
                  {details && details[item.key]}
                </CCardText>
                &nbsp;
                {item.label === "GST Number" && details[item.key].length > 0 && (
                  <>
                    <a
                      href="javascript:void(0)"
                      onClick={() => {
                        updateStatus(details[item.key]);
                      }}
                    >
                      Verify
                    </a>
                    &nbsp;
                    <a
                      href={details.documentDetails[0].documentfile}
                      target="_blank"
                    >
                      | View
                    </a>
                  </>
                )}
              </div>
            ))}
          </div>
          <div></div>
        </>
      )}
      <CModal alignment="center" show={modal} size="lg" onClose={() => {}}>
        <CModalHeader>
          <CModalTitle>GST Verification</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className="d-flex justify-content-between mb-4 ms-3">
            <div className="w-33.3 col-4">
              {gst?.data ? (
                <>
                  <div className="row">
                    GST Number:&nbsp;<b>{gst?.data?.gstin}</b>
                  </div>
                  <div className="mt-2 row">
                    Trader Name: <b>{gst?.data?.tradeNam}</b>
                  </div>
                  <div className="mt-2 row">
                    Registration Date:&nbsp; <b> {gst?.data?.rgdt}</b>
                  </div>
                  <div className="mt-2 row">
                    Frequency Type:&nbsp; <b>{gst?.data?.frequencyType}</b>
                  </div>
                  <div className="mt-2 row">
                    CTB:&nbsp; <b> {gst?.data?.ctb}</b>
                  </div>
                  <div className="mt-2 row">
                    CTJ:&nbsp; <b>{gst?.data?.ctj}</b>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="col-4">
              {gst?.data ? (
                <>
                  <div className="row">
                    CXTD:&nbsp; <b>{gst.data.cxdt}</b>
                  </div>
                  <div className="mt-2 row">
                    DTY:&nbsp; <b>{gst.data.dty}</b>
                  </div>
                  <div className="mt-2 row">
                    Last Update:&nbsp; <b>{gst.data.lstupdt}</b>
                  </div>
                  <div className="mt-2 row">
                    Stj:&nbsp; <b>{gst.data.stj}</b>
                  </div>
                  <div className="mt-2 row">
                    Stj Cd:&nbsp; <b>{gst.data.stjCd}</b>
                  </div>
                  <div className="mt-2 row">
                    CtjCd:&nbsp; <b>{gst.data.ctjCd}</b>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="col-4">
              {gst?.data ? (
                <div>
                  <div
                    className="row"
                    style={{ display: "flex", width: "45%" }}
                  >
                    NTR:<b> {gst?.data?.pradr?.ntr} </b>
                  </div>
                  <div className="mt-2 row">
                    Location:&nbsp; <b> {gst?.data?.pradr?.addr?.loc} </b>
                  </div>
                  <div className="mt-2 row">
                    DST:&nbsp; <b> {gst?.data?.pradr?.addr?.dst} </b>
                  </div>
                  <div className="mt-2 row">
                    PinCode:&nbsp; <b> {gst?.data?.pradr?.addr?.pncd} </b>
                  </div>
                  <div className="mt-2 row">
                    ST:&nbsp; <b> {gst?.data?.pradr?.addr?.st} </b>
                  </div>
                  <div className="mt-2 row">
                    STCD:&nbsp; <b> {gst?.data?.pradr?.addr?.stcd} </b>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </CModalBody>
        <CModalFooter>
          {gst?.data ? (
            <>
              <div style={{ color: "red", marginRight: "563px" }}>
                Note: {gst.message}
              </div>
            </>
          ) : (
            ""
          )}
          <CButton
            color="secondary"
            onClick={() => {
              setModal(false);
            }}
          >
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default CompanyInformation;
