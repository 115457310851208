import React, { useState, useEffect, useMemo } from "react";
import { Form, Menu, Dropdown, Table } from "semantic-ui-react";
import { postAPI } from "../../apiServices";
// import { useHistory, useLocation } from "react-router-dom";
import Pagination from "../../common-components/Pagination";
import {
  CPagination,
} from "@coreui/react";
const localPermits = JSON.parse(localStorage.getItem("module"));

const defaultState = {
  action: "",
  entries: [],
  totalEntries: 0,
  page: 0,
  recPerPage: 10,
};
const BuyerHistory = ({ details = {}, type }) => {
  // const history = useHistory();
  const [buyerHistory, setbuyerHistory] = useState([]);
  const [state, setState] = useState(defaultState);
  const [totalRecords, settotalRecords] = useState(0);
  const [orderPermission, setOrderPermission] = useState(localPermits.find((o) => o.moduleName == "Orders"))
  const [page, setPage] = useState(0);

  const fetchBuyerHistory = async (pagenumber) => {
    const data = {
      page: state.page,
      recPerPage: state.recPerPage,
      action: type,
      userID: details.userId,
      searchGlobally: 2,
    };

    const buyerhistory = await postAPI("/buyer/orders/list", data);
    if (buyerhistory.status === 1) {
      setbuyerHistory(buyerhistory.payload.data);
      settotalRecords(buyerhistory.payload.totalRecords);
      setState((prevState) => ({
        ...prevState,
        entries: buyerhistory.payload.data,
        recPerPage: state.recPerPage,
        page: state.page,
        totalEntries: buyerhistory.payload.totalRecords
      }));
    } else {
      setbuyerHistory("");
    }
  };

  useEffect(() => {
    fetchBuyerHistory(0);
  }, [state.page, state.recPerPage]);

  const viewOrderDetail = (orderId, currentStatus) => {
    window.open("/#/order/"+currentStatus+"?q="+orderId, "_blank");
  }

  const handlePagination = (pgInfo) => {
    setState((prevState) => ({
      ...prevState,
      recPerPage: parseInt(pgInfo?.recPerPage),
      page: pgInfo?.page
    }));
  }

  return (
    <div>
      {buyerHistory?.length > 0 ? (
        <React.Fragment>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Order Number</Table.HeaderCell>
                <Table.HeaderCell>Item Count</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
                <Table.HeaderCell>Order Value</Table.HeaderCell>
                <Table.HeaderCell>Order Date</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Seller Name</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {buyerHistory?.map((buyer, index) => {
                return (
                  <>
                    <Table.Row key={index}>
                      <Table.Cell className="viewLink" onClick={() => viewOrderDetail(buyer.orderNumber, buyer.currentStatus)}>{buyer.orderNumber}</Table.Cell>
                      <Table.Cell>2</Table.Cell>
                      <Table.Cell>{buyer.quantities}</Table.Cell>
                      <Table.Cell>{buyer.netAmount}</Table.Cell>
                      <Table.Cell>{buyer.placedOn}</Table.Cell>
                      <Table.Cell style={{textTransform:"capitalize"}}>{buyer.currentStatus}</Table.Cell>
                      <Table.Cell>{buyer.sellerName}</Table.Cell>
                    </Table.Row>
                  </>
                );
              })}
            </Table.Body>
          </Table>
          {/* <CPagination
                activePage={page+1}
                pages={Math.ceil(totalRecords / 10)}
                onActivePageChange={(page) =>
                  setPage(page ? page -1 : page)
                }
              /> */}

          <Pagination handlePagination={handlePagination} state={state} />
          {/* <Pagination
            defaultActivePage={1}
            totalPages={Math.floor(totalRecords / 10)}
            onPageChange={(event, data) => fetchBuyerHistory(data.activePage)}
          /> */}
        </React.Fragment>
      ) : (
        "No Data Found"
      )}
    </div>
  );
};

export default React.memo(BuyerHistory);
