import React from 'react';
import { CSpinner } from '@coreui/react';

const NoDetails = ({
  tabName = '',
  isLoading = false,
}) => {
  return (
    <div className='d-flex align-items-center justify-content-center p-2 w-100'>
      {isLoading ? (
        <CSpinner color="primary" />
      ) : (
        <h6>{tabName} details not found</h6>
      )}
    </div>
  );
};

export default NoDetails;