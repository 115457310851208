import React, { useState, useEffect, useCallback, useContext } from "react";
import { AppContext } from "../../contexts/app";
import CategoryGrids from "./CategoryGrids";
import { toast } from "react-toastify";
import { postAPI, uploadImageAPI, uploadImageAPIb2b } from "../../apiServices";
import MESSAGES from "../../message.json";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import { CCard, CCardBody } from "@coreui/react";
import { Menu, Table, Icon, Form, Button } from "semantic-ui-react";
import Swal from "sweetalert2";
let timeout = null;
const defaultState = {
  entries: [],
  totalEntries: 0,
  page: 0,
  recPerPage: 50,
  searchKeyword: "",
  loading: true,
  slugType: "category-level-1",
};

const CategoryEdit = () => {
  const { setSecondaryHeader } = useContext(AppContext);
  const [state, setState] = useState(defaultState);
  const [imagefilename, setimagefilename] = useState();
  const [priority, setPriority] = useState(1);
  const [termtype, setTermType] = useState("category");
  const [termname, setTermName] = useState();
  const [Category, setCategory] = useState([]);
  const [parent, setparent] = useState();
  const [parentid, setparentid] = useState();
  const [description, setDescription] = useState();
  const [message, setmessage] = useState();
  const [leadImg, setleadImg] = useState();
  const [uploadImage, setuploadImage] = useState();

  const [isparent, setisparent] = useState(true);
  const location = useLocation();
  let queries = queryString.parse(location.search);
  const fetchProducts = useCallback(async (searchKeyword = "") => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    const page = 0;
    const recPerPage = 50;
    const id = queries.id;
    const slugType = queries.slugType;
    const type = queries.type;
    const parent = "";

    const { status, payload, message } = await postAPI("/get-categories-list", {
      id,
      page,
      recPerPage,
      slugType,
      parent,
    });
    const getallcategories = await postAPI("/get-all-categories", {}).then(
      (res) => {
        setCategory(res.payload.categories);
      }
    );

    setState((prevState) => ({
      ...prevState,
      loading: false,
    }));
    Swal.close();

    if (!status) {
      if (message !== "No product found")
        toast.error(message || MESSAGES?.sellers?.defaultApiError);
      setState((prevState) => ({
        ...prevState,
        entries: defaultState.entries,
        totalEntries: defaultState.totalEntries,
      }));
    } else {
      payload.data.map((data) => {
        setTermName(data.name);
        setparent(data.parentName);
        setleadImg(data.leadImg);
        setparentid(data.parentId);
        setPriority(data.priority);
      });
    }
  }, []);

  useEffect(() => {
    fetchProducts(
      state.page,
      state.recPerPage,
      state.searchKeyword,
      state.slugType
    );
  }, [fetchProducts, state.page, state.recPerPage, state.slugType]);
  if (state.loading) {
    let timerInterval;
    Swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          // const content = Swal.getContent();
          // if (content) {
          //   const b = content.querySelector("b");
          //   if (b) {
          //     b.textContent = Swal.getTimerLeft();
          //   }
          // }
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
      }
    });
  }
  const fileChangedHandler = (event) => {
    // const file = event.target.value
    setuploadImage(event);
  };

  const editCategory = async () => {
    var payloadimg = "";
    var imagePath = "";
    if (uploadImage != undefined) {
      payloadimg = await uploadImageAPIb2b("/uploads", uploadImage);
      setleadImg(payloadimg.url[0]);
      imagePath = payloadimg.url[0];
    } else {
      imagePath = leadImg;
    }
    const po = {
      id: queries.id,
      type: termtype,
      parent: parentid,
      termName: termname,
      leadImg: imagePath,
      priority,
      status: 1,
    };

    const { status, payload, message } = await postAPI(
      "/save-category-data",
      po,
      {}
    );
    if (status == 1) {
      toast.success(message);
      // setmessage(message)
    } else {
      toast.error(message);
    }
  };

  let categoryarryay = [];
  /*  categoryarryay.push(<option  value={parentid} >{parent}</option>) */
  let optionItems = Category.map((child) => {
    if (child.id == parentid) {
      categoryarryay.push(
        <option selected key={child.id} value={child.id}>
          {child.name}
        </option>
      );
    } else {
      categoryarryay.push(
        <option key={child.id} value={child.id}>
          {child.name}
        </option>
      );
    }
    child.child.map((child1) => {
      if (child1.id == parentid) {
        categoryarryay.push(
          <option selected key={child1.id} value={child1.id}>
            --{child1.name}
          </option>
        );
      } else {
        categoryarryay.push(
          <option key={child1.id} value={child1.id}>
            --{child1.name}
          </option>
        );
      }
      child1.child.map((child2) => {
        if (child2.id == parentid) {
          categoryarryay.push(
            <option selected key={child2.id} value={child2.id}>
              -----{child2.name}
            </option>
          );
        } else {
          categoryarryay.push(
            <option key={child2.id} value={child2.id}>
              -----{child2.name}
            </option>
          );
        }
        /* child2.child.map((child3) => {
             categoryarryay.push(<option key={child3.id} value={child3.id}>--------{child3.name}</option>)

           }); */
      });
    });
  });

  return (
    <CCard>
      <CCardBody>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h3>{message}</h3>
        </div>
        <Form>
          <Form.Field>
            <span>
              <h5 style={{ marginBottom: "10px" }}>Image</h5>
            </span>
            <input
              type="file"
              onChange={(e) => fileChangedHandler(e.target.files)}
              className="inputfile"
              id="embedpollfileinput"
            />
            <div style={{ marginTop: 10 }}>
              {" "}
              <img src={leadImg} width="100" height="100" />
            </div>
          </Form.Field>
          <div className='d-flex'>
          <Form.Field className='w-50 me-1'>
            <span>
              <h5 style={{ marginBottom: "10px" }}>Term Name</h5>
            </span>
            <input
              type="text"
              placeholder="Term name"
              value={termname}
              onChange={(e) => setTermName(e.target.value)}
            />
          </Form.Field>
          <Form.Field className='w-50 ms-1'>
            <span>
              <h5 style={{ marginBottom: "10px" }}>Term Type</h5>
            </span>
            <select onChange={(e) => setTermType(e.target.value)}>
              <option value="category">Category </option>
            </select>
          </Form.Field>
          </div>
          {queries.type != 1 ? (
            <Form.Field>
              <span>
                <h5 style={{ marginBottom: "10px" }}>Parent</h5>
              </span>
              <select onChange={(e) => setparentid(e.target.value)}>
                {categoryarryay}
              </select>
            </Form.Field>
          ) : null}
          <Form.Field>
            <span>
              <h5 style={{ marginBottom: "10px" }}>Priority</h5>
            </span>
            <input
              type="number"
              placeholder="Priority"
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
            />
          </Form.Field>
          <Form.Field>

          <span><h5 style={{ marginBottom: '10px' }}>Description</h5></span>
          <textarea onChange={(e) => setDescription(e.target.value)} defaultValue={description}></textarea>
          </Form.Field>
          <div style={{ float: "right" }}>
            <Button primary onClick={editCategory}>
              Submit
            </Button>
          </div>
        </Form>
      </CCardBody>
    </CCard>
  );
};

export default CategoryEdit;
