import React, { useEffect, useState } from "react";
import { CCol, CRow, CCard, CCardBody} from "@coreui/react";

  import Swal from 'sweetalert2'
  import { Button, Table, Input, Form } from "semantic-ui-react";
  import { postAPI, getApiCall } from "../../../apiServices"

const GlobalPlatformMargin = () => {
    const defaultData = {
        platformMargin: 17,
        applyOn: 1
    }
    const [margin, setMargin] = useState(defaultData);
    useEffect(()=>{

    },[margin])
    const handleData = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        switch (name) {
            case "margin":
                // setMargin(...margin, {platformMargin:value})
                if(value < 0){
                    value = 0;
                }

                setMargin(margin => ({
                    ...margin,
                    ...{platformMargin:value}
                  }));
                
                break;
        
            default:
                break;
        }
    }
    return (
        <React.Fragment>
      <CRow>
        <CCol lg={12}>
            <CCard>
                <CCardBody>
                    <div>
                        <Form>
                            <lable>Platform Margin</lable>
                            <div className="mt-2">
                                <Input type="number" min={0} name="margin" defaultValue={margin.platformMargin} onChange={(e) => handleData(e)} /> %
                            </div>
                            <div className="mt-3">
                               <label><input type="radio" name="applyOn" value={1} checked={margin.applyOn === 1 ? 'checked': ''}  /> Apply on all sellers &amp; Products</label>
                            </div>
                            <div className="mt-2">
                                <label><input type="radio" name="applyOn" value={0} checked={margin.applyOn === 0 ? 'checked': ''} /> Apply on all sellers &amp; Products where margin is {margin.platformMargin} %</label>
                            </div>
                            <div className="mt-2">
                                <Button className="primary" disabled={margin.platformMargin === "17" ? true : false}>Update</Button>
                            </div>
                        </Form>
                    </div>
                </CCardBody>
            </CCard>
        </CCol>
      </CRow>
      

    
    </React.Fragment>
    )
}
export default GlobalPlatformMargin;