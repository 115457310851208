import React, { useState, useEffect, useMemo } from "react";
import { Table } from "semantic-ui-react";
import { postAPI } from "../../../apiServices";
import Pagination from "../../../common-components/Pagination";

const defaultState = {
  action: "",
  entries: [],
  totalEntries: 0,
  page: 0,
  recPerPage: 10,
};

const BuyerHistory = ({ details = {}, match,}) => {
  const [buyerHistory, setbuyerHistory] = useState([]);
  const [state, setState] = useState(defaultState);
  const [totalRecords, settotalRecords] = useState(0);
  const [actiontype, setactiontype] = useState(defaultState);
  try {
    var urlPar = match;
    if (urlPar === "placed") {
      urlPar = "place";
    } else if (urlPar === "confirmed") {
      urlPar = "confirm";
    } else if (urlPar === "packaged") {
      urlPar = "ready";
    } else if (urlPar === "dispatched") {
      urlPar = "dispatch";
    } else if (urlPar === "delivered") {
      urlPar = "deliver";
    } else if (urlPar === "cancelled") {
      urlPar = "cancel";
    } else if (urlPar === "rto") {
      urlPar = "rto";
    }
    defaultState.action = urlPar;
  } catch (er) {
  }


  const fetchBuyerHistory = async (pagenumber) => {
    const data = {
      page: state.page,
      recPerPage: state.recPerPage,
      action: actiontype.action,
      userID: details.userID,
      searchGlobally: 2,
    };
    setactiontype((prevState) => ({ ...prevState }));
    const buyerhistory = await postAPI("/buyer/orders/list", data);
    if (buyerhistory.status === 1) {
      setbuyerHistory(buyerhistory.payload.data);
      settotalRecords(buyerhistory.payload.totalRecords);
      setState((prevState) => ({
        ...prevState,
        entries: buyerhistory.payload.data,
        recPerPage: state.recPerPage,
        page: state.page,
        totalEntries: buyerhistory.payload.totalRecords
      }));
    } else {
      setbuyerHistory("");
    }
  };

  useEffect(() => {
    fetchBuyerHistory(0);
  }, [state.recPerPage, state.page]);

  const viewOrderDetail = (orderId, currentStatus) => {
    if(currentStatus === 'packed'){
      currentStatus = 'ready';
    }
    window.open("/#/order/"+currentStatus+"?q="+orderId+"&global=1", "_blank");
  }

  const handlePagination = (pgInfo) => {
    // fetchBuyerHistory(pgInfo?.page)
    setState((prevState) => ({
      ...prevState,
      recPerPage: parseInt(pgInfo?.recPerPage),
      page: pgInfo?.page
    }));
  }

  return (
    <div>
      {buyerHistory?.length > 0 ? (
        <React.Fragment>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Order Number</Table.HeaderCell>
                <Table.HeaderCell>Item Count</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
                <Table.HeaderCell>Order Value</Table.HeaderCell>
                <Table.HeaderCell>Order Date</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Seller Name</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {buyerHistory?.map((buyer, index) => {
                return (
                  <>
                    <Table.Row key={index}>
                      <Table.Cell className="viewLink" onClick={() => viewOrderDetail(buyer.orderNumber, buyer.currentStatus)}>{buyer.orderNumber}</Table.Cell>
                      <Table.Cell>2</Table.Cell>
                      <Table.Cell>{buyer.quantities}</Table.Cell>
                      <Table.Cell>{buyer.netAmount }</Table.Cell>
                      <Table.Cell>{buyer.placedOn}</Table.Cell>
                      <Table.Cell style={{textTransform:"capitalize"}}>{buyer.currentStatus}</Table.Cell>
                      <Table.Cell>{buyer.sellerName}</Table.Cell>
                    </Table.Row>
                  </>
                );
              })}
            </Table.Body>
          </Table>
          {totalRecords > 10 && (
            <Pagination handlePagination={handlePagination} state={state} />
            // <Pagination
            //   defaultActivePage={1}
            //   totalPages={Math.floor(totalRecords / 10)+1}
            //   onPageChange={(event, data) => fetchBuyerHistory(data.activePage-1)}
            // />
          )}
        </React.Fragment>
      ) : (
        "No Data Found"
      )}
    </div>
  );
};

export default React.memo(BuyerHistory);
