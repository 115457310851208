import React, { useState, useEffect, useMemo } from "react";
import { Form, Menu, Dropdown, Table, Pagination } from "semantic-ui-react";
import { getApiCall, postAPI } from "../../../apiServices";
import { useHistory, useLocation } from "react-router-dom";
import { CPagination } from "@coreui/react";
import { numberWithComma } from "../../../constants/comonFunction";
const localPermits = JSON.parse(localStorage.getItem("module"));

const SCash = (props) => {
  const [sCashDetail, setSCashDetail] = useState();
  const [page, setPage] = useState(1);
  const [recPerPage, setRecPerPage] = useState(5);

  useEffect(() => {
    const getScash = async () => {
      let res = await getApiCall('/buyer/' + props?.details?.userId + '/scash?page=' + (page) + '&recPerPage=' + recPerPage);
      if (res.status === 1) {
        setSCashDetail(res.payload);
      }
    };
    getScash();
  }, [page, recPerPage]);

  const handleRecPerPage = (e) => {
    setRecPerPage(e.target.value);
  };

  const handlePage = (pg) => {
    setPage(pg);
  };

  return (
    <>
      <Table>
        <Table.Row>
          <Table.Cell><strong>TOTAL: </strong> ₹ {sCashDetail ? numberWithComma(sCashDetail?.totalWalletBalance?.toString()) : '0'}</Table.Cell>
          <Table.Cell><strong>USED: </strong> ₹ {sCashDetail ? numberWithComma(sCashDetail?.usedWalletBalance?.toString()) : '0'}</Table.Cell>
          <Table.Cell><strong>BALANCE: </strong> ₹ {sCashDetail ? numberWithComma((sCashDetail?.totalWalletBalance - sCashDetail?.usedWalletBalance).toString()) : '0'}</Table.Cell>
        </Table.Row>
      </Table>
      {sCashDetail?.statements?.length > 0 && (
        <>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Transaction Type</Table.HeaderCell>
                <Table.HeaderCell>Source</Table.HeaderCell>
                <Table.HeaderCell style={{ minWidth: "120px" }}>Amount</Table.HeaderCell>
                <Table.HeaderCell>For</Table.HeaderCell>
                <Table.HeaderCell>Order Id</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Remarks</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {sCashDetail?.statements?.map((row) => {
                return (
                  <Table.Row>
                    <Table.Cell>{row?.type}</Table.Cell>
                    <Table.Cell style={{ textTransform: "capitalize" }}>{row?.source}</Table.Cell>
                    <Table.Cell>{row?.type === 'Debit' ? '(-)' : ''}₹ {row?.amount ? numberWithComma(row?.amount?.toString()) : '0'}</Table.Cell>
                    <Table.Cell>{row?.redeemedFor}</Table.Cell>
                    <Table.Cell>{row?.orderNumber}</Table.Cell>
                    <Table.Cell>{row?.date}</Table.Cell>
                    <Table.Cell>{row?.remarks}</Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </>
      )}

      {sCashDetail && sCashDetail.totalRecords > 0 && (
        <div className="d-flex justify-content-between">
          <span className="d-flex">
            <select value={recPerPage} className="form-control w-10" style={{ width: "80px" }} onChange={(e) => handleRecPerPage(e)}>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </select>
            <p className="w-125" style={{ paddingTop: "7px" }}> &nbsp; Records per page</p>
          </span>
          <span className="d-flex">
            <p style={{ paddingTop: "7px" }}>{`Showing ${recPerPage * (page - 1) + 1} to ${
              recPerPage * (page - 1) + sCashDetail?.statements.length
            } of ${sCashDetail.totalRecords} record(s)`} &nbsp; </p>
            <CPagination
              activePage={page}
              pages={Math.ceil(sCashDetail.totalRecords / recPerPage)}
              onActivePageChange={(pg) => handlePage(pg)}
            />
          </span>
        </div>
      )}
    </>
  );
};

export default SCash;
