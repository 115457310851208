import React from 'react'

const Dashboard = () => {
  return (
    <>
      <h3 style={{textAlign:"center"}}>Welcome to Showroom B2B Dashboard</h3>
    </>
  )
}

export default Dashboard
