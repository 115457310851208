import React from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';

const noop = () => { };

const ImageViewer = ({
  open = false,
  images = [],
  onClose = noop,
}) => {
  return (
    <ModalGateway>
      {open ? (
        <Modal onClose={onClose}>
          {!!images.length ? (
            <Carousel
              currentIndex={0}
              views={images.map(image => ({
                ...image,
                srcset: image.srcSet,
              }))}
            />
          ) : (
            <p>Image(s) Not Found</p>
          )}
        </Modal>
      ) : null}
    </ModalGateway>
  );
};

export default ImageViewer;

