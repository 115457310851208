import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button } from "semantic-ui-react";
import { postAPI, uploadImageAPIb2b } from "../../apiServices";
import { format } from 'date-fns';

import DatePicker from "react-datepicker";

const defaultPaymentMethod = [
    {id:1, name:"Cash", value:"cash"},
    {id:2, name:"Cheque", value:"cheque"},
    {id:3, name:"Online", value:"online"},
]
const pocRestricted = ["bnpl", "online", "srb2b-credit"]
function AddPoc(props){
    const [viewPoc, setViewPoc] = useState(props?.poc)
    const [paymentMethod, setpaymentMethod] = useState(defaultPaymentMethod);
    const [rowList, setRowList] = useState([{method:"cash", amount:"", dateOfPayment:"", transactionID:"", files:[]}])
    const [rowListError, setRowListError] = useState([{method:"", amount:"", dateOfPayment:"", transactionID:"", files:""}])
    const [cancelMessageTitle, setCancelMessageTitle] = useState("")
    const [cancelMessageText, setCancelMessageText] = useState("")
    const [cancelConfirmVisible, setCancelConfirmVisible] = useState(false);
    const [pocPayload, setPocPayload] = useState();
    const [amountMessage, setAmountMessage] = useState("");
    const [amountReceive, setAmountReceive] = useState(0);
    const [cancelConfirmAmountVisible, setCancelConfirmAmountVisible] = useState(false);


    const handleViewPocClose = () => {
        setCancelMessageTitle("Do you want to cancel?")
        setCancelMessageText("All unsaved changes will be lost")
        setCancelConfirmVisible(true)
    }

    const rejectCancel = () => {
        setCancelMessageTitle("")
        setCancelMessageText("")
        setCancelConfirmVisible(false)
    }

    const acceptCancel = () => {
        setViewPoc(!viewPoc)
        props?.handleShowAddPoc(false)
    }

    const handlePaymentReceive = () => {
        let amountReceived = props?.order?.advance;
        rowList.forEach(ele => {
            if(ele.method === 'cash'){
                amountReceived+=parseInt(ele.amount)
            }else if(ele.method === 'cheque'){
                amountReceived+=parseInt(ele.amount)
            }else if(ele.method === 'online'){{
                amountReceived+=parseInt(ele.amount)
            }}
        });
        if(amountReceived < props?.order?.totalDue){
            setAmountMessage("Amount received is less than due amount by ₹"+ (props?.order?.totalDue - amountReceived) +". Do you want to save the changes?")
        }else if(amountReceived > props?.order?.totalDue){
            setAmountMessage("Amount received is greater than due amount by ₹"+ (amountReceived - props?.order?.totalDue) +".");
        }else{
            setAmountMessage("")
        }
        setAmountReceive(amountReceived)
    }


    const addRowListFormFields = () => {
        setRowList([...rowList, { method:"cash", amount: "", dateOfPayment: "", transactionID:"", files:[] }])
        setRowListError([...rowListError, { method:"", amount: "", dateOfPayment: "", transactionID:"", files:"" }])
      }

    const removeRowListFields = (i) => {
    let newRowFormValues = [...rowList];
    newRowFormValues.splice(i, 1);
    setRowList(newRowFormValues)

    let newRowError = [...rowListError];
    newRowError.splice(i, 1);
    setRowListError(newRowError)
    handlePaymentReceive()
}

    const handleRowListChange = (e, i) => {
        let newFormValues = [...rowList];
        let value = e.target.value;
        if(e.target.name === 'dateOfPayment' && value !=""){
            value = format(new Date(value), 'dd-MM-yyyy')
            e.target.blur();
        }
        if(e.target.name === 'method'){
            if(value === 'cash'){
                document.getElementById("transactionID"+i).disabled = true;
                document.getElementById("images"+i).disabled = true;
            }else{
                document.getElementById("transactionID"+i).disabled = false;
                document.getElementById("images"+i).disabled = false;
            }
        }

        newFormValues[i][e.target.name] = value;
        setRowList(newFormValues);
        handlePaymentReceive()
    }

    const handleRowListDateChange = (date, i) => {
        let updatedData = [...rowList]; // Create a copy of the array
        updatedData[i] = { ...updatedData[i], dateOfPayment:  date }; // Update the specific object's property
        setRowList(updatedData); // Update the state
    }

    const handlePocFile = async (e, i) => {
        let newRowListValues = [...rowList];
        let files = e.target.files
        let images = [];
        let payloadimg = await uploadImageAPIb2b("/uploads", files);
        if(payloadimg?.status === 1){
            payloadimg?.url?.forEach(ele => {
                images.push(ele)
            })
        }
        newRowListValues[i]['files'] = images;
        setRowList(newRowListValues);
    }

    const handleSubmit = async() => {
        let isError = false;
        let rowListErr = [...rowListError]
        if(pocRestricted.indexOf(props?.order?.paymentMode) < 0){
            if(rowList?.length > 0){
                let i = 0;
                rowList?.forEach(row => {
                    if(!row?.method || !row?.amount || !row?.dateOfPayment){
                        if(!row?.method){
                            rowListErr[i]["method"] = "Required";
                        }else{
                            if(row?.method !== 'cash'){
                                if(!row?.transactionID){
                                    rowListErr[i]['transactionID'] = "Required";
                                }
                                if(row.files?.length === 0){
                                    rowListErr[i]['files'] = "Required";
                                }
                                isError = true
                                setRowListError(rowListErr)
                            }else{
                                rowListErr[i]['transactionID'] = "";
                                rowListErr[i]['files'] = "";
                                setRowListError(rowListErr)
                            }
                        }
                        if(!row?.amount){
                            rowListErr[i]["amount"] = "Required";
                        }
                        if(!row?.dateOfPayment){
                            rowListErr[i]["dateOfPayment"] = "Required";
                        }
                        isError = true;
                        setRowListError(rowListErr)
                    }else{
                        rowListErr[i]['method'] = "";
                        rowListErr[i]['amount'] = "";
                        rowListErr[i]['dateOfPayment'] = "";
                        rowListErr[i]['transactionID'] = "";
                        rowListErr[i]['files'] = "";
                        setRowListError(rowListErr)
                    }
                    i=i+1;
                })
            }
        }
        if(isError){
            return false;
        }else{
            let cash = [];
            let cheque = [];
            let online = [];
            let amountReceived = props?.order?.advance;;
            rowList.forEach(ele => {
                let newDate = format(new Date(ele?.dateOfPayment), 'dd-MM-yyyy')
                ele = { ...ele, dateOfPayment:  newDate }
                if(ele.method === 'cash'){
                    amountReceived+=parseInt(ele.amount)
                    cash.push(ele)
                }else if(ele.method === 'cheque'){
                    amountReceived+=parseInt(ele.amount)
                    cheque.push(ele)
                }else if(ele.method === 'online'){{
                    amountReceived+=parseInt(ele.amount)
                    online.push(ele)
                }}
            });
            let payloadPOC = {
                orderID: props?.order?.orderID,
                paymentDetails:{
                    cash: cash,
                    cheque: cheque,
                    online: online,
                }
            }
            setPocPayload(payloadPOC)
            setAmountReceive(amountReceived)
          if(amountReceived < props?.order?.totalDue){
            setAmountMessage("Amount received is less than due amount by ₹"+ (props?.order?.totalDue - amountReceived) +". Do you want to save the changes?")
            setCancelConfirmAmountVisible(true)

          }else if(amountReceived > props?.order?.totalDue){
            setAmountMessage("Amount received is greater than due amount by ₹"+ (amountReceived - props?.order?.totalDue) +".")
            setCancelConfirmAmountVisible(true)
          }else{
            await postAPI('/orders/poc/add-details/', payloadPOC);
            setViewPoc(!viewPoc)
            props.updatePaymentList(); // Update the payment list after adding POC
          }
        }
    }

    const rejectAmount = () => {
        setAmountMessage("")
        setCancelMessageTitle("")
        setCancelMessageText("")
        setCancelConfirmAmountVisible(false)
    }

    const submitPoc = async () => {
        let pocRes = await postAPI('/orders/poc/add-details/', pocPayload);
        setCancelConfirmVisible(false)
        setCancelConfirmAmountVisible(false)
        setViewPoc(false)
        // props.updateNetDue(amountReceive)
        props.updatePaymentList(); // Update the payment list after adding POC
    }
    return (<>
    <Modal show={viewPoc} onHide={handleViewPocClose} backdrop="static" keyboard={false} size="lg" centered={true}>
        <Modal.Header>
            <Modal.Title>Proof of Collection </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="container">
                <div className="row">
                    {pocRestricted?.indexOf(props?.order?.paymentMode) < 0 ? (
                        <>

                    <div className="col-md-12" style={{marginTop:"20px"}}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Method</th>
                                    <th>Amount</th>
                                    <th>Payment Date</th>
                                    <th>Trxn ID/Cheque No.</th>
                                    <th>Files</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {rowList?.map((row, i) => {
                                    return (<>
                                    <tr>
                                        <td style={{width:"12%"}}>
                                            <select name="method" className="form-control" onChange={(e) => handleRowListChange(e,i)}>
                                                {paymentMethod?.map((pr) => {
                                                    return (<>
                                                        <option value={pr?.value}>{pr.name}</option>
                                                    </>)
                                                })}
                                            </select>
                                        </td>
                                        <td style={{width:"12%"}}>
                                            <input type="number" className={rowListError?.[i]?.amount ? "form-control requiredFiledBorder" : "form-control "} name="amount" onChange={(e) => handleRowListChange(e, i)} />
                                        </td>
                                        <td style={{width:"14%"}}>
                                        <DatePicker
                                            selected={row?.dateOfPayment}
                                            className={rowListError?.[i]?.dateOfPayment ? "form-control date requiredFiledBorder" : "form-control date"}
                                            onChange={(date) => handleRowListDateChange(date, i)}
                                            placeholderText="DD-MM-YYYY"
                                            dateFormat="dd-MM-yyyy"
                                            disabled={pocRestricted?.indexOf(props?.order?.paymentMode) < 0 ? false : true}
                                            minDate={new Date(props?.order?.placedOn)}
                                            maxDate={row?.method === 'cheque' ? "" : new Date()}
                                        />
                                        </td>
                                        <td style={{width:"22%"}}>
                                            <input type="text" className={rowListError?.[i]?.transactionID ? "form-control requiredFiledBorder" : "form-control "} id={"transactionID"+i} placeholder={row?.method === "online" ? "Transaction ID" : row?.method === "cheque" ? "Cheque No" : ""} name="transactionID" onChange={(e) => handleRowListChange(e, i)} disabled={true} />
                                        </td>
                                        <td style={{width:"22%"}}>
                                            <input type="file" className={"fileBrowse "+ rowListError?.[i]?.images ? "fileBrowse requiredFiledBorder" : "fileBrowse "} id={"images"+i} multiple onChange={(e) => handlePocFile(e, i)} disabled={true} />
                                        </td>
                                        <td style={{width:"5%"}}>
                                            <span>
                                                {i > 0 ? (<>
                                                    <FontAwesomeIcon
                                                        icon={faTrashAlt}
                                                        style={{ cursor: "pointer" }}
                                                        className="me-2 text-danger"
                                                        onClick={() => removeRowListFields(i)}
                                                    />
                                                </>) : (<></>)}

                                            </span>
                                            {i === rowList?.length -1 && (
                                                <FontAwesomeIcon
                                                icon={faPlus}
                                                style={{ cursor: "pointer" }}
                                                className="me-2 text-primary"
                                                onClick={() => addRowListFormFields()}
                                            />
                                            )}
                                        </td>
                                    </tr>
                                    </>)
                                })}

                            </tbody>
                        </table>
                    </div>
                        </>
                    ) : (<></>)}

                    {/*  */}
                </div>

            </div>
        </Modal.Body>
        <Modal.Footer>
            {!cancelConfirmVisible ? (<>

                {!cancelConfirmAmountVisible ? (<>
                    <Button className="btn btn-danger" onClick={handleViewPocClose}>  Cancel </Button>
                    <Button className="btn blue btn-primary btn-md"  onClick={handleSubmit}> Submit </Button>
                </>) : (<>
                    <p>{amountMessage}</p>
                    {amountReceive > props?.order?.totalDue ? (<>
                        <Button className="btn blue btn-primary" onClick={rejectAmount}>  No </Button>
                        <Button className="btn blue btn-primary btn-md"  onClick={submitPoc}> Yes </Button>
                    </>) : (<>
                        <Button className="btn btn-danger" onClick={rejectAmount}>  No </Button>
                        <Button className="btn blue btn-primary btn-md"  onClick={amountReceive > props?.order?.totalDue ? rejectAmount : submitPoc}> Yes </Button>
                    </>)}
                </>)}

            </>) : (<>
                <div>
                    <p><strong>{cancelMessageTitle}</strong> {" "}{cancelMessageText}</p>
                </div>
                <Button className="btn btn-danger" onClick={rejectCancel}>  No </Button>
                <Button className="btn blue btn-primary btn-md"  onClick={acceptCancel}> Yes </Button>
            </>)}
        </Modal.Footer>
    </Modal>
    </>);
}
export default AddPoc;
