import React, { useEffect, useState } from "react";
import { Button } from "semantic-ui-react";
import { postAPI } from "../../../apiServices";
import { toast } from "react-toastify";

const Settings = (props) => {
  const [enableExtend, setEnableExtend] = useState(props?.details?.allowReturnExtension);
  const extendReturn = async(orderId) => {
    let res = await postAPI('/orders/'+orderId+'/extend-return')
    if(res.status === 1){
      setEnableExtend(false)
      toast.success(res?.message)

    }else{
      toast.error(res?.message)
    }
  }
    return (
      <>

      <div className="d-flex">
        {props?.section === "delivered" ?
            (
              <>

              {props?.canEdit && (<>
                {props?.details?.allowReturnExtension && (<>
                  <Button color="green" disabled={!enableExtend} style={{paddingRight:"1em", paddingLeft:"1em"}} onClick={() => extendReturn(props?.details?.orderID)}>
                    Extend Return
                  </Button>
                </>)}
              </>)}

              </>
            ) : (
              ""
            )}
      </div>
    </>
      );
}
export default Settings;
