import React, { useState, useEffect, useCallback, useContext } from "react";
import { Table, Button } from "semantic-ui-react";
import { postAPI, putAPI } from "../../../apiServices";
import { toast } from "react-toastify";
import { Checkbox, Dropdown } from "semantic-ui-react";
import {
  CBadge,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CFormTextarea,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { getApiCall } from "../../../apiServices";
import ImageGalleryWithCaption from "../../../common-components/image-viewer/ImageGalleryWithCaption";
import GalleryOnModal from "../../../common-components/image-viewer/GalleryOnModal";

const defaultState = {
  entries: [],
  totalEntries: 0,
  page: 0,
  recPerPage: 50,
  loading: false,
  confirmDialog: null,
  selectedItem: [],
  subTotalQty: 0,
  subTotalValue: 0,
  grandTotalQty: 0,
  grandTotalValue: 0,
  debitNoteDetail: {}
};
const localPermits = JSON.parse(localStorage.getItem("module"));
const ReturnItem = ({ details = {}, type, section, status, updateStatus,  }) => {
  let  returnStatus = details?.status.label;
  const navigate = useNavigate();
  const [dueDetails, setDueDetails] = useState(details?.returns?.details || []);
  const [viewProofModal, setViewProofModal] = useState(false)
  const [returnItems, setReturnItems] = useState([]);
  const [completeReason, setCompleteReason] = useState()
  const [totalQty, setTotalQty] = useState(0);
  // const [selectedQty, setSelectedQty] = useState(defaultState.selectedItem);
  const [selectedQty, setSelectedQty] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [state, setState] = React.useState(defaultState);
  const [visibleReadMore, setVisibleReadMore] = useState(false);
  const [visible, setVisible] = useState(false);
  const [settle, setSettle] = useState(false);
  const [showHideTabDetialsBtn, setShowHideTabDetialsBtn] = useState(false);
  const [checked, setchecked] = useState(false);
  const [returnBearer, setReturnBearer] = useState("");
  const [forwardShippingBearer, setForwardShippingBearer] = useState("");
  const [forwardShippingUnitCharge, setForwardShippingUnitCharge] = useState(0);
  const [reverseShippingBearer, setReverseShippingBearer] = useState("");
  const [discountUnitCharge, setDiscountCharge] = useState(0);
  const [errorMessage, setErrorMessage] = useState();
  const [canEdit, setCanEdit] = useState(false)
  const [permissions, setPermissions] = useState(localPermits.find((o) => o.moduleName == "Return & Refund"));
  const [duePermission, setDuePermission] = useState(
    permissions?.childItems?.find((o) => o.moduleName == "Due")
  );
  const [ApprovedPermission, setApprovedPermission] = useState(
    permissions?.childItems?.find((o) => o.moduleName == "Approved")
  );
  const [settlementPermission, setSettlementPermission] = useState(
    permissions?.childItems?.find((o) => o.moduleName == "Settlement")
  );
  const [RejectedPermission, setRejectedPermission] = useState(
    permissions?.childItems?.find((o) => o.moduleName == "Rejected")
  );
  const [CompletedPermission, setCompletedPermission] = useState(
    permissions?.childItems?.find((o) => o.moduleName == "Completed")
  );

  useEffect(() => {
    const localNavigation = JSON.parse(localStorage.getItem("module"));
    localNavigation?.forEach(Orderelement => {
      if(Orderelement.moduleName === 'Return & Refund'){
        let canIsEdit = false;
        Orderelement?.childItems?.forEach(element => {
          if('/order/'+section === element.modulePath){
            canIsEdit = element.isEdit === 0 ? false:true
            setCanEdit(canIsEdit)
          }
        });
      }
    });
  })

  useEffect(() => {
    async function fetchData() {
      const res = await getApiCall('/return/'+details.returnId+'/items')
      if(res.status === 1){
        setReturnItems(res?.payload)
        let totalQ = totalQty;
        res?.payload?.forEach((ele) => {
          totalQ = totalQ + ele.quantity
        })
        setTotalQty(totalQ);
        if(details?.forwardShipping > 0){
          setForwardShippingUnitCharge(details?.forwardShipping/totalQ);
          setDiscountCharge(details?.couponDiscount/totalQ);
        }

        setState((pr) => ({
          ...pr,
          debitNoteDetail: res?.data?.totalSellerAmount
        }));

      }
    }
    fetchData();
  }, [details]);

  const clickOnApprovedBtn = () => {
    setShowHideTabDetialsBtn(true);
    returnItems.forEach((ele) => {
      ele.selected = true;
      ele.selectedQtyValue = 0;
      ele.qty = 0;
      ele.approvedAmount = 0;
    });
    setDueDetails(returnItems);
  };

  // Reject Update
  const confirmRejectBtn = async (remark) => {
    let returnObj = {
      action: "reject",
      returnId: details.returnId,
      orderId: details.orderID,
      items: [],
      status: 2,
      amount: "",
      buyerPerson: "",
      remark: state?.confirmDialog?.remarks,
    };
    updateStatus('reject',returnObj);
    setVisible(false);
  };

  const confirmSettlementBtn = async (remark) => {
    setErrorMessage();
    let settleObj = {
      action: "settle",
      returnId: details.returnId,
      orderId: details.orderID,
      items: selectedQty,
      status: 3,
      buyerPerson: details?.buyer?.name,
      amount: state?.confirmDialog?.amount,
      remark: state?.confirmDialog?.remarks,
      bankName: "",
      branchName: "",
      accountNumber: "",
      ifsc: "",
      transactionId: "",
      forwardShippingBearer: forwardShippingBearer,
      reverseShippingBearer: reverseShippingBearer,
    };
    if(selectedQty.length > 0){
      let selQuantity = 0;
      selectedQty?.map((selQty) => {
        selQuantity += selQty?.qty;
      })
      if(selQuantity > 0){
        updateStatus('settle',settleObj);
        setSettle(false);
      }

    }else{
      setErrorMessage("Please select return quantity")
    }
  };

  // Approved Update
  const returnApprovedBtn = async ({debitNote, onlyApprove}) => {
    setErrorMessage();
    let payloadApprove = {
      action: "approve",
      returnId: details?.returnId,
      orderId: details?.orderID,
      items: selectedQty,
      status: 1,
      amount: "",
      buyerPerson: details?.buyer.name,
      remark: "",
      bankName: "",
      branchName: "",
      accountNumber: "",
      ifsc: "",
      transactionId: "",
      forwardShippingBearer: forwardShippingBearer,
      reverseShippingBearer: reverseShippingBearer,
      withDebitNote: debitNote,
      onlyApprove: onlyApprove
    };
    if(selectedQty.length > 0){
      let selQuantity = 0;
      selectedQty?.map((selQty) => {
        selQuantity += selQty?.qty;
      })
      if(selQuantity > 0){
        updateStatus('approve',payloadApprove);
        // selectedQty([])
        setSelectedQty([])
        setSettle(false);
      }
      else{
        setErrorMessage("Please select return quantity")
      }
    }else{
      setErrorMessage("Please select return quantity")
    }
  };

  const handleClickForwardShipping = (val) => {
    setForwardShippingBearer(val);
  };
  const handleClickReverseShipping = (val) => {
    setReverseShippingBearer(val);
  };

  const selectQuantity = (item, qItem, index) => {
    try {
      let reData = returnItems;
      for (let i = 0; i < reData.length; i++) {
        let amount = qItem * parseFloat(item.rate);
        reData[index].approvedAmount = amount;
        reData[index].selectedQtyValue = qItem;
        reData[index].qty = qItem;
      }
      let _selectedQtyValue = reData.reduce((a, b) =>
        a + (b.selectedQtyValue ? b.selectedQtyValue : 0), 0

      );

      // let _selectedQtyValue = reData.reduce(
      //   (a, b) => a + b.selectedQtyValue,
      //   0
      // );
      let _approvedAmount = reData.reduce((a, b) => a + (b.approvedAmount ? b.approvedAmount : 0), 0).toFixed(2);
      setState((pr) => ({
        ...pr,
        subTotalQty: _selectedQtyValue,
        subTotalValue: _approvedAmount,
        grandTotalQty: _selectedQtyValue,
        grandTotalValue: _approvedAmount,
      }));
      setReturnItems([]);
      setReturnItems(reData);
    } catch (er) {
    }
  };

  if (state.loading) {
    let timerInterval;
    Swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          // const content = Swal.getContent();
          // if (content) {
          //   const b = content.querySelector("b");
          //   if (b) {
          //     b.textContent = Swal.getTimerLeft();
          //   }
          // }
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
      }
    });
  }
  const checkItem = (retId, value) => {
    let filters = selectedQty.filter(
      (item) => parseInt(item.returnItemId) !== retId
    );
    setSelectedQty(filters);
    setchecked(value);
  };

  const changeItemQty = (selectItm, qty, index) => {
    // let isfind = defaultState.selectedItem.find(
    //   (item) => parseInt(item.returnItemId) === selectItm.returnItemId
    // );
    // if (!isfind) {
    //   defaultState.selectedItem.push({
    //     returnItemId: `${selectItm.returnItemId}`,
    //     qty: `${qty}`,
    //   });
    // } else {
    //   const objIndex = defaultState.selectedItem.findIndex(
    //     (obj) => parseInt(obj.returnItemId) === selectItm.returnItemId
    //   );
    //   defaultState.selectedItem[objIndex].qty = qty;
    // }
    let selItem = selectedItems;
    let isfind = selItem.find(
      (item) => parseInt(item.returnItemId) === selectItm.returnItemId
    );
    if (!isfind) {
      selItem.push({
        returnItemId: `${selectItm.returnItemId}`,
        qty: `${qty}`,
      });
    } else {
      const objIndex = selItem.findIndex(
        (obj) => parseInt(obj.returnItemId) === selectItm.returnItemId
      );
      selItem[objIndex].qty = qty;

    }
    setSelectedItems(selItem);
    setSelectedQty(selItem);
  };

  const viewReturnReasonInDetail = (reason) => {
    setCompleteReason(reason)
    setVisibleReadMore(true);
  }

  const handleViewProofModal = (d) => {
    setViewProofModal(d)
  }
  return (
    <div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Product</Table.HeaderCell>
            <Table.HeaderCell>Return Reason</Table.HeaderCell>
            <Table.HeaderCell>Price per item</Table.HeaderCell>
            <Table.HeaderCell>Quantity</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            {returnStatus !== "Initiated" ? (<>
              <Table.HeaderCell>Credit Amount</Table.HeaderCell>
              { details?.approvedWithDebitNote === 1 && (
                <Table.HeaderCell>Debit Amount</Table.HeaderCell>
              )}

            </>) : (<>
              <Table.HeaderCell>Approved Amount</Table.HeaderCell>
            </>)}

          </Table.Row>
        </Table.Header>

        <Table.Body>
          {(returnItems || []).map((item, index) => {
            let qtyArray = []
            if(item?.quantity){
              qtyArray = Array.from({ length: item.quantity + 1 }, (_, index) => index);
            }
            return (
              <>
              <Table.Row key={index}>
                <Table.Cell className="d-flex">
                  <div className="ms-3">
                    {item.title}
                    <br />
                    <div className="pt-2 pb-2">
                      <CBadge className="p-2" color="warning">
                        {item.productType === 1
                          ? "Color Variant"
                          : "Set Variant"}
                      </CBadge>
                    </div>
                  </div>
                </Table.Cell>

                <Table.Cell>
                {/* <div>
                   {item?.remarks}
                    </div> */}
                  <span style={{cursor:"pointer", color:"blue"}}>

                    <span onClick={() => setViewProofModal(true)}>View Detail</span>
                    {/* {<ImageGalleryWithCaption images={item.img} captions={item.reasons} viewPreview={false} />} */}
                    {viewProofModal && <GalleryOnModal images={item.img} captions={item.reasons} remarks={item?.remarks} handleViewProofModal={handleViewProofModal} viewPreview={false} />}
                  </span>

                </Table.Cell>

                <Table.Cell>₹{item.rate}</Table.Cell>

                <Table.Cell>
                  {returnStatus === "Initiated" ? (
                    <div style={{ display: "flex" }}>
                      <div>{item.quantity}</div>
                      <Dropdown
                        className="ui search dropdown"
                        style={{
                          width: "100%",
                          border: "1px solid lightgray",
                          background: "none",
                          marginLeft: 5,
                        }}
                        text={
                          item.selectedQtyValue
                            ? item.selectedQtyValue
                            : "Select"
                        }
                        // disabled={!showHideTabDetialsBtn}
                      >
                        <Dropdown.Menu>
                          {(qtyArray || []).map((qItem, qIndex) => {
                            return (
                              <Dropdown.Item
                                style={{ scrollY: "auto", height: "50px" }}
                                key={qIndex}
                                text={qItem}
                                value={qItem}
                                onClick={() => {
                                  selectQuantity(item, qItem, index, qIndex);
                                  changeItemQty(item, qItem, qIndex);
                                }}
                              />
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <div>{`${item.quantity} / ${item?.approval?.quantity}`}</div>{" "}
                    </div>
                  )}
                </Table.Cell>

                <Table.Cell>₹{item.amount.toFixed(2)}</Table.Cell>

                <Table.Cell>
                  <div style={{ marginLeft: "40px" }}>
                    {returnStatus !== "Initiated" ? (
                      <React.Fragment>
                        ₹{item.approval.amount ? item.approval.amount : 0}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {selectedQty?.map((rate) => (
                          <React.Fragment>
                            {parseInt(rate.returnItemId) === item.returnItemId
                              ? (rate.qty > 0 ? '₹'+(item.rate * rate.qty).toFixed(2) : 0)
                              : ''}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    )}
                  </div>
                </Table.Cell>
                {returnStatus !== "Initiated" && details?.approvedWithDebitNote === 1 ? (<>
                {/* debit note column */}
                <Table.Cell>
                  <div style={{ marginLeft: "40px" }}>
                    {returnStatus !== "Initiated" ? (
                      <React.Fragment>
                         ₹{item.sellerAmount ? item.sellerAmount : 0}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {selectedQty?.map((rate) => (
                          <React.Fragment>
                             ₹{parseInt(rate.returnItemId) === item.returnItemId
                              ? (item.rate * rate.qty).toFixed(2)
                              : 0}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    )}
                  </div>
                </Table.Cell>
                </>) : (<></>)}

              </Table.Row>
              {/* More reason */}
            </>
            );
          })}

          <Table.Row>
            <Table.Cell></Table.Cell>
            <Table.Cell colspan="2">
              <div>Subtotal</div>
              <div>Forward Handling Charges</div>
              <div>Discount</div>
            </Table.Cell>
            <Table.Cell>
              <div>
                {details?.returns?.quantity} /{" "}
                {state?.subTotalQty === 0
                  ? details?.returns.approval?.quantity
                  : state?.subTotalQty}
              </div>
              <div> _ </div>
              <div> _ </div>
            </Table.Cell>
            <Table.Cell>
              <div>₹ {details?.returns?.subTotal} </div>
              <div>₹ {details?.forwardShipping} </div>
              <div>₹ {details?.couponDiscount} </div>
            </Table.Cell>

              <Table.Cell>
              <div style={{ marginLeft: "40px" }}>
              {" ₹ "}
                {state?.subTotalValue === 0
                  ? details?.returns.approval?.amount
                  // ? details?.returns?.subTotal
                  : state?.subTotalValue}{" "}
              </div>
              <div style={{ marginLeft: "40px" }}>{" ₹ "}{((state?.grandTotalQty === 0
                ? details?.returns.approval?.quantity
                : state?.grandTotalQty) * forwardShippingUnitCharge).toFixed(2)}
              </div>
              <div style={{ marginLeft: "40px" }}>{" -₹ "}{((state?.grandTotalQty === 0
              ? details?.returns.approval?.quantity
              : state?.grandTotalQty) * discountUnitCharge).toFixed(2)}
              </div>
            </Table.Cell>
            {returnStatus !== 'Initiated' && details?.approvedWithDebitNote === 1 ? (<>
            <Table.Cell>
              <div style={{ marginLeft: "40px" }}>
                {/* debit note details */}
              ₹{" "}
                {/* {state?.subTotalValue === 0
                  ? details?.returns.approval?.amount
                  // ? details?.returns?.subTotal
                  : state?.subTotalValue}{" "} */}
                  {state?.debitNoteDetail?.subTotal}
              </div>
              <div style={{ marginLeft: "40px" }}>
                {state?.debitNoteDetail?.shipping > 0 ? '₹ ': ''}
              {state?.debitNoteDetail?.shipping}
                {/* ₹{((state?.grandTotalQty === 0
                ? details?.returns.approval?.quantity
                : state?.grandTotalQty) * forwardShippingUnitCharge).toFixed(2)} */}
                </div>
              <div style={{ marginLeft: "40px" }}>{state?.debitNoteDetail?.couponDiscount > 0 ? "- ₹ " : ""}
              {state?.debitNoteDetail?.couponDiscount}
              {/* ₹ {((state?.grandTotalQty === 0
              ? details?.returns.approval?.quantity
              : state?.grandTotalQty) * discountUnitCharge).toFixed(2)} */}
              </div>
            </Table.Cell>
            </>) : (<></>)}

          </Table.Row>
          <Table.Row>
            <Table.Cell></Table.Cell>
            <Table.Cell colspan="2">
              {details?.revisedCreditGrandTotal !== 0 || details?.revisedDebitGrandTotal !== 0 ? (
                <>
                <div style={{textDecoration:"line-through"}}>Grand Total</div>
                <div>Revised Grand Total</div>
                </>
              ) : (
                <>Grand Total</>
              )}

            </Table.Cell>
            <Table.Cell>
              {details?.returns?.quantity} /{" "}
              {state?.grandTotalQty === 0
                ? details?.returns.approval?.quantity
                : state?.grandTotalQty}
            </Table.Cell>
            <Table.Cell>₹ {parseFloat(details?.returns?.grandTotal).toFixed(0)} </Table.Cell>

              <Table.Cell>
              <div style={{ marginLeft: "40px" }}>
              {" "}
                  {details?.revisedCreditGrandTotal !== 0 ? (
                    <>
                    <div style={{textDecoration:"line-through"}}>
                    ₹{(parseFloat(state?.grandTotalValue === 0
                      ? details?.returns.approval?.amount
                      : state?.grandTotalValue)+parseFloat((state?.grandTotalQty === 0
                        ? details?.returns.approval?.quantity
                        : state?.grandTotalQty) * forwardShippingUnitCharge)
                        -parseFloat((state?.grandTotalQty === 0
                          ? details?.returns.approval?.quantity
                          : state?.grandTotalQty) * discountUnitCharge)).toFixed(0)}
                    </div>
                    <div>₹{details?.revisedCreditGrandTotal.toFixed(0)}</div>
                    </>
                  ) : (
                    <>₹{(parseFloat(state?.grandTotalValue === 0
                      ? details?.returns.approval?.amount
                      : state?.grandTotalValue)+parseFloat((state?.grandTotalQty === 0
                        ? details?.returns.approval?.quantity
                        : state?.grandTotalQty) * forwardShippingUnitCharge)
                      -parseFloat((state?.grandTotalQty === 0
                        ? details?.returns.approval?.quantity
                        : state?.grandTotalQty) * discountUnitCharge)).toFixed(0)}</>
                  )}
                  {""}
              </div>


            </Table.Cell>
            {returnStatus !== 'Initiated' && details?.approvedWithDebitNote === 1 ? (<>
            <Table.Cell>
            <div style={{ marginLeft: "40px" }}>
              {details?.revisedDebitGrandTotal !== 0 ? (<>
                <div style={{textDecoration:"line-through"}}>
                  ₹ {state?.debitNoteDetail?.grandTotal}
                </div>
                <div>₹{details?.revisedDebitGrandTotal.toFixed(0)}</div>
              </>) : (<>
                ₹ {state?.debitNoteDetail?.grandTotal}
              </>)}



              </div>
            </Table.Cell>
            </>) : (<></>)}

          </Table.Row>
        </Table.Body>
      </Table>
      {returnStatus === "Initiated" ? (
        <div style={{ display: "flex", justifyContent: "right" }}>
          {showHideTabDetialsBtn === true ? (
            <>
              <CDropdown className="me-2">
                <CDropdownToggle color="info">
                  Forward Shipping Bearer
                </CDropdownToggle>
                <CDropdownMenu>
                  {[
                    { key: "Seller", value: "seller" },
                    { key: "Buyer", value: "buyer" },
                    { key: "Showroom B2B", value: "platform" },
                  ].map((item) => (
                    <CDropdownItem
                      href="#"
                      key={item.key}
                      onClick={() => handleClickForwardShipping(item.value)}
                    >
                      {item.key}
                    </CDropdownItem>
                  ))}
                </CDropdownMenu>
              </CDropdown>
              <CDropdown className="me-2">
                <CDropdownToggle color="info">
                  Reverse Shipping Bearer
                </CDropdownToggle>
                <CDropdownMenu>
                  {[
                    { key: "Seller", value: "seller" },
                    { key: "Buyer", value: "buyer" },
                    { key: "Showroom B2B", value: "platform" },
                  ].map((item) => (
                    <CDropdownItem
                      href="#"
                      key={item.key}
                      onClick={() => handleClickReverseShipping(item.value)}
                    >
                      {item.key}
                    </CDropdownItem>
                  ))}
                </CDropdownMenu>
              </CDropdown>
              <Button
                color="grey"
                onClick={() => {
                  setShowHideTabDetialsBtn(false);
                }}
              >
                Cancel
              </Button>
              <Button primary onClick={() => returnApprovedBtn({debitNote:0})}>
                Save
              </Button>
            </>
          ) : (
            <>
            {errorMessage ? (<>
              <div className="alert alert-danger" style={{width:"100%", padding:"0.5rem 1.25rem", marginRight:"10px", marginBottom:"0"}}>
                {errorMessage}
              </div>
            </>) : (<></>)}
            {duePermission.isEdit === 1 && <Button
                primary
                disabled={selectedQty?.length > 0 ? false : true}
                onClick={() => {
                  setErrorMessage()
                  let checkLength = 0;
                  if(selectedQty?.length > 0){
                    selectedQty?.map((selQty) => {
                      checkLength += selQty.qty;
                    })
                    if(checkLength > 0){
                      setSettle(!settle);
                    }else{
                      setErrorMessage("Please select quantity")
                    }

                  }else{
                    setErrorMessage("Please select quantity")
                  }

                }}
              >
                Settlement
              </Button>}
              {duePermission.isEdit === 1 && <Button
                 color="purple"
                variant="outline"
                onClick={() => {
                  setVisible(true);
                }}
              >
                Reject
              </Button>}

                {duePermission?.isEdit === 1 && <CDropdown className="me-2">
                  <CDropdownToggle disabled={selectedQty?.length > 0 ? false : true} className={"ui primary button"} type="button" primary color="primary">
                    Approve &nbsp;{" "}

                  </CDropdownToggle>
                  <CDropdownMenu style={{width:"100%",top:"5px", marginTop: "10px", borderRadius:"8px"}}>
                    {/* <CDropdownItem style={{whiteSpace:"normal", paddingLeft:2, paddingRight:0}} onClick={() => returnApprovedBtn({onlyApprove:1})}>
                       &nbsp;{" "}Only Approve
                    </CDropdownItem> */}
                    <CDropdownItem style={{whiteSpace:"normal", paddingLeft:2, paddingRight:0}} onClick={() => returnApprovedBtn({debitNote:1})}>
                       &nbsp;{" "}With Debit Note
                    </CDropdownItem>
                    <CDropdownItem style={{whiteSpace:"normal", paddingLeft:2, paddingRight:0}} onClick={() => returnApprovedBtn({debitNote:0})}>
                       &nbsp;{" "}Without Debit Note
                    </CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>}

            </>
          )}

        </div>
      ) : (
        " "
      )}

      <CModal visible={visible} onDismiss={() => setVisible(false)}>
        <CModalHeader onDismiss={() => setVisible(false)}>
          <CModalTitle> Rejection Reason</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>Please write the reason for rejection</p>
          <CFormTextarea
            size="lg"
            value={state.confirmDialog?.remarks || ""}
            className="mt-3 mb-3"
            maxLength={255}
            placeholder="Reason"
            onChange={(evt) => {
              const { value } = evt.currentTarget;
              setState((prevState) => ({
                ...prevState,
                confirmDialog: {
                  ...prevState.confirmDialog,
                  remarks: value || "",
                },
              }));
            }}
          />
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            Cancel
          </CButton>
          <CButton
            color="primary"
            onClick={() => {
              confirmRejectBtn(state.confirmDialog?.remarks);
              setState((prevState) => ({
                ...prevState,
                confirmDialog: null,
              }));
            }}
          >
            Save
          </CButton>
        </CModalFooter>
      </CModal>

      <CModal visible={settle} onDismiss={() => setSettle(false)}>
        <CModalHeader onDismiss={() => setSettle(false)}>
          <CModalTitle>Settlement Confirmation</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>Please enter amount on which settlement is done</p>
          <div className="ms-3 d-flex">
            <span
              style={{
                fontSize: "24px",
                marginRight: "5px",
                marginTop: "5px",
              }}
            >
              ₹
            </span>
            <div>
              <input
                placeholder="Amount"
                style={{
                  border: "1px solid #d0ceced9",
                  height: "30px",
                  borderRadius: "4px",
                }}
                onChange={(evt) => {
                  const { value } = evt.currentTarget;
                  setState((prevState) => ({
                    ...prevState,
                    confirmDialog: {
                      ...prevState.confirmDialog,
                      amount: value || "",
                    },
                  }));
                }}
              />
            </div>

            <div style={{ marginLeft: "15px" }}>
              <select className="form-control" onChange={(e) => setReturnBearer(e.target.value)}>
                <option value="">Select</option>
                <option value="seller">Seller</option>
                <option value="buyer">Buyer</option>
                <option value="showroomb2b">Showroom B2B</option>
              </select>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              marginTop: "17px",
            }}
          >
            <select className="form-control" onChange={(e) => setForwardShippingBearer(e.target.value)}>
                <option value="">Forward Shipping Bearer</option>
                <option value="seller">Seller</option>
                <option value="buyer">Buyer</option>
                <option value="showroomb2b">Showroom B2B</option>
              </select>

            <select className="form-control" onChange={(e) => setReverseShippingBearer(e.target.value)}>
                <option value="">Forward Reverse Bearer</option>
                <option value="seller">Seller</option>
                <option value="buyer">Buyer</option>
                <option value="showroomb2b">Showroom B2B</option>
              </select>
          </div>

          <p className="mt-2">Remarks</p>
          <CFormTextarea
            size="lg"
            value={state.confirmDialog?.remarks || ""}
            className="mt-1 mb-3"
            maxLength={255}
            placeholder="Remarks"
            onChange={(evt) => {
              const { value } = evt.currentTarget;
              setState((prevState) => ({
                ...prevState,
                confirmDialog: {
                  ...prevState.confirmDialog,
                  remarks: value || "",
                },
              }));
            }}
          />
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setSettle(false)}>
            Cancel
          </CButton>
          <CButton
            color="primary"
            onClick={() => {
              confirmSettlementBtn(state.confirmDialog?.remarks);
              // updateStatus(
              //   state.confirmDialog?.updatedStatus,
              //   state.confirmDialog?.id,
              //   state.confirmDialog?.remarks
              // );
              // state.confirmDialog.callback();
              setState((prevState) => ({
                ...prevState,
                confirmDialog: null,
              }));
            }}
          >
            Save
          </CButton>
        </CModalFooter>
      </CModal>

    </div>
  );
};

export default ReturnItem;
