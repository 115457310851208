import React, { useState, useEffect } from "react";
import { CPagination, CPaginationItem, CFormSelect } from "@coreui/react";

function Pagination(props) {
  const [pageInfo, setPageInfo] = useState(props?.state);
  const [loading, setLoading] = useState(false);

  const totalPages = Math.ceil(props?.state.totalEntries / props?.state.recPerPage);
  const currentPage = props?.state?.page;

  const handlePageInfo = () => {
    if (loading) {
      setLoading(false);
      props?.handlePagination(pageInfo);
    }
  };

  useEffect(() => {
    handlePageInfo();
  }, [pageInfo]);

  const handlePageChange = (page) => {
    setPageInfo((prevState) => ({
      ...prevState,
      page,
    }));
    setLoading(true);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const siblingsCount = 2;

    if (totalPages > 1) {
      const startPage = Math.max(2, currentPage - siblingsCount);
      const endPage = Math.min(totalPages - 1, currentPage + siblingsCount);

      // Add the first page
      pages.push(1);

      if (startPage > 2) {
        pages.push('...');
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage < totalPages - 1) {
        pages.push('...');
      }

      // Add the last page if there are more than one pages
      if (totalPages > 1) {
        pages.push(totalPages);
      }
    } else {
      // If there's only one page, just add page 1
      pages.push(1);
    }

    return pages;
  };

  const pageNumbers = generatePageNumbers();

  return (
    <div className="d-flex align-items-center justify-content-between" style={{marginTop:"10px", paddingRight:"1.5rem"}}>

        <CFormSelect size="sm"
          className="mb-3"
          aria-label="Small select example"
          style={{width:"auto"}}
          value={props?.state.recPerPage}
          onChange={(e) => {
            let rec = e.target?.value;
            setPageInfo((prevState) => ({
              ...prevState,
              recPerPage: parseInt(rec),
            }));
            setLoading(true);
          }}
        >
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </CFormSelect>
      <p>{`Showing ${props?.state.recPerPage * props?.state.page + 1} to ${
        props?.state.recPerPage * props?.state.page + props?.state.entries.length
      } of ${props?.state.totalEntries} record(s)`}</p>

      <CPagination aria-label="Page navigation example">
        <CPaginationItem
          aria-label="Previous"
          disabled={currentPage === 0}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <span aria-hidden="true">&laquo;</span>
        </CPaginationItem>

        {pageNumbers.map((page, index) => (
          <CPaginationItem
            key={index}
            className={currentPage === page - 1 ? "active" : ""}
            onClick={() => page !== '...' && handlePageChange(page - 1)}
            disabled={page === '...'}
          >
            {page}
          </CPaginationItem>
        ))}

        <CPaginationItem
          aria-label="Next"
          disabled={currentPage === totalPages - 1}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <span aria-hidden="true">&raquo;</span>
        </CPaginationItem>
      </CPagination>
    </div>
  );
}

export default Pagination;
