import React from "react";
import { removeTokenData } from "../utils";
import {
  CHeader,
  CHeaderNav,
} from "@coreui/react";
import { Link } from "react-router-dom";

import Breadcrumbs from "./Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from '@fortawesome/free-solid-svg-icons';

const TheHeader = (props) => {

  return (
    <>
    <CHeader>

      <header>
      <span className="side-nav-toggle-btn" onClick={()=>props?.toggleSidebar()} style={{zIndex:1000, position: "absolute"}}><FontAwesomeIcon icon={faBars}></FontAwesomeIcon></span>
    </header>

      <CHeaderNav className="px-3">
        <Link
          to="/login"
          style={{ cursor: "pointer" }}
          onClick={() => removeTokenData()}
        >
          Log Out
        </Link>
      </CHeaderNav>
    </CHeader>

    <CHeader>
      <Breadcrumbs />
    </CHeader>
    </>
  );
};

export default TheHeader;
