import React, { useState, useEffect } from 'react';
import { Button, Table } from "semantic-ui-react";
import { getApiCall, postAPI } from "../../../apiServices"
import PocDetail from "./PocDetail";
import TabContentMessage from '../../../common-components/TabContentMessage';
import { Popover, Typography, CircularProgress, Link } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { numberWithComma } from '../../../constants/comonFunction';
import AddPoc from '../AddPoc';

const Payment = ({ details = {}, match, section, canEdit, isLoading = false }) => {
  const [paymentSummary, setPaymentSummary] = useState();
  const [paymentList, setPaymentList] = useState([]);
  const [message, setMessage] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAddPoc, setShowAddPoc] = useState(false);

  useEffect(() => {
    fetchPaymentData();
  }, []);

  const fetchPaymentData = async () => {
    setLoading(true)
    let res = await getApiCall('/order/' + details?.orderID + '/payment-summary');
    if (res.status === 1) {
      setPaymentList(res?.payload.payments);
      setPaymentSummary(res.payload.summary);
    } else {
      setMessage(res?.message);
    }
    setLoading(false)
  };

  const fetchOrderByTransactionId = async () => {
    let data = {
      transactionID: details?.transactionID
    };
    try {
      setLoading(true);
      const res = await postAPI('/orders/transaction/list', data);
      if (res.status === 1) {
        setPopoverContent(res.payload);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleButtonClick = async (event) => {
    if (anchorEl === null) {
      fetchOrderByTransactionId();
      setAnchorEl(event.currentTarget);
      setPopoverContent();
    } else {
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {paymentSummary ? (
        <>
          {(details?.paymentMode === 'cod' || details?.paymentMode === 'adv-10-90') && paymentSummary?.amountToBeCollected > 0 && (section === 'placed' || section === 'confirmed' || section === 'packed' || section === 'dispatched' || section === 'delivered') && (
            <div style={{ textAlign: "right" }}>
              <button className='btn btn-info' onClick={() => setShowAddPoc(!showAddPoc)}>
                Add Proof Of Collection
              </button>

              {showAddPoc && <AddPoc poc={showAddPoc} order={details} updatePaymentList={() => fetchPaymentData()} />}
            </div>
          )}
          <Table className='payment-poc-summary'>
            <Table.Row>
              <Table.Cell>
                <span className='tab-text-primary-label'>ORDER VALUE: </span>
                <span className='tab-text-primary-value'>₹ {paymentSummary?.netAmount > 0 ? numberWithComma(paymentSummary?.netAmount?.toString()) : 0}</span>
              </Table.Cell>
              <Table.Cell style={{ textTransform: "capitalize" }}><strong>PAYMENT METHOD: </strong> {paymentSummary?.paymentMode} </Table.Cell>
              <Table.Cell>
                <strong>TRANSACTION ID: </strong>{paymentSummary?.transactionID}  {" "}<FontAwesomeIcon icon={faEye} onClick={handleButtonClick} style={{ cursor: "pointer" }} />
                {anchorEl !== null && <div style={{
                  position: "absolute",
                  top: '30px',
                  right: '90px',
                  zIndex: "1000",
                  backgroundColor: "rgb(244, 241, 241)",
                  padding: '5px', maxWidth: '300px', marginTop: "2px"
                }}>
                  {loading ? (
                    <CircularProgress />
                  ) : popoverContent ? (
                    <>
                      {popoverContent?.map((row) => {
                        return (
                          <div className='mb-2' key={row.orderNumber}>
                            <a href={"/#/order/placed?q=" + row.orderNumber + "&global=1"} target="_blank" rel="noopener noreferrer">
                              <div>{row.orderNumber}</div>
                            </a>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </div>}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell><strong>PAID: </strong> ₹ {paymentSummary?.paidAmount > 0 ? numberWithComma(paymentSummary?.paidAmount?.toString()) : 0}</Table.Cell>
              <Table.Cell><strong>AMOUNT TO BE COLLECTED: </strong> ₹ {paymentSummary?.amountToBeCollected > 0 ? numberWithComma(paymentSummary?.amountToBeCollected?.toString()) : 0}</Table.Cell>
              <Table.Cell><strong>S-CASH USED: </strong> {paymentSummary?.totalsCashUsed > 0 ? numberWithComma((paymentSummary?.totalsCashUsed).toString()) : 0}</Table.Cell>
            </Table.Row>
          </Table>
          <div>
            {paymentList?.length > 0 ? (
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Transaction ID</Table.HeaderCell>
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                    <Table.HeaderCell>Gateway/Payment Mode</Table.HeaderCell>
                    <Table.HeaderCell>Proof</Table.HeaderCell>
                    <Table.HeaderCell>Remarks</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {paymentList?.map((row, index) => (
                    <Table.Row key={index}>
                      <Table.Cell>{row?.dateOfPayment}</Table.Cell>
                      <Table.Cell style={{ textTransform: "capitalize" }}>{row?.type}</Table.Cell>
                      <Table.Cell>{row.transactionID ? row.transactionID : "-"}</Table.Cell>
                      <Table.Cell>₹ {row?.amount}</Table.Cell>
                      <Table.Cell style={{ textTransform: "capitalize" }}>{row.paymentGateway ? row.paymentGateway : row.paymentMode}</Table.Cell>
                      <Table.Cell>
                        {row.files?.[0] ? (
                          <a href={row?.files?.[0]} target='_blank' rel='noopener noreferrer'>View</a>
                        ) : (
                          <>NA</>
                        )}
                      </Table.Cell>
                      <Table.Cell>{row.remarks}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            ) : (
              // Uncomment below line to show message
              // <TabContentMessage message={message} />
              <></>
            )}
          </div>
        </>
      ) : (
        <>
          {loading ? (<center>Loading...</center>) : (<></>)}
        </>

      )}
    </>
  );
};

export default Payment;
