import React, {useState} from "react";
import { Grid } from "semantic-ui-react";

const localPermits = JSON.parse(localStorage.getItem("module"));

const BuyerDetails = ({ details = {} }) => {
  const [buyerPermission, setBuyerPermission] = useState(localPermits.find((o) => o.moduleName == "Buyers"))
  const viewBuyerDetail = (mobile, buyerStatus) => {
    window.open("/#/buyers/"+buyerStatus+"?q="+mobile+"&f=mobile&o=equals", "_blank");
  }
  return (
    <div>
      <Grid className="ml-1">
        <Grid.Row>
          <Grid.Column width={8}>
            <div style={{ textDecoration: "underline", marginBottom: "6px" }}>
              Buyer Information:
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              Name:{" "}
              <span style={{ fontWeight: "normal" }}>{details?.buyerName}</span>{" "}
            </div>
            {/* <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              Email:{" "}
              <span style={{ fontWeight: "normal" }}>{details?.byrEmail}</span>
            </div> */}
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              Mobile:{" "}
              <span style={{ fontWeight: "normal" }}>{details?.byrMobile}</span>
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
             Buyer Onborded By:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.buyerOnboardedByName + " ("+details?.buyerOnBoardedBy+")"}
              </span>
            </div>
            {buyerPermission?.isView && (<>
              <div className="viewLink" style={{ fontWeight: "bold", fontSize: "13px" }} onClick={()=> viewBuyerDetail(details?.byrMobile, details?.byrAccountStatus)}>
                View Detail{" "}
            </div>
            </>)}
          </Grid.Column>
          <Grid.Column width={8}>
            <div style={{ textDecoration: "underline", marginBottom: "6px" }}>
              Shipping Address
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              Address:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.shippingAddress?.delAddress}
              </span>
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              Landmark:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.shippingAddress?.delCity}
              </span>
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              City:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.shippingAddress?.delCity}
              </span>
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              State:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.shippingAddress?.delState}
              </span>
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
              Pincode:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.shippingAddress?.delPincode}
              </span>
            </div>

          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default React.memo(BuyerDetails);
