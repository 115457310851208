import React, { useState } from "react";
import { DateRangePicker } from 'react-date-range';
import { Button } from "semantic-ui-react";
import { downloadFile } from "./../../../apiServices";
import { format } from 'date-fns';

import Swal from "sweetalert2";
import { toast } from "react-toastify";

function CancelledOrderReport(props){
    const [selectedDateRange, setSelectedDateRange] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
      ]);
      const [fileFormat, setFileFormat] = useState('xlsx')

      const downloadSaleBill = async () => {
        let sDate = format(new Date(selectedDateRange[0]?.startDate), 'dd-MMM-yy')
        let eDate = format(new Date(selectedDateRange[0]?.endDate), 'dd-MMM-yy');
        let tDate = format(new Date(), 'dd-MMM-yy')

        let fileName = "Cancelled-Order_"+sDate+"_To_"+eDate+"_"+tDate;
        const data = {
          from: format(new Date(selectedDateRange[0]?.startDate), 'dd-MM-yyyy'),
          to: format(new Date(selectedDateRange[0]?.endDate), 'dd-MM-yyyy'),
          format: fileFormat,
          fileName:fileName
      };
      let res = await downloadFile("/report/cancelled-orders?from="+data?.from+"&to="+data?.to+"&format="+fileFormat, data)
      if(res === false){
        toast.error("No cancelled order found for selected date range");
      }
    //   window.open(BASEURL+"/finance/sales-report?from="+data?.from+"&to="+data?.to+"&format="+fileFormat)

      };
    return (<>

    <DateRangePicker
        onChange={item => {
            setSelectedDateRange([item.selection])
            if(item.selection.startDate && item.selection.endDate){
                // setDateRangeModal(false)
            }
        }}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        showMonthAndYearPickers={true}
        showDateDisplay={true}
        dateDisplayFormat={"d MMM, yyyy"}
        months={1}
        ranges={selectedDateRange}
        direction="horizontal"
        minDate={new Date(props?.minDate)}
        maxDate={new Date()}
        inputRanges={[]}
        size="sm"
        />
        <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
                <select className="form-control" onChange={(e)=>setFileFormat(e.target.value)}>
                    <option value={"xlsx"}>MS Excel (.xlsx)</option>
                    <option value={"csv"}>CSV (.csv)</option>
                </select>
            </div>
            <div className="col-md-4">
            <Button primary onClick={() => downloadSaleBill()}><i className="fa fa-download"></i> Download</Button>
            </div>
        </div>

    </>)
}

export default CancelledOrderReport
