import React, { useState, useEffect, useCallback, useRef, useContext, } from "react";
import { AppContext } from "../../contexts/app";
import OrderPage from "./Order";
import { toast } from "react-toastify";
import { postAPI, putAPI, getApiCall } from "../../apiServices";
import MESSAGES from "../../message.json";
import { Checkbox, Button, Popup } from "semantic-ui-react";
import download from "downloadjs";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { CCol, CRow, CPagination, CHeader, CButton, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CFormTextarea, CFormText, } from "@coreui/react";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faInfoCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import { format } from 'date-fns';

import { useLocation, useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "../../common-components/Pagination";
import { changeDateFormat_DMY } from "../../constants/comonFunction";

const localPermits = JSON.parse(localStorage.getItem("module"));

const Orders = ({ match }) => {
  const filterSubmitBtnRef = useRef();
  // var urlPar = match?.params?.type;
  var {type} = useParams()
  let urlPar = type
  const search = useLocation().search;
  const location = useLocation();
  let qglobal = "2";
  let qpage = new URLSearchParams(search).get("pg");
  let qkeyword = new URLSearchParams(search).get("q");
  let qFilterType = new URLSearchParams(search).get("filterType");
  let qStartDate = null;
  qStartDate = new URLSearchParams(search).get("startDate");
  let qEndDate = null;
  qEndDate = new URLSearchParams(search).get("endDate");
  let qSubFilterType = new URLSearchParams(search).get("subFilterType");
  qglobal = new URLSearchParams(search).get("global");
  if(qpage != null && qpage > 0){
    qpage = qpage-1;
  }else{
    qpage = 0;
  }

  let rangeStartD = null;
  let rangeEndD = null;
  if(qStartDate != null){
    const parts = qStartDate.split('-');
    const dayS = parseInt(parts[0], 10);
    const monthS = parseInt(parts[1], 10); // Months are 0-based
    const yearS = parseInt(parts[2], 10);
    rangeStartD = monthS+"-"+dayS+"-"+yearS;

  }

  if(qEndDate != null){
    const partE = qEndDate.split('-');
    const dayE = parseInt(partE[0], 10);
    const monthE = parseInt(partE[1], 10); // Months are 0-based
    const yearE = parseInt(partE[2], 10);
    rangeEndD = monthE+"-"+dayE+"-"+yearE;
  }

  let shouldSearchGlobally = null;
  if(qglobal === "1"){
    shouldSearchGlobally = true;
  }else{
    shouldSearchGlobally = false;
  }

  let timeout = null;
  const defaultState = {
    entries: [],
    totalEntries: 0,
    page: qpage,
    recPerPage: 50,
    searchKeyword: qkeyword,
    startDate: qStartDate,
    endDate: qEndDate,
    filterType: qFilterType,
    subFilterType: qSubFilterType,
    searchGlobally: qglobal,
    action: "deliver",
    loading: true,
    confirmDialog: null,
    shouldSearchGlobally: shouldSearchGlobally,
    showClearFilter: search ? true : false
  };

let filterOpt = []

  try {
    if (urlPar === "placed") {
      urlPar = "place";
    } else if (urlPar === "confirmed") {
      urlPar = "confirm";
    } else if (urlPar === "packaged") {
      urlPar = "ready";
    } else if (urlPar === "dispatched") {
      urlPar = "dispatch";
    } else if (urlPar === "delivered") {
      urlPar = "deliver";
    } else if (urlPar === "cancelled") {
      urlPar = "cancel";
    } else if (urlPar === "rto") {
      urlPar = "rto";
    } else if (urlPar === "lost") {
      urlPar = "lost";
    }else if (urlPar === "failed") {
      urlPar = "failed";
    }
    defaultState.action = urlPar;
  } catch (er) {
  }
  const { setSecondaryHeader } = useContext(AppContext);
  const [state, setState] = useState(defaultState);
  const [loading, setLoading] = useState(true)
  const [filterOptions, setFilterOptions] = useState();
  const [subFilterOptions, setSubFilterOptions] = useState();
  // const history = useHistory();
  const navigate = useNavigate();
  const [keywordError, setKeywordError] = useState()
  const [operatorError, setOperatorError] = useState()
  const [filterTypeError, setFilterTypeError] = useState()
  const [permissions, setPermissions] = useState(localPermits.find((o) => o.moduleName == "Orders"));
  const [widnowLocation, setWindowLocation] = useState(window.scrollY);
  const [rangeStart, setRangeStart] = useState(rangeStartD != null ? new Date(rangeStartD) : null);
  const [rangeEnd, setRangeEnd] = useState(rangeEndD != null ? new Date(rangeEndD) : null);

  const [offset, setOffset] = useState(0);

    // useEffect(() => {
    //     const onScroll = () => setOffset(window.pageYOffset);
    //     // clean up code
    //     window.removeEventListener('scroll', onScroll);
    //     window.addEventListener('scroll', onScroll, { passive: true });
    //     return () => window.removeEventListener('scroll', onScroll);
    // }, []);


  if(!state?.searchKeyword){
    let el = document.getElementById('searchKeywordId');
    if(el){
      el.value = "";
    }
  }

  const handleUrls = async () => {
    let data = [];
    if(state.searchKeyword){
      data.push( {key:'q', val:state.searchKeyword});
    }
    if(state?.startDate){
      setRangeStart(new Date(state.startDate))
      data.push({key:'startDate', val:state.startDate})
    }
    if(state?.endDate){
      setRangeEnd(new Date(state.endDate))
      data.push({key:'endDate', val:state.endDate})
    }
    if(state?.filterType){
      data.push({key:'filterType', val:state.filterType})
    }
    if(state?.subFilterType){
      data.push({key:'subFilterType', val:state.subFilterType})
    }
    if(state?.page){
      data.push({key:'pg', val:state?.page+1})
    }
    if(state.shouldSearchGlobally === true && (!state.searchKeyword && !state.startDate && !state.endDate && !state.subFilterType)){
      toast.error("Please apply filter to search globally")
    }else{
      if(state.shouldSearchGlobally === true){
        data.push({key:'global', val:1})
      }

    }
    let searchUrl = '';
      data?.map((ele) => {
        searchUrl = searchUrl + ele?.key+'='+ele?.val+'&'
      })
      let lastChar = searchUrl.charAt(searchUrl.length - 1)
      if(lastChar === '&'){
        searchUrl = searchUrl.slice(0, -1)
      }

    // history.push({search : searchUrl})
    navigate(`?${searchUrl}`, { replace: true });
  }

  useEffect(() => {
    async function getFilterList() {
      let filters = await getApiCall('/orders/filters');
      if(filters.status === 1){
        filterOpt = filters.payload;
        var x = document.getElementById("filter");
        let length = x?.length;
        while (length > 0) {
          x.remove(length);
          length = length - 1;
        }
        filterOpt.forEach(element => {
          var option = document.createElement("option");
          option.value = JSON.stringify(element);
          option.text = element.label;
          option.selected = element.value === state.filterType ? true : false;
          x?.add(option);
        });
        setLoading(false)
      }
    }
    getFilterList()
  }, [loading])

  const downloadCSV = async () => {
    let filters = [];
    if(Math.floor(new Date(state.startDate+' 00:00:00').getTime()/1000) > 0 ||
    Math.floor(new Date(state.endDate+' 23:59:59').getTime()/1000) >0){
      filters.push({param:"date-range", value:{
        start: Math.floor(new Date(state.startDate+' 00:00:00').getTime()/1000),
        end: Math.floor(new Date(state.endDate+' 23:59:59').getTime()/1000),
    }})
    }
    let f = document.getElementById('filter');
    let filterval = f?.value;
    let selectedFilterVal;
    let selectedSubFilterVal;
    if(filterval){
      selectedFilterVal = JSON.parse(filterval)?.value
    }
    let s;
    if(filterval && JSON.parse(filterval)?.type == 'default'){
      s = document.getElementById('subfilter');
      let subfilterval = s?.value;
      if(subfilterval){
        selectedSubFilterVal = JSON.parse(subfilterval)?.value;
      }
    }else{
      s = document.getElementById('subfilterTxt');
      selectedSubFilterVal = s?.value;
    }

    if(selectedFilterVal && selectedSubFilterVal){
      filters.push({param:selectedFilterVal, value:selectedSubFilterVal})
    }
    let sG = state.shouldSearchGlobally ? 1 : 2
    if(sG === 1 && permissions?.childItems?.length < 9){
      let msg = 'Your download has started. The downloaded file will contain orders in ';
      let count = 0;
      permissions?.childItems?.map((child, i) => {
        if(child?.isView === 1){
          msg =  msg + (i > 0 ? ', ': '') + child?.moduleName;
          count++;
        }
      })
      msg = msg + ' state';
      if(count > 1){
        msg = msg+'s'
      }
      toast.success(msg);
    }
    var urlPar = type;
    if (urlPar === "placed") {
      urlPar = "place";
    } else if (urlPar === "confirmed") {
      urlPar = "confirm";
    } else if (urlPar === "packaged") {
      urlPar = "ready";
    } else if (urlPar === "dispatched") {
      urlPar = "dispatch";
    } else if (urlPar === "delivered") {
      urlPar = "deliver";
    } else if (urlPar === "cancelled") {
      urlPar = "cancel";
    } else if (urlPar === "rto") {
      urlPar = "rto";
    } else if (urlPar === "lost") {
      urlPar = "lost";
    }else if (urlPar === "failed") {
      urlPar = "failed";
    }

    const response = await postAPI("/order-export-data", {
      action: urlPar,
      searchKeyword: state.searchKeyword !== "" ? state.searchKeyword : "",
      page:state.page,
        recPerPage : state.recPerPage ? state.recPerPage :50,
        startDate:state.startDate,
        endDate:state.endDate,
        searchGlobally: state.shouldSearchGlobally ? 1 : 2,
        filters: filters,
        filterType: state.filterType,
        subFilterType: state.subFilterType,
    });

    download(response, urlPar+'_'+format(new Date(), 'd-MM-yyyy kk:mm').toString()+".csv", "text/csv");
  };

  useEffect(() => {
    // clearFilter()
    changeDateRange([null,null]);
    if(!search){
      fetchOrders()
    }
  },[type])

  const fetchOrders = async (
    page = qpage,
    recPerPage,
    searchKeyword = "",
    startDate="",
    endDate="",
    filterType,
    subFilterType,
    shouldSearchGlobally = false) => {
      setState((prevState) => ({ ...prevState, entries: [], totalEntries: 0 }));
      setState((prevState) => ({ ...prevState, loading: true }));
      let filters = [];
    if(Math.floor(new Date(state.startDate+' 00:00:00').getTime()/1000) > 0 || Math.floor(new Date(state.endDate+' 23:59:59').getTime()/1000) >0){
        filters.push({param:"date-range", value:{
          start: Math.floor(new Date(state.startDate+' 00:00:00').getTime()/1000),
          end: Math.floor(new Date(state.endDate+' 23:59:59').getTime()/1000),
      }})
    }
    let f = document.getElementById('filter');
    let filterval = f?.value;
    let selectedFilterVal;
    let selectedSubFilterVal;
    if(filterval){
      selectedFilterVal = JSON.parse(filterval)?.value
    }
    let s;
    if(filterval && JSON.parse(filterval)?.type == 'default'){
      s = document.getElementById('subfilter');
      let subfilterval = s?.value;
      if(subfilterval){
        selectedSubFilterVal = JSON.parse(subfilterval)?.value;
      }
    }else{
      s = document.getElementById('subfilterTxt');
      selectedSubFilterVal = s?.value;
    }

    if(selectedFilterVal && selectedSubFilterVal){
      filters.push({param:state.filterType, value:state.subFilterType})
    }
    if(shouldSearchGlobally && (!searchKeyword && !startDate && !endDate && !subFilterType)){
      toast.error("Please apply filter to search globally")
      return false
    }

       const data = {
        page,
        recPerPage : recPerPage ? recPerPage :50,
        // action: defaultState.action,
        searchKeyword,
        startDate : state.startDate ? changeDateFormat_DMY(state?.startDate, 'dd-MM-yyyy') : null,
        endDate : state.endDate ? changeDateFormat_DMY(state?.endDate, 'dd-MM-yyyy') : null,
        filterType,
        subFilterType,
        searchGlobally: shouldSearchGlobally ? 1 : 2,
        filters: filters,
        // action : urlPar,
      };
      data.action = urlPar;
      const { status, payload, message } = await postAPI("/orders/list", data);
      setState((prevState) => ({ ...prevState, loading: false }));

      Swal.close();
      if (!status) {
        if (message !== "No Order found")
          toast.error(message || MESSAGES?.sellers?.defaultApiError);
        setState((prevState) => ({
          ...prevState,
          entries: defaultState.entries,
          totalEntries: defaultState.totalEntries,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          entries: payload?.data || defaultState.entries,
          totalEntries: payload?.totalRecords || defaultState.totalEntries,
          loading: false
        }));

      }
      // window.scrollTo(0, 0);
    };

  const updateStatus = useCallback(
    async (updatedStatus = {}, id, remarks = "") => {
      const { status, payload, message } = await postAPI(
        "/update-user-status",
        {
          id,
          type: updatedStatus.value,
          userType: "seller",
          remarks,
        }
      );

      if (!status) {
        toast.error(message || MESSAGES?.sellers?.defaultApiError);
      } else {
        fetchOrders(
          state.page,
          state.recPerPage,
          state.searchKeyword,
          state.startDate,
          state.endDate,
          state.filterType,
          state.subFilterType,
          state.shouldSearchGlobally
        );
      }
    },
    [
      state.page,
      state.recPerPage,
      state.searchKeyword,
      state.startDate,
      state.endDate,
      state.filterType,
      state.subFilterType,
      state.remarks,
      state.shouldSearchGlobally,
    ]
  );

  // without filters
  useEffect(() => {
    if(!search){
      setShowClearFilter(false)
      setState((prevState) => ({
        ...prevState, filterType: "", subFilterType: "", searchKeyword: '', startDate:"", endDate: "", page:0, showClearFilter: false, filters: [], shouldSearchGlobally: false
      }))
      clearFilter();
      if(!state.loading){
        fetchOrders(0, state.recPerPage, "", "", "", "", "", "", 2);
      }
    }
  }, [search,
    // match?.params?.type,
    state.recPerPage])

  // With Filters
  useEffect(() => {
    handleUrls()
    if(search && state.shouldSearchGlobally !== null){
      fetchOrders(
        state.page,
        state.recPerPage,
        state.searchKeyword,
        state.startDate,
        state.endDate,
        state.filterType,
        state.subFilterType,
        state.shouldSearchGlobally,
      );
    }

}, [search, state?.page]);

const clearFilter = () => {
  // setLoading(true)
  let sDate = document?.getElementById("startDate");
  let eDate = document?.getElementById("endDate");
  let sKey = document.getElementById("searchKey");
  let sFtext = document.getElementById("subfilterTxt");
  var x = document.getElementById("subfilter");
  if(x){
    let length = x.length;
    while (length > 0) {
      x.remove(length);
      length = length - 1;
    }
    var option = document.createElement("option");
    option.value = "";
    option.text = "Select";
    x.add(option);
  }
  if(sDate?.value){
    sDate.value = "";
  }
  if(eDate?.value){
    eDate.value = "";
  }
  if(sKey?.value){
    sKey.value = "";
  }
  if(sFtext?.value){
    sFtext.value = "";
  }
  setShowClearFilter(false)
  setState((prevState) => ({
    ...prevState,
    startDate: "",
    endDate: "",
    searchKeyword: "",
    filterType: "",
    subFilterType: "",
    showClearFilter:false,
    shouldSearchGlobally:false,
    filters: [],
  }));
}


  const [delivered, setDelivered] = useState(permissions?.childItems?.find((o) => o.moduleName == "Delivered"));

  const [ready, setReady] = useState(permissions?.childItems?.find((o) => o.moduleName == "Ready"));
  const [dispatch, setDispatch] = useState(permissions?.childItems?.find((o) => o.moduleName == "Dispatched"));
  const [Confirmed, setConfirmed] = useState(permissions?.childItems?.find((o) => o.moduleName == "Confirmed"));
  const [Placed, setPlaced] = useState(permissions?.childItems?.find((o) => o.moduleName == "Placed"));
  const [lost, setLost] = useState(permissions?.childItems?.find((o) => o.moduleName == "Lost"));
  const [rto, setRto] = useState(permissions?.childItems?.find((o) => o.moduleName == "Rto"));
  const [cancel, setCancel] = useState(permissions?.childItems?.find((o) => o.moduleName == "Cancelled"));
  const [failed, setFailed] = useState(permissions?.childItems?.find((o) => o.moduleName == "Failed"));
  const [showClearFilter, setShowClearFilter] = useState(false)
  // const [dateRange, setDateRange] = useState([rangeStart, rangeEnd]);
  // const [startDate, endDate] = dateRange;



  const changeFilter = async (e) => {
    let value;
    let type = 'default';
    let fType = '';
    // let fType = JSON.parse(e.target.value).value;
    if(e.target.value){
      value = JSON.parse(e.target.value).value;
      fType = JSON.parse(e.target.value).value;
      type = JSON.parse(e.target.value).type
    }
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setShowClearFilter(true)
      setState((prevState) => ({
        ...prevState,
        filterType:fType,
        showClearFilter: true,
        searchKeyword: "",
        subFilterType: "",
      }));
    })

    if(type==='default'){
      document.getElementById('subfilterTxt').style.display = "none"
      document.getElementById('subfilter').style.display = "flex"
      let data = {type:value}
      var subfilters = await postAPI('/orders/sub-filters',data);
      if(subfilters.status === 1){
        filterOpt = subfilters.payload;
        var x = document.getElementById("subfilter");
        let length = x?.length;
        while (length > 0) {
          x.remove(length);
          length = length - 1;
        }
        filterOpt.forEach(element => {
          var option = document.createElement("option");
          option.value = JSON.stringify(element);
          option.text = element.label;
          x?.add(option);
        });
        // await setSubFilterOptions(subfilters.payload);
      }
      else{
        if(subfilters.payload.length === 0){
          document.getElementById('subfilterTxt').value = ""
          var x = document.getElementById("subfilter");
          let length = x.length;
          while (length > 0) {
            x.remove(length);
            length = length - 1;
          }
        }
      }
    }else{
      document.getElementById('subfilter').style.display = "none"
      document.getElementById('subfilterTxt').style.display = "flex"
      document.getElementById('subfilterTxt').type = type ? type.split('#')[1] : 'number';
      document.getElementById('subfilterTxt').min = 0;
      document.getElementById('subfilterTxt').pattern = "[0-9]";
      document.getElementById('subfilterTxt').max = 99;
    }
  }

  const subfilterTxtHandle = (e) => {
    let value = e.target.value;
    // setShowClearFilter(true)
    setState((prevState) => ({
      ...prevState,
      showClearFilter: true,
      subFilterType: value,
    }));
  }

  useEffect(() => {
    setSecondaryHeader([renderSecondaryHeader()]);
    return () => setSecondaryHeader([]);
  }, [
    state.page,
    state.recPerPage,
    state.searchKeyword,
    state.startDate,
    state.endDate,
    state.filterType,
    state.subFilterType,
    state.shouldSearchGlobally,
    state.showClearFilter,
    type,
    showClearFilter
  ]);

  const changeDateRange = (update) => {
    let startDate = update[0] ? format(new Date(update[0]), 'MM-dd-yyyy').toString() : "";
    let endDate = update[1] ? format(new Date(update[1]), 'MM-dd-yyyy').toString() : "";

    clearTimeout(timeout);
    if(update[0] || update[1]){
      setShowClearFilter(true);
    }


    setState((prevState) => ({
        ...prevState,
        startDate: startDate,
        endDate: endDate,
        showClearFilter: true,
    }));

  }

  const resetFilter = () => {
    setShowClearFilter(false)
    setState((prevState) => ({
      ...prevState,
      startDate: "",
      endDate: "",
      searchKeyword: "",
      filterType: "",
      subFilterType: "",
      showClearFilter:false,
      shouldSearchGlobally:false,
      filters: [],
    }));
    changeDateRange([null,null]);
    document.getElementById("filter").value = "";
    document.getElementById("subfilter").value = "";
    document.getElementById("searchKey").value = "";
    document.getElementById("subfilterTxt").value = "";
    // history.replace({ pathname: history.location.pathname})
    navigate(location.pathname, { replace: true });
  }
  const renderSecondaryHeader = () => {

    return (
      <></>
    );
  };

  const downloadCSVPermissions = () => {
    if (urlPar === 'place') {
      if(Placed?.isDownload === 0){
        return true;
      }else{
        return false;
      }
    }else if (urlPar === 'confirm') {
      if(Confirmed?.isDownload === 0){
        return true;
      }else{
        return false;
      }
    }else if (urlPar === 'ready') {
      if(ready?.isDownload === 0){
        return true;
      }else{
        return false;
      }
    }else if (urlPar === 'dispatch') {
      if(dispatch?.isDownload === 0){
        return true;
      }else{
        return false;
      }
    }else if (urlPar === 'deliver') {
      if(delivered?.isDownload === 0){
        return true;
      }else{
        return false;
      }
    }else if (urlPar === 'lost') {
      if(lost?.isDownload === 0){
        return true;
      }else{
        return false;
      }
    }else if (urlPar === 'rto') {
      if(rto?.isDownload === 0){
        return true;
      }else{
        return false;
      }
    }else if (urlPar === 'cancel') {
      if(cancel?.isDownload === 0){
        return true;
      }else{
        return false;
      }
    }else if (urlPar === 'failed') {
      if(failed?.isDownload === 0){
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }

    };

  const test = (e) =>{
    // let a = document.getElementById("dateRange");
    // document.getElementById("filterSubmitBtnRef").focus();
  }

  // useEffect(() => {
  //   try {
  //     if (searchRef.current) {
  //       setState((prevState) => ({ ...prevState, searchKeyword: "" }));
  //       setState((prevState) => ({
  //         ...prevState,
  //         shouldSearchGlobally: false,
  //       }));
  //       setState((prevState) => ({ ...prevState, page: 0 }));
  //       searchRef.current.value = "";
  //       fetchOrders();
  //     }
  //   } catch (er) {
  //   }
  // }, []);

  // if (state.loading) {
  //   let timerInterval;
  //   Swal.fire({
  //     title: "Loading...",
  //     timerProgressBar: true,
  //     allowOutsideClick: false,
  //     didOpen: () => {
  //       Swal.showLoading();
  //       timerInterval = setInterval(() => {
  //         const content = Swal.getContent();
  //         if (content) {
  //           const b = content.querySelector("b");
  //           if (b) {
  //             b.textContent = Swal.getTimerLeft();
  //           }
  //         }
  //       }, 100);
  //     },
  //     willClose: () => {
  //       clearInterval(timerInterval);
  //     },
  //   }).then((result) => {
  //     if (result.dismiss === Swal.DismissReason.timer) {

  //     }
  //   });
  // }

  const updateOrderStatus = async ({orderId, confirmBtnLabel, payloadData = null}) => {
    let dateOfDelivery = payloadData?.dateOfDelivery;
    let refundDestination;
    if(payloadData?.action === 'cancel'){
      refundDestination = payloadData?.refundDestination;
      payloadData = {reason:payloadData?.reason}

    }
    if(dateOfDelivery){
      delete payloadData["dateOfDelivery"];
      payloadData = payloadData
    }
      const { status, payload, message } = await putAPI("/orders/update-status", { action: confirmBtnLabel, orderId: orderId, dateOfDelivery: dateOfDelivery, refundDestination:refundDestination, payloadData });

      if (!status) {
        toast.error(message || MESSAGES?.products?.defaultApiError);
      } else {
        if(confirmBtnLabel === 'dispatch' || confirmBtnLabel === 'deliveredToHub' || confirmBtnLabel === 'deliver'){
           if(payloadData && payloadData.pod){
            await postAPI('/orders/pod/add-details/', payloadData?.pod)
           }
           if(payloadData && payloadData.poc){
            await postAPI('/orders/poc/add-details/', payloadData?.poc);
           }
        }
        toast.success(message);
        fetchOrders(
          state.page,
          state.recPerPage,
          state.searchKeyword,
          state.startDate,
          state.endDate,
          state.filterType,
          state.subFilterType,
          state.shouldSearchGlobally,
        );
      }

    };

  const downloadInvoice = useCallback(async (type, orderId) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      var url;
      if(type === 0){
        url = "/orders/invoice/buyer-order"
      }else if(type === 1){
        url = "/orders/invoice/showroom";
      }
      else if(type === 2){
        url = "/orders/seller-custom-invoice-url";
      }
      const { status, payload, message } = await postAPI(url, {
        orderId: orderId,
      });
      Swal.close();
      setState((prevState) => ({ ...prevState, loading: false }));

      if (!status) {
        toast.error(message || MESSAGES?.products?.defaultApiError);
      } else {
        // window.open(payload.url, '_blank');
        toast.success(message);
      }
    } catch (er) {

    }
  }, []);

  const biltiUpdate = (pos) => {
    // const specificPosition = 500; // Replace with the desired vertical position
    setWindowLocation(pos)
    fetchOrders(
      state.page,
      state.recPerPage,
      state.searchKeyword,
      state.startDate,
      state.endDate,
      state.filterType,
      state.subFilterType,
      state.shouldSearchGlobally,
    );
    window.scrollTo(0, pos);
  }

  const handlePageChange = (newPage) => {
    // setPage(newPage);
    setState((prevState) => ({
      ...prevState,
      page: newPage
    }));
    // Fetch new data based on newPage and recPerPage
  };

  const handleRecPerPageChange = (newRecPerPage) => {
    // setRecPerPage(newRecPerPage);
    setState((prevState) => ({
      ...prevState,
      recPerPage: parseInt(newRecPerPage),
    }));
    // Fetch new data based on newRecPerPage and page
  };

  const handlePagination = (pgInfo) => {
    setState((prevState) => ({
      ...prevState,
      recPerPage: parseInt(pgInfo?.recPerPage),
      page: pgInfo?.page
    }));
  }
  return (
    <>
    <CHeader>
      <div style={{width:"100%"}}>
      <CRow>
        <CCol>
          <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
            <form
              id="filter-form"
              className="d-flex flex-column flex-sm-row align-items-center flex-wrap w-100 searchForm"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  setState((prevState) => ({
                    ...prevState,
                    page: 0,
                  }));
                  e.preventDefault();
                  handleUrls();
                }
              }}
            >
              <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-2">
                {/* {state.startDate ? new Date(state.startDate) : "no"}-{state.endDate} */}
                <DatePicker
                  className="form-control"
                  id="dateRange"
                  maxDate={new Date()}
                  dateFormat="dd-MM-yyyy"
                  selectsRange={true}
                  onCalendarClose={() => test()}
                  startDate={state.startDate ? new Date(state.startDate) : null}
                  endDate={state.endDate ? new Date(state?.endDate) : ""}
                  autoComplete="nope"
                  onChange={(update) => changeDateRange(update)}
                  placeholderText="Select Date"
                  showPreviousMonths
                  monthsShown={1}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  form="filter-form"
                />

                {/* <DatePicker
                className="form-control"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                /> */}
              </div>

              <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-2">
                <select
                  id="filter"
                  className="form-control"
                  onChange={changeFilter}
                >
                  <option value="">Filters</option>
                  {filterOptions?.map((filter_opt) => (
                    <option key={filter_opt.value} value={JSON.stringify(filter_opt)}>
                      {filter_opt.value}
                    </option>
                  ))}
                </select>
              </div>

              <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-2">
                <select
                  className="form-control"
                  id="subfilter"
                  onChange={(e) => {
                    let value;
                    if (e.target.value) {
                      value = JSON.parse(e.target.value)?.value;
                    }
                    setShowClearFilter(true);
                    setState((prevState) => ({
                      ...prevState,
                      showClearFilter: true,
                      subFilterType: value,
                    }));
                  }}
                >
                  <option value="">Sub Filters</option>
                  {subFilterOptions?.map((subfilter) => (
                    <option key={subfilter.value} value={JSON.stringify(subfilter)}>
                      {subfilter.label}
                    </option>
                  ))}
                </select>

                <input
            className="form-control ms-2"
            id="subfilterTxt"
            style={{display:"none"}}
            pattern="/[0-9]"
            minValue={0}
            maxValue={99}
            onBlur={(e) => {
              if(e.target.value < 0){
                document.getElementById('subfilterTxt').value = 0
              }
              if(e.target.value > 99){
                document.getElementById('subfilterTxt').value = 99
              }
            }}
            onChange={(e) => subfilterTxtHandle(e)}
           />
              </div>

              <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-2">
                <input
                  className="form-control"
                  placeholder="Name, Email, Order ID, Mobile"
                  id="searchKey"
                  defaultValue={state?.searchKeyword}
                  onChange={(evt) => {
                    const { value } = evt.currentTarget;
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                      setShowClearFilter(true);
                      setState((prevState) => ({
                        ...prevState,
                        searchKeyword: value.trim(),
                        showClearFilter: true,
                      }));
                    });
                  }}
                />
              </div>

              <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-2">
                <Checkbox
                  label="Search Globally"
                  value={state.shouldSearchGlobally}
                  checked={state.shouldSearchGlobally}
                  id="searchGlobally"
                  className="ms-4 searchGloballyText"
                  onChange={(evt) => {
                    setShowClearFilter(true);
                    setState((prevState) => ({
                      ...prevState,
                      shouldSearchGlobally: !prevState.shouldSearchGlobally,
                      showClearFilter: true,
                    }));
                  }}
                />
                {permissions?.childItems?.length < 9 && (
                  <>
                    &nbsp;
                    <Popup
                      trigger={<FontAwesomeIcon icon={faInfoCircle} style={{ cursor: "pointer", marginBottom: "0.4rem", fontSize: "16px" }} />}
                      content={
                        <>
                          <div>Global search will work only on the following order statuses:</div>
                          <div>
                            {permissions?.childItems?.map((child, i) =>
                              child?.isView === 1 ? (
                                <span key={i}><i>{i > 0 ? ', ' : ''}{child?.moduleName}</i></span>
                              ) : null
                            )}
                          </div>
                        </>
                      }
                      position="bottom center"
                    />
                  </>
                )}
              </div>

              <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-2">
                <CButton
                  color="primary"
                  className="ms-1"
                  onClick={() => {
                    setState((prevState) => ({
                      ...prevState,
                      page: 0,
                    }));
                    handleUrls();
                    fetchOrders(
                      state.page,
                      state.recPerPage,
                      state.searchKeyword,
                      state.startDate,
                      state.endDate,
                      state.filterType,
                      state.subFilterType,
                      state.shouldSearchGlobally
                    );
                  }}
                >
                  Go
                </CButton>
              </div>

              {showClearFilter && (
                <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-2">
                  <CButton
                    color="danger"
                    className="ms-2"
                    onClick={resetFilter}
                  >
                    Clear
                  </CButton>
                </div>
              )}
            </form>

            {downloadCSVPermissions() === false && (
              <div className="d-flex align-items-center justify-content-end mt-3 mt-md-0">
                <button
                  color="blue"
                  onClick={downloadCSV}
                  title="Export CSV"
                  className="btn btn-info"
                >
                  <FontAwesomeIcon icon={faDownload} style={{ cursor: "pointer" }} />
                </button>
              </div>
            )}
          </div>
        </CCol>
      </CRow>
      </div>
    </CHeader>
      <CRow>
        <CCol lg={12}>
        {!!state.entries.length && (
            <Pagination handlePagination={handlePagination} state={state} />
          )}
          {state.loading && (<>
            <center>Loading...</center>
          </>)}
          {!state.loading && !state.entries.length ? (
            <>
              <div className="d-flex flex-column align-items-center">

                <h4>No Order(s) found</h4>
              </div>
            </>
          ) : (
            (state.entries || []).map((entry) => (

              <OrderPage
                entry={entry}
                section={entry.currentStatus}
                onStatusChange={(updatedStatus = {}, id, action, entryName, pod, poc, payloadData,
                ) => {
                  updateOrderStatus(updatedStatus, id, action, entryName, pod, poc, payloadData);
                }}
                downlaodInvoice={(type = 0, id, e) => {
                  downloadInvoice(type, id);
                }}
                updateBilti={(pos) => biltiUpdate(pos)}
                widnowLocation={offset}
              />
            ))
          )}

          {!!state.entries.length && (
            <Pagination handlePagination={handlePagination} state={state} />
          )}
        </CCol>
      </CRow>

      <CModal
        alignment="center"
        show={!!state.confirmDialog}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            confirmDialog: null,
          }));
        }}
      >
        <CModalHeader>
          <CModalTitle>{`${state.confirmDialog?.action} - ${state.confirmDialog?.entryName}`}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>Are you sure you want to continue?</div>
          {(state?.confirmDialog?.updatedStatus?.value === "rejected" ||
            state?.confirmDialog?.updatedStatus?.value === "blocked") && (
            <>
              {" "}
              <CFormTextarea
                size="lg"
                value={state.confirmDialog?.remarks || ""}
                className="mt-3 mb-3"
                maxLength={255}
                placeholder="Reason"
                onChange={(evt) => {
                  const { value } = evt.currentTarget;
                  setState((prevState) => ({
                    ...prevState,
                    confirmDialog: {
                      ...prevState.confirmDialog,
                      remarks: value || "",
                    },
                  }));
                }}
              />
              <CFormText color="link" className="help-block mb-1 validation">
                {state.confirmDialog?.remarks?.length > 254 &&
                  "Max length: 255"}
              </CFormText>
            </>
          )}
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                confirmDialog: null,
              }));
            }}
          >
            Cancel
          </CButton>
          {state.confirmDialog?.remarks &&
            state?.confirmDialog?.updatedStatus?.value === "rejected" && (
              <CButton
                color="primary"
                onClick={() => {
                  updateStatus(
                    state.confirmDialog?.updatedStatus,
                    state.confirmDialog?.id,
                    state.confirmDialog?.remarks
                  );
                  state.confirmDialog.callback();
                  setState((prevState) => ({
                    ...prevState,
                    confirmDialog: null,
                  }));
                }}
              >
                Ok
              </CButton>
            )}
          {state?.confirmDialog?.updatedStatus?.value !== "rejected" && (
            <CButton
              color="primary"
              onClick={() => {
                updateStatus(
                  state.confirmDialog?.updatedStatus,
                  state.confirmDialog?.id,
                  state.confirmDialog?.remarks
                );
                state.confirmDialog.callback();
                setState((prevState) => ({
                  ...prevState,
                  confirmDialog: null,
                }));
              }}
            >
              Ok
            </CButton>
          )}
        </CModalFooter>
      </CModal>
    </>
  );
};

export default React.memo(Orders);
