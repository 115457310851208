export const setTokenData = (payload = {}) => {
  localStorage.setItem("TOKEN", payload?.token);
  localStorage.setItem("email", payload?.email);
  localStorage.setItem("fullName", payload?.fullname);
  localStorage.setItem("mobile", payload?.mobile);
  localStorage.setItem("roleId", payload?.roleId);
};

export const getTokenData = () => {
  const TOKEN = localStorage.getItem("TOKEN");
  const email = localStorage.getItem("email");
  const fullName = localStorage.getItem("fullName");
  const mobile = localStorage.getItem("mobile");
  const roleId = localStorage.getItem("mobiroleIdle");
  return {
    TOKEN,
    email,
    fullName,
    mobile,
    roleId
  };
};

export const removeTokenData = () => {
  localStorage.removeItem("TOKEN");
  localStorage.removeItem("email");
  localStorage.removeItem("fullName");
  localStorage.removeItem("mobile");
  localStorage.removeItem("module");
  localStorage.removeItem("roleId");
};

// const EXPIRE_TIME = 24 * 1000 * 60 * 60;
// setTimeout(function () {
//   localStorage.removeItem("TOKEN");
// }, EXPIRE_TIME);
