import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBold, faItalic, faUnderline, faListOl, faListUl, faTable } from '@fortawesome/free-solid-svg-icons';
import './contentEditable.css'; // Import CSS for basic styling

const ContentEditable = (props) => {
  const [text, setText] = useState('');
  const [showTableGrid, setShowTableGrid] = useState(false);
  const [tableRows, setTableRows] = useState(0);
  const [tableCols, setTableCols] = useState(0);
  const editorRef = useRef(null);
  const tableGridRef = useRef(null);
  const savedRangeRef = useRef(null); // Reference to store the selection range
  const shiftEnterCount = useRef(0);

  const handleInput = (e) => {
    setText(e.currentTarget.innerHTML);
  };

  const handleSubmitByClick = () => {
    props.onClickSubmit(text);
    editorRef.current.innerHTML = '';
    setText('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      shiftEnterCount.current += 1;
      setTimeout(() => {
        if (shiftEnterCount.current === 1) {
          const selection = window.getSelection();
          let anchorNode = selection.anchorNode;
          // Traverse up to the nearest element node
          while (anchorNode && anchorNode.nodeType !== Node.ELEMENT_NODE) {
            anchorNode = anchorNode.parentNode;
          }
          const isInsideOrderedList = anchorNode && anchorNode.closest('ol');
          const isInsideUnorderedList = anchorNode && anchorNode.closest('ul');
          if (isInsideOrderedList) {
            document.execCommand('insertParagraph', false, null);
            document.execCommand('insertOrderedList');
            document.execCommand('insertOrderedList');
          } else if (isInsideUnorderedList) {
            document.execCommand('insertParagraph', false, null);
            document.execCommand('insertUnorderedList');
            document.execCommand('insertUnorderedList');
          } else {
            // document.execCommand('insertLineBreak');
            document.execCommand('insertParagraph', false, null);
          }
        } else if (shiftEnterCount.current === 2) {
          document.execCommand('insertParagraph', false, null);
        }
        shiftEnterCount.current = 0;
      }, 200);
    } else if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (props.enterSubmit) {
        handleSubmitByClick();
      }
    } else if (e.key === 'Tab') {
      e.preventDefault();
      handleTabKey(e);
    }
  };

  const handleTabKey = (e) => {
    const selection = window.getSelection();
    let anchorNode = selection.anchorNode;

    // Traverse up to the nearest element node
    while (anchorNode && anchorNode.nodeType !== Node.ELEMENT_NODE) {
      anchorNode = anchorNode.parentNode;
    }

    if (anchorNode && anchorNode.tagName === 'TD') {
      const cell = anchorNode;
      const row = cell.parentNode;
      const table = row.parentNode;
      let nextCell = cell.nextElementSibling;

      if (nextCell) {
        moveCursorToElement(nextCell);
      } else {
        const nextRow = row.nextElementSibling;
        if (nextRow) {
          moveCursorToElement(nextRow.cells[0]);
        } else {
          // Add a new row if at the last cell
          const newRow = row.cloneNode(true);
          newRow.querySelectorAll('td').forEach(td => td.innerHTML = '&nbsp;');
          table.appendChild(newRow);
          moveCursorToElement(newRow.cells[0]);
        }
      }
    }
  };

  const moveCursorToElement = (element) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
    element.focus();
  };

  const cleanHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');

    const tables = doc.querySelectorAll('table');
    tables.forEach((table) => {
      table.style.width = '100%';
      table.style.borderCollapse = 'collapse';
      table.querySelectorAll('td, th').forEach((cell) => {
        cell.style.border = '1px solid #ccc';
        cell.style.padding = '8px';
      });
    });

    return doc.body.innerHTML;
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('text/html') || clipboardData.getData('text/plain');
    const cleanedData = cleanHTML(pastedData);
    document.execCommand('insertHTML', false, cleanedData);
  };

  const handleFormat = (command, value = null) => {
    document.execCommand(command, false, value);
  };

  const handleInsertTableClick = () => {
    // Save the current selection range
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      savedRangeRef.current = selection.getRangeAt(0);
    }
    setShowTableGrid(!showTableGrid);
  };

  const handleMouseOverCell = (row, col) => {
    setTableRows(row + 1);
    setTableCols(col + 1);
  };

  const handleInsertTable = () => {
    if (tableRows > 0 && tableCols > 0) {
      let table = '<table style="width:100%;border-collapse:collapse;">';
      for (let i = 0; i < tableRows; i++) {
        table += '<tr>';
        for (let j = 0; j < tableCols; j++) {
          table += '<td style="border:1px solid #ccc;padding:8px;">&nbsp;</td>';
        }
        table += '</tr>';
      }
      table += '</table>';

      if (editorRef.current) {
        editorRef.current.focus();

        // Restore the selection range
        const selection = window.getSelection();
        if (savedRangeRef.current) {
          selection.removeAllRanges();
          selection.addRange(savedRangeRef.current);

          // Insert the table at the saved selection range
          const range = selection.getRangeAt(0);
          const tableNode = range.createContextualFragment(table);
          const lastNode = tableNode.lastChild;
          range.deleteContents(); // Ensure contents at the range are cleared before insertion
          range.insertNode(tableNode);

          // Move the cursor after the inserted table
          const newRange = document.createRange();
          newRange.setStartAfter(lastNode);
          newRange.setEndAfter(lastNode);
          selection.removeAllRanges();
          selection.addRange(newRange);
        }
      }

      setShowTableGrid(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tableGridRef.current && !tableGridRef.current.contains(event.target)) {
        setShowTableGrid(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="toolbar">
        <button onClick={() => handleFormat('bold')}><FontAwesomeIcon icon={faBold} className="fa-icon" /></button>
        <button onClick={() => handleFormat('italic')}><FontAwesomeIcon icon={faItalic} className="fa-icon" /></button>
        <button onClick={() => handleFormat('underline')}><FontAwesomeIcon icon={faUnderline} className="fa-icon" /></button>
        <button onClick={() => handleFormat('insertOrderedList')}><FontAwesomeIcon icon={faListOl} className="fa-icon" /></button>
        <button onClick={() => handleFormat('insertUnorderedList')}><FontAwesomeIcon icon={faListUl} className="fa-icon" /></button>
        <button onClick={handleInsertTableClick}><FontAwesomeIcon icon={faTable} className="fa-icon" /></button>
        <button disabled={text === ''} onClick={handleSubmitByClick} className={text === '' ? 'ui button' : 'ui blue button'} style={{ float: "right", padding: "inherit" }}> Submit </button>
      </div>
      {showTableGrid && (
        <div ref={tableGridRef} className="table-grid" style={{ top: '40px', left: '10px', zIndex:"100" }}>
          {[...Array(10)].map((_, row) => (
            [...Array(10)].map((_, col) => (
              <div
                key={`${row}-${col}`}
                className={`cell${row < tableRows && col < tableCols ? ' selected' : ''}`}
                onMouseOver={() => handleMouseOverCell(row, col)}
                onClick={handleInsertTable}
              />
            ))
          ))}
        </div>
      )}
      <div
        contentEditable
        ref={editorRef}
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        className="editable"
        data-placeholder="Type your remarks here..."
        style={{ border: '1px solid #ccc', padding: '10px', minHeight: '50px' }}
      ></div>
      <div style={{textAlign:"right"}}><strong>Shift + Enter </strong>to add new line</div>
    </div>
  );
};

export default ContentEditable;
