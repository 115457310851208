import React, { useMemo } from 'react';
import {
  CCard,
  CCardBody,
  CCardText,
} from '@coreui/react';
import NoDetails from './NoDetails';
import { Accordion, Label } from 'semantic-ui-react';

const Level1Content = ({
  details = []
}) => {
  const panels = useMemo(() => {
    return (details || []).map(detail => {
      if (detail.child) {
        return { key: 'panel-1', title: { content: <Label style={{background: '#fff'}} content={(
          <div className='d-flex align-items-center'>
            {detail?.image ? (
              <img src={detail?.image} style={{ width: 25, height: 25, borderRadius: 25 }} />
            ) : (
              <div style={{ width: 25, height: 25, borderRadius: 20, background: '#39f', color: '#fff' }} className='d-flex align-items-center justify-content-center'>
              <h5>{(detail?.name || ' ')[0].toUpperCase()}</h5>
            </div>
            )}
            <h4 className='m-0 p-0 ms-2'>{detail?.name}</h4>
          </div>
        )} />}, content: { content: <Level2Content details={detail?.child} /> } }
      } else {
        return { key: 'panel-1', content: (
          <div className='d-flex align-items-center p-2 ms-2 me-2'>
            {detail?.image ? (
              <img src={detail?.image} style={{ width: 25, height: 25, borderRadius: 25 }} />
            ) : (
              <div style={{ width: 25, height: 25, borderRadius: 20, background: '#39f', color: '#fff' }} className='d-flex align-items-center justify-content-center'>
              <h5>{(detail?.name || ' ')[0].toUpperCase()}</h5>
            </div>
            )}
            <h4 className='m-0 p-0 ms-2'>{detail?.name}</h4>
          </div>
        )}
      }
    })
  }, [details]);
  return (
    <div>
      <Accordion.Accordion panels={panels} />
    </div>
  )
};

const Level2Content = ({
  details = []
}) => {
  const panels = useMemo(() => {
    return (details || []).map(detail => {
      if (detail.child) {
        return { key: 'panel-1', title: { content: <Label style={{background: '#fff'}} content={(
          <div className='d-flex align-items-center'>
            {detail?.image ? (
              <img src={detail?.image} style={{ width: 25, height: 25, borderRadius: 25 }} />
            ) : (
              <div style={{ width: 25, height: 25, borderRadius: 20, background: '#39f', color: '#fff' }} className='d-flex align-items-center justify-content-center'>
              <h5>{(detail?.name || ' ')[0].toUpperCase()}</h5>
            </div>
            )}
            <h4 className='m-0 p-0 ms-2'>{detail?.name}</h4>
          </div>
        )} />}, content: { content: <Level3Content details={detail?.child} /> } }
      } else {
        return { key: 'panel-2', content: (
          <div className='d-flex align-items-center p-2'>
            {detail?.image ? (
              <img src={detail?.image} style={{ width: 25, height: 25, borderRadius: 25 }} />
            ) : (
              <div style={{ width: 25, height: 25, borderRadius: 20, background: '#39f', color: '#fff' }} className='d-flex align-items-center justify-content-center'>
              <h5>{(detail?.name || ' ')[0].toUpperCase()}</h5>
            </div>
            )}
            <h4 className='m-0 p-0 ms-2'>{detail?.name}</h4>
          </div>
        )}
      }
    })
  }, [details]);
  return (
    <div>
      <Accordion.Accordion panels={panels} />
    </div>
  )
};

const Level3Content = ({
  details = []
}) => {
  const panels = useMemo(() => {
    return (details || []).map(detail => {
      if (detail.child) {
        return { key: 'panel-1', title: { content: <Label style={{background: '#fff'}} content={(
          <div className='d-flex align-items-center'>
            {detail?.image ? (
              <img src={detail?.image} style={{ width: 25, height: 25, borderRadius: 25 }} />
            ) : (
              <div style={{ width: 25, height: 25, borderRadius: 20, background: '#39f', color: '#fff' }} className='d-flex align-items-center justify-content-center'>
              <h5>{(detail?.name || ' ')[0].toUpperCase()}</h5>
            </div>
            )}
            <h4 className='m-0 p-0 ms-2'>{detail?.name}</h4>
          </div>
        )} />}, content: { content: <Level4Content details={detail?.child} /> } }
      } else {
        return { key: 'panel-3', content: (
          <div className='d-flex align-items-center p-2'>
            {detail?.image ? (
              <img src={detail?.image} style={{ width: 25, height: 25, borderRadius: 25 }} />
            ) : (
              <div style={{ width: 25, height: 25, borderRadius: 20, background: '#39f', color: '#fff' }} className='d-flex align-items-center justify-content-center'>
              <h5>{(detail?.name || ' ')[0].toUpperCase()}</h5>
            </div>
            )}
            <h4 className='m-0 p-0 ms-2'>{detail?.name}</h4>
          </div>
        )}
      }
    })
  }, [details]);
  return (
    <div>
      <Accordion.Accordion panels={panels} className='d-flex align-items-center' />
    </div>
  )
};

const Level4Content = ({
  details = []
}) => {
  const panels = useMemo(() => {
    return (details || []).map(detail => {
      if (detail.child) {
        return { key: 'panel-1', title: { content: <Label style={{background: '#fff'}} content={(
          <div className='d-flex align-items-center'>
            {detail?.image ? (
              <img src={detail?.image} style={{ width: 25, height: 25, borderRadius: 25 }} />
            ) : (
              <div style={{ width: 25, height: 25, borderRadius: 20, background: '#39f', color: '#fff' }} className='d-flex align-items-center justify-content-center'>
              <h5>{(detail?.name || ' ')[0].toUpperCase()}</h5>
            </div>
            )}
            <h4 className='m-0 p-0 ms-2'>{detail?.name}</h4>
          </div>
        )} />}, content: { content: <Level4Content details={detail?.child} /> } }
      } else {
        return { key: 'panel-4', content: (
          <div className='d-flex align-items-center p-2'>
            {detail?.image ? (
              <img src={detail?.image} style={{ width: 25, height: 25, borderRadius: 25 }} />
            ) : (
              <div style={{ width: 25, height: 25, borderRadius: 20, background: '#39f', color: '#fff' }} className='d-flex align-items-center justify-content-center'>
              <h5>{(detail?.name || ' ')[0].toUpperCase()}</h5>
            </div>
            )}
            <h4 className='m-0 p-0 ms-2'>{detail?.name}</h4>
          </div>
        )}
      }
    })
  }, [details]);
  return (
    <div>
      <Accordion.Accordion panels={panels}  className='d-flex align-items-center'/>
    </div>
  )
};

const Category = ({
  details = null,
  isLoading = false,
}) => {

  const panels = useMemo(() => {
    return (details || []).map(detail => {
      if (detail.child) {
        return { key: 'panel-1', title: { content: <Label style={{background: '#fff'}} content={(
          <div className='d-flex align-items-center'>
            {detail?.image ? (
              <img src={detail?.image} style={{ width: 25, height: 25, borderRadius: 25 }} />
            ) : (
              <div style={{ width: 25, height: 25, borderRadius: 20, background: '#39f', color: '#fff' }} className='d-flex align-items-center justify-content-center'>
              <h5>{(detail?.name || ' ')[0].toUpperCase()}</h5>
            </div>
            )}
            <h4 className='m-0 p-0 ms-2'>{detail?.name}</h4>
          </div>
        )} />}, content: { content: <Level1Content details={detail?.child} /> } }
      } else {
        return { key: 'panel-1', content: (
          <div className='d-flex align-items-center p-2'>
            {detail?.image ? (
              <img src={detail?.image} style={{ width: 25, height: 25, borderRadius: 25 }} />
            ) : (
              <div style={{ width: 25, height: 25, borderRadius: 20, background: '#39f', color: '#fff' }} className='d-flex align-items-center justify-content-center'>
              <h5>{(detail?.name || ' ')[0].toUpperCase()}</h5>
            </div>
            )}
            <h4 className='m-0 p-0 ms-2'>{detail?.name}</h4>
          </div>
        )}
      }
    })
  }, [details]);
  // const rootPanels = (details || []).map();
  return (
    <div className='d-flex'>
      <div className='w-100'>
        {!details ? (
          <NoDetails isLoading={isLoading} tabName='Category' />
        ) : (
            <Accordion
              defaultActiveIndex={0}
              panels={panels}
              styled
          />
          )
        }
      </div>
    </div>
  )
};

export default Category;
