import React, { useState, useEffect, useCallback, useContext } from 'react';
import { AppContext } from '../../contexts/app';
import CategoryGrids from './CategoryGrids';
import { toast } from 'react-toastify';
import { postAPI,uploadImageAPI, uploadImageAPIb2b } from '../../apiServices';
import MESSAGES from '../../message.json';
import { useNavigate, Link } from 'react-router-dom'
import {
  CCard,
  CCardBody,
} from '@coreui/react';
import { Menu, Table, Icon, Form, Button } from 'semantic-ui-react'
import Swal from 'sweetalert2';
let timeout = null;
const defaultState = {
  entries: [],
  totalEntries: 0,
  page: 0,
  recPerPage: 50,
  searchKeyword: '',
  loading: true,
  slugType: 'category-level-1'
};

const CategoryAdd = () => {
  const { setSecondaryHeader } = useContext(AppContext);
  const [state, setState] = useState(defaultState);
  const [imagefilename, setimagefilename] = useState();
  const [termtype, setTermType] = useState("category");
  const [termname, setTermName] = useState();
  const [parent, setparent] = useState();
  // const [parent, setparent] = useState(router.location.parentId?.categoryId);
  const [description, setDescription] = useState();
  const [message, setmessage] = useState();
  const [uploadImage, setuploadImage] = useState();

  const [isparent, setisparent] = useState(true);

  const fetchProducts = useCallback(async (page = 0, recPerPage = recPerPage, searchKeyword = '', slugType = slugType, parent = '') => {
    setState(prevState => ({
      ...prevState,
      loading: true,
    }));
    const { status, payload, message } = await postAPI('/get-all-categories', {

    });

    setState(prevState => ({
      ...prevState,
      loading: false,
    }));
    Swal.close()

    if (!status) {


      if (message !== 'No product found') toast.error(message || MESSAGES?.sellers?.defaultApiError);
      setState(prevState => ({
        ...prevState,
        entries: defaultState.entries,
        totalEntries: defaultState.totalEntries,
      }))

    } else {


      setState(prevState => ({


        ...prevState,
        entries: payload.categories || defaultState.entries,
        totalEntries: payload?.totRecords || defaultState.totalEntries,
      }));
    }
  }, []);

  useEffect(() => {
    fetchProducts(
      state.page,
      state.recPerPage,
      state.searchKeyword,
      state.slugType
    );

  }, [
    fetchProducts,
    state.page,
    state.recPerPage,
    state.slugType
  ]);
  if (state.loading) {
    let timerInterval;
    Swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          // const content = Swal.getContent();
          // if (content) {
          //   const b = content.querySelector("b");
          //   if (b) {
          //     b.textContent = Swal.getTimerLeft();
          //   }
          // }
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
      }
    });
  }
  const fileChangedHandler = (event) => {

    setuploadImage(event)
  }


  const createCategory = async () => {
    var payloadimg = '';
    var imagePath = '';
    if (uploadImage != undefined) {
      payloadimg = await uploadImageAPIb2b('/uploads', uploadImage)
      imagePath = payloadimg.url[0];
    }

      const po = {
      type: termtype,
      parent: parent,
      termName: termname,
      leadImg: imagePath,
      status: 1
    }

     const { status, payload, message } = await postAPI('/save-category-data', po, {
    });
    if (status == 1) {
    toast.success(message)

    } else {
      toast.error(message)
    }
  };

  let categoryarryay = [];
  categoryarryay.push(<option >Select</option>)
  let optionItems = state.entries.map((child) => {
    categoryarryay.push(<option key={child.id} value={child.id}>{child.name}</option>)

    child.child.map((child1) => {
      categoryarryay.push(<option key={child1.id} value={child1.id}>--{child1.name}</option>)

      if (child.name!="Home Furnishing")
      {
        child1.child.map((child2) => {

          categoryarryay.push(<option key={child2.id} value={child2.id}>-----{child2.name}</option>)
          /* child2.child.map((child3) => {
            categoryarryay.push(<option key={child3.id} value={child3.id}>--------{child3.name}</option>)

          }); */
        });

      }




    });
  });


  return (
    <CCard>
      <CCardBody>
        <div style={{
          alignItems: "center", display: "flex",
          justifyContent: "center",
        }} ><h3>{message}</h3></div>
        <Form>
          <Form.Field>
            <span><h5 style={{ marginBottom: '10px' }}>Image</h5></span>
            <input type="file"  onChange={e => fileChangedHandler (e.target.files)} className="inputfile" id="embedpollfileinput" />

          </Form.Field>
          <Form.Field>

            <span><h5 style={{ marginBottom: '10px' }}>Term Name</h5></span>
            <input type='text' placeholder='Term name' onChange={e => setTermName(e.target.value)} />

          </Form.Field>
          <Form.Field>

            <span><h5 style={{ marginBottom: '10px' }}>Term Type</h5></span>
            <select onChange={e => setTermType(e.target.value)}>
              <option value="category">Category </option>
            </select>
          </Form.Field>
          {isparent ? <Form.Field>

            <span><h5 style={{ marginBottom: '10px' }}>Parent</h5></span>
            <select
              onChange={(e) => setparent(e.target.value)} >
              {categoryarryay}
            </select>


          </Form.Field>
            : null
          }

          <Form.Field>

          <span><h5 style={{ marginBottom: '10px' }}>Description</h5></span>
          <textarea onChange={(e) => setDescription(e.target.value)}></textarea>
          </Form.Field>



          <div style={{ float: 'right' }}>
            <Button primary onClick={createCategory}>Submit</Button>
          </div></Form>
      </CCardBody>
    </CCard>
  )
}

export default CategoryAdd
