import React, { useCallback, useEffect, useMemo } from "react";
import { Avatar } from "../../common-components";
import {
  Select,
  Menu,
  Table,
  Icon,
  Form,
  Dropdown,
  Button,
} from "semantic-ui-react";
import { CButton, CBadge, CTabs } from "@coreui/react";
import { MultiSelect } from "react-multi-select-component";
import { postAPI } from "../../apiServices";
import Swal from "sweetalert2";

const PRODUCT_TYPE = {
  1: { label: "COLOR VARIANTS", color: "success" },
  2: { label: "SETS VARIANTS", color: "warning" },
};
const noop = () => {};
const ProductDetailsUpdate = ({
  productUpdate = {},
  onUpdateProduct = noop,
}) => {
  // debugger

  const [fullName, setFullName] = React.useState("");
  const [shortName, setShortName] = React.useState("");
  const [description, setDescription] = React.useState("");

  const [product, setProduct] = React.useState(productUpdate);
  const [SkuVal, setSkuVal] = React.useState([]);
  const [productAttributes, setProductAttributes] = React.useState([]);

  // Only for Product Category
  const [getBaseCatList, setGetBaseCatList] = React.useState([]);
  const [getMainCatList, setGetMainCatList] = React.useState([]);
  const [getSubCatList, setGetSubCatList] = React.useState([]);
  const [getProductCatList, setGetProductCatList] = React.useState([]);

  const [selectBaseCat, setSelectBaseCat] = React.useState("1");
  const [selectMainCat, setSelectMainCat] = React.useState("");
  const [selectSubCat, setSelectSubCat] = React.useState("");
  const [selectProductCat, setSelectProductCat] = React.useState("");

  // Brand
  const [getBrandsList, setGetBrandsList] = React.useState([]);
  const [selectBrandItem, setSelectBrandItem] = React.useState("");
  const [selectedBrandName, setSelectedBrandName] =
    React.useState("Select One");

  const [productOccasionAttributes, setProductOccasionAttributes] =
    React.useState([]);
  const [selected, setSelected] = React.useState([]);

  useEffect(() => {
    try {
      console.clear();
      setProduct(productUpdate);
      setSkuVal(productUpdate.skus);
      if (productUpdate.productId) {
        if (productUpdate.brandDetails) {
          setSelectBrandItem(productUpdate.brandDetails[0].id);
          setSelectedBrandName(productUpdate.brandDetails[0].name);
        }
        setProductAttributes(productUpdate.productAttributes);
        fetchBrandData();
        fetchCategoriesData();
      }
    } catch (er) {
      console.clear();
    }
  }, [productUpdate]);

  const fetchBrandData = useCallback(async () => {
    let { status, payload } = await postAPI("/get-seller-brands", {
      userId: productUpdate.sellerId,
    });
    if (status == 1) {
      setGetBrandsList(payload.brands);
    }
  });

  const fetchCategoriesData = useCallback(async () => {
    try {
      var { status, payload } = await postAPI("/get-seller-categories", {
        userId: productUpdate.sellerId,
      });
      if (status == 1) {
        let _catBaseData = payload.categories;
        let baseCatData = [],
          mainCatData = [],
          subCatData = [],
          catData = [];
        for (let c = 0; c < _catBaseData.length; c++) {
          let _img = _catBaseData[c].image ? _catBaseData[c].image : "";
          baseCatData.push({
            key: _catBaseData[c].id,
            text: _catBaseData[c].name,
            value: _catBaseData[c].id,
            arr: _catBaseData[c],
            image: _img,
          });

          let mainC = _catBaseData[c].child;
          for (let mc = 0; mc < mainC.length; mc++) {
            let _img = mainC[mc].image ? mainC[mc].image : "";
            mainCatData.push({
              key: mainC[mc].id,
              text: mainC[mc].name,
              value: mainC[mc].id,
              arr: mainC[mc],
              image: _img,
            });

            let subC = mainC[mc].child;
            for (let sc = 0; sc < subC.length; sc++) {
              let _img = subC[sc].image ? subC[sc].image : "";
              subCatData.push({
                key: subC[sc].id,
                text: subC[sc].name,
                value: subC[sc].id,
                arr: subC[sc],
                image: _img,
              });

              let cat = subC[sc].child;
              for (let cd = 0; cd < cat.length; cd++) {
                let _img = cat[cd].image ? cat[cd].image : "";
                catData.push({
                  key: cat[cd].id,
                  text: cat[cd].name,
                  value: cat[cd].id,
                  arr: cat[cd],
                  image: _img,
                });
                if (productUpdate.subCategoryId == cat[cd].id) {
                  bindAttributeField(cat[cd].attributes);
                  bindAttributeFieldNew(
                    cat[cd].attributes,
                    productUpdate.productAttributes
                  );
                }
              }
            }
          }
        }
        setGetBaseCatList(baseCatData);
        setGetMainCatList(mainCatData);
        setGetSubCatList(subCatData);
        setGetProductCatList(catData);

        setSelectBaseCat(parseInt(productUpdate.superCategoryId));
        setSelectMainCat(parseInt(productUpdate.mainCategoryId));
        setSelectSubCat(parseInt(productUpdate.categoryId));
        setSelectProductCat(parseInt(productUpdate.subCategoryId));
      }
    } catch (er) {
    }
  });

  // useMemo(() => {
  //   setProduct(productUpdate)
  //   setSkuVal(productUpdate.skus)
  // }, [SkuVal])

  const selectProdAttrNew = (product, item, index, aitem, aindex) => {
    try {
      let proAttrb = productAttributes;
      proAttrb[index].selected_attb = aitem.attb_title;
      proAttrb[index].selected_attb_id = aitem.attb_key;
      setTimeout(() => {
        setProductAttributes([]);
        setProductAttributes(proAttrb);
      }, 40);
    } catch (err) {
    }
  };

  const updateDimenFieldChanged = (index, e, name) => {
    // let newArr = SkuVal;
    // newArr[index][name] = e.target.value;
    // setTimeout(() => {
    //   setSkuVal([])
    //   setSkuVal(newArr)
    // }, 20);
    if (name == "packageWeightType" || name == "dimensionType") {
      let newArr = [...SkuVal];
      newArr[index][name] = e.value;
      setSkuVal([]);
      setSkuVal(newArr);
    } else {
      let newArr = [...SkuVal];
      newArr[index][name] = e.target.value;
      setSkuVal([]);
      setSkuVal(newArr);
    }
  };

  const updateFieldChanged = (index, e, name) => {
    let value = e.target.value;
    let id = e.target.id;
    switch (id) {
      case "fullName":
        product.fullName = value;
        setFullName(value);
        break;

      case "shortName":
        product.shortName = value;
        setShortName(value);
        break;

      case "description":
        product.description = value;
        setDescription(value);
        break;
    }
  };

  const bindAttributeField = (atrData) => {
    try {
      for (let p = 0; p < atrData.length; p++) {
        let val = atrData[p].attb_values;
        atrData[p].selected_attb = "Select One";
        val.forEach((element) => {
          element.key = element.attb_key;
          element.text = element.attb_title;
          element.value = element.attb_key;
        });
        if (atrData[p].attb_title === "Occasion") {
          val.forEach((element) => {
            element.label = element.attb_title;
            element.value = element.attb_key;
            element.select_Attb_values = 51;
          });
        }
      }

      let _data = atrData.filter((re) => {
        {
          return re.attb_title != "Occasion";
        }
      });
      try {
        let occaData = atrData.filter((re) => {
          {
            return re.attb_title == "Occasion";
          }
        })[0].attb_values;
      } catch (er) {
      }
      setProductAttributes([]);
      setProductAttributes(_data);
      // setProductOccasionAttributes(occaData)
      return;
    } catch (err) {
    }
  };

  const bindAttributeFieldNew = async (atrData, userAttb) => {
    try {
      for (let p = 0; p < atrData.length; p++) {
        let val = atrData[p].attb_values;
        let findVal = userAttb.filter((re) => {
          return re.id == atrData[p].attb_key;
        });
        if (findVal.length > 0) {
          try {
            if (findVal[0].value === "Occasion") {
              let ocData = [];
              for (let ocd = 0; ocd < findVal[0].child.length; ocd++) {
                ocData.push({
                  value: findVal[0].child[ocd].id,
                  label: findVal[0].child[ocd].value,
                });
              }
              setSelected(ocData);
            } else {
              atrData[p].selected_attb = findVal[0].child[0].value;
              atrData[p].selected_attb_id = findVal[0].child[0].id;
            }
          } catch (err) {
          }
        } else {
          atrData[p].selected_attb = "Select One";
        }

        val.forEach((element) => {
          element.key = element.attb_key;
          element.text = element.attb_title;
          element.value = element.attb_key;
          element.label = element.attb_title;
          element.select_Attb_values = 51;
        });
        if (atrData[p].attb_title === "Occasion") {
          val.forEach((element) => {
            element.label = element.attb_title;
            element.value = element.attb_key;
            element.select_Attb_values = 51;
          });
        }
      }
      let occaData = atrData.filter((re) => {
        {
          return re.attb_title == "Occasion";
        }
      })[0].attb_values;

      let filterCccaData = userAttb.filter((re) => {
        {
          return re.value == "Occasion";
        }
      });
      if (filterCccaData.length == 0) {
        atrData = atrData.filter((re) => {
          {
            return re.attb_title != "Occasion";
          }
        });
      }
      setProductAttributes([]);
      setProductAttributes(atrData);
      setProductOccasionAttributes(occaData);
      return;
    } catch (err) {
    }
  };

  const selectBrand = (data) => {
    setSelectBrandItem(data.id);
    setSelectedBrandName(data.name);
  };

  const chooseCategory = (event, data, type) => {
    try {
      setProductAttributes([]);
      setProductOccasionAttributes([]);

      let baseValues = parseInt(data.value);
      let mainCat = [];
      if (type == 0) {
        let returnArr = name(getBaseCatList, baseValues);
        for (let i = 0; i < returnArr.length; i++) {
          mainCat.push({
            key: returnArr[i].id,
            text: returnArr[i].name,
            value: returnArr[i].id,
            arr: returnArr[i],
            image: returnArr[i].image,
            color: "red",
          });
        }
        setSelectBaseCat(baseValues);
        setSelectMainCat("");
        setSelectSubCat("");
        setSelectProductCat("");

        setGetMainCatList([]);
        setGetSubCatList([]);
        setGetProductCatList([]);
        setGetMainCatList(mainCat);
      } else if (type == 1) {
        let returnArr = name(getMainCatList, baseValues);
        for (let i = 0; i < returnArr.length; i++) {
          mainCat.push({
            key: returnArr[i].id,
            text: returnArr[i].name,
            value: returnArr[i].id,
            arr: returnArr[i],
            image: returnArr[i].image,
          });
        }
        setSelectMainCat(baseValues);
        setSelectSubCat("");
        setSelectProductCat("");
        setGetSubCatList(mainCat);
      } else if (type == 2) {
        let returnArr = name(getSubCatList, baseValues);
        for (let i = 0; i < returnArr.length; i++) {
          mainCat.push({
            key: returnArr[i].id,
            text: returnArr[i].name,
            value: returnArr[i].id,
            arr: returnArr[i],
            image: returnArr[i].image,
          });
        }
        setSelectSubCat(baseValues);
        setSelectProductCat("");
        setGetProductCatList(mainCat);
      } else {
        try {
          let productCat = getProductCatList;
          let attrData = productCat.filter((pre) => {
            return pre.key == baseValues;
          })[0].arr;
          setSelectProductCat(baseValues);

          // For Attributes
          let attributeData = attrData.attributes;
          let atrData = attributeData;
          bindAttributeField(atrData);
        } catch (err) {
        }
      }
    } catch (err) {
    }
  };

  function name(arr, baseValues) {
    let returnArr = arr.filter((er) => {
      {
        return er.key == baseValues;
      }
    })[0].arr.child;
    return returnArr;
  }

  const UpdateProductBtn = async () => {
    console.clear();
    try {
      let categories = [
        { level: 1, id: selectBaseCat },
        { level: 2, id: selectMainCat },
        { level: 3, id: selectSubCat },
        { level: 4, id: selectProductCat },
      ];
      let productSKU = productUpdate.skus;
      for (let s = 0; s < productSKU.length; s++) {
        productSKU[s].dimensionValue =
          productSKU[s].dimention1 +
          "*" +
          productSKU[s].dimention2 +
          "*" +
          productSKU[s].dimention3;
      }
      let _productAttributes = [];
      for (let pa = 0; pa < productAttributes.length; pa++) {
        if (productAttributes[pa].attb_title != "Occasion") {
          if (productAttributes[pa].selected_attb_id) {
            _productAttributes.push({
              id: productAttributes[pa].selected_attb_id,
              value: productAttributes[pa].selected_attb,
            });
          }
        }
      }
      for (let ocd = 0; ocd < selected.length; ocd++) {
        _productAttributes.push({
          id: selected[ocd].value,
          value: selected[ocd].label,
        });
      }

      let updateObj = {
        sellerId: productUpdate.sellerId,
        categories: categories,
        productId: productUpdate.productId,
        fullName: productUpdate.fullName,
        shortName: productUpdate.shortName,
        description: productUpdate.description,
        overview: productUpdate.overview,
        productType: productUpdate.productType,
        brand: {
          id: selectBrandItem,
          name: selectedBrandName,
        },
        productAttributes: _productAttributes,
        skus: productSKU,
      };

      console.clear();

      // return
      let { status, payload, message } = await postAPI(
        "/save-product-data",
        updateObj
      );
      if (status == 1) {
        var _icon = "success";
        setTimeout(() => {
          onUpdateProduct({ mes: "updated" });
        }, 3000);
      } else {
        var _icon = "error";
      }
      Swal.fire({
        icon: _icon,
        title: message,
        showConfirmButton: false,
        timer: 3000,
      });
    } catch (er) {
      console.clear();
    }
  };

  const skuWeightOption = [
    { key: "gram", value: "69", text: "Gram" },
    { key: "kg", value: "96", text: "Kg" },
  ];
  const skuDimensionTypeOption = [{ key: "cm", value: "69", text: "cm" }];

  return (
    <div>
      <div className="d-flex align-items-center">
        <Avatar alt={product?.fullName} size={60} />
        <div className="ml-2">
          <h5 className="mb-1">{product?.fullName}</h5>
          <div className="pt-2 pb-2">
            <CBadge
              className="p-2"
              color={PRODUCT_TYPE[product.productType]?.color}
            >
              {PRODUCT_TYPE[product.productType]?.label}
            </CBadge>
          </div>
        </div>
      </div>
      <br />
      <h5 className="mb-2">Product Details:</h5>
      <div
        style={{
          width: "100%",
          height: 0.5,
          backgroundColor: "#08176C",
          marginBottom: "20px",
        }}
      />

      <div className="row  mt-2 d-flex justify-content-between">
        <div className="col-md-12">
          <Form>
            <Form.Group widths="equal">
              <Form.Field>
                <label>
                  <span>Brand Name *</span>
                </label>
                <Dropdown
                  style={{
                    width: "100%",
                    border: "1px solid lightgray",
                    background: "none",
                  }}
                  text={selectedBrandName}
                  labeled
                  button
                  className="icon"
                >
                  <Dropdown.Menu>
                    <Dropdown.Divider />
                    {(getBrandsList || []).map((item, index) => {
                      return (
                        <Dropdown.Item
                          text={item.name}
                          onClick={() => selectBrand(item)}
                        />
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Field>

              <Form.Field>
                <label>
                  <span>Super Category *</span>
                </label>
                <Form.Field
                  control={Select}
                  options={getBaseCatList}
                  value={selectBaseCat}
                  placeholder="Super Category"
                  onChange={(e, data) => chooseCategory(e, data, 0)}
                  search
                  searchInput={{ id: "baseDetailsDrop" }}
                />
              </Form.Field>

              <Form.Field>
                <label>
                  <span>Main Category *</span>
                </label>

                <Form.Field
                  control={Select}
                  options={getMainCatList}
                  value={selectMainCat}
                  placeholder="Main Category"
                  onChange={(e, data) => chooseCategory(e, data, 1)}
                  search
                  searchInput={{ id: "mainCategoryDrop" }}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <label>
                  {" "}
                  <span>Category *</span>
                </label>
                <Form.Field
                  control={Select}
                  options={getSubCatList}
                  value={selectSubCat}
                  placeholder="Category"
                  onChange={(e, data) => chooseCategory(e, data, 2)}
                  search
                  searchInput={{ id: "subCatDrop" }}
                />
              </Form.Field>

              <Form.Field>
                <label>Sub Category</label>
                <Form.Field
                  control={Select}
                  options={getProductCatList}
                  value={selectProductCat}
                  placeholder="Sub Category"
                  onChange={(e, data) => chooseCategory(e, data, 3)}
                  search
                  searchInput={{ id: "subCatDrop" }}
                />
              </Form.Field>
              <Form.Field></Form.Field>
            </Form.Group>

            <Form.Group widths="equal" style={{ with: "34%" }}>
              <Form.Field>
                <label>Product Name: </label>
                <input
                  type="text"
                  id="fullName"
                  onChange={(e) => updateFieldChanged(0, e, "fullName")}
                  style={{ border: "1px solid lightgray" }}
                  value={product?.fullName}
                />
              </Form.Field>
              <Form.Field>
                <label>Short Name:</label>
                <input
                  type="text"
                  value={product?.shortName}
                  id="shortName"
                  onChange={(e) => updateFieldChanged(0, e, "shortName")}
                  style={{ border: "1px solid lightgray" }}
                />
              </Form.Field>
              <Form.Field></Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <label>
                  <span>Description: </span>
                </label>
                <textarea
                  type="text"
                  id="description"
                  value={product?.overview}
                  onChange={(e) => updateFieldChanged(0, e, "description")}
                  style={{
                    border: "1px solid lightgray",
                    width: "100%",
                    height: "100px",
                  }}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </div>
      </div>

      <br />
      <h5 className="mt-0">
        {product.productType === 1 ? "Attributes" : "Specification:"}
      </h5>
      <div
        style={{
          width: "100%",
          height: 0.5,
          backgroundColor: "#08176C",
          marginBottom: "15px",
        }}
      />
      <div
        className="row mt-2 d-flex justify-content-between"
        style={{ paddingRight: "14px" }}
      >
        {(productAttributes || []).map((item, index) => {
          return (
            <div
              className="col-md-6"
              style={{ paddingRight: "0px", paddingBottom: "15px" }}
            >
              <label>{item.attb_title}</label>

              {item.attb_title == "Occasion" ? (
                <MultiSelect
                  options={productOccasionAttributes}
                  value={selected}
                  onChange={setSelected}
                  labelledBy="Select"
                />
              ) : (
                <Dropdown
                  style={{
                    width: "100%",
                    border: "1px solid lightgray",
                    background: "none",
                  }}
                  text={item.selected_attb}
                  labeled
                  button
                  className="icon"
                >
                  <Dropdown.Menu>
                    {(item.attb_values || []).map((aitem, aindex) => {
                      return (
                        <Dropdown.Item
                          text={aitem.text}
                          onClick={() =>
                            selectProdAttrNew(
                              productAttributes,
                              item,
                              index,
                              aitem,
                              aindex
                            )
                          }
                        />
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          );
        })}
      </div>
      <br />

      <h5 className="mt-0">SKU / Variants</h5>
      <div
        style={{
          width: "100%",
          height: 0.5,
          backgroundColor: "#08176C",
          marginBottom: "15px",
        }}
      />
      <div>
        {(SkuVal || []).map((item, index) => {
          try {
            item.packageWeight = item.packageWeight();
          } catch (er) {
          }
          return (
            <>
              <div className="row" key={index} style={{ height: "150px" }}>
                <div className="col-3">
                  <img
                    src={
                      item.images.length > 0
                        ? item.images[0]
                        : "/avatars/ad.png"
                    }
                    style={{ height: "100px", marginTop: "5px" }}
                  />
                </div>

                <div className="col-md-5">
                  <label
                    className="mt-2"
                    style={{ color: "rgba(0,0,0,.87)", fontWeight: 700 }}
                  >
                    Dimension:
                  </label>
                  <br />
                  <div className="row">
                    <div className="col-2 mr-2">
                      <input
                        type="text"
                        style={{
                          border: "1px solid lightgray",
                          width: "64px",
                          textAlign: "center",
                          height: "36px",
                          borderRadius: "3px",
                        }}
                        value={item.dimention1}
                        id={item.dimention1}
                        onChange={(e) =>
                          updateDimenFieldChanged(index, e, "dimention1")
                        }
                      />
                    </div>
                    <div className="col-2">
                      <input
                        type="text"
                        style={{
                          border: "1px solid lightgray",
                          width: "64px",
                          textAlign: "center",
                          height: "36px",
                          borderRadius: "3px",
                          marginLeft: "1px",
                        }}
                        value={item.dimention2}
                        id={item.dimention2}
                        onChange={(e) =>
                          updateDimenFieldChanged(index, e, "dimention2")
                        }
                      />
                    </div>
                    <div className="col-2" style={{ color: "rgba(0,0,0,.87)" }}>
                      <input
                        type="text"
                        style={{
                          border: "1px solid lightgray",
                          width: "64px",
                          textAlign: "center",
                          height: "36px",
                          borderRadius: "3px",
                          marginLeft: "9px",
                        }}
                        value={item.dimention3}
                        id={item.dimention3}
                        onChange={(e) =>
                          updateDimenFieldChanged(index, e, "dimention3")
                        }
                      />
                    </div>

                    <div className="ml-4">
                      <Dropdown
                        fluid
                        button
                        text={item.dimensionType == "71" ? "cm" : "cm"}
                        className="icon"
                        style={{
                          width: "80px",
                          height: "36px",
                          border: "1px solid lightgray",
                          background: "none",
                          marginLeft: "20px",
                        }}
                      >
                        <Dropdown.Menu>
                          <Dropdown.Divider style={{ marginLeft: "30px" }} />
                          {skuDimensionTypeOption.map((dItem, dindex) => {
                            return (
                              <Dropdown.Item
                                text={dItem.text}
                                onClick={() =>
                                  updateDimenFieldChanged(
                                    index,
                                    dItem,
                                    "dimensionType"
                                  )
                                }
                              />
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <label
                    className="mt-2"
                    style={{ color: "rgba(0,0,0,.87)", fontWeight: 700 }}
                  >
                    Weight:
                  </label>
                  <br />
                  <div className="d-flex">
                    <input
                      style={{
                        border: "1px solid lightgray",
                        width: "50%",
                        borderRadius: "3px",
                        marginRight: "2px",
                      }}
                      type="text"
                      value={item.packageWeight}
                      id={item.packageWeight}
                      onChange={(e) =>
                        updateDimenFieldChanged(index, e, "packageWeight")
                      }
                    />
                    <Dropdown
                      fluid
                      button
                      className="icon"
                      text={item.packageWeightType == 69 ? "Gram" : "Kg"}
                      style={{
                        width: "50%",
                        border: "1px solid lightgray",
                        background: "none",
                        marginLeft: "1px",
                      }}
                    >
                      <Dropdown.Menu>
                        {(skuWeightOption || []).map((_item, _index) => {
                          return (
                            <Dropdown.Item
                              text={_item.text}
                              onClick={() =>
                                updateDimenFieldChanged(
                                  index,
                                  _item,
                                  "packageWeightType"
                                )
                              }
                            />
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>

      <div
        style={{
          textAlign: "right",
          borderTop: "1px solid #d3d3d385",
          paddingTop: "15px",
        }}
      >
        <CButton
          color="primary"
          onClick={() => UpdateProductBtn()}
          style={{ marginRight: "15px" }}
        >
          Update
        </CButton>
        <CButton
          color="secondary"
          onClick={() => onUpdateProduct({ close: "Close" })}
        >
          Close
        </CButton>
      </div>
    </div>
  );
};
export default ProductDetailsUpdate;
