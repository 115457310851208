import { CCard, CCardBody } from "@coreui/react";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import { Button, Form, Checkbox } from "semantic-ui-react";
import { getApiCall, postAPI } from "./../../apiServices";
const defaultState = {
  userIds: [],
  selectedItems: [],
};
function UserAdd({ visible, onClose, userdata, userRoleId = [], userstatus }) {
  const [userData, setuserData] = useState(userdata);
  const [userStatus, setuserStatus] = useState(null);
  const [userRoleList, setuserRoleList] = useState([]);
  const [selectedRoleId, setselectedRoleId] = useState(defaultState.userIds);
  const [rebind, setrebind] = useState(false);
  const addNewUser = async () => {
    const userjson = {
      userId: userData?.userId ? userData?.userId : "",
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      status: userStatus,
      userEmail: userData?.userEmail,
      employeeID: userData?.employeeID,
      userPhone: userData?.phone,
      password: userData?.password,
      userProfileImg: "",
      parentUserId: "",
      roleId: selectedRoleId,
    };
    const { status, message } = await postAPI("/users/manage", userjson);
    if (status) {
      window.location.reload();
    }
    toast.info(message);
    onClose();
    setuserData("");
  };
  const getUserListRole = async () => {
    const { status, payload } = await getApiCall("/roles/list");
    if (status) {
      setuserRoleList(payload);
    }
  };

  useEffect(() => {
    getUserListRole();
  }, []);

  useEffect(() => {
    setuserData(userdata);
    setuserStatus(userstatus);
    if (userRoleId.length > 0) {
      const newArray = userRoleId;
      defaultState.userIds.push(...newArray);
    }
  }, []);
  const handleradioChange = (e, { value }) => {
    setuserStatus(value);
  };

  const handleChange = (event) => {
    event.persist();
    setuserData((preState) => ({
      ...preState,
      [event.target.name]: event.target.value,
    }));
  };

  const changeRoleIds = (e, { value, name }) => {
    let objIndex = defaultState.userIds.findIndex((obj) => obj === value);
    if (objIndex === -1) {
      defaultState.userIds.push(value);
    } else {
      defaultState.userIds.splice(objIndex, 1);
    }
    setrebind(!rebind);
  };

  return (
    <Modal show={visible} backdrop="static">
      <Modal.Body>
        <CCard>
          <CCardBody>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            ></div>
            <Form onSubmit={() => addNewUser()}>
              <Form.Group widths="equal">
                <Form.Field>
                  <span>
                    <h5 style={{ marginBottom: "10px" }}>First Name </h5>
                  </span>
                  <input
                    type="text"
                    name="firstName"
                    value={userData?.firstName}
                    placeholder="First Name"
                    onChange={handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <span>
                    <h5 style={{ marginBottom: "10px" }}>Last Name </h5>
                  </span>
                  <input
                    type="text"
                    name="lastName"
                    value={userData?.lastName}
                    placeholder="Last Name"
                    onChange={handleChange}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
              <Form.Field>
                  <span>
                    <h5 style={{ marginBottom: "10px" }}>Employee ID </h5>
                  </span>
                  <input
                    type="text"
                    name="employeeID"
                    value={userData?.employeeID}
                    placeholder="Employee ID"
                    onChange={handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <span>
                    <h5 style={{ marginBottom: "10px" }}>Email </h5>
                  </span>
                  <input
                    type="email"
                    name="userEmail"
                    value={userData?.userEmail}
                    placeholder="Email"
                    onChange={handleChange}
                  />
                </Form.Field>

              </Form.Group>
              <Form.Group widths="equal">
              <Form.Field>
                  <span>
                    <h5 style={{ marginBottom: "10px" }}>Mobile</h5>
                  </span>
                  <input
                    type="text"
                    maxlength="10"
                    name="phone"
                    value={userData?.phone}
                    placeholder="Mobile"
                    onChange={handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <span>
                    <h5 style={{ marginBottom: "10px" }}>Password</h5>
                  </span>
                  <input
                    type="Password"
                    name="password"
                    placeholder="Password"
                    onChange={handleChange}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field style={{ marginTop: "8%" }}>
                  <Checkbox
                    radio
                    label="Active"
                    name="status"
                    value="1"
                    checked={userStatus === "1"}
                    onChange={handleradioChange}
                  />
                </Form.Field>
                <Form.Field style={{ marginTop: "8%" }}>
                  <Checkbox
                    radio
                    label="InActive"
                    name="status"
                    value="0"
                    checked={userStatus === "0"}
                    onChange={handleradioChange}
                  />
                </Form.Field>
              </Form.Group>
              <span>
                <h5 style={{ marginBottom: "10px" }}>Role</h5>
              </span>
              <div className="parent">
                {userRoleList?.map((item) => (
                  <Form.Field className="child">
                    <Checkbox
                      label={item.roleName}
                      value={item.roleId}
                      checked={
                        defaultState.userIds.filter(
                          (obj) => obj === item.roleId
                        )?.length > 0
                      }
                      name="roleId"
                      onChange={changeRoleIds}
                    />
                  </Form.Field>
                ))}
              </div>
              <div className="d-flex justify-content-lg-end">
                <Button primary type="submit">
                  Save
                </Button>
                <Button
                  danger
                  type="button"
                  onClick={() => {
                    defaultState.userIds = [];
                    onClose();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </CCardBody>
        </CCard>
      </Modal.Body>
    </Modal>
  );
}

export default UserAdd;
