import React from "react";
import { Menu, Dropdown, Table, Grid } from "semantic-ui-react";
import moment from "moment";
const defaultState = {
  entries: {},
  totalEntries: 0,
  page: 0,
  recPerPage: 50,
  loading: false,
};
const PaymentDetails = ({ details = {}, isLoading = false }) => {
  return (
    <React.Fragment>


      <div>
      <Grid className="ms-1">
        <Grid.Row>
          <Grid.Column width={6}>
            <div style={{ textDecoration: "underline", marginBottom: "6px" }}>
              Payment Information:
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
            Amount:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.refund?.payment?.amount}
              </span>{" "}
            </div>
            {/* <div style={{ fontWeight: "bold", fontSize: "13px" }}>Email: <span style={{ fontWeight: "normal" }}>{details?.buyer?.byrEmail}</span></div> */}
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
            Transaction Date:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.refund?.payment?.transactionOn > 0 && moment(details?.refund?.payment?.transactionOn*1000).format( "YYYY-MM-DD")}
              </span>
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
            Refund Status:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.paymentOption === 'rupify' ? details?.refund?.rupifiRefundStatus : details?.refund?.status?.label}
              </span>
            </div>

            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
            Remarks:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.remarks}
              </span>
            </div>

            {/* <div style={{ fontWeight: "bold", fontSize: "13px" }}>Mobile: <span style={{ fontWeight: "normal" }}>{details?.buyer?.byrMobile}</span></div> */}
          </Grid.Column>

          <Grid.Column width={4}>
            <div style={{ textDecoration: "underline", marginBottom: "6px" }}>
              Credit Note Information:
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
            Credit Note Number:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.creditNoteNo}
              </span>{" "}
            </div>
            {/* <div style={{ fontWeight: "bold", fontSize: "13px" }}>Email: <span style={{ fontWeight: "normal" }}>{details?.buyer?.byrEmail}</span></div> */}
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
            Amount:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.revisedCreditGrandTotal > 0 ? details?.revisedCreditGrandTotal : details?.returns?.grandTotal}
              </span>
            </div>
            {details?.creditNoteUploadReason && <div style={{ fontWeight: "bold", fontSize: "13px" }}>
            Reason:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.creditNoteUploadReason}
              </span>
            </div>}


          </Grid.Column>
          {details?.approvedWithDebitNote === 1 && <Grid.Column width={4}>
            <div style={{ textDecoration: "underline", marginBottom: "6px" }}>
              Debit Note Information:
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
            Debit Note Number:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.debitNoteNo}
              </span>{" "}
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
            Amount:{" "}
              <span style={{ fontWeight: "normal" }}>
              {details?.revisedDebitGrandTotal > 0 ? details?.revisedDebitGrandTotal : details?.returns?.grandTotal}
              </span>
            </div>
            {details?.debitNoteUploadReason && <div style={{ fontWeight: "bold", fontSize: "13px" }}>
            Reason:{" "}
              <span style={{ fontWeight: "normal" }}>
                {details?.debitNoteUploadReason}
              </span>
            </div>}


          </Grid.Column>}

        </Grid.Row>
      </Grid>
    </div>
    </React.Fragment>
  );
};

export default React.memo(PaymentDetails);
