import React, { useEffect, useState } from "react";
import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import { getUrlFileExtension } from "../../constants/comonFunction";

function ImageGallery(props){
    const [showImage,  setShowImage] = useState(true);
    const [imageFile, setImageFile] = useState([]);
    const [pdfFile, setPdfFile] = useState([]);
    const onInit = () => {
    };
    useEffect(() => {
        let pdf = [];
        let image = [];
        props?.images?.map((ele) => {
            if(getUrlFileExtension(ele) === 'pdf'){
                pdf.push(ele)
            }else{
                image.push(ele)
            }
        })
        setImageFile(image);
        setPdfFile(pdf)
    },[props])

    const viewPdf = (url) => {
        // window.open(
        //     url,
        //     '_blank' // <- This is what makes it open in a new window.
        //   );
    }

    return (<>
        <div className="App">
            {showImage ? (<>
                {pdfFile?.map((row) => {
                    return (<>
                         <div>
                            <div>
                            <embed src={row} style={{width:"150px", height:"150px", borderRadius:"5px", padding:"10px 10px 5px 10px"}}></embed>
                            </div>
                            <div style={{width:"150px", textAlign:"center"}}>
                            <a href={row} target="_blank">View</a>
                            </div>
                        </div>
                    </>)
                })}

            <LightGallery
                    // onInit={onInit}
                    // speed={500}
                    // plugins={[lgThumbnail, lgZoom]}
                >
                {imageFile?.map((row) => {
                    return (<>
                        <a href={row} style={{float:"left"}}>
                            <div>
                                <img src={row} style={{width:"150px", height:"150px", borderRadius:"5px", padding:"10px"}} />
                            </div>
                            <div style={{width:"150px", textAlign:"center"}}>
                            <a href={row}>View</a>
                            </div>
                        </a>
                    </>)
                })}
            </LightGallery>
            </>) : (<></>)}
        </div>
    </>)
}

export default ImageGallery
