import React, { useState, useCallback, useEffect, useMemo } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { postAPI } from "../../apiServices";
import MESSAGES from "../../message.json";
// import ChangePassword from "./tab-panels/ChangePassword";
import {
  PersonalInformation,
  CompanyInformation,
  BankDetails,
  Brands,
  Category,
  Commercials,
} from "./tab-panels";
import ChangePassword from "./tab-panels/ChangePassword";
import {
  CCard,
  CCardBody,
  CCardText,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import EmployeeId from "./tab-panels/EmployeeId";
import BuyerHistory from "./BuyerHistory";
import ActionConfirmation from "./deleteRequest/actionConfirmation";
import SCash from "./tab-panels/SCash";

const localPermits = JSON.parse(localStorage.getItem("module"));
const permissions = localPermits?.find((o) => o.moduleId == 9);
const noop = () => {};
// const BUYER_STATUS = [
//   { label: 'Active', value: 'active', key: 1, type: 'status' },
//   { label: 'Inactive', value: 'inactive', key: 0, type: 'status' },
//   { label: 'Blocked', value: 'blocked', key: -1, type: 'status' },
// ];
const BUYER_STATUS = [
  {
    label: "Active",
    value: "active",
    key: 1,
    type: "status",
    sections: ["approved", "blocked"],
  },
  {
    label: "Inactive",
    value: "inactive",
    key: 0,
    type: "status",
    sections: ["approved", "blocked"],
  },
  {
    label: "Blocked",
    value: "blocked",
    key: -1,
    type: "status",
    sections: ["approved"],
  },
  {
    label: "Approved",
    value: "approved",
    key: 1,
    type: "approve",
    sections: ["new", "rejected"],
  },
  {
    label: "Not Approved",
    value: "not-approved",
    key: 0,
    type: "approve",
    sections: ["rejected", "approved"],
  },
  {
    label: "Rejected",
    value: "rejected",
    key: 2,
    type: "approve",
    sections: ["new", "approved"],
  },
];
const TABS = [
  {
    label: "Business Info",
    key: "company",
    panelComponent: CompanyInformation,
    statusKey: "companyProfileCompleted",
  },
  {
    label: "Bank Details",
    key: "bank",
    panelComponent: BankDetails,
    statusKey: "bankProcessCompleted",
  },
  {
    label: "General Info",
    key: "employeeId",
    panelComponent: EmployeeId,
    statusKey: "empProcessCompleted",
  },
  {
    label: "Order History",
    key: "buyerHistory",
    panelComponent: BuyerHistory,
    statusKey: "empProcessCompleted",
  },
  {
    label: "Change Password",
    key: "changePassword",
    panelComponent: ChangePassword,
    statusKey: "changePasswordCompleted",
  },
  {
    label: "S-Cash",
    key: "sCash",
    panelComponent: SCash,
    statusKey: "SCash",
  },
];

// const BUYER_Approvel = [
//   { label: "Approved", value: "approved", type: "buyer-approval-status" },
//   { label: "Not Approved", value: "not-approved", type: "buyer-not-approval" },
//   { label: "Rejected", value: "rejected", type: "buyer-approval-status" },
// ];

const defaultState = {
  activeTabIndex: -1,
  buyerStatus: BUYER_STATUS.find((status) => status.type === "status"),
  approveStatus: BUYER_STATUS.find((status) => status.type === "approve"),
  company: null,
  bank: null,
  brand: null,
  category: null,
  isFetching: {
    company: false,
    bank: false,
  },
};

const Buyer = ({ entry = {}, section = "", matchParamType="", onStatusChange = noop, onDeleteAction=noop }) => {
  // useEffect(() => {
  //   setState(prevState => ({
  //     ...prevState,
  //     buyerStatus: BUYER_STATUS.find(
  //       (status) => status.key === entry.status && status.type === "status"
  //     ),
  //     approveStatus: BUYER_STATUS.find(
  //       (status) =>
  //         status.key === entry.approvalStatus && status.type === "approve"
  //     ),
  //   }));
  // }, [entry]);

  // Permission Code Start
  const [state, setState] = useState(defaultState);

  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteAction, setDeleteAction] = useState();

  // Permission Code End

  useEffect(() => {
    setState((prevState) => ({
      ...defaultState,
      buyerStatus: BUYER_STATUS.find(
        (status) => status.key === entry.status && status.type === "status"
      ),
      approveStatus: BUYER_STATUS.find(
        (status) =>
          status.key === entry.approvalStatus && status.type === "approve"
      ),
    }));
  }, [entry]);

  const fetchBuyerInfo = async (userId, type) => {
    const infoData = {
      company: {
        // endpoint: "/get-buyers-companyinfo",
        endpoint: "/get-buyer-info",
      },
      bank: {
        endpoint: "/get-buyer-bankinfo",
      },
      commercials: {
        endpoint: "/get-buyers-list",
      },
      employeeId: {
        endpoint: "/get-buyers-list",
      },
      buyerHistory: {
        endpoint: "/get-buyers-list",
      },
    };

    setState((prevState) => ({
      ...prevState,
      isFetching: {
        ...prevState.isFetching,
        [type]: true,
      },
    }));
    if (infoData[type].endpoint !== "/get-buyers-list") {
      var { status, payload } = await postAPI(infoData[type].endpoint, {
        userId,
      });
    } else {
      let getComOBJ = {
        page: 0,
        recPerPage: "100",
        searchKeyword: "",
        listingType: "all",
        filters: [
          {
            param: "userId",
            value: [userId],
          },
          {
            param: "buyer-status",
            value: [],
          },
          {
            param: "buyer-approval-status",
            value: [],
          },
        ],
      };
      try {
        var { status, payload } = await postAPI(
          infoData[type].endpoint,
          getComOBJ
        );
        // payload = { commercial: payload.data[0] }
        if (infoData.employeeId !== undefined) {
          payload = { employeeId: payload.data[0] };
        } else {
          payload = { commercial: payload.data[0] };
        }
      } catch (er) {

      }
    }

    const responseKey = {
      company: "data",
      bank: "data",
      brand: "brands",
      commercials: "commercial",
      employeeId: "employeeId",
    };

    setState((prevState) => ({
      ...prevState,
      [type]: payload[responseKey?.[type]] || defaultState?.[type],
      isFetching: {
        ...prevState.isFetching,
        [type]: false,
      },
    }));
  };

  const handleTabChange = (index, userId, key) => {
    if (!userId) {
      return;
    }
    setState((prevState) => ({
      ...prevState,
      activeTabIndex: prevState.activeTabIndex === index ? -1 : index,
    }));
    // if (!state[key]) {
    //   fetchBuyerInfo(userId, key);
    // }
  };

  const handleStatusChange = (
    status = {},
    type = "",
    id = null,
    action = "",
    entryName = ""
  ) => {
    onStatusChange(status, id, action, entryName, () => {
      setState((prevState) => ({
        ...prevState,
        [type]: status,
      }));
    });
  };

  const handleDeleteRequest = (d, action) => {
    setDeleteConfirm(d)
    setDeleteAction(action)
  }

  const handleShowConfirmation = (d, action) => {
    setDeleteConfirm(d)
    setDeleteAction()
    if(action === 'loading'){
      onDeleteAction()
    }
  }

  // const handleStatusChange = ((status = {}, type = '', id = null, action = '', entryName = '') => {
  //   onStatusChange(status, id, action, entryName, () => {
  //     setState(prevState => ({
  //       ...prevState,
  //       [type]: status,
  //     }));
  //   });
  // }, []);

  return (
    <>
    <CCard style={{marginBottom:"20px"}}>
      <CCardBody>
        <div className="d-flex justify-content-between mb-4">
          <div className="w-50">
            {[
              // { label: "Company Name", key: "companyName" },
              { label: "Name", key: "fullName" },
              { label: "Contact Number", key: "mobile" },
              { label: "Email ID", key: "email" },
              { label: "Register Date", key: "createdOn" },
              { label: "Onboarded By", key: "onboardedByName" },

              { label: `Reason For ${ section === "rejected" ? "Rejection" : "blocking" }`,
                key: "remarks", },
            ].filter((item) => {
                if (section === "incomplete" && item.key === "fullName") {
                  return false;
                } else {
                  return true;
                }
              })
              .filter((item) =>
                item.key === "remarks"
                  ? section === "rejected" || section === "blocked"
                  : true
              )
              .map((item) => {
                return (
                  <div className={clsx({ "d-flex": item.key !== "remarks" })}>
                    <CCardText
                      className={clsx("text-primary-label font-weight-bold m-1", {
                        "text-danger": item.key === "remarks",
                      })}
                    >
                      {item.label}:
                    </CCardText>
                    <CCardText
                      className={clsx("m-1 ms-1 text-primary-value", {
                        "text-danger": item.key === "remarks",
                      })}
                    >
                      {item.key === "createdOn"
                        ? moment(entry[item.key]).format(
                            "DD MMMM YYYY, h:mm:ss a"
                          )
                        : (<>
                        {item.key === "onboardedByName" ? (<>

                          {entry[item.key] || entry['showroomB2BEmployeeId'] ? entry[item.key] + " ("+entry['showroomB2BEmployeeId']+")" : ""}
                        </>) : (<>
                          {entry[item.key] || "-"}{" "}
                        </>)}
                        </>)}


                    </CCardText>
                  </div>
                );
              })}
            {/* {section === "rejected" && (
              <div>
                <CCardText className={clsx("text-danger font-weight-bold m-1")}>
                  Reason For Rejection:
                </CCardText>
                <CCardText className={clsx("m-1 ms-1 text-danger")}>
                  {Buyer.rejectionRemarks}
                </CCardText>
              </div>
            )} */}
          </div>
          <div>
            {[
              { label: "Total Order", key: "totalOrderCount" },
              { label: "Total Order Value", key: "totalOrderValue" },
              { label: "Last Updated Date", key: "updatedOn" },
              { label: "Current Status", key: "currentStatus" },
            ]
              .filter((item) =>
                section === "incomplete" ? entry[item.key] : true
              )
              .map((item) => (
                <div className="d-flex">
                  <CCardText className="text-primary-label font-weight-bold m-1">
                    {item.label}:
                  </CCardText>
                  <CCardText className="m-1 ms-1 text-primary-value">
                    {item.key === "updatedOn"
                      ? moment(entry[item.key]).format("DD MMM YYYY, ddd")
                      : entry[item.key]}
                  </CCardText>
                </div>
              ))}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end">
          <div className={clsx("d-flex align-items-center")}>
            {BUYER_STATUS.some((buyer) => buyer.type === "status" && buyer.sections.includes(section) && (matchParamType !== "delete-request" && matchParamType !== "deleted")) && (
              <CDropdown className="me-2">
                <CDropdownToggle disabled color="info">
                  {state.buyerStatus?.label}
                </CDropdownToggle>
                <CDropdownMenu>
                  {BUYER_STATUS.filter(
                    (status) =>
                      status.type === "status" &&
                      status.key !== state.buyerStatus?.key &&
                      status.sections.includes(section)
                  ).map((status) => (
                    <CDropdownItem
                      // href="#"
                      // disabled={!entry.allProfileCompleted}
                      onClick={() =>
                        handleStatusChange(
                          status,
                          "buyerStatus",
                          entry.userId,
                          `Update Status`,
                          entry.fullName || entry.email || entry.mobile
                        )
                      }
                    >
                      {status.label}
                    </CDropdownItem>
                  ))}
                </CDropdownMenu>
              </CDropdown>
            )}

            {BUYER_STATUS.some((buyer) => buyer.type === "approve" && buyer.sections.includes(section) && (matchParamType !== "delete-request" && matchParamType !== "deleted")) && (
              <CDropdown>
                <CDropdownToggle
                  color="primary"
                  disabled={permissions.isEdit === 0 ? true : false}
                >
                  {(() => {
                    if (section === "approved") {
                      return "Approved";
                    } else if (section === "rejected") {
                      return "Rejected";
                    } else {
                      return "Not Approved";
                    }
                  })()}

                  {/* Not Approved */}
                </CDropdownToggle>
                <CDropdownMenu>
                  {BUYER_STATUS.filter(
                    (status) =>
                      status.type === "approve" &&
                      status.key !== state.approveStatus?.key &&
                      status.sections.includes(section)
                  ).map((status) => (
                    <CDropdownItem
                      // href="#"
                      // disabled={!entry.allProfileCompleted}
                      onClick={() =>
                        handleStatusChange(
                          status,
                          "approveStatus",
                          entry.userId,
                          `Update Approval Status`,
                          entry.fullName || entry.email || entry.mobile
                        )
                      }
                    >
                      {status.label}
                    </CDropdownItem>
                  ))}
                </CDropdownMenu>
              </CDropdown>
            )}

            {matchParamType === "delete-request" && (<>
              <CDropdown>
                <CDropdownToggle
                  color="primary"
                  disabled={permissions.isEdit === 0 ? true : false}
                >
                  Delete Request
                </CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem
                      // href="#"
                      onClick={() =>
                        handleDeleteRequest(true, 'accept')
                      }
                    >
                      Accept
                    </CDropdownItem>

                    <CDropdownItem
                      // href="#"
                      onClick={() =>
                        handleDeleteRequest(true, 'reject')
                      }
                    >
                      Reject
                    </CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </>)}
          </div>
        </div>

        <>
          <CNav variant="tabs">
            {TABS.filter((tab) => {
              if (
                section === "incomplete" &&
                (tab.key === "company" || tab.key === "bank")
              ) {
                return false;
              }else if((section === 'incomplete' || section === 'new') && (tab.key === 'sCash')){
                return false;
              } else {
                return true;
              }
            }).map((tab, index) => (
              <CNavItem>
                <CNavLink
                  // href="#"
                  active={state.activeTabIndex === index}
                  onClick={() => handleTabChange(index, entry.userId, tab.key)}
                >
                  <div>
                    {/* {tab.label === "Business Info" || (
                      <>
                        {!entry[tab.statusKey] && (
                          <FontAwesomeIcon
                            icon={faQuestionCircle}
                            className={clsx("me-2", {
                              "text-secondary":
                                tab.label === "Bank Details" ||
                                tab.label === "Brands" ||
                                tab.label === "General Info",
                            })}
                          />
                        )}
                      </>
                    )} */}
                    {tab.label}
                  </div>
                </CNavLink>
              </CNavItem>
            ))}
          </CNav>
          <CTabContent>
            {TABS.filter((tab) => {
              if (
                section === "incomplete" &&
                (tab.key === "company" || tab.key === "bank")
              ) {
                return false;
              } else {
                return true;
              }
            }).map((tab, index) => (
              <CTabPane
                visible={state.activeTabIndex === index}
                style={{
                  opacity: state.activeTabIndex === index ? 1 : 0,
                  display: state.activeTabIndex === index ? "block" : "none",
                }}
              >
                <CCard>
                  <CCardBody style={{ background: "#ebedef" }}>
                    {state.activeTabIndex === index && (
                      <tab.panelComponent details={entry} type={section} />
                    )}
                  </CCardBody>
                </CCard>
              </CTabPane>
            ))}
          </CTabContent>
        </>
      </CCardBody>
    </CCard>
    {deleteConfirm ? (<><ActionConfirmation deleteConfirm={deleteConfirm} deleteAction={deleteAction} entry={entry} handleShowConfirmation={handleShowConfirmation}  /></>) : (<></>)}

    </>
  );
};

export default Buyer;
