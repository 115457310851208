import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { getApiCall } from "../../apiServices";
import {
  OrderItem,
  ReturnItem,
  SellerDetails,
  BuyerDetails,
  PaymentDetails,
  ReturnHistory,
  ReturnRemarks
} from "./tab-panels";
import {
  CCard,
  CCardBody,
  CCardText,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from "@coreui/react";
import { Button } from "semantic-ui-react";
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import CreditDebitNotePreview from "./CreditDebitNotePreview";
import CreditDebitNoteUpload from "./CreditDebitNoteUpload";
import { toast } from "react-toastify";

const TABS = [
  { label: "Order Items", key: "company", panelComponent: OrderItem, statusKey: "companyProfileCompleted", },
  { label: "Return Items", key: "company", panelComponent: ReturnItem, statusKey: "ReturnItem", },
  { label: "Buyer Details", key: "buyer", panelComponent: BuyerDetails, statusKey: "buyerDetailsCompleted", },
  { label: "Seller Details", key: "seller", panelComponent: SellerDetails, statusKey: "sellerDetailsompleted", },
  { label: "Payment & Refund Details", key: "paymentRefund", panelComponent: PaymentDetails, statusKey: "bankProcessCompleted", },
  { label: "Return History", key: "history", panelComponent: ReturnHistory, statusKey: "categoryProcessCompleted", },
  { label: "Remarks", key: "remarks", panelComponent: ReturnRemarks, statusKey: "returnRemarks", },
];

const defaultState = {
  activeTabIndex: -1,
  company: null,
  dueData: {},
  bank: null,
  brand: null,
  category: null,
  isFetching: {
    company: false,
    bank: false,
    brand: false,
    category: false,
    commercials: false,
  },
};
const localPermits = JSON.parse(localStorage.getItem("module"));
const ReturnList = ({ entry = {}, section, status=null, updateStatus, fetchReturns}, props) => {
  let  returnStatusLabel = entry?.status?.label;
  let returnStatusCode = entry?.status?.code;
  const [state, setState] = useState(defaultState);
  const [currentReturn, setCurrentReturn] = useState();
  const [selectedPaymentMode, setSelectedPaymentMode] = useState();
  const [amount, setAmount] = useState(entry?.refund?.payment?.amount)
  const [transactionId, setTransactionId] = useState()
  const [transactionIdPlaceHolder, setTransactionIdPlaceholder] = useState('Transaction ID')
  const [errorMessage, setErrorMessage] = useState();
  const [viewTransnModal, setViewTransnModal] = useState(false);
  const [viewTransnForm, setViewTransnForm] = useState(false);
  const [remarks, setRemarks] = useState()
  const [remarksCount, setRemarksCount] = useState(entry?.remarksCount)
  const [noteType, setNoteType] = useState();
  const [notePreviewModal, setNotePreviewModal] = useState(false)
  const [noteUploadModal, setNoteUploadModal] = useState(false)
  const [activeReturn, setActiveReturn] = useState();
  const [canEdit, setCanEdit] = useState(false);
  const [cancelConfirmVisible, setCancelConfirmVisible] = useState(false);
  const [permissions, setPermissions] = useState(localPermits.find((o) => o.moduleName == "Return & Refund"));
  const [duePermission, setDuePermission] = useState(
    permissions?.childItems?.find((o) => o.moduleName == "Due")
  );
  const [ApprovedPermission, setApprovedPermission] = useState(
    permissions?.childItems?.find((o) => o.moduleName == "Approved")
  );
  const [settlementPermission, setSettlementPermission] = useState(
    permissions?.childItems?.find((o) => o.moduleName == "Settlement")
  );
  const [RejectedPermission, setRejectedPermission] = useState(
    permissions?.childItems?.find((o) => o.moduleName == "Rejected")
  );
  const [CompletedPermission, setCompletedPermission] = useState(
    permissions?.childItems?.find((o) => o.moduleName == "Completed")
  );


  useEffect(() => {
    const localNavigation = JSON.parse(localStorage.getItem("module"));
    localNavigation?.forEach(Orderelement => {
      if(Orderelement.moduleName === 'Return & Refund'){
        let canIsEdit = false;
        Orderelement?.childItems?.forEach(element => {
          if('/order/'+section === element.modulePath){
            canIsEdit = element.isEdit === 0 ? false:true
            setCanEdit(canIsEdit)
          }
        });
      }
    });
  })

  const handleTabChange = (index, returnId) => {
    setActiveReturn(returnId)
    setState((prevState) => ({
      ...prevState,
      activeTabIndex: prevState.activeTabIndex === index ? -1 : index,
    }));
  };

  const handleViewTransnModalClose = () => {
    setViewTransnForm(false);
    setCancelConfirmVisible(false);
    setViewTransnModal(!viewTransnModal);
}

const handlePaymentMode = (e) => {
  let paymentMode = e.target.value;
  if(paymentMode === 'cheque'){
    setTransactionIdPlaceholder('Cheque No*')
  }else if(paymentMode === "online" || paymentMode === "upi"){
    setTransactionIdPlaceholder('Transaction ID*')
  }else{
    setTransactionIdPlaceholder('Transaction ID')
  }
  setSelectedPaymentMode(e.target.value)
}

const rejectComplete = () => {
  setViewTransnForm(false);
  setCancelConfirmVisible(false);
  setViewTransnModal(!viewTransnModal);
}

const acceptComplete = () => {
  setCancelConfirmVisible(true);
  setViewTransnForm(true);
}

const handleComplete = (entry) => {
  if((entry?.creditNoteURL === null || entry?.creditNoteURL === '')){
    // set credit note error
    toast.error("Credit note is required*")
  }else if(entry?.approvedWithDebitNote === 1 && (entry?.debitNoteURL === null || entry?.debitNoteURL === '')){
    // set debit note error
    toast.error("Debit note is required*")
  }else{
    setCurrentReturn(entry)
    setViewTransnModal(true);
    setCancelConfirmVisible(true);
    setViewTransnForm(true);
  }

}

  const markComplete = (entry) => {
    // if((!transactionId && !selectedPaymentMode) && (!remarks)){
    //   setErrorMessage("Either payment mode and transaction id or Remarks is required");
    // }

    if(!selectedPaymentMode){
      setErrorMessage("Payment mode is required");
    }else if(!amount){
      setErrorMessage("Amount is required");
    }else if(amount < 1){
      setErrorMessage("Amount can not less than 1");
    }else if(amount > parseInt(entry?.refund?.payment?.amount)){
      setErrorMessage("Amount can not greater than "+entry?.refund?.payment?.amount);
    }else if((selectedPaymentMode &&
      (selectedPaymentMode === 'upi' || selectedPaymentMode === 'online' || selectedPaymentMode === 'cheque')
      && !transactionId)){
      setErrorMessage("Payment transaction id is required");
    }else if(!remarks){
      setErrorMessage("Remarks is required");
    }else{
      let payloadComplete = {
        action: "complete",
        returnId: currentReturn?.returnId,
        orderId: currentReturn?.orderID,
        // items: selectedQty,
        status: 4,
        amount: amount,
        buyerPerson: currentReturn?.buyer.name,
        remark: remarks,
        selectedPaymentMode: selectedPaymentMode,
        branchName: "",
        accountNumber: "",
        ifsc: "",
        transactionId: transactionId,
      };
      updateStatus('complete',payloadComplete);
      setViewTransnModal(false)
    }

  }

  const toggelNoteUploadModal = (action) => {
    setNoteUploadModal(!noteUploadModal)
    if(action !== 'cancel'){
      fetchReturns()
    }
  }
  const toggelNotePreviewModal = (action) => {
    setNotePreviewModal(!notePreviewModal)
    if(action !== 'cancel'){
      fetchReturns()
    }
  }

  const downloadNotes = async(type, entry)=>{
    let url = 'creditNoteURL';
    if(type === 'debit-note'){
      url = 'debitNoteURL';
    }else{
      url = 'creditNoteURL';
    }
    const value = await getApiCall('/return/'+entry?.returnId+'/'+type);

      if(value.status === 1){
        const link = document.createElement('a');
        if(type=== 'debit-note'){
          link.href = value?.payload?.debitNoteURL;
        }else{
          link.href = value?.payload?.creditNoteURL;
        }

        link.target = '_blank';
        link.click();
        link.remove();
        toast.success(value?.message)

      }else{
        toast.error(value.message)
      }
  }

  const updateRemarkCount = (newCount) => {
    setRemarksCount(newCount)
  };

  return (
    <>
    <CCard className="mb-3">
      <CCardBody>
        <div
          className="d-flex justify-content-between mb-3 mt-2"
          style={{
            paddingBottom: "15px !important",
          }}
        >
          <div style={{width: "33%"}}>
            {[
              { label: "Buyer", name: "buyer", key: "buyer" },
              { label: "Credit Note", name: "creditNote", key: "creditNote" },
              { label: "Seller", name: "seller", key: "seller" },
              { label: "Debit Note", name: "debitNote", key: "debitNote" },
              { label: "Payment Mode", name: "paymentMode", key: "paymentMode" },
              { label: "Delivered On", name: "deliveredOn", key: "deliveredOn" },
              { label: "Return Initiated On", name: "initiatedOn", key: "initiatedOn" },
              { label: "Return Approved On", name: "approvedOn", key: "approvedOn" },
              { label: "Return Settled On", name: "settledOn", key: "settledOn" },
              { label: "Return Completed On", name: "completedOn", key: "completedOn" },
              { label: "Return Rejected On", name: "rejectedOn", key: "rejectedOn" },
              { label: "Return Cancelled On", name: "cancelledOn", key: "cancelledOn" },
            ].filter((sec) => {

              if(sec?.key === 'approvedOn' || sec?.key === 'settledOn' || sec?.key === 'completedOn' || sec?.key === 'rejectedOn' || sec?.key === 'cancelledOn'){
                if(sec.key === 'approvedOn' && entry.approvedOn === 0){
                  return false
                }
                else if(sec.key === 'settledOn' && entry.settledOn === 0){
                  return false
                }else if(sec.key === 'completedOn' && entry.completedOn === 0){
                  return false
                }else if(sec.key === 'rejectedOn' && entry.rejectedOn === 0){
                  return false
                }else if(sec.key === 'cancelledOn' && entry.cancelledOn === 0){
                  return false
                }
                else{
                  return true;
                }
              }

              if(returnStatusCode === 1 || returnStatusCode === 3){
                if(sec.key === "debitNote" && entry?.approvedWithDebitNote === 0){
                  return false;
                }else{
                  return true;
                }

              }
              else if(returnStatusCode === 4){
                if(sec.key === "debitNote" && !entry?.debitNoteURL){
                  return false
                }else if(sec.key === "creditNote" && !entry?.creditNoteURL){
                  return false
                }else{
                  return true
                }
              }
              else if(sec?.key === "debitNote" || sec?.key === "creditNote"){
                return false
              }else{
                return true
              }
            }).map((item) => {
              return (
                <div className={clsx({ "d-flex": item.key !== "remarks" })}>
                  <CCardText
                    className={clsx("text-primary-label font-weight-bold m-1", {
                      "text-danger": item.key === "remarks",
                    })}
                  >
                    {item.label.trim()}:

                  </CCardText>

                  <CCardText className={clsx("m-1 ms-1 text-primary-value", { "text-danger": item.key === "remarks",})} style={{textTransform:"capitalize"}}>
                    {(() => {
                      if (item.name === "buyer") {
                        return (<>
                        {entry[item.key]["name"]?.trim() +  " ("+entry[item.key]["tradeName"]?.trim()+") "}

                        </>)

                      } else if (item.name === "seller") {
                        return (<>
                        {entry[item.key]["name"]?.trim() +  " ("+entry[item.key]["companyName"]?.trim()+") "}

                        </>)

                      }
                      else if ((returnStatusCode === 1 || returnStatusCode === 3) && item.name === "creditNote") {
                        return  <>
                          <span>
                              {entry?.creditNoteURL ? (<>
                                <span className="text-primary-value" style={{cursor:"pointer"}} onClick={()=>downloadNotes('credit-note', entry)}>Download</span>
                              </>) : (<>
                                {/* <span style={{marginRight:"5px"}}>
                                    <span className="" style={{cursor:"pointer"}} onClick={()=>{
                                      setNoteType('creditNote')
                                      setNotePreviewModal(true)
                                    }}>Preview</span>
                                  </span> */}
                                  <span style={{marginRight:"5px"}}>
                                    <span className="text-primary-value" style={{cursor:"pointer"}} onClick={() => {
                                      setNoteType('creditNote')
                                      setNoteUploadModal(true)
                                    }}>Upload</span>
                                </span>
                              </>)}
                            </span>
                        </>
                      }
                      else if ((returnStatusCode === 1 || returnStatusCode === 3) && item.name === "debitNote") {
                        return  <>
                          <span>
                            {entry?.debitNoteURL ? (<>
                              <span className="text-primary-value" style={{cursor:"pointer"}} onClick={()=>downloadNotes('debit-note', entry)}>Download</span>
                            </>) : (<>
                                {/* <span style={{marginRight:"5px"}}>
                                  <span className="" style={{cursor:"pointer"}} onClick={()=>{
                                        setNoteType('debitNote')
                                        setNotePreviewModal(true)
                                      }}>Preview
                                  </span>
                                  </span> */}
                                  <span style={{marginRight:"5px"}}>
                                    <span className="text-primary-value" style={{cursor:"pointer"}} onClick={() => {
                                      setNoteType('debitNote')
                                      setNoteUploadModal(true)
                                      }}>Upload
                                    </span>
                                  </span>
                            </>)}
                          </span>
                        </>
                      }
                      else if(returnStatusCode === 4 && item.name === "creditNote"){
                        return (<>
                        <span>
                          {entry?.creditNoteURL ? (<>
                            <span className="text-primary-value" style={{cursor:"pointer"}} onClick={()=>downloadNotes('credit-note', entry)}>Download</span>
                          </>) : ("")}
                        </span>
                        </>)

                      }
                      else if(returnStatusCode === 4 && item.name === "debitNote"){
                        return (<>
                        <span>
                          {entry?.debitNoteURL ? (<>
                            <span className="text-primary-value" style={{cursor:"pointer"}} onClick={()=>downloadNotes('debit-note', entry)}>Download</span>
                          </>) : ("")}
                        </span>
                        </>)

                      }
                      else if (item.name === "returnDetail") {
                        return  <>
                          {entry[item.key]["items"]} Item{entry[item.key]["items"] > 1 && 's'}{" "}
                          {entry[item.key]["quantity"]} Quantity
                        </>
                      }else if (item.name === "amount") {
                        return "₹"+entry?.returns?.amount;
                      }else if (item.name === "paymentMode") {
                        return (<>
                        {entry?.paymentMode} {(entry?.paymentOption != 'cod' && entry?.paymentOption != null)  ? ("("+entry?.paymentOption+")"):""}
                          {/* {entry?.paymentMode === 'online' ? (<>Online (<>{entry?.paymentOption}</>)</>) : (<>{entry?.paymentMode}</>)} */}
                          </>
                        );
                      }else if (item.name === 'deliveredOn') {
                        return entry[item.key];
                      }
                      else if(item.name === "initiatedOn"){
                        return (<>
                          {moment(entry[item.key]*1000).format( "YYYY-MM-DD")}
                        </>)
                      }
                      else if(item.name === "approvedOn" &&  entry?.approvedOn !== 0){
                        return (<>
                          {moment(entry[item.key]*1000).format( "YYYY-MM-DD")}
                        </>)
                      }
                      else if(item.name === "settledOn" &&  entry?.settledOn !== 0){
                        return (<>
                          {moment(entry[item.key]*1000).format( "YYYY-MM-DD")}
                        </>)
                      }
                      else if(item.name === "completedOn" &&  entry?.completedOn !== 0){
                        return (<>
                          {moment(entry[item.key]*1000).format( "YYYY-MM-DD")}
                        </>)
                      }
                      else if(item.name === "rejectedOn" &&  entry?.rejectedOn !== 0){
                        return (<>
                          {moment(entry[item.key]*1000).format( "YYYY-MM-DD")}
                        </>)
                      }
                      else if(item.name === "cancelledOn" &&  entry?.cancelledOn !== 0){
                        return (<>
                          {moment(entry[item.key]*1000).format( "YYYY-MM-DD")}
                        </>)
                      }
                       else if (entry[item.key]) {
                        // return entry[item.key];
                      }
                    })()}
                  </CCardText>
                </div>
              );
            })}

            {/* {returnStatusCode !== 0 ? (<>
              <div className={clsx( "d-flex" )}>
                  <CCardText className={clsx("text-primary font-weight-bold m-1")}
                  >
                    {returnStatusCode === 1 && ('Return Approved On:')}
                    {returnStatusCode === 2 && ('Return Rejected On:')}
                    {returnStatusCode === 3 && ('Return Settlement On:')}
                    {returnStatusCode === 4 && ('Return Completed On:')}

                  </CCardText>

                  <CCardText className={clsx("m-1 ms-1")}>
                  {moment(entry?.processedOn*1000).format( "YYYY-MM-DD")}
                  </CCardText>
                </div>
            </>) : ""} */}

            {returnStatusCode === 2 ? (<>
              <div className={clsx( "d-flex" )}>
                  <CCardText className={clsx("text-primary-label font-weight-bold m-1")}
                  >
                    <span style={{color:"red"}}>Reason for rejection:</span>
                  </CCardText>

                  <CCardText className={clsx("m-1 ms-1 text-primary-value")}>
                  <span style={{color:"red"}}>{entry?.remarks}</span>
                  </CCardText>
                </div>
            </>) : ""}
          </div>
          <div>
            {[
              { label: "Order Number", key: "orderNumber" },
              { label: "Buyer Invoice No.", key: "buyerInvoicenumber" },
              { label: "Seller Invoice No.", key: "sellerInvoiceNumber" },
              { label: "Return Number", key: "returnOrderNumber" },
              // { label: "Delivery To", key: "deliveredOn" },
            ].map((item) => {
              return (
                <div className={clsx({ "d-flex": item.key !== "remarks" })}>
                  <CCardText className={clsx("text-primary-label font-weight-bold m-1", {"text-danger": item.key === "remarks",})}>
                    {item.label}:
                  </CCardText>

                  <CCardText className={clsx("m-1 me-1 text-primary-value", {"text-danger": item.key === "remarks",})}>
                    {(() => {
                      if (entry[item.key]?.["items"]) {
                        return (
                          <>
                            {entry[item.key]["items"]} Item{entry[item.key]["items"] > 1 && 's'}{" "}
                            {entry[item.key]["quantity"]} Quantity
                          </>
                        );
                      }else{
                        return (<>
                          {entry[item.key]}
                        </>)
                      }
                    })()}
                  </CCardText>
                </div>
              );
            })}
          </div>

          <div>
            <div>
            <Button basic color="brown">

              ₹ {entry?.returns?.grandTotal } |
              {/* If approval items then show approval item details else show return items details */}
              {entry?.returns?.approval.items !== 0 ? (entry?.returns?.approval.items) : (entry?.returns?.items)}
              {/* If approval items is greater than 1 then Items else check for return items and if return items is greater than 1 'display Items' else 'Item */}
              {entry?.returns?.approval.items !== 0 ? (entry?.returns?.approval.items > 1 ? ' Items ' : ' Item ') : entry?.returns?.items > 1 ? ' Items ' : ' Item '}
              { entry?.returns?.approval.quantity !== 0 ? entry?.returns?.approval.quantity : entry?.returns?.quantity} Qty

            </Button>

            </div>
            {/* {status === 0 ? (<>
              <Button
                color="green"
                style={{marginTop:"10px", float:"right"}}
                onClick={() => markSettle(entry)}
              >
                Settle
              </Button>
            </>) : (<></>)} */}
            {(returnStatusCode === 3 && settlementPermission.isEdit === 1) || (returnStatusCode === 1 && ApprovedPermission.isEdit === 1) ? (<>
              <Button
                color="green"
                style={{marginTop:"10px", float:"right"}}
                // onClick={() => setViewTransnModal(true)}
                onClick={() => handleComplete(entry)}
              >
                Mark Complete
              </Button>

            </>) : (<></>)}
            <div>

            </div>

          </div>

        </div>

        <div
          className={clsx("d-flex align-items-center", {
            "justify-content-between": !entry.allProfileCompleted,
            "justify-content-end": entry.allProfileCompleted,
          })}
        >
          {/* {!entry.allProfileCompleted && (
            <div class="ui raised segment">
              <a class="ui red ribbon label">Note</a>
              <span>Profile details are not completed</span>
            </div>
          )} */}
        </div>
        <>
              <CNav variant="tabs" className="returnbar">
                {TABS.filter((tab) => {
                  if(tab.key === 'paymentRefund' && (returnStatusCode === 0 || returnStatusCode === 1 || returnStatusCode === 2 || returnStatusCode === 5)){
                    return false
                  }
                  return true;
                }).map((tab, index) => {
                  return (
                    <>
                      <CNavItem>
                        <CNavLink
                          // href="#"
                          active={state.activeTabIndex === index && activeReturn === entry?.returnId}
                          onClick={() =>
                            handleTabChange(index, entry?.returnId)
                          }
                        >
                          <div>{tab.label}
                          {tab?.key === 'remarks' && (remarksCount > 0) ? (<>
                            {" ("+remarksCount+")"}
                          </>) : (<></>)}
                          </div>
                        </CNavLink>
                      </CNavItem>
                    </>
                  );
                })}
              </CNav>
              <CTabContent>

                {TABS.filter((tab) => {
                  if(tab.key === 'paymentRefund' && (returnStatusCode === 0 || returnStatusCode === 1 || returnStatusCode === 2 || returnStatusCode === 5)){
                    return false
                  }
                  return true;
                }).map((tab, index) => (
                  <CTabPane
                    show={state.activeTabIndex === index && activeReturn === entry?.returnId}
                    style={{
                      opacity: state.activeTabIndex === index && activeReturn === entry?.returnId ? 1 : 0,
                      display:
                        state.activeTabIndex === index && activeReturn === entry?.returnId ? "block" : "none",
                    }}
                  >
                    <CCard>
                      <CCardBody style={{ background: "#ebedef" }}>
                        {state.activeTabIndex === index && activeReturn === entry?.returnId && (
                          <tab.panelComponent
                            details={entry}
                            isLoading={state.isFetching[tab.key]}
                            status={returnStatusCode}
                            section={section}
                            updateStatus={updateStatus}
                            updateRemarkCount={updateRemarkCount}
                          />
                        )}
                      </CCardBody>
                    </CCard>
                  </CTabPane>
                ))}
              </CTabContent>

        </>
        </CCardBody>
    </CCard>


    <Modal show={viewTransnModal} onHide={handleViewTransnModalClose} backdrop="static" keyboard={false} size="md">
        <Modal.Header>
            <Modal.Title>{viewTransnForm ? 'Transaction Detail' : ''} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {viewTransnForm ? (<>
            <div className="container">
                <div className="row">
                    <div className="col-12 mb-2">
                        <div>
                          Payment mode<span>*</span>:
                           <select name="payment_mode" className="form-control" onChange={(e) => handlePaymentMode(e)}>
                            <option value="">Select </option>
                            <option value="upi">UPI</option>
                            <option value="online">Online</option>
                            {/* <option value="rupifi">Rupifi</option> */}
                            <option value="switchpe">SwitchPe</option>
                            <option value="cheque">Cheque</option>
                            <option value="scash">S-Cash</option>
                           </select>
                        </div>
                    </div>

                    <div className="col-12 mb-2">
                        <div>
                          Amount<span>*</span>:
                          <input type="number" name="amount" className="form-control" placeholder={amount}
                          defaultValue={amount}
                          onChange = {(e) => setAmount(e.target.value)}
                          max={entry?.refund?.payment?.amount}
                          min={1}
                          // onChange={(e) => handleTransDetail(e)}
                           />
                        </div>
                    </div>

                    <div className="col-12 mb-2">
                        <div> {transactionIdPlaceHolder}:
                          <input type="text" name="transactionId" className="form-control"
                          onChange = {(e) => setTransactionId(e.target.value)}
                          // onChange={(e) => handleTransDetail(e)}
                           />
                        </div>
                    </div>

                    <div className="col-12">
                        <div>
                          Remarks<span>*</span>:
                          <textarea className="form-control" onChange={(e) => setRemarks(e.target.value)}></textarea>
                        </div>
                    </div>

                </div>

            </div>
          </>) : (<></>)}

        </Modal.Body>
        <Modal.Footer>

            {!cancelConfirmVisible ? (<>
              <Button className="btn btn-danger" onClick={() => rejectComplete()}>  Cancel </Button>
              <Button className="btn blue btn-primary btn-md"  onClick={() => acceptComplete()}> Submit </Button>
              <span style={{color:"red"}}>{errorMessage}</span>
            </>) : (<>
              <span style={{color:"red"}}>{errorMessage}</span>
                <Button className="btn btn-danger" onClick={() => rejectComplete()}>  No </Button>
                <Button className="btn blue btn-primary btn-md"  onClick={() => markComplete(entry)}> Yes </Button>

            </>)}
        </Modal.Footer>
    </Modal>

    {notePreviewModal ? (<>
      <CreditDebitNotePreview entry={entry} noteType={noteType} notePreviewModal={notePreviewModal} toggelNotePreviewModal={toggelNotePreviewModal} />
    </>) : (<></>)}
    {noteUploadModal ? (<>
      <CreditDebitNoteUpload entry={entry} noteType={noteType} noteUploadModal={noteUploadModal} toggelNoteUploadModal={toggelNoteUploadModal} />
    </>) : (<></>)}
    </>
  );
};

export default React.memo(ReturnList);
