import React, { useEffect, useState, useRef } from "react";
import {
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBRow,
} from "mdb-react-ui-kit";
import moment from 'moment';
import { postAPI } from "../../../apiServices";
import { getApiCall } from "../../../apiServices";
import RemarkEditor from "../../../common-components/contentEditable/ContentEditable"

const RemarksHistory = (props) => {
    const [remarks, setRemarks] = useState();
    const [showLegend, setShowLegend] = useState(false);
    const [newNote, setNewNote] = useState()

    const contentEditableRef = useRef(null);

    useEffect(() => {
        getRemarks(props?.details?.orderID)
    },[])

    const getRemarks = async(orderId) => {
        let res = await getApiCall('/order/' + orderId + '/get-remarks')
        if(res?.status === 1){
            setRemarks(res?.payload)
            props?.updateRemarkCount(res?.payload.length);
        }
    }

    const placeCaretAtEnd = (element) => {
      element.focus();
      if (typeof window.getSelection != 'undefined' && typeof document.createRange != 'undefined') {
        const range = document.createRange();
        range.selectNodeContents(element);
        range.collapse(false);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      }
    };

    useEffect(() => {
      if (contentEditableRef.current) {
        placeCaretAtEnd(contentEditableRef.current);
      }
    }, [newNote]);

    const submitNote = async (remarksText) => {
        let orderId = props?.details?.orderID
        let data = {
            orderID: orderId,
            remarks: remarksText
        }

        let res = await postAPI('/order/save-remarks', data)
        if(res?.status === 1){
            setNewNote()
            setShowLegend(false)
            getRemarks(orderId)
        }
    }

    return (
        <MDBContainer style={{ maxWidth: "1000px" }}>
      <MDBRow className="justify-content-center">
        <MDBCol md="8" lg="12">
          <MDBCard
            className="shadow-0 border"
            style={{ backgroundColor: "#f0f2f5" }}
          >

            <MDBCardBody>

                  <div className="remarkField form-group">
                    <RemarkEditor enterSubmit={true} onClickSubmit={submitNote} />

                </div>
                <div style={{maxHeight:"300px", overflowY:"scroll"}}>
                    {remarks?.map((item) => {

                        let oStatusColor = '';
                        if(item?.orderStatus === 'Placed'){
                            oStatusColor = "placed"
                        }else if(item?.orderStatus === 'Confirmed'){
                            oStatusColor = "confirmed"
                        }else if(item?.orderStatus === 'Packaged'){
                            oStatusColor = "packaged"
                        }else if(item?.orderStatus === 'Dispatched'){
                            oStatusColor = "dispatched"
                        }else if(item?.orderStatus === 'Delivered'){
                            oStatusColor = "delivered"
                        }else if(item?.orderStatus === 'Canceled'){
                          oStatusColor = "canceled"
                      }else if(item?.orderStatus === 'RTO'){
                        oStatusColor = "rto"
                      }
                        return (<>
                        <MDBCard className="mb-1">
                        <MDBCardBody>
                          {/* {item?.remarks?.map((line) => {
                            return (<>
                              <p><span dangerouslySetInnerHTML={{ __html:  line }} />{item?.author ? (<><span> by {item?.createdByName} {item?.employeeID ? ("("+item?.employeeID+")") : ("")}</span>
                              <span style={{float:"right"}}>

                                <span className={"badge bg-"+oStatusColor} style={{color:"#fff", marginLeft:"5px"}} > {" "}{item?.orderStatus}</span>
                                <span title={item?.createdOn} style={{cursor:"pointer"}}> {moment(item?.createdOn).fromNow()}</span>
                              </span></>) : (<></>)}</p>
                            </>)
                          })} */}
                          <p><span dangerouslySetInnerHTML={{ __html:  item.remarks }} />{item?.author ? (<><span> by {item?.createdByName} {item?.employeeID ? ("("+item?.employeeID+")") : ("")}</span>
                              <span style={{float:"right"}}>

                                <span className={"badge bg-"+oStatusColor} style={{color:"#fff", marginLeft:"5px"}} > {" "}{item?.orderStatus}</span>
                                <span title={item?.createdOn} style={{cursor:"pointer"}}> {moment(item?.createdOn).fromNow()}</span>
                              </span></>) : (<></>)}</p>

                        {!item?.author ? (<>
                          <div className="d-flex justify-content-between">
                              <div className="d-flex flex-row align-items-center">
                              <div className="mb-0">
                                  <span className="userLetter" style={{float:"left", textTransform:"uppercase"}}>{item?.createdByName?.[0]}</span>
                                  <span style={{marginLeft:"10px", marginTop:"7px"}}>{item?.createdByName}</span>
                                  <span className={"badge bg-"+oStatusColor} style={{color:"#fff", marginLeft:"5px", marginTop:"7px"}} > {" "}{item?.orderStatus}</span>
                              </div>
                              </div>
                              <div className="d-flex flex-row align-items-center">
                              <p title={item?.createdOn} style={{cursor:"pointer"}}>{moment(item?.createdOn).fromNow()}</p>
                              </div>
                          </div>
                        </>) : (<></>)}


                        </MDBCardBody>
                    </MDBCard>
                        </>)
                    })}
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
      );
}
export default RemarksHistory;
