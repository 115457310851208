import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { postAPI } from "../../apiServices";
import {
  CCardText,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CButton,
  CBadge,
} from "@coreui/react";
import { Table } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Skus from "./Skus";
import Swal from "sweetalert2";

const noop = () => {};
const localPermits = JSON.parse(localStorage.getItem("module"));

const CATEGORIES = ["superCategory", "mainCategory", "category", "subCategory"];
const PRODUCT_TYPE = {
  1: { label: "COLOR VARIANTS", color: "success" },
  2: { label: "SETS VARIANTS", color: "warning" },
};
const PRODUCT_STATUS = [
  { label: "Active", value: "active", key: 1, type: "status" },
  { label: "Inactive", value: "inactive", key: 0, type: "status" },
  { label: "Blocked", value: "blocked", key: -1, type: "status" },
  { label: "Approved", value: "approved", key: 1, type: "approve" },
  // { label: "Not Approved", value: "not-approved", key: 0, type: "approve" },
  { label: "Rejected", value: "rejected", key: 2, type: "approve" },
];

const defaultState = {
  productStatus: PRODUCT_STATUS.find((status) => status.type === "status"),
  approveStatus: PRODUCT_STATUS.find((status) => status.type === "approve"),
};

const Product = ({
  product = {},
  index = 0,
  onStatusChange = noop,
  onDelete = noop,
  showImageViewer = noop,
  showProductDetails = noop,
  updateProductDetails = noop,
  section = "",
}) => {
  const [state, setState] = useState(defaultState);
  const [loyaltyPoints, setLoyaltyPoints] = useState();

  useEffect(() => {
    setState((prevState) => ({
      ...defaultState,
      productStatus: PRODUCT_STATUS.find(
        (status) => status.key === product.status && status.type === "status"
      ),
      approveStatus: PRODUCT_STATUS.find(
        (status) =>
          status.value === product.currentStatus && status.type === "approve"
      ),
    }));
  }, [product]);

  const handleChange = (e) => {
    let value = e.target.value;
    let id = e.target.id;
    switch (id) {
      case "loyaltyPoints":
        var reg = /^\d+$/;
        var vaidNum = reg.test(value);
        if (!vaidNum && value != "") {
          break;
        }

        // details.showroomMarginInPercent = value;
        setLoyaltyPoints(value);
        break;
    }
  };
  const updateLoyaltyPoints = async () => {
    if (!product.sellerId) {
      return;
    }
    setState((prevState) => ({ ...prevState, loading: true }));
    const { status, payload, message, error } = await postAPI(
      "/set-loyalty-points",
      {
        sellerId: product?.sellerId,
        productId: product?.productId,
        amount: loyaltyPoints,
      }
    );
    Swal.close();
    setState((prevState) => ({ ...prevState, loading: false })) ;

    if (status == 0) {
      toast.error(message);
    } else {
      toast.success(message);
    }
  };

  const handleStatusChange = (
    status = {},
    type = "",
    id = null,
    action = "",
    entryName = ""
  ) => {
    onStatusChange(status, type, id, action, entryName, () => {
      setState((prevState) => ({
        ...prevState,
        [type]: status,
      }));
    });
  };
  const [permissions, setPermissions] = useState(
    localPermits.find((o) => o.moduleId == 16)
  );
  return (
    <>
    <Table.Row>
        <Table.Cell
          className="ps-2"
          style={{ border: "1px solid #ccc", borderRight: 0, borderBottom: 0 }}
        >
          {index + 1}
        </Table.Cell>

        <Table.Cell
          style={{
            border: "1px solid #ccc",
            borderRight: 0,
            borderBottom: 0,
            borderLeft: 0,
          }}
        >
          {!product.sellerApprovalStatus && (
            <div class="ui raised segment w-50">
              <a class="ui red ribbon label">Note</a>
              <span>Seller Not Approved</span>
            </div>
          )}
          <h3
            onClick={() => showProductDetails(product)}
            style={{ cursor: "pointer" }}
          >
            {product.fullName}
          </h3>
          <div className="d-flex">
            {CATEGORIES.map((item, index) => (
              <div className="d-flex align-items-center">
                <div>
                  <p className="me-2 text-muted">{product[item]}</p>
                </div>
                {product[CATEGORIES[index + 1]] && (
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="text-muted me-2"
                  />
                )}
              </div>
            ))}
          </div>
          {product?.brandDetails && product.brandDetails[0]?.name && (
            <div className="pt-2 pb-2">
              <p>
                {(product?.brandDetails && product.brandDetails[0]?.name) ||
                  "-"}
              </p>
            </div>
          )}
          {product?.sellerName && (
            <div className="pt-2 pb-2">
              <p>
                {" "}
                Company Name:{" "}
                {product.companyDetails[0].companyName +
                  " (" +
                  product?.sellerName +
                  ")" || "-"}
              </p>
            </div>
          )}
          <div className="pt-2 pb-2">
            <CBadge
              className="p-2"
              color={PRODUCT_TYPE[product.productType]?.color}
            >
              {PRODUCT_TYPE[product.productType]?.label}
            </CBadge>
          </div>
          {section === "rejected" && (
            <div>
              <CCardText className={clsx("text-danger font-weight-bold m-1")}>
                Reason For Rejection:
              </CCardText>
              <CCardText className={clsx("m-1 ms-1 text-danger")}>
                {product.rejectionRemarks}
              </CCardText>
            </div>
          )}
        </Table.Cell>

        <Table.Cell
          className="pe-2"
          style={{ border: "1px solid #ccc", borderBottom: 0, borderLeft: 0 }}
        >
          <div className="d-flex align-items-center justify-content-end">
            {section === "in-review" ? (
              <CButton
                disabled={permissions.isEdit === 0 ? true : false}
                color="primary"
                className="ms-1 me-1"
                onClick={() => updateProductDetails(product)}
                style={{ cursor: "pointer", width: "117px" }}
              >
                Update Product
              </CButton>
            ) : (
              ""
            )}

            <CButton
              disabled={permissions.isEdit === 0 ? true : false}
              color="success"
              className="ms-1 me-2"
              onClick={() => showProductDetails(product)}
              style={{ cursor: "pointer", width: "101px" }}
            >
              View Product
            </CButton>

            <CDropdown className="me-2">
              <CDropdownToggle
                color="info"
                disabled={permissions.isEdit === 0 ? true : false}
              >
                {state.approveStatus?.label || "Not Approved"}
              </CDropdownToggle>
              <CDropdownMenu>
                {PRODUCT_STATUS.filter(
                  (status) =>
                    status.type === "approve" &&
                    status.key !== state.approveStatus?.key
                ).map((status) => (
                  <CDropdownItem
                    disabled={!product.sellerApprovalStatus}
                    onClick={() =>
                      handleStatusChange(
                        status,
                        "product-approval",
                        product.productId,
                        "Update Product Approval Status",
                        product.fullName || product.email || product.mobile
                      )
                    }
                  >
                    {status.label}
                  </CDropdownItem>
                ))}
              </CDropdownMenu>
            </CDropdown>
            <CButton
              disabled={permissions.isEdit === 0 ? true : false}
              color="danger"
              variant="outline"
              className="me-2"
              onClick={() =>
                onDelete(
                  product.productId,
                  "product-trash",
                  "Delete Product",
                  product.fullName || product.email || product.mobile
                )
              }
            >
              Delete
            </CButton>
          </div>
          {/* <div className="d-flex align-items-right justify-content-end" style={{ marginTop: "31px", marginRight: '101px' }}>
          <b>SCash</b>
          </div>
          <div className="d-flex align-items-right justify-content-end" >
          <div className="d-flex">
            <Form>
              <Form.Field>
                <input
                  disabled={permissions.isEdit === 0 ? true : false}
                  type="text"
                  className="inputfile"
                  onChange={(e) => handleChange(e)}
                  // id="loyaltyPoints"
                  placeholder="SCash"
                  defaultValue={product?.loyalty_points_per100}
                />
              </Form.Field>
            </Form>
            <CButton
              disabled={permissions.isEdit === 0 ? true : false}
              color="primary"
              className="ms-1 me-1"
              onClick={() => updateLoyaltyPoints()}
            >
              Update (Per 100)
            </CButton>
          </div>
        </div> */}
        </Table.Cell>
      </Table.Row>
      {product.skus.map((sku, index) =>
        sku.skuCode ? (
          <Table.Row>
            <Table.Cell
              style={{
                borderLeft: "1px solid #ccc",
                borderBottom:
                  product.skus.length - 1 === index ? "1px solid #ccc" : "none",
              }}
            ></Table.Cell>
            <Table.Cell
              colspan={2}
              style={{
                borderRight: "1px solid #ccc",
                borderBottom:
                  product.skus.length - 1 === index ? "1px solid #ccc" : "none",
              }}
            >
              <Skus
                sku={sku}
                product={product}
                onDelete={onDelete}
                showImageViewer={showImageViewer}
                showProductDetails={showProductDetails}
                onStatusChange={handleStatusChange}
              />
            </Table.Cell>
          </Table.Row>
        ) : (
          sku.variants.map((variant, index) => (
            <Table.Row>
              <Table.Cell
                style={{
                  borderLeft: "1px solid #ccc",
                  borderBottom:
                    product?.skus.length - 1 === index
                      ? "1px solid #ccc"
                      : "none",
                }}
              ></Table.Cell>
              <Table.Cell
                colspan={2}
                style={{
                  borderRight: "1px solid #ccc",
                  borderBottom:
                    product.skus.length - 1 === index
                      ? "1px solid #ccc"
                      : "none",
                }}
              >
                <Skus
                  sku={{
                    ...sku,
                    skuCode: variant.skuCode,
                    color: sku.colorName,
                  }}
                  product={product}
                  onDelete={onDelete}
                  showImageViewer={showImageViewer}
                  showProductDetails={showProductDetails}
                />
              </Table.Cell>
            </Table.Row>
          ))
        )
      )}
      <Table.Row>
        <Table.Cell colspan={3}></Table.Cell>
      </Table.Row>
    </>
  );
};

export default Product;
