import React, { Suspense } from "react";
// import { Redirect, Route, Switch } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { CContainer } from "@coreui/react";

// routes config
import routes from "../routes";
import { getTokenData } from "../utils";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const TheContent = () => {
  const token = (getTokenData() || {}).TOKEN;
  return (
    <main className="c-main">
      <CContainer fluid>
        <Suspense fallback={loading}>
          <Routes>
            {routes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={(props) => (
                      <>
                        {token ? (
                          // <route.component {...props} />
                          <route.component {...props} />
                        ) : (
                          <></>
                          // <Navigate to={{ pathname: "/login" }} />
                        )}
                      </>
                    )}
                  />
                )
              );
            })}

            {/*

              <Redirect from="/" to="/sellers/approved" />
            */}
            {/* <Navigate from="/" to="/dashboard" /> */}
          </Routes>
        </Suspense>
      </CContainer>
    </main>
  );
};

export default React.memo(TheContent);
