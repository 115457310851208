import React, { useState, useEffect } from "react";
import { CCardText } from "@coreui/react";
import NoDetails from "./NoDetails";
import { postAPI } from "./../../../apiServices";

const BankDetails = ({ details = null, isLoading = false }) => {
  const [bankDetails, setbankDetails] = useState([]);
  useEffect(() => {
    async function getBuyerInfo() {
      let userId = details?.userId;
      var { status, payload } = await postAPI("/get-buyer-bankinfo", {
        userId,
      });
      if (status === 1) {
        setbankDetails(payload.data);
      } else {
        setbankDetails("");
      }
    }
    getBuyerInfo();
  }, []);
  return (
    <div className="d-flex">
      <div className="w-100">
        {bankDetails.length === 0 ? (
          <NoDetails isLoading={isLoading} tabName="Bank" />
        ) : (
          [
            { label: "Beneficiary", key: "beneficiary" },
            { label: "Account Number", key: "accountNumber" },
            { label: "Bank", key: "bankName" },
            { label: "Branch", key: "branchName" },
            { label: "IFSC", key: "ifsc" },
            { label: "View Cancelled Cheque", key: "cancelledCheque" },
          ].map((item) => (
            <div className="d-flex">
              {item.key === "cancelledCheque" ? (
                <a target="_blank" href={details && details[item.key]}>
                  {item.label}
                </a>
              ) : (
                <>
                  <CCardText className="font-weight-bold">
                    {item.label}:
                  </CCardText>
                  <CCardText className="ms-1">
                    {details && details[item.key]}
                  </CCardText>
                </>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default BankDetails;
