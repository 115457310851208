import React, { useMemo } from 'react';
import { Table } from 'semantic-ui-react';

const Grid = ({
  config = [],
  data = []
}) => {
  const columnConfig = useMemo(() => config.filter(item => !item.isHidden), [config]);
  
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          {columnConfig.map(item => (
            <Table.Cell>{item.label}</Table.Cell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map((item, index) => (
          <Table.Row>
            {columnConfig.map(con => (
              <Table.Cell>{con.render ? con.render(item, index) : item[con.field]}</Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default Grid;