import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import Grid from "./Grid";
import {
  CButton,
  CCol,
  CContainer,
  CPagination,
  CRow,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import Swal from "sweetalert2";
import { postAPI } from "../../apiServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { AppContext } from "../../contexts";
import { toast } from "react-toastify";

const defaultState = {
  entries: [],
  totalEntries: 0,
  page: 0,
  recPerPage: 50,
  searchKeyword: "",
  loading: false,
  parentId: "",
  confirmDialog: null,
};

const Attributes = ({ match }) => {
  // const history = useHistory();
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState(defaultState);
  const {params} = useParams();
  const { attributeId, level } = useParams();
  // var {type} = useParams()
  const handleDelete = async (data) => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    const { status, payload, message } = await postAPI(
      "/delete-category-data",
      {
        id: data?.id,
        type: "product-attribute",
      }
    );
    setState((prevState) => ({
      ...prevState,
      loading: false,
    }));
    Swal.close();
    if (!status) {
      toast.error(message);
    } else {
      toast.success("Deleted Successfully");
      fetchAttribute(
        state.page,
        state.recPerPage,
        state.searchKeyword,
        attributeId, level
      );
    }
  };

  const fetchAttribute = useCallback(
    async (page = 0, recPerPage = 50, searchKeyword = "", parent = "") => {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      const data = {
        page,
        recPerPage,
        searchKeyword,
        parent,
        slugType: "product-attribute",
      };
      const { status, payload, message } = await postAPI(
        "/get-categories-list",
        data
      );

      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      Swal.close();

      if (!status) {
        setState((prevState) => ({
          ...prevState,
          entries: defaultState.entries,
          totalEntries: defaultState.totalEntries,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          entries: payload?.data || defaultState.entries,
          totalEntries: payload?.totRecords || defaultState.totalEntries,
          parentId: (payload?.data || [])[0]?.parentId || defaultState.parentId,
          confirmDialog: null,
        }));
      }
    },
    [attributeId, level]
  );

  useEffect(() => {
    fetchAttribute(
      state.page,
      state.recPerPage,
      state.searchKeyword,
      attributeId, level
    );
  }, [
    fetchAttribute,
    state.page,
    state.recPerPage,
    state.searchKeyword,
    attributeId, level,
  ]);

  const columnConfig = useMemo(
    () => [
      { label: "S.No", render: (row, index) => index + 1 },
      { label: "Category", field: "name" },
      { label: "Type", field: "attributeType" },
      {
        label: "Image",
        render: (row) => (
          <img src={row.leadImage} style={{ maxHeight: 100, maxWidth: 60 }} />
        ),
      },
      { label: "Parent", field: "parentId", isHidden: !state.parent },
      { label: "Priority", field: "priority" },
      {
        label: "Action",
        render: (row) => (
          <div className="d-flex align-items-center">
            <CButton
              variant="outline"
              color="primary"
              onClick={() => {
                const levelNew = +(level ?? 0) + 1;
                navigate(`/attribute-list/attributeId/${row.id}/level/${levelNew}`)
                // history.push({
                //   pathname: `/attribute-list/attributeId/${row.id}/level/${level}`,
                // });
              }}
            >
              Show Child
            </CButton>
          </div>
        ),
      },
      {
        render: (row) => (
          <div className="d-flex align-items-center justify-content-end">
            <FontAwesomeIcon
              icon={faTrashAlt}
              style={{ cursor: "pointer" }}
              color="primary"
              className="me-4 text-danger"
              // onClick={() => handleDelete(row)}
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  confirmDialog: {
                    row,
                    action: "Delete Attribute",
                    entryName: row.name,
                  },
                }));
              }}
            />
            <FontAwesomeIcon
              icon={faPencilAlt}
              style={{ cursor: "pointer" }}
              color="primary"
              className="me-2 text-primary"
              onClick={() => {
                navigate(`/edit-attribute?id=${row.id}&slugType=${row.slug}`)
                // history.push({
                //   pathname: "/edit-attribute",
                //   search: `?id=${row.id}&slugType=${row.slug}`,
                // });
              }}
            />
          </div>
        ),
      },
    ],
    [state]
  );

  if (state.loading) {
    let timerInterval;
    Swal.fire({
      title: "Loading...",
      timerProgressBar: false,
      allowOutsideClick: false,
      showCancelButton:false,
      showConfirmButton:false

    })
  }
  return (
    <>
      <CContainer>
        <CRow>
          <CCol lg="12" className="mb-2">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                {+(level ?? 0) > 0 && (
                  <CButton
                    color="primary"
                    onClick={() => {
                      const levelnew = +(level ?? 0);
                      if(level > 1 && state.parentId){
                        navigate(`/attribute-list/attributeId/${
                              state.parentId
                            }/level/${levelnew - 1}`)
                      }else{
                        navigate('/attribute-list')
                      }

                      // history.push(
                      //   level > 1 && state.parentId
                      //     ? `/attribute-list/attributeId/${
                      //         state.parentId
                      //       }/level/${level - 1}`
                      //     : "/attribute-list"
                      // );
                    }}
                  >
                    Move Level Up
                  </CButton>
                )}
              </div>
              <div></div>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="12">
            <Grid config={columnConfig} data={state.entries} />
          </CCol>
        </CRow>
        <CRow lg="12" className="mt-4">
          <CCol>
            {!!state.entries.length && (
              <div className="d-flex align-items-center justify-content-between">
                <p>{`Showing ${state.recPerPage * state.page + 1} to ${
                  state.recPerPage * state.page + state.entries.length
                } of ${state.totalEntries} record(s)`}</p>
                <CPagination
                  activePage={state.page + 1}
                  pages={Math.ceil(state.totalEntries / state.recPerPage)}
                  onActivePageChange={(page) => {
                    setState((prevState) => ({
                      ...prevState,
                      page: page ? page - 1 : page,
                    }));
                  }}
                />
              </div>
            )}
          </CCol>
        </CRow>
      </CContainer>
      <CModal
        alignment="center"
        show={!!state.confirmDialog}
        onClosed={() => {
          setState((prevState) => ({
            ...prevState,
            confirmDialog: null,
          }));
        }}
      >
        <CModalHeader>
          <CModalTitle>{`${state.confirmDialog?.action}`}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>{`Are you sure you want to continue?`}</div>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                confirmDialog: null,
              }));
            }}
          >
            Cancel
          </CButton>
          <CButton
            color="primary"
            onClick={() => {
              handleDelete(state?.confirmDialog?.row);
            }}
          >
            Delete
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default Attributes;
