import isEmpty from "validator/es/lib/isEmpty";
import isLength from "validator/es/lib/isLength";
import isFloat from "validator/es/lib/isFloat";
import isEmail from "validator/es/lib/isEmail";

// const REGEX_PHONE_EMAIL = /^(?:\d{10}|\w+@\w+\.\w{2,3})$/;
const REGEX_PHONE_EMAIL = /^(?:\d{10}|[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,})$/;
export const VALIDATIONS = {
  MAX_LENGTH: "maxLength",
  MIN_LENGTH: "minLength",
  MAX: "max",
  MIN: "min",
  REQUIRED: "required",
  EMAIL: "email",
};

export const validator = (
  validatorType,
  validationValue,
  value,
  type = "string"
) => {
  let message = null;
  value = (value ?? "").toString().trim();
  switch (validatorType) {
    case VALIDATIONS.REQUIRED:
      if (validationValue && isEmpty(value)) {
        message = "Required";
      }
      break;
    case VALIDATIONS.MAX_LENGTH:
      if (value && !isLength(value, { max: parseInt(validationValue) })) {
        message = `Max: ${validationValue} ${
          type === "number" ? "digit(s)" : "char(s)"
        }.`;
      }
      break;
    case VALIDATIONS.MIN_LENGTH:
      if (value && !isLength(value, { min: parseInt(validationValue) })) {
        message = `Min: ${validationValue} ${
          type === "number" ? "digit(s)" : "char(s)"
        }.`;
      }
      break;
    case VALIDATIONS.MAX:
      if (value && !isFloat(value, { max: parseInt(validationValue) })) {
        message = `Max value: ${validationValue}.`;
      }
      break;
    case VALIDATIONS.MIN:
      if (value && !isFloat(value, { min: parseInt(validationValue) })) {
        message = `Min value: ${validationValue}.`;
      }
      break;
    case VALIDATIONS.EMAIL:
      if (value && !isEmail(value)) {
        message = `Invalid email address.`;
      }
      break;
    case VALIDATIONS.REGEX_PHONE_EMAIL:
      if (value && !REGEX_PHONE_EMAIL.test(value)) {
        message = `Invalid email or mobile.`;
      }
      break;
  }
  return {
    isValid: !message,
    message,
  };
};
