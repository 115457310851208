import React, { useState, useEffect} from "react";
import { CButton} from "@coreui/react";
import { Form } from "semantic-ui-react";
import { postAPI } from "../../../apiServices";
import { toast } from "react-toastify";
const localPermits = JSON.parse(localStorage.getItem("module"));

const EmployeeId = ({ details = {}, isLoading = false }) => {
  const [employeeId, setEmployeeId] = useState(details.showroomB2BEmployeeId);
  const [userId, setUserId] = useState(details.userId || "");
  const [permissions, setPermissions] = useState(
    localPermits.find((o) => o.moduleId == 2)
  );

  useEffect(() => {
    if (details.userId) {
      setEmployeeId(details.showroomB2BEmployeeId);
      setUserId(details.userId || "");
    }
    async function fetchData() {}
    fetchData();
  }, []);

  const handleChange = (e) => {
    let value = e.target.value;
    let id = e.target.id;
    switch (id) {
      case "employeeId":
        var reg = /[^a-zA-Z0-9]/g;
        var vaidNum = reg.test(value);
        if (vaidNum && value != "") {
          break;
        }
        details.showroomB2BEmployeeId = value;
        setEmployeeId(value);
        break;
    }
  };

  const updateSellerEmpId = async () => {
    if (!details.userId || !employeeId) {
      return;
    }
    const { status, payload, message, error } = await postAPI(
      "/update-user-details",
      {
        userId: details.userId,
        userType: "seller",
        showroomB2BEmployeeId: employeeId,
      }
    );
    if (status == 1) {
      return toast.success(message);
    }
    toast.error("Something went wrong");
  };
  return (
    <>
      <b>Employee Id</b>
      <div className="d-flex">
        <Form>
          <Form.Field>
            <input
              disabled={permissions.isEdit === 0 ? true : false}
              type="text"
              className="inputfile"
              onChange={(e) => handleChange(e)}
              id="employeeId"
              value={details.showroomB2BEmployeeId}
              placeholder="Employee Id"
            />
          </Form.Field>
        </Form>
        <CButton
          disabled={permissions.isEdit === 0 ? true : false}
          color="primary"
          className="ms-1 me-1"
          onClick={() => updateSellerEmpId()}
        >
          Update
        </CButton>
      </div>
    </>
  );
};
export default EmployeeId;
