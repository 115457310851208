import React, { useState } from "react";
import { Card, Button } from "semantic-ui-react";
import { downloadFile } from "./../../apiServices";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';

const localPermits = JSON.parse(localStorage.getItem("module"));
function Finance() {
  const url = window.location.hash;
    if((url.match(/\//g) || []).length === 1){
      let listItems = document.querySelectorAll('li');
      listItems.forEach(item => item.classList.remove('c-show'));
    }
  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const downloadSaleBill = async () => {
    const data = {
      action: "deliver",
      searchKeyword: "",
      searchGlobally: 2,
      filters: [
        {
          param: "date-range",
          value: {
            start: Math.floor(new Date(selectedDateRange[0]?.startDate?.toString()).getTime()/1000),
            end: Math.floor(new Date(selectedDateRange[0]?.endDate?.toString()).getTime()/1000)
          }
        }]
    };
    await downloadFile("/order-sales-report", data);
  };
  const [permissions, setPermissions] = useState(
    localPermits.find((o) => o.moduleId == 33)
  );
  return (
    <div className="container">
      <Card fluid>
        <Card.Content header="Finance" />
        <Card.Content extra>
          <DateRangePicker
            onChange={item => {
                    setSelectedDateRange([item.selection])
                    if(item.selection.startDate && item.selection.endDate){
                        // setDateRangeModal(false)
                    }
                }}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            showMonthAndYearPickers={true}
            showDateDisplay={true}
            dateDisplayFormat={"d MMM, yyyy"}
            months={1}
            ranges={selectedDateRange}
            direction="horizontal"
          />;
          <Button
            disabled={permissions.isDownload === 0 ? true : false}
            primary
            onClick={() => downloadSaleBill()}
          >
            Sale Bill Download
          </Button>
        </Card.Content>
      </Card>
    </div>
  );
}

export default Finance;
