
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Grid } from "semantic-ui-react";
import { format } from "date-fns";

const RefundDetails = ({ details = {}, match, section, canEdit, isLoading = false }) => {
  useEffect(() => {
    async function fetchData() { }
    fetchData()
  }, []);
  return (
    <>
    <div>
      {details.refundDetails && (<>
        <Grid className="ml-1">
        <Grid.Row>
          <Grid.Column width={8}>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
            Payment Mode:{" "}
              <span style={{ fontWeight: "normal", textTransform:"capitalize" }}> {details?.refundDetails?.paymentMode}</span>{" "}
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
            Amount:{" "}
              <span style={{ fontWeight: "normal" }}> ₹{details?.refundDetails?.amount}</span>{" "}
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
            Payment Id:{" "}
              <span style={{ fontWeight: "normal" }}> {details?.refundDetails?.gateWayPaymentID}</span>
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
            Refund Transaction Id:{" "}
              <span style={{ fontWeight: "normal" }}> {details?.refundDetails?.refund_id}</span>
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
           Status:{" "}
              <span style={{ fontWeight: "normal", textTransform:"capitalize" }}> {details?.refundDetails?.status}</span>
            </div>
            <div style={{ fontWeight: "bold", fontSize: "13px" }}>
            Redunded On:{" "}
              <span style={{ fontWeight: "normal" }}>
               {format(new Date(details?.refundDetails?.createdOn*1000) ,'dd-MM-yyyy')}
              {/* {details?.refundDetails?.gateWayPaymentID} */}
              </span>
            </div>
          </Grid.Column>

          </Grid.Row>
        </Grid>
      </>)}

    </div>
    </>
  )
};

export default RefundDetails;
