import React, {useState, useEffect, useCallback, useRef, useContext} from "react";
import { AppContext } from "../../contexts/app";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Seller from "./Seller";
import { toast } from "react-toastify";
import { postAPI } from "../../apiServices";
import MESSAGES from "../../message.json";
import { Checkbox } from "semantic-ui-react";
import {CCol, CRow, CPagination, CFormInput, CButton, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CFormTextarea, CFormText, CHeader, } from "@coreui/react";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../common-components/Pagination";
import { minWidth } from "@mui/system";

let timeout = null;
const SELLER_TYPE = [
  {label: "new", value: 0, type: "seller-approval-status"},
  {label: "incomplete", value: null, type: null},
  {label: "approved", value: 1, type: "seller-approval-status"},
  {label: "rejected", value: 2, type: "seller-approval-status"},
  {label: "blocked", value: -1, type: "seller-status"},
  {label: "delete-request", value: 2, type: "delete-request"},
  {label: "deleted", value: 2, type: "seller-status"},
];

const Sellers = () => {
  const search = useLocation().search;
  var {type} = useParams()
  let qglobal = 0;
  let qpage = new URLSearchParams(search).get("pg");
  let qkeyword = new URLSearchParams(search).get("q");
  let qOperator = new URLSearchParams(search).get("o");
  let qFilterType = new URLSearchParams(search).get("f");
  qglobal = new URLSearchParams(search).get("global");
  if(qpage != null && qpage > 0){
    qpage = qpage-1;
  }else{
    qpage = 0;
  }
  const defaultState = {
    entries: [],
    totalEntries: 0,
    page: qpage,
    recPerPage: 50,
    searchKeyword: qkeyword,
    operator:qOperator,
    filterType: qFilterType,
    loading: false,
    confirmDialog: null,
    // shouldSearchGlobally: qglobal && qglobal === 0 ? false : true,
    shouldSearchGlobally:false
  };

  const { setSecondaryHeader } = useContext(AppContext);
  const [state, setState] = useState(defaultState);
  const [keywordError, setKeywordError] = useState()
  const [operatorError, setOperatorError] = useState()
  const [filterTypeError, setFilterTypeError] = useState()
  const navigate = useNavigate();


  const handleUrls = async () => {
    let data = [];
    if(state.searchKeyword && state.filterType && state.operator){
      data.push({key:'q', val:state.searchKeyword},{key:'f', val:state?.filterType},{key:'o', val:state.operator});
    }
    if(state?.page){
      data.push({key:'pg', val:state?.page+1})
    }
    if(state.shouldSearchGlobally){
      data.push({key:'global', val:1})
    }

    let searchUrl = '';
    data?.map((ele) => {
      searchUrl = searchUrl + ele?.key+'='+ele?.val+'&'
    })
    let lastChar = searchUrl.charAt(searchUrl.length - 1)
    if(lastChar === '&'){
      searchUrl = searchUrl.slice(0, -1)
    }
    navigate(`?${searchUrl}`, { replace: true });
  }

  const fetchSellers = async (
      page = qpage,
      recPerPage,
      searchKeyword = "",
      operator = "equals",
      filterType,
      shouldSearchGlobally = false,
      listingType = "all",
      searchGlobally = 0,

    ) => {
      setState((prevState) => ({ ...prevState, entries: [], totalEntries: 0 }));
      setState((prevState) => ({ ...prevState, loading: true }));
      const data = {
        page,
        recPerPage,
        searchKeyword,
        listingType,
        searchGlobally,
        operator,
        filterType,
        filters: [
          {
            param:
              SELLER_TYPE.find((item) => item.label === type)
                ?.type || "",
            value: [
              SELLER_TYPE.find((item) => item.label === type)
                ?.value,
            ],
          },
        ],
      };
      if (type === "incomplete" && !shouldSearchGlobally) {
        delete data.filters;
        data.listingType = "incomplete";
      }

      if (type === "delete-request" && !shouldSearchGlobally) {
        delete data.filters;
        data.listingType = "delete-requested";
      }

      if (shouldSearchGlobally) {
        delete data.filters;
        data.listingType = "";
        data.searchGlobally = 1;
      }
      if(filterType){
        data.filterType = filterType
      }
      const { status, payload, message } = await postAPI("/get-sellers-list", data);
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      Swal.close();

      if (!status) {
        if (message !== "No seller found")
          toast.error(message || MESSAGES?.sellers?.defaultApiError);
        setState((prevState) => ({
          ...prevState,
          entries: payload,
          totalEntries: state.totalEntries,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          entries: payload?.data || state.entries,
          // entries: [],
          totalEntries: payload?.totRecords || state.totalEntries,
        }));
      }
      // window.scrollTo(0, 0);
    }

    const updateStatus = useCallback(
      async (updatedStatus = {}, id, remarks = "") => {
        const { status, payload, message } = await postAPI(
          "/update-user-status",
          {
            id,
            type: updatedStatus.value,
            userType: "seller",
            remarks,
          }
        );

        if (!status) {
          toast.error(message || MESSAGES?.sellers?.defaultApiError);
        } else {
          handleUrls()
          fetchSellers(
            state.page,
            state.recPerPage,
            state.searchKeyword,
            state.operator,
            state.filterType,
            state.shouldSearchGlobally,
          );
        }
      },
      [
        fetchSellers,
        state.page,
        state.recPerPage,
        state.searchKeyword,
        state.operator,
        state.filterType,
        state.remarks,
        state.shouldSearchGlobally,

      ]
    );

  useEffect(() => {
    fetchSellers(
      state.page,
      state.recPerPage,
      state.searchKeyword,
      state.operator,
      state.filterType,
      state.shouldSearchGlobally,
    );

  }, [
    state.page,
    state.recPerPage,
    // state.searchKeyword,
    // state.operator,
    // state.filterType,
    // state.shouldSearchGlobally,
    type
    ])

    useEffect(() => {
      handleUrls();
    },[
        state.page,
      state.recPerPage,
      state.searchKeyword,
      state.operator,
      state.filterType,
      state.shouldSearchGlobally,
      type
    ])



  const handleData = async (e) => {
    setKeywordError()
    setOperatorError()
    setFilterTypeError()
    let id = e.target.id;
    let value = e.target.value;
    if(id==='filterType'){
      await setState((prevState) => ({
        ...prevState, 'filterType':value
      }));
      if(value){
        document.getElementById("filterType").style.borderColor = ""
      }
    }else if(id === 'operator'){
      await setState((prevState) => ({
        ...prevState, 'operator':value
      }));
      if(value){
        document.getElementById("operator").style.borderColor = ""
      }
    }

  }


  const resetFilter = () => {
    // history.location.pathname
    setState((prevState) => ({
      ...prevState,
      page:0,
      searchKeyword: "",
      operator: null,
      filterType: null,
      shouldSearchGlobally: false
    }))

    fetchSellers(0, state.recPerPage, null, null, null, false);
    // navigate({ pathname: navigate.location.pathname})
  }


  const handlePagination = (pgInfo) => {
    setState((prevState) => ({
      ...prevState,
      recPerPage: parseInt(pgInfo?.recPerPage),
      page: pgInfo?.page
    }));
  }

  return (
    <>
      <CHeader>
        <CRow>
          <CCol>
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
              <form
                className="d-flex flex-column flex-sm-row align-items-center flex-wrap w-100 searchForm"
                onKeyPress={(e) => {
                if (e.key === "Enter") {
                  setState((prevState) => ({
                    ...prevState,
                    page: 0,
                  }));
                  e.preventDefault();
                  document.getElementById("searchKeywordId").style.borderColor = ""
                  document.getElementById("filterType").style.borderColor = ""
                  document.getElementById("operator").style.borderColor = ""
                  setKeywordError()
                  setOperatorError()
                  setFilterTypeError()
                  if((state.searchKeyword && state.operator && state.filterType) || state.shouldSearchGlobally){
                    handleUrls()
                  }else{
                    if(!state.searchKeyword){
                      document.getElementById("searchKeywordId").style.borderColor = "red"
                      setKeywordError("Required")
                    }
                    if(!state.operator){
                      document.getElementById("operator").style.borderColor = "red"
                      setOperatorError("Required")
                    }
                    if(!state.filterType){
                      document.getElementById("filterType").style.borderColor = "red"
                      setFilterTypeError("Required")
                    }
                  }
                }
              }}>
              <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-2">

                    <select className="ms-2 form-control"
                    onChange={(e) => handleData(e)}
                    id="filterType"
                    style={{borderColor: filterTypeError ? "red" : "", minWidth:"150px"}}
                    >
                      <option value="" selected={state.filterType==='' ? true :false}>Select</option>
                      <option value="name" selected={state.filterType==='name' ? true :false}>Name</option>
                      <option value="email" selected={state.filterType==='email' ? true :false}>Email</option>
                      <option value="mobile" selected={state.filterType==='mobile' ? true :false}>Mobile</option>
                  </select>
                  </div>
                  <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-2">
                  <select className="ms-2 form-control" id="operator" onChange={(e) => handleData(e)} style={{borderColor: operatorError ? "red" : "", minWidth:"150px"}}>
                    <option value="" selected={state.filterType==='' ? true :false}>Select</option>
                    <option value={"equals"} selected={state.operator==='equals' ? true :false}>Equals</option>
                    <option value={"contains"} selected={state.operator==='contains' ? true :false}>Contains</option>

                  </select>
                  </div>
                  <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-2">
            <input
              type="text"
              id="searchKeywordId"
              className="ms-2 form-control"
              placeholder="Name, Email, and Mobile"
              value={state?.searchKeyword}
              style={{borderColor: keywordError ? "red" : ""}}
              onChange={(evt) => {
                const { value } = evt.currentTarget;
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                  setState((prevState) => ({
                    ...prevState,
                    searchKeyword: (value || "").trim(),
                  }));
                });
              }}
            />
            </div>
          <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-2">
            <Checkbox
              label="Search Globally"
              value={state?.shouldSearchGlobally}
              checked={state?.shouldSearchGlobally}
              className="ms-4 searchGloballyText"
              onChange={(evt) => {
                setState((prevState) => ({
                  ...prevState,
                  shouldSearchGlobally: !prevState.shouldSearchGlobally,
                }));
              }}
            />
            </div>

          <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-2">
          <CButton
              color="primary"
              className="ms-1"
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  page: 0,
                }));
                document.getElementById("searchKeywordId").style.borderColor = ""
                document.getElementById("filterType").style.borderColor = ""
                document.getElementById("operator").style.borderColor = ""
                setKeywordError()
                setOperatorError()
                setFilterTypeError()
                if((state.searchKeyword && state.operator && state.filterType) || state.shouldSearchGlobally){
                  handleUrls()
                  fetchSellers(
                    state.page,
                    state.recPerPage,
                    state.searchKeyword,
                    state.operator,
                    state.filterType,
                    state.shouldSearchGlobally
                  );
                }else{

                  if(!state.searchKeyword){
                    document.getElementById("searchKeywordId").style.borderColor = "red"
                    setKeywordError("Required")
                  }
                  if(!state.operator){
                    document.getElementById("operator").style.borderColor = "red"
                    setOperatorError("Required")
                  }
                  if(!state.filterType){
                    document.getElementById("filterType").style.borderColor = "red"
                    setFilterTypeError("Required")
                  }
                }
              }}
              // onClick={() => filterData()}
            >
              <FontAwesomeIcon icon={faSearch} style={{ cursor: "pointer" }} />
            </CButton>
            </div>
            {(state.shouldSearchGlobally || state.searchKeyword && state.operator && state.filterType) &&
              <>
              <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-2">
              <CButton
                color="danger"
                className="ms-5"
                onClick={()=>resetFilter()}
                // onClick={() => filterData()}
              >
                Clear
              </CButton>
              </div>
              </>
            }

        </form>
            </div>

          </CCol>
        </CRow>
      </CHeader>
      <CRow>
        <CCol lg={12}>
          {!!state.entries?.length && (
            <Pagination handlePagination={handlePagination} state={state} />
          )}
          {!state.loading && !state.entries?.length ? (
            <>
              <div className="d-flex flex-column align-items-center">
                <h4>No Seller(s) found</h4>
              </div>
            </>
          ) : (
            state.entries.map((entry, i) => (
              <Seller
                key={"sellerList"+i}
                entry={entry}
                section={entry.currentStatus}
                matchParamType={type}
                onDeleteAction={() =>fetchSellers() }
                onStatusChange={(updatedStatus = {}, id, action, entryName, callback = () => {} ) => {
                  updateStatus(updatedStatus,
                    id,
                    action,
                    entryName,
                    callback)
                  // setState((prevState) => ({
                  //   ...prevState,
                  //   confirmDialog: {
                  //     updatedStatus,
                  //     id,
                  //     action,
                  //     entryName,
                  //     callback,
                  //   },
                  // }));
                }}
              />
            ))
          )}

          {!!state.entries?.length && (
            <Pagination handlePagination={handlePagination} state={state} />
          )}
        </CCol>
      </CRow>

      <CModal alignment="center" show={!!state.confirmDialog}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            confirmDialog: null,
          }));
        }}
      >
        <CModalHeader>
          <CModalTitle>{`${state.confirmDialog?.action} - ${state.confirmDialog?.entryName}`}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>Are you sure you want to continue?</div>
          {(state?.confirmDialog?.updatedStatus?.value === "rejected" ||
            state?.confirmDialog?.updatedStatus?.value === "blocked") && (
            <>
              {" "}
              <CFormTextarea
                size="lg"
                value={state.confirmDialog?.remarks || ""}
                className="mt-3 mb-3"
                maxLength={255}
                placeholder="Reason"
                onChange={(evt) => {
                  const { value } = evt.currentTarget;
                  setState((prevState) => ({
                    ...prevState,
                    confirmDialog: {
                      ...prevState.confirmDialog,
                      remarks: value || "",
                    },
                  }));
                }}
              />
              <CFormText color="link" className="help-block mb-1 validation">
                {state.confirmDialog?.remarks?.length > 254 &&
                  "Max length: 255"}
              </CFormText>
            </>
          )}
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                confirmDialog: null,
              }));
            }}
          >
            Cancel
          </CButton>
          {state.confirmDialog?.remarks &&
            state?.confirmDialog?.updatedStatus?.value === "rejected" && (
              <CButton
                color="primary"
                onClick={() => {
                  updateStatus(
                    state.confirmDialog?.updatedStatus,
                    state.confirmDialog?.id,
                    state.confirmDialog?.remarks
                  );
                  state.confirmDialog.callback();
                  setState((prevState) => ({
                    ...prevState,
                    confirmDialog: null,
                  }));
                }}
              >
                Ok
              </CButton>
            )}
          {state?.confirmDialog?.updatedStatus?.value !== "rejected" && (
            <CButton
              color="primary"
              onClick={() => {
                updateStatus(
                  state.confirmDialog?.updatedStatus,
                  state.confirmDialog?.id,
                  state.confirmDialog?.remarks
                );
                state.confirmDialog.callback();
                setState((prevState) => ({
                  ...prevState,
                  confirmDialog: null,
                }));
              }}
            >
              Ok
            </CButton>
          )}
        </CModalFooter>
      </CModal>
    </>
  );
};

export default Sellers;
