import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "./user.css";
import moment from "moment";
import {
  CCol,
  CRow,
  CPagination,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CCard,
  CCardBody,
} from "@coreui/react";
import { Button, Table, Form, Checkbox } from "semantic-ui-react";
import { deleteAPI, getApiCall } from "../../apiServices";
import { postAPI } from "./../../apiServices";
import UserAdd from "./UserAdd";
const defaultState = {
  entries: [],
  totalEntries: 0,
  page: 0,
  recPerPage: 50,
  searchKeyword: "",
  loading: true,
  slugType: "category",
  confirmDialog: null,
  users: null,
  userIds: [],
};
const localPermits = JSON.parse(localStorage.getItem("module"));
const UserManagement = () => {
  const [visible, setVisible] = useState(false);
  const [state, setState] = useState(defaultState);
  const [userList, setuserList] = useState([]);
  const [userRoleId, setuserRoleId] = useState([]);
  const [userStatus, setuserStatus] = useState(null);
  const [userData, setuserData] = useState("");
  const [showConfirm, setshowConfirm] = useState(false);
  const [userId, setuserId] = useState(null);
  const [userName, setuserName] = useState(null);
  const [permissions, setPermissions] = useState(
    localPermits.find((o) => o.moduleId == 40)
  );
  const [roleAssign, setRoleAssign] = useState(
    permissions?.childItems.find((o) => o.moduleId == 44)
  );
  const getUserData = async () => {
    const { status, payload } = await getApiCall("/users/list");
    if (status) {
      setuserList(payload);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const editCategoryGrids = (user) => {
    setuserData(user);
    let newList = [];
    user?.roles?.forEach((item) => {
      newList.push(item.roleId);
    });
    setuserRoleId(newList);
    setuserStatus(user?.status.toString());
    setVisible(true);
  };

  const addUserGrids = () => {
    setuserData("");
    setuserStatus("");
    setVisible(true);
  };

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };
  const bindData = (data) => {
    var list = "";
    data.forEach((item) => {
      list += item.roleName + ",";
    });
    return list;
  };
  const onclose = () => {
    setVisible(false);
  };
  const handleDeleteConfirm = async () => {
    const { status, message } = await deleteAPI(`/users/delete/${userId}`);
    if (status) {
      getUserData();
      toast.info(message);
    }
    setshowConfirm(false);
  };
  return (
    <React.Fragment>
      <CRow>
        <CCol lg={12}>
          <CRow>
            <CCol lg={9}>
              <div style={{ width: 200, marginBottom: 10 }}>
                <Form>
                  <Form.Field></Form.Field>
                </Form>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="d-flex align-items-center justify-content-between w-100">
                <div className="d-flex w-50"></div>
                <div className="d-flex " style={{ marginBottom: 10 }}>
                  <CButton
                    disabled={roleAssign.isAdd === 0 ? true : false}
                    color="primary"
                    className="ms-1 me-1"
                    onClick={() => addUserGrids()}
                  >
                    Add User
                  </CButton>
                </div>
              </div>
            </CCol>
          </CRow>
          {!state.loading && !state.entries.length ? (
            <div className="d-flex flex-column align-items-center"></div>
          ) : (
            <>
              <CCard>
                <CCardBody>
                  <Table basic="very">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell style={{ border: 0 }}>
                          S.No
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Employee ID
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Full Name
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Role
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Status
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Ceated
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Email
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          <div className="d-flex align-items-center justify-content-center">
                            Action
                          </div>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {userList?.map((user, index) => (
                        <Table.Row key={index}>
                          <Table.Cell>{index + 1 + "."}</Table.Cell>
                          <Table.Cell>{`${user.employeeID}`}</Table.Cell>
                          <Table.Cell>{`${user.firstName} ${user.lastName}`}</Table.Cell>
                          {user?.roles?.length > 0 ? (
                            <React.Fragment>
                              <Table.Cell>{bindData(user?.roles)}</Table.Cell>
                            </React.Fragment>
                          ) : (
                            <Table.Cell>NA</Table.Cell>
                          )}
                          {user.status === 1 ? (
                            <Table.Cell positive>Active</Table.Cell>
                          ) : (
                            <Table.Cell negative>InActive</Table.Cell>
                          )}
                          <Table.Cell>
                            {moment(new Date(user.createdOn * 1000)).format(
                              "DD MMM yyyy"
                            )}
                          </Table.Cell>
                          <Table.Cell>{user.userEmail}</Table.Cell>

                          <Table.Cell>
                            <div style={{ paddingRight: 0, marginRight: -90 }}>
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                disabled={roleAssign.isTrash === 0 ? true : false}
                                style={{ cursor: "pointer", color: `${roleAssign.isTrash === 0 ? 'lightgrey':''}` }}
                                color="primary"

                                className={roleAssign.isTrash === 0 ? "me-4" : "me-4 text-danger"}
                                onClick={() => {
                                  if(roleAssign.isTrash === 1){
                                    setshowConfirm(true);
                                    setuserId(user?.userId);
                                    setuserName(
                                      `${user.firstName} ${user.lastName}`
                                    );
                                  }

                                }}
                              />
                              <FontAwesomeIcon
                                icon={faPencilAlt}
                                style={{ cursor: "pointer", color: `${roleAssign.isEdit === 0 ? '#ccc':''}` }}
                                disabled={roleAssign.isEdit === 0 ? true : false}
                                color="primary"
                                className={roleAssign.isEdit === 0 ? "me-4" : "me-4 text-danger"}
                                onClick={() => {
                                  if(roleAssign.isEdit === 1){
                                    editCategoryGrids(user)
                                  }
                                }}
                              />
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </CCardBody>
              </CCard>
              {!!state.entries.length && (
                <div className="d-flex align-items-center justify-content-between">
                  <p>{`Showing ${state.recPerPage * state.page + 1} to ${
                    state.recPerPage * state.page + state.entries.length
                  } of ${state.totalEntries} record(s)`}</p>
                  <CPagination
                    activePage={state.page + 1}
                    pages={Math.ceil(state.totalEntries / state.recPerPage)}
                    onActivePageChange={(page) => {
                      setState((prevState) => ({
                        ...prevState,
                        page: page ? page - 1 : page,
                      }));
                    }}
                  />
                </div>
              )}
            </>
          )}
        </CCol>
      </CRow>
      <CModal
        alignment="center"
        show={showConfirm}
        onClosed={() => {
          setState((prevState) => ({
            ...prevState,
            confirmDialog: null,
          }));
        }}
      >
        <CModalHeader>
          <CModalTitle>{`Delete User ${userName}`}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>{`Are you sure you want to continue?`}</div>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() => {
              setshowConfirm(false);
            }}
          >
            Cancel
          </CButton>
          <CButton
            color="primary"
            onClick={() => {
              handleDeleteConfirm();
            }}
          >
            Delete
          </CButton>
        </CModalFooter>
      </CModal>
      {visible && (
        <UserAdd
          visible={visible}
          onClose={onclose}
          userdata={userData}
          userRoleId={userRoleId}
          userstatus={userStatus}
          getUserData={getUserData}
        />
      )}
    </React.Fragment>
  );
};

export default UserManagement;
