import React, { useState, useEffect, useCallback, useContext } from "react";
import NoDetails from "./NoDetails";
import {
  CCard,
  CCardBody,
  CCardText,
  CContainer,
  CButton,
  CRow,
  CCol,
} from "@coreui/react";
import { Form } from "semantic-ui-react";
import { postAPI } from "../../../apiServices";

const TYPE = [
  { label: "Unregistered", value: 0 },
  { label: "Registered", value: 1 },
];

const DOCUMENT_TYPE = [
  { label: "Unknown", value: 0 },
  { label: "Ownership", value: 1 },
  { label: "Distributorship", value: 2 },
];
const localPermits = JSON.parse(localStorage.getItem("module"));

const Commercials = ({ details = {}, isLoading = false }) => {
  const [margin, setMargin] = useState(details.showroomMarginInPercent);
  const [userId, setUserId] = useState(details.userId || "");
  const [permissions, setPermissions] = useState(
    localPermits.find((o) => o.moduleId == 9)
  );

  useEffect(() => {
    if (details.userId) {
      setMargin(details.showroomMarginInPercent);
      setUserId(details.userId || "");
    }
    async function fetchData() {}
    fetchData();
  }, []);

  const handleChange = (e) => {
    let value = e.target.value;
    let id = e.target.id;
    switch (id) {
      case "margin":
        details.showroomMarginInPercent = value;
        setMargin(value);
        break;
    }
  };

  const updateMargin = async () => {
    if (!details.userId || !margin) {
      return;
    }
    const { status, payload, message, error } = await postAPI(
      "/update-seller-commercials",
      {
        id: details.userId,
        type: "showroom-margin",
        showroomMargin: margin,
      }
    );
    alert(message);
  };
  return (
    <>
      <b>Margin (%)</b>
      <div className="d-flex">
        <Form>
          <Form.Field>
            <input
              disabled={permissions.isEdit === 0 ? true : false}
              type="number"
              className="inputfile"
              onChange={(e) => handleChange(e)}
              value={details.showroomMarginInPercent}
              placeholder="Margin"
            />
          </Form.Field>
        </Form>
        <CButton
          disabled={permissions.isEdit === 0 ? true : false}
          color="primary"
          className="ms-1 me-1"
          onClick={() => updateMargin()}
        >
          Update
        </CButton>
      </div>
    </>
  );
};

export default Commercials;
